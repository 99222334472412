import { MriSM } from "src/assets/icon";
import styles from "./dashboard.module.scss";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBreadCrumbs, setIcons } from "src/store/slice/breadCrumbSlice";

export default function Dashboard() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadCrumbs([]));
    dispatch(setIcons([]));
  }, []);

  return (
    <main style={{ height: "600px" }}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <MriSM width={514} height={120} />
        </div>
      </div>
    </main>
  );
}
