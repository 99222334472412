import React, { useState, useCallback, useRef } from "react";
import { EndPoints } from "src/constants/endpoint";
import debounce from "lodash.debounce";
import { AutoComplete, AutoCompleteSelectEvent } from "primereact/autocomplete";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { advanceSearch, noDataFound } from "src/constants/constant";
import { httpPost } from "src/services/api";
import SearchResultTemplate from "src/components/shared/globalSearch/searchResultTemplate";
import { handleItemClick } from "src/utils/advanceSearchHelper";
import styles from "./searchInputAuto.module.scss";

interface AutoCompleteSearchProps {
  placeholder?: string;
}

export const AutoCompleteSearch: React.FC<AutoCompleteSearchProps> = ({ placeholder = "Search" }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [result, setResult] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const autoCompleteRef = useRef<AutoComplete>(null);

  const handleSubmit = (event: any) => {
    event?.originalEvent?.preventDefault();
    if (event?.query?.trim().length !== 0) {
      delayedSearch(event?.query);
    }
  };

  const handleSearch = useCallback(async (searchTerm: string, page: number, lazyloading?: boolean) => {
    if (searchTerm?.trim().length === 0) {
      return;
    }
    try {
      setLoading(true);
      let searchValue = `{ "searchQuery" : "${searchTerm}" }`;
      let response: any = await httpPost(EndPoints.search + `?pageNo=${page}&pageSize=100`, JSON.stringify(searchValue));
      const formattedResults = response.data;

      const index = formattedResults?.findIndex((item: any) => item.name === advanceSearch.corporation);
      if (index !== -1) {
        const ownersCorporationObject = formattedResults.splice(index, 1)[0];
        formattedResults.unshift(ownersCorporationObject);
      }

      if (response?.data.length > 0) {
        if (lazyloading) {
          setResult((prevResults) => {
            formattedResults.forEach((formattedResult: any) => {
              const existingGroup = prevResults?.find((group) => group.name === formattedResult.name);

              if (existingGroup) {
                const existingItem = existingGroup.items.find((item: any) => item.id === formattedResult.items[0].id);

                if (!existingItem) {
                  existingGroup.items.push(...formattedResult.items);
                }
              } else {
                prevResults.push(formattedResult);
              }
            });
            return [...prevResults];
          });
        } else {
          setResult(formattedResults);
        }
      } else {
        if (!lazyloading) {
          setResult([]);
        }
      }
    } catch (error) {
      setResult([]);
    } finally {
      setLoading(false);
    }
  }, []);

  const delayedSearch = useCallback(
    debounce((value: string) => {
      handleSearch(value, 1, false);
    }, 500),
    []
  );

  const handleChange = (e: any) => {
    if (e.originalEvent.type === "change") {
      const { value } = e.target;
      setSearchTerm(value);
      delayedSearch(value);
    }
  };

  const handleFocus = () => {
    if (result?.length > 0 && !loading) {
      if (autoCompleteRef.current) {
        autoCompleteRef.current.show();
      }
    }
  };

  const groupedItemTemplate = (group: any) => {
    return (
      <div className="flex align-items-center" style={{ background: "#D1D5D9" }}>
        <div className="flex items-center p-2 h-8" style={{ color: "#737980" }}>
          {group?.name}
        </div>
      </div>
    );
  };

  return (
    <div
      className="flex items-center border rounded-md"
      style={{
        background: "#ffffff",
        borderStyle: "solid",
        borderColor: "#ced4da",
      }}
    >
      <AutoComplete
        name="globalSearch"
        data-testid="search-input"
        field="name"
        placeholder={placeholder}
        value={searchTerm}
        suggestions={result}
        ref={autoCompleteRef}
        onFocus={handleFocus}
        optionGroupChildren="items"
        optionGroupTemplate={groupedItemTemplate}
        itemTemplate={(item) => <SearchResultTemplate item={item} searchTerm={searchTerm} setSearchTerm={setSearchTerm} autoCompleteRef={autoCompleteRef} />}
        optionGroupLabel="name"
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSearch(searchTerm, 1, false);
          }
        }}
        onSelect={(e: AutoCompleteSelectEvent) => {
          const fields = JSON.parse(e?.value?.name);
          const id =
          e?.value?.type === advanceSearch.corporation
            ? fields["PlanNumber"]
            : e?.value?.type === advanceSearch.owner || e?.value?.type === advanceSearch.committee || e?.value?.type === advanceSearch.tenant
            ? fields["Lot"]
            : e?.value?.type === advanceSearch.buildingManager
            ? fields["Plan"]
            : 0;
          handleItemClick({route: e?.value?.route, type: e?.value?.type, name: id, dispatch, navigate, autoCompleteRef, setSearchTerm});
        }}
        onChange={handleChange}
        completeMethod={handleSubmit}
        emptyMessage={noDataFound}
        showEmptyMessage={result.length > 0 || loading ? false : true}
        scrollHeight="400px"
        inputStyle={{
          padding: "9px",
          border: "None",
          outline: "none",
          boxShadow: "none",
          width: "100%"
        }}
        panelClassName=""
        panelStyle={{
          height: "400px",
          overflow: "auto",
          maxWidth: "500px",
        }}
        // virtualScrollerOptions={{
        //   onScroll(event) {
        //     const target = event.currentTarget;

        //     if (
        //       target.scrollTop + target.clientHeight >=
        //       target.scrollHeight - 100
        //     ) {
        //       if (!loading) {
        //         setPageNo((prevPageNo) => prevPageNo + 1);
        //         handleSearch(searchTerm, pageNo + 1, true);
        //       }
        //     }
        //   },
        // }}
        pt={{
          root: {className: `${styles.inputWidth}`},
          itemGroup: { className: "!h-11" },
          item: { className: "!h-auto !py-0" },
        }}
      />
    </div>
  );
};
