'use client'
import React, { useRef } from 'react'
import { httpUpdate } from 'src/services/api'
import { SpinnerHorizontal } from 'src/components/ui/loading/spinnerHorizontal';
import { ConvertPlainTextToRTF, ConvertRTFToPlainText } from 'src/utils/utility';
import { useEffect, useReducer } from 'react';
import { EditNotes } from './editNotes';
import styles from './editNotes.module.scss';
import { EndPoints } from 'src//constants/endpoint';

export const CorpNotes : React.FC<{isEditable?: boolean, corpId: any, data?: any, error?: any, updateUrl?: string, setNotes?: any, bodyKey?: string, isConvertToRTF?: boolean}> = ({isEditable=false, corpId, data, error, updateUrl, setNotes, bodyKey= 'note', isConvertToRTF = true}) =>{
  
  const sepratedNotes: {current: string[];} = useRef([]);
  
  function noteReducer(notes: any, action: any){
    switch(action.type){
      case 'LOAD':
        return action.payload ;
      case 'ADD':
        return [...notes, action.payload]
      case 'UPDATE':
        const newNotes = [...notes];
        newNotes.splice(action.payload.index, 1, action.payload.value);
        return newNotes
      case 'DELETE':
        return notes.filter((x:any,i:any) => i !== action.payload)
      case 'API':
        UpdateNote(notes, corpId);
        return notes
      default :
        return notes
    }
  }

  function combineNotes(notes:any){
    var newNotes = [...notes];
    var combineNote = newNotes.join('\r\n');
    return isConvertToRTF ? ConvertPlainTextToRTF(combineNote) : combineNote;
  }
  
  async function UpdateNote(notes:any, corpId:any){
    var updatedNote = combineNotes(notes);
    var url = updateUrl ? updateUrl : EndPoints.corporation + `${corpId}/notes`;
    const output = await httpUpdate(url,{ [bodyKey]:updatedNote });
    if (output?.status === 200) {
      if(setNotes)
      {
        setNotes(updatedNote);
      }
    }
  }
  
  const[notes, dispatch] = useReducer(noteReducer, sepratedNotes.current);

  useEffect(()=>{
    if(data?.note){
      var note = ConvertRTFToPlainText(data.note);
      if(note !== "")
      {
        sepratedNotes.current = note.split('\r\n');
        sepratedNotes.current = sepratedNotes.current.filter(x => x.trim() !== "");
        dispatch({type:'LOAD', payload: sepratedNotes.current});
      }
    }
	}, [data]);

  if (error) {
    return (
      <>
        <div className="text-red-500 m-4">Something went wrong!</div>
      </>
    )
  }
  if (!data) {
    return (
      <>
        <div className='flex w-full min-h-full items-center justify-center'>
          <div className='w-8 h-8'>
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    )
  }
  return (
    <div className = {`h-auto cusrsor-pointer ${styles.scrollMax}`}>
      {
        isEditable ?
        <EditNotes notes={notes} dispatch={dispatch}/>
      :
        notes?.length > 0 &&
        [...notes].reverse().map((note:any,index:any) =>{
        return(
                <div key={note+"_"+index} className="flex justify-between border-b text-sm p-0.5">
                  <label className='flex basis-full sm:px-3 py-2 text-left text-sm font-normal '>{note}</label>
                </div>                                        
              )
        })
      }
    </div>
  )
}


