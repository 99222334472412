import { Control } from "react-hook-form";
import { Card } from "src/components/ui/card/card";
import { SMDatePicker } from "src/components/ui/datePicker/datePicker";
import { Labels } from "src/constants/labels";
import styles from "../../quickReports.module.scss";

const RelevantDates = ({ control }: { control: Control<any, any> }) => {
  return (
    <Card>
      <div className="flex flex-col gap-4">
        <div className={`flex gap-4`}>
          <label className={`w-full border-b pt-2  font-semibold pb-4 ${styles.label}`}>{Labels.RELEVANT_DATES}</label>
        </div>
        <div className="flex items-center gap-2">
          <label className={`w-1/4 ${styles.label}`}>{Labels.FIRST_AGM}</label>
          <div className="flex items-center gap-1">
            <SMDatePicker inputDate={""} control={control} name="levyYearFrom" width="w-full" />
            <label className={`${styles.label}`}>{Labels.TO}</label>
            <SMDatePicker inputDate={""} control={control} name="levyYearTo" width="w-full" />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <label className={`w-1/4 ${styles.label}`}>{Labels.YEAR_END_DATE}</label>
          <div className="flex items-center gap-1">
            <SMDatePicker inputDate={""} control={control} name="levyYearFrom" width="w-full" />
            <label className={`${styles.label}`}>{Labels.TO}</label>
            <SMDatePicker inputDate={""} control={control} name="levyYearTo" width="w-full" />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <label className={`w-1/4 ${styles.label}`}>{Labels.TAX_YEAR_END}</label>
          <div className="flex items-center gap-1">
            <SMDatePicker inputDate={""} control={control} name="levyYearFrom" width="w-full" />
            <label className={`${styles.label}`}>{Labels.TO}</label>
            <SMDatePicker inputDate={""} control={control} name="levyYearTo" width="w-full" />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <label className={`w-1/4 ${styles.label}`}>{Labels.LAST_LEVY_NOTICE}</label>
          <div className="flex items-center gap-1">
            <SMDatePicker inputDate={""} control={control} name="levyYearFrom" width="w-full" />
            <label className={`${styles.label}`}>{Labels.TO}</label>
            <SMDatePicker inputDate={""} control={control} name="levyYearTo" width="w-full" />
          </div>
        </div>
        <div className="flex items-center gap-2">
          <label className={`w-1/4 ${styles.label}`}>{Labels.VALUATION}</label>
          <div className="flex items-center gap-1">
            <SMDatePicker inputDate={""} control={control} name="levyYearFrom" width="w-full" />
            <label className={`${styles.label}`}>{Labels.TO}</label>
            <SMDatePicker inputDate={""} control={control} name="levyYearTo" width="w-full" />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default RelevantDates;
