import React, { useEffect, useMemo, useState } from "react";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import { checkZeroValue, getFromUnitLot, mailToSend } from "src/utils/utility";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";
import { Pencil, Star } from "src/assets/icon";
import { Tooltip } from "primereact/tooltip";
import { Drawer } from "src/components/ui/drawer/drawer";
import CommitteeTableDrawer from "./committeeTableDrawer";
import { ICommittee, ICommitteeData } from "src/interfaces";
import { setCommitteeList, setSelectedCommittee, updateCommitteeList } from "src/store/slice/committeeSlice";
import { useDispatch } from "react-redux";
import { NONE, httpCode, toastErrorTimeLimit, toastSuccessTimeLimit } from "src/constants/constant";
import { IconYesNoNull } from "src/components/ui/iconYesNoNull";
import FormModal from "src/components/ui/dialog/formModal";
import { IContact } from "src/interfaces/ownerContact";
import { IAdditionalContact } from "src/interfaces/additionalContact";
import ContactForm from "src/pages/lot/owner/shared/contact";
import { httpPost } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { useParams } from "react-router-dom";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { ILotInterface, ILots } from "src/interfaces/lotList";

export const CommitteeTable: React.FC<{ data: ICommitteeData; lotData: ILots; toast: any; showSidebar: boolean; setShowSidebar: any }> = ({
  data,
  lotData,
  toast,
  showSidebar,
  setShowSidebar,
}) => {
  const { agency } = useSelector((state: RootState) => state?.agencySettingSlice);
  const { committeeList, selectedCommittee } = useSelector((state: RootState) => state?.committeeSlice);
  const { loggedInUserInfo } = useSelector((state: RootState) => state?.userSlice);
  const { plaNumber } = useSelector((state: RootState) => state?.planDetail);
  const { corpId } = useParams();
  const [showContactCard, setShowContactCard] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const formId = "updateCommitteeContact";
  const columns = useMemo(() => {
    return [
      {
        field: "fromUnitLot",
        header: `FROM ${Labels.UNIT?.substring?.(0, 4)} & ${Labels.LOT}`,
        body: (data: any) => {
          return <p>{data?.fromUnitLot}</p>;
        },
        width: "8rem",
        sortable: true,
      },
      {
        field: "executivePositionName",
        header: Labels.POSITION,
        sortable: true,
      },
      {
        field: "name",
        header: Labels.NAME,
        body: (data: any) => {
          return (
            <div
              className="text !font-bold hyperlink cursor-pointer"
              onClick={() => {
                dispatch(setSelectedCommittee(data));
                setShowContactCard(true);
              }}
            >
              {data?.name}
            </div>
          );
        },
        width: "8rem",
        sortable: true,
      },
      {
        field: "nominatedBy",
        header: Labels.NOMINATED_BY,
        body: (data: any) => {
          return <p>{data?.nominatedBy}</p>;
        },
        sortable: true,
      },
      {
        field: "receiveReports",
        header: Labels.REPORTS,
        body: (data: any) => {
          return <IconYesNoNull type={data?.receiveReports} />;
        },
        sortable: true,
      },
      {
        field: "interimDeliveryMethod",
        header: Labels.METHOD,
        body: (data: any) => {
          return (
            <p>
              {data?.interimDeliveryMethod === Labels.EMAIL_TENANT_NOTICE_ISSUES
                ? Labels.EMAIL_RADIOBUTTON_TEXT
                : data?.interimDeliveryMethod === Labels.PRINT_TENANT_NOTICE_ISSUES
                ? Labels.PRINT_RADIOBUTTON_TEXT
                : ""}
            </p>
          );
        },
        sortable: true,
      },
      {
        field: "onlineInvoiceApprover",
        header: Labels.APPROVER,
        body: (data: any) => {
          return <IconYesNoNull type={data?.onlineInvoiceApprover} />;
        },
        hidden: !data?.onlineInvoiceApprovalData?.onlineInvoiceApprovalActivated,
        sortable: true,
      },
      {
        field: "address",
        header: Labels.ADDRESS_1,
        body: (data: any) => {
          return (
            <p>
              {!data?.poBox && data?.buildingName} {!data?.poBox && checkZeroValue(data?.streetNumber)} {!data?.poBox && data?.streetName} {data?.poBox}{" "}
              {data?.town} {data?.state} {data?.postcode} {data?.country}
            </p>
          );
        },
        width: "10rem",
        sortable: true,
      },
      {
        field: "telephone3",
        header: Labels.MOBILE?.substring?.(0, 6)?.toUpperCase?.(),
        body: (data: any) => {
          return (
            <a href={`tel:${data?.telephone3}`} className="hyperlink">
              {data?.telephone3}
            </a>
          );
        },
        sortable: true,
      },
      {
        field: "committeeEmailAddress",
        header: Labels.EMAIL?.substring?.(0, 5)?.toUpperCase?.(),
        body: (data: any) => {
          return (
            <a
              className="hyperlink"
              onClick={() => {
                if (!data?.committeeEmailAddress && !data?.email) {
                  toast?.current?.show({
                    severity: "warn",
                    summary: "Warning",
                    detail: Messages.NO_EMAIL_ADDRESSES,
                    life: 2000,
                  });
                } else {
                  mailToSend([data], toast, agency?.useAdditionalEmailAddresses, agency?.additionalEmailAddressOptions, false);
                }
              }}
            >
              {Boolean(data?.committeeEmailAddress) ? data?.committeeEmailAddress : data?.email}
            </a>
          );
        },
        sortable: true,
      },
      {
        field: "telephone1",
        header: Labels.HOME_PHONE?.substring?.(0, 10)?.toUpperCase?.(),
        body: (data: any) => {
          return (
            <a href={`tel:${data?.telephone1}`} className="hyperlink">
              {data?.telephone1}
            </a>
          );
        },
        width: "6rem",
        sortable: true,
      },
      {
        field: "telephone2",
        header: Labels.WORK_PHONE?.substring?.(0, 10)?.toUpperCase?.(),
        body: (data: any) => {
          return (
            <a href={`tel:${data?.telephone2}`} className="hyperlink">
              {data?.telephone2}
            </a>
          );
        },
        width: "6rem",
        sortable: true,
      },
      {
        field: "edit",
        header: "",
        body: (data: any) => {
          return (
            <div className="flex gap-3 justify-end">
              <span
                onClick={() => {
                  markAsImportantContact(data);
                }}
              >
                <Star height={18} width={18} fill={data?.isImportantContact ? "#FFB433" : "none"} stroke={data?.isImportantContact ? "" : "#4B5563"} />
              </span>
              <span
                onClick={() => {
                  setShowSidebar(true);
                  dispatch(setSelectedCommittee(data));
                }}
              >
                <Tooltip target="#edit" content={Labels.EDIT_COMMITTEE_DETAILS} position="bottom"></Tooltip>
                <Pencil id="edit" />
              </span>
            </div>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(
      setCommitteeList(
        data?.committeeList?.map((data: ICommittee) => {
          return {
            ...data,
            address: data?.poBox
              ? `${data?.poBox} ${data?.town} ${data?.state} ${data?.postcode} ${data?.country}`
              : `${data?.buildingName} ${data?.streetNumber ?? 0} ${data?.streetName} ${data?.town} ${data?.state} ${data?.postcode} ${data?.country}`,
            method:
              data?.interimDeliveryMethod === Labels.EMAIL_TENANT_NOTICE_ISSUES
                ? Labels.EMAIL_RADIOBUTTON_TEXT
                : data?.interimDeliveryMethod === Labels.PRINT_TENANT_NOTICE_ISSUES
                ? Labels.PRINT_RADIOBUTTON_TEXT
                : "",
            fromUnitLot: data?.lotId ? getFromUnitLot(data) : NONE,
            nominatedBy: data?.nominatedByLotId
              ? getFromUnitLot({
                  unitNumberPrefix: data?.nominatedUnitNumberPrefix,
                  unitNumber: data?.nominatedUnitNumber,
                  unitNumberSuffix: data?.nominatedUnitNumberSuffix,
                  lotNumberprefix: data?.nominatedLotNumberPrefix,
                  lotNumber: data?.nominatedLotNumber,
                  lotNumberSuffix: data?.nominatedLotNumberSuffix,
                })
              : NONE,
          };
        })
      )
    );
  }, [data]);

  const markAsImportantContact = async (data: ICommittee) => {
    setIsLoading(true);
    let res: any = await httpPost(`${EndPoints.contact}${EndPoints.plan}${corpId}/important-contacts`, {
      contactId: data?.contactId,
      isFavorite: !data?.isImportantContact,
    });
    if (res?.status === httpCode.SUCCESS) {
      toast?.current?.show({ severity: "success", summary: "Success", detail: Messages.SUCCESS_MESSAGE, life: toastSuccessTimeLimit });
      dispatch(updateCommitteeList({ ...data, isImportantContact: !data?.isImportantContact }));
    } else {
      toast?.current?.show({ severity: "error", summary: "Error", detail: Messages.ERROR_MESSAGE, life: toastErrorTimeLimit });
    }
    setIsLoading(false);
  };

  return (
    <div className="w-full pt-10">
      {isLoading && (
        <div className="spinnerContainer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <SMDataTableContainer
        tableData={committeeList}
        tableColumns={columns}
        stateKey={`${loggedInUserInfo?.email}/committeeTable`}
        showEditIcon={false}
        showSelectableRowCheckbox={false}
        enableGlobalSearch
        enableColumnConfig
        sortField="fromUnitLot"
        globalFilterFields={[
          "fromUnitLot",
          "executivePositionName",
          "name",
          "nominatedBy",
          "interimDeliveryMethod",
          "country",
          "postcode",
          "state",
          "town",
          "streetName",
          "streetNumber",
          "buildingName",
          "poBox",
          "telephone3",
          "email",
          "committeeEmailAddress",
          "telephone1",
          "telephone2",
          "address",
          "method",
        ]}
        resizableColumns
      />
      <Drawer
        title={selectedCommittee?.executiveMemberId ? Labels.EDIT_COMMITTEE_DETAILS : Labels.ADD_COMMITTEE_MEMBER}
        isOpen={showSidebar}
        onClose={() => {
          setShowSidebar(false);
        }}
        width="w-[50vw]"
      >
        <CommitteeTableDrawer
          setShowSidebar={setShowSidebar}
          toast={toast}
          onlineInvoiceApproval={data?.onlineInvoiceApprovalData?.onlineInvoiceApprovalActivated}
        />
      </Drawer>
      <FormModal
        width="80vw"
        formId={formId}
        header={`${Labels.EDIT_CONTACT}
        ${
          selectedCommittee?.lotId
            ? `${selectedCommittee?.contactId ? " - " : ""}
        ${lotData?.lotList?.find?.((lot: ILotInterface) => lot?.lLotID === selectedCommittee?.lotId)?.sNameOntitle ?? ""}
        ${"- Lot"}
        ${selectedCommittee?.lotNumber ?? ""}
        ${" - "}
        ${plaNumber ?? ""}`
            : ""
        }
          `}
        isOpen={showContactCard}
        onClose={() => {
          if (isFormDirty) {
            setShowCancelModal(true);
          } else {
            setShowContactCard(false);
          }
        }}
        isDirty={isFormDirty}
      >
        <div className="px-10">
          <ContactForm
            contactId={selectedCommittee?.contactId}
            title=""
            formId={formId}
            contactType={Labels.OWNER_CONTACT_TYPE}
            showCollapsible={false}
            lmcCommunicationChecks={false}
            committeeMember
            showCancelModal={showCancelModal}
            setIsFormDirty={setIsFormDirty}
            setShowCancelModal={setShowCancelModal}
            setEditable={setShowContactCard}
            setData={(data: IContact) => {
              dispatch(
                setCommitteeList(
                  committeeList?.map((committee: ICommittee) => {
                    if (committee?.contactId === data?.contactId) {
                      return {
                        ...committee,
                        buildingName: data?.buildingName,
                        streetNumber: data?.streetNumber,
                        streetName: data?.streetName,
                        poBox: data?.poBox,
                        town: data?.town,
                        state: data?.state,
                        postcode: data?.postcode,
                        country: data?.country,
                        email: data?.email,
                        committeeEmailAddress: data?.committeeEmailAddress,
                        telephone1: data?.telephone1,
                        telephone2: data?.telephone2,
                        telephone3: data?.telephone3,
                      };
                    } else {
                      return { ...committee };
                    }
                  })
                )
              );
            }}
            setAdditionalContactData={(data: Array<IAdditionalContact>) => {
              dispatch(
                setCommitteeList(
                  committeeList?.map((committee: ICommittee) => {
                    if (committee?.contactId === selectedCommittee?.contactId) {
                      return {
                        ...committee,
                        additionalContactDetails: data,
                      };
                    } else {
                      return { ...committee };
                    }
                  })
                )
              );
            }}
            toastRef={toast}
          />
        </div>
      </FormModal>
    </div>
  );
};
