import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
  isDirty: false,
};

const dirtyFormSlice = createSlice({
  name: "dirtyForm",
  initialState,
  reducers: {
    setIsFormDirty: (state, action: PayloadAction<boolean>) => {
      state.isDirty = action.payload;
    },
  },
});

export const { setIsFormDirty } = dirtyFormSlice.actions;
export default dirtyFormSlice.reducer;
