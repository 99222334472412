import React from "react";
import { useHttpGet } from "src/services/api";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { EndPoints } from "src/constants/endpoint";
import { AddressTable } from "./otherAddressesTable";

export const OtherAddresses: React.FC<{ corpId: any }> = ({ corpId }) => {
  const { data, error } = useHttpGet(EndPoints.corporation + `${corpId}/structure/addresses`);

  if (error) {
    return (
      <>
        <div className="text-red-500">Something went wrong!</div>
      </>
    );
  }

  if (!data) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-10 h-10">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }

  return <AddressTable addressData={data} corpId={corpId}/>;
};
