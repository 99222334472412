import { Card } from "src/components/ui/card/card";
import { Labels } from "src/constants/labels";
import styles from "../../../budget.module.scss";
import { checkZeroValue } from "src/utils";
import { NA, currency, locale } from "src/constants/constant";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/store/rootReducer";
import { setShowActualForNonBudgetAccounts } from "src/store/slice/budget";
import { IBudgetDate, IBudgetExpenseRevenue } from "src/interfaces";
import { useMemo } from "react";
import { calculateSurplusDeficit } from "src/utils/formula";

const AdminFundCard = ({ balance, fundType }: { balance: any; fundType?: number }) => {
  const { showActualForNonBudgetAccounts, budgetDates, selectedDate, revenueList, expenseList } = useSelector((state: RootState) => state?.budgetSlice);
  const dispatch = useDispatch();
  let valueToDisplayIfBudgetExists = useMemo(() => {
    let indexOfSelectedBudget = budgetDates?.budgetDates?.findIndex((budget: IBudgetDate) => budget?.budgetId === selectedDate?.budgetId);
    return {
      previous: budgetDates?.budgetDates?.find(
        (budget: IBudgetDate, index: number) => index > indexOfSelectedBudget && budget?.groupCodeId === selectedDate?.groupCodeId
      )?.budgetId
        ? 0
        : NA,
      current: budgetDates?.budgetDates?.length > 0 ? 0 : NA,
      next: budgetDates?.budgetDates?.find(
        (budget: IBudgetDate, index: number) => index < indexOfSelectedBudget && budget?.groupCodeId === selectedDate?.groupCodeId
      )?.budgetId
        ? 0
        : NA,
    };
  }, [selectedDate?.budgetId]);

  let expenseBalance = useMemo(() => {
    return expenseList
      ?.filter?.((expense: IBudgetExpenseRevenue) => (showActualForNonBudgetAccounts ? true : expense?.budgetId))
      ?.reduce?.(
        (accumulator: any, item: any) => {
          if (!showActualForNonBudgetAccounts) {
            return {
              ...accumulator,
              surplusDeficitBudgeted: {
                ...accumulator.surplusDeficitBudgeted,
                prevAdminBudgeted: accumulator?.surplusDeficitBudgeted?.prevAdminBudgeted + item?.previousYear?.budgetedAmount,
                prevAdminActual: accumulator?.surplusDeficitBudgeted?.prevAdminActual + item?.previousYear?.actualAmount,
                adminBudgeted: accumulator?.surplusDeficitBudgeted?.adminBudgeted + item?.thisYear?.budgetedAmount,
                adminActual: accumulator?.surplusDeficitBudgeted?.adminActual + item?.thisYear?.actualAmount,
                nextAdminBudgeted: accumulator?.surplusDeficitBudgeted?.nextAdminBudgeted + item?.nextYear?.budgetedAmount,
              },
            };
          } else {
            return {
              ...accumulator,
              surplusDeficitNonBudgeted: {
                ...accumulator.surplusDeficitNonBudgeted,
                prevAdminBudgeted: accumulator?.surplusDeficitNonBudgeted?.prevAdminBudgeted + item?.previousYear?.budgetedAmount,
                prevAdminActual: accumulator?.surplusDeficitNonBudgeted?.prevAdminActual + item?.previousYear?.actualAmount,
                adminBudgeted: accumulator?.surplusDeficitNonBudgeted?.adminBudgeted + item?.thisYear?.budgetedAmount,
                adminActual: accumulator?.surplusDeficitNonBudgeted?.adminActual + item?.thisYear?.actualAmount,
                nextAdminBudgeted: accumulator?.surplusDeficitNonBudgeted?.nextAdminBudgeted + item?.nextYear?.budgetedAmount,
              },
            };
          }
        },
        {
          surplusDeficitBudgeted: {
            prevAdminBudgeted: 0,
            prevAdminActual: 0,
            adminBudgeted: 0,
            adminActual: 0,
            nextAdminBudgeted: 0,
          },
          surplusDeficitNonBudgeted: {
            prevAdminBudgeted: 0,
            prevAdminActual: 0,
            adminBudgeted: 0,
            adminActual: 0,
            nextAdminBudgeted: 0,
          },
        }
      );
  }, [showActualForNonBudgetAccounts, selectedDate?.budgetId, balance, expenseList]);

  let revenueBalance = useMemo(() => {
    return revenueList
      ?.filter?.((expense: IBudgetExpenseRevenue) => (showActualForNonBudgetAccounts ? true : expense?.budgetId))
      ?.reduce?.(
        (accumulator: any, item: any) => {
          if (!showActualForNonBudgetAccounts) {
            return {
              ...accumulator,
              surplusDeficitBudgeted: {
                ...accumulator.surplusDeficitBudgeted,
                prevAdminBudgeted: accumulator?.surplusDeficitBudgeted?.prevAdminBudgeted + item?.previousYear?.budgetedAmount,
                prevAdminActual: accumulator?.surplusDeficitBudgeted?.prevAdminActual + item?.previousYear?.actualAmount,
                adminBudgeted: accumulator?.surplusDeficitBudgeted?.adminBudgeted + item?.thisYear?.budgetedAmount,
                adminActual: accumulator?.surplusDeficitBudgeted?.adminActual + item?.thisYear?.actualAmount,
                nextAdminBudgeted: accumulator?.surplusDeficitBudgeted?.nextAdminBudgeted + item?.nextYear?.budgetedAmount,
              },
            };
          } else {
            return {
              ...accumulator,
              surplusDeficitNonBudgeted: {
                ...accumulator.surplusDeficitNonBudgeted,
                prevAdminBudgeted: accumulator?.surplusDeficitNonBudgeted?.prevAdminBudgeted + item?.previousYear?.budgetedAmount,
                prevAdminActual: accumulator?.surplusDeficitNonBudgeted?.prevAdminActual + item?.previousYear?.actualAmount,
                adminBudgeted: accumulator?.surplusDeficitNonBudgeted?.adminBudgeted + item?.thisYear?.budgetedAmount,
                adminActual: accumulator?.surplusDeficitNonBudgeted?.adminActual + item?.thisYear?.actualAmount,
                nextAdminBudgeted: accumulator?.surplusDeficitNonBudgeted?.nextAdminBudgeted + item?.nextYear?.budgetedAmount,
              },
            };
          }
        },
        {
          surplusDeficitBudgeted: {
            prevAdminBudgeted: 0,
            prevAdminActual: 0,
            adminBudgeted: 0,
            adminActual: 0,
            nextAdminBudgeted: 0,
          },
          surplusDeficitNonBudgeted: {
            prevAdminBudgeted: 0,
            prevAdminActual: 0,
            adminBudgeted: 0,
            adminActual: 0,
            nextAdminBudgeted: 0,
          },
        }
      );
  }, [showActualForNonBudgetAccounts, selectedDate?.budgetId, balance, revenueList]);

  const convertToCurrency = (value: number, returnValue: number | string = NA) => {
    return !isNaN(value)
      ? checkZeroValue(value, returnValue)?.toLocaleString?.(locale, {
          style: "currency",
          currency: currency,
          minimumFractionDigits: 2,
        })
      : NA;
  };

  return (
    <Card bgColor="bg-gray-100">
      <div>
        <div className={`w-full flex justify-between items-center ${styles.lineBreak}`}>
          <label className="font-semibold text-sm">{fundType === 2 ? Labels.CAPITAL_WORKS_FUND : Labels.ADMINISTRATIVE_FUND}</label>
        </div>
        <div className="flex items-center mt-5">
          <input type="checkbox" checked={showActualForNonBudgetAccounts} onChange={(e) => dispatch(setShowActualForNonBudgetAccounts(e?.target?.checked))} />
          <label className="text-xs ml-2">{Labels.SHOW_ACTUAL_NON_BUDGET_ACCOUNTS}</label>
        </div>
        <div>
          <div className="w-full flex justify-between mt-5">
            <div className="w-1/4"></div>
            <div className={`${styles.container} bg-white ${styles.text} p-3`}>{Labels.LAST_PERIOD}</div>
            <div className={`${styles.container} bg-gray-300 ${styles.text} p-3`}>{Labels.CURRENT_PERIOD}</div>
            <div className={`${styles.container} bg-white ${styles.text} p-3`}>{Labels.NEXT_PERIOD}</div>
          </div>
          <div className="w-full flex justify-between">
            <div className="w-1/4 pt-3 pb-3 border-r-2">
              <label className={`${styles.text}`}>{Labels.SURPLUS_DEFICIT}</label>
            </div>
            <div className={`${styles.container} ${styles.text}`}>
              <div className={`${styles.value} pr-3`}>
                {showActualForNonBudgetAccounts
                  ? convertToCurrency(
                      calculateSurplusDeficit(
                        revenueBalance?.surplusDeficitNonBudgeted?.prevAdminBudgeted,
                        expenseBalance?.surplusDeficitNonBudgeted?.prevAdminBudgeted
                      ),
                      valueToDisplayIfBudgetExists?.previous
                    )
                  : convertToCurrency(
                      calculateSurplusDeficit(
                        revenueBalance?.surplusDeficitBudgeted?.prevAdminBudgeted,
                        expenseBalance?.surplusDeficitBudgeted?.prevAdminBudgeted
                      ),
                      valueToDisplayIfBudgetExists?.previous
                    )}
              </div>
              <div className={`${styles.value} border-r-2 pr-3`}>
                {showActualForNonBudgetAccounts
                  ? convertToCurrency(
                      calculateSurplusDeficit(
                        revenueBalance?.surplusDeficitNonBudgeted?.prevAdminActual,
                        expenseBalance?.surplusDeficitNonBudgeted?.prevAdminActual
                      ),
                      valueToDisplayIfBudgetExists?.previous
                    )
                  : convertToCurrency(
                      calculateSurplusDeficit(revenueBalance?.surplusDeficitBudgeted?.prevAdminActual, expenseBalance?.surplusDeficitBudgeted?.prevAdminActual),
                      valueToDisplayIfBudgetExists?.previous
                    )}
              </div>
            </div>
            <div className={`${styles.container} ${styles.text}`}>
              <div className={`${styles.value} pr-3`}>
                {showActualForNonBudgetAccounts
                  ? convertToCurrency(
                      calculateSurplusDeficit(
                        revenueBalance?.surplusDeficitNonBudgeted?.adminBudgeted,
                        expenseBalance?.surplusDeficitNonBudgeted?.adminBudgeted
                      ),
                      valueToDisplayIfBudgetExists?.current
                    )
                  : convertToCurrency(
                      calculateSurplusDeficit(revenueBalance?.surplusDeficitBudgeted?.adminBudgeted, expenseBalance?.surplusDeficitBudgeted?.adminBudgeted),
                      valueToDisplayIfBudgetExists?.current
                    )}
              </div>
              <div className={`${styles.value} border-r-2 pr-3`}>
                {showActualForNonBudgetAccounts
                  ? convertToCurrency(
                      calculateSurplusDeficit(revenueBalance?.surplusDeficitNonBudgeted?.adminActual, expenseBalance?.surplusDeficitNonBudgeted?.adminActual),
                      valueToDisplayIfBudgetExists?.current
                    )
                  : convertToCurrency(
                      calculateSurplusDeficit(revenueBalance?.surplusDeficitBudgeted?.adminActual, expenseBalance?.surplusDeficitBudgeted?.adminActual),
                      valueToDisplayIfBudgetExists?.current
                    )}
              </div>
            </div>
            <div className={`${styles.container} ${styles.text}`}>
              <div className={`${styles.value} pr-3`}>
                {showActualForNonBudgetAccounts
                  ? convertToCurrency(
                      calculateSurplusDeficit(
                        revenueBalance?.surplusDeficitNonBudgeted?.nextAdminBudgeted,
                        expenseBalance?.surplusDeficitNonBudgeted?.nextAdminBudgeted
                      ),
                      valueToDisplayIfBudgetExists?.next
                    )
                  : convertToCurrency(
                      calculateSurplusDeficit(
                        revenueBalance?.surplusDeficitBudgeted?.nextAdminBudgeted,
                        expenseBalance?.surplusDeficitBudgeted?.nextAdminBudgeted
                      ),
                      valueToDisplayIfBudgetExists?.next
                    )}
              </div>
              <div className={`${styles.value} border-r-2`}></div>
            </div>
          </div>
          <div className="w-full flex justify-between">
            <div className="w-1/4 pt-3 pb-3 border-r-2">
              <label className={`${styles.text}`}>{Labels.OPENING_BALANCE}</label>
            </div>
            <div className={`${styles.container} ${styles.text}`}>
              <div className={`${styles.value} pr-4 ml-2 border-b-2`}>
                {convertToCurrency(balance?.openingBalance?.prevBalance, valueToDisplayIfBudgetExists?.previous)}
              </div>
              <div className="w-1/2 border-r-2"></div>
            </div>
            <div className={`${styles.container} ${styles.text}`}>
              <div className={`${styles.value} pr-4 ml-2 border-b-2`}>
                {convertToCurrency(balance?.openingBalance?.balance, valueToDisplayIfBudgetExists?.current)}
              </div>
              <div className="w-1/2 border-r-2"></div>
            </div>
            <div className={`${styles.container} ${styles.text}`}>
              <div className={`${styles.value} pr-4 ml-2 border-b-2`}>
                {convertToCurrency(balance?.openingBalance?.nextBalance, valueToDisplayIfBudgetExists?.next)}
              </div>
              <div className="w-1/2 border-r-2"></div>
            </div>
          </div>
          <div className="w-full flex justify-between">
            <div className="w-1/4 pt-3 pb-3 border-r-2">
              <label className={`${styles.text}`}>{Labels.CLOSING_BALANCE}</label>
            </div>
            <div className={`${styles.container} ${styles.text}`}>
              <div className={`${styles.value} pr-4 ml-2 border-b-2`}>
                {showActualForNonBudgetAccounts
                  ? convertToCurrency(
                      calculateSurplusDeficit(
                        revenueBalance?.surplusDeficitNonBudgeted?.prevAdminBudgeted,
                        expenseBalance?.surplusDeficitNonBudgeted?.prevAdminBudgeted
                      ) + balance?.openingBalance?.prevBalance,
                      valueToDisplayIfBudgetExists?.previous
                    )
                  : convertToCurrency(
                      calculateSurplusDeficit(
                        revenueBalance?.surplusDeficitBudgeted?.prevAdminBudgeted,
                        expenseBalance?.surplusDeficitBudgeted?.prevAdminBudgeted
                      ) + balance?.openingBalance?.prevBalance,
                      valueToDisplayIfBudgetExists?.previous
                    )}
              </div>
              <div className="w-1/2 border-r-2"></div>
            </div>
            <div className={`${styles.container} ${styles.text}`}>
              <div className={`${styles.value} pr-4 ml-2 border-b-2`}>
                {showActualForNonBudgetAccounts
                  ? convertToCurrency(
                      calculateSurplusDeficit(
                        revenueBalance?.surplusDeficitNonBudgeted?.adminBudgeted,
                        expenseBalance?.surplusDeficitNonBudgeted?.adminBudgeted
                      ) + balance?.openingBalance?.balance,
                      valueToDisplayIfBudgetExists?.current
                    )
                  : convertToCurrency(
                      calculateSurplusDeficit(revenueBalance?.surplusDeficitBudgeted?.adminBudgeted, expenseBalance?.surplusDeficitBudgeted?.adminBudgeted) +
                        balance?.openingBalance?.balance,
                      valueToDisplayIfBudgetExists?.current
                    )}
              </div>
              <div className="w-1/2 border-r-2"></div>
            </div>
            <div className={`${styles.container} ${styles.text}`}>
              <div className={`${styles.value} pr-4 ml-2 border-b-2`}>
                {showActualForNonBudgetAccounts
                  ? convertToCurrency(
                      calculateSurplusDeficit(
                        revenueBalance?.surplusDeficitNonBudgeted?.nextAdminBudgeted,
                        expenseBalance?.surplusDeficitNonBudgeted?.nextAdminBudgeted
                      ) + balance?.openingBalance?.nextBalance,
                      valueToDisplayIfBudgetExists?.next
                    )
                  : convertToCurrency(
                      calculateSurplusDeficit(
                        revenueBalance?.surplusDeficitBudgeted?.nextAdminBudgeted,
                        expenseBalance?.surplusDeficitBudgeted?.nextAdminBudgeted
                      ) + balance?.openingBalance?.nextBalance,
                      valueToDisplayIfBudgetExists?.next
                    )}
              </div>
              <div className="w-1/2 border-r-2"></div>
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};

export default AdminFundCard;
