import React from "react";
import styles from "../committee.module.scss";
import { Labels } from "src/constants/labels";
import { SMDatePicker } from "src/components/ui/datePicker/datePicker";
import { FormatDate } from "src/utils/utility";
import { ICommitteeData } from "src/interfaces";

export const CommitteeDetails: React.FC<{ data: ICommitteeData }> = ({ data }) => {
  let committeeDetails = { ...data?.onlineInvoiceApprovalData };
  let onlineApprovalRequired = committeeDetails?.onlineInvoiceApprovalRequiredForAllInvoices;
  return (
    <div className="w-full flex pt-8 gap-10">
      <div className="w-1/2">
        <div className={`flex flex-row justify-between ${styles.lineBreak}`}>
          <label className="text !text-sm">{Labels.NUMBER_OF_MEMBERS}</label>
          <div className="text !text-sm !font-bold pr-4">{data?.executiveCommitteeSize}</div>
        </div>
        <br />
        <div className="flex flex-row justify-between">
          <label className="text !text-sm">{Labels.LAST_MEETING}</label>
          <SMDatePicker inputDate={FormatDate(data?.lastExecutiveMeeting) ?? ""} disabled width="w-1/2" />
        </div>
      </div>
      {committeeDetails?.onlineInvoiceApprovalActivated && (
        <div className="w-1/2">
          <div className={`flex flex-row justify-between ${styles.lineBreak}`}>
            <label className="text !text-sm">{Labels.NUMBER_OF_APPROVERS_REQUIRED}</label>
            <div className="text !text-sm !font-bold pr-4">{committeeDetails?.onlineInvoiceApproverSize}</div>
          </div>
          <br />
          <div className={`flex flex-row pb-2.5 mt-2 items-center`}>
            <input className="mr-2 rounded-sm" type="checkbox" checked={onlineApprovalRequired} disabled />
            <label className={`text !text-sm pb-0.5 ${!onlineApprovalRequired ? "opacity-40" : ""}`}>{Labels.INVOICE_APPROVAL_REQUIRED_FOR_ALL_INVOICES}</label>
          </div>
          <br />
          <div className={`flex flex-row justify-between ${styles.lineBreak}`}>
            <label className="text !text-sm">{Labels.INVOICE_APPROVAL_REQUIRED_FOR_ALL_INVOICES} over</label>
            <div className="text !text-sm !font-bold pr-4">
              {!onlineApprovalRequired && `$${committeeDetails?.onlineInvoiceApprovalMinimumAmount.toFixed(2)}`}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
