import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { Controller } from "react-hook-form";

interface IDropDown {
  id?: string;
  name?: string;
  rules?: object;
  control?: any;
  placeholder?: string;
  onChange?: any;
  value?: string;
  options: any;
  editable?: boolean;
  optionLabel?: string;
  optionValue?: string;
  maxLength?: number;
  disabled?: boolean;
  filter?: boolean;
  valueTemplate?: React.ReactNode | ((option: any) => React.ReactNode) | undefined;
  showClear?: boolean | undefined;
}

export const SMDropDown: React.FC<IDropDown> = ({
  id,
  name,
  rules,
  control,
  value,
  options,
  placeholder,
  onChange,
  editable = false,
  optionLabel = "name",
  optionValue = "name",
  maxLength,
  disabled = false,
  filter,
  valueTemplate,
  showClear = false,
}) => {
  return name && control ? (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Dropdown
          id={field.name ?? ""}
          name={field.name}
          value={field.value}
          placeholder={placeholder}
          showClear={showClear}
          filter={filter}
          options={options}
          optionLabel={optionLabel}
          optionValue={optionValue}
          focusInputRef={field.ref}
          onChange={(e) => {
            field.onChange(e.value);
            if (onChange) onChange(e?.value);
          }}
          editable={editable}
          disabled={disabled}
          maxLength={maxLength}
          valueTemplate={valueTemplate}
          className={classNames({ "p-invalid": fieldState.error })}
          pt={{
            root: {
              className: "input-control  items-center !rounded !shadow-none !basis-full",
            },
            input: {
              className: "!p-1 !text-sm !font-semibold ",
            },
            trigger: {
              className: "!w-5",
            },
            item: {
              className: "!p-2 !text-sm !bg-gray-100 !text-neutral-500",
            },
            header: {
              className: "!p-2",
            },
            filterInput: {
              className: "!p-2",
            },
            clearIcon: {
              className: "!cursor-pointer",
            },
          }}
        />
      )}
    />
  ) : (
    <Dropdown
      id={id || "dropdown"}
      value={value}
      onChange={onChange}
      filter={filter}
      options={options}
      optionLabel={optionLabel}
      optionValue={optionValue}
      placeholder={placeholder}
      disabled={disabled}
      valueTemplate={valueTemplate}
      className={`input-control  items-center`}
      pt={{
        root: {
          className: "!border-neutral-200 !shadow-none !basis-full",
        },
        input: {
          className: "!p-1 !text-sm",
        },
        trigger: {
          className: "!w-5",
        },
        item: {
          className: "!p-2 !text-sm !bg-gray-100 !text-neutral-500",
        },
      }}
    />
  );
};
