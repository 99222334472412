import { Tooltip } from "primereact/tooltip";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AddUser, Binocular } from "src/assets/icon";
import { Errors } from "src/components/shared/error/error";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import FormModal from "src/components/ui/dialog/formModal";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Modal } from "src/components/ui/modal/modal";
import { SMRadioButton } from "src/components/ui/radioControl/radioButton";
import { NONE, cancelButtonText, contactHeader, httpCode, modalDiscardMessageText, saveButtonText, selectButtonText } from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import { ICommittee, IPosition } from "src/interfaces/committeeDetails";
import { IContactDetail } from "src/interfaces/contactDetail";
import { ILotInterface } from "src/interfaces/lotList";
import { IContact } from "src/interfaces/ownerContact";
import ContactForm from "src/pages/lot/owner/shared/contact";
import { ContactDetail } from "src/pages/lot/owner/shared/contactDetail";
import { httpPost, httpUpdate } from "src/services/api";
import { RootState } from "src/store/rootReducer";
import { setCommitteeList, setSelectedCommittee } from "src/store/slice/committeeSlice";
import { getFromUnitLot } from "src/utils/utility";

const CommitteeForm = ({
  lots,
  positions,
  setShowSidebar,
  toast,
  onlineInvoiceApproval,
}: {
  lots: Array<ILotInterface>;
  positions: Array<IPosition>;
  setShowSidebar: any;
  toast: any;
  onlineInvoiceApproval: boolean;
}) => {
  const { corpId } = useParams();
  const { selectedCommittee, committeeList } = useSelector((state: RootState) => state?.committeeSlice);
  const [unitLotList, setUnitLotList] = useState<any>();
  const [nominatedByList, setNominatedByList] = useState<any>();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showContactCard, setShowContactCard] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [selectContactModal, setSelectContactModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<IContactDetail>({} as IContactDetail);
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    trigger,
    resetField,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      positionName: selectedCommittee?.executivePositionName ?? "",
      fromUnitLot: selectedCommittee?.fromUnitLot ?? "",
      nominatedBy: selectedCommittee?.nominatedBy,
      name: selectedCommittee?.name ?? "",
      receiveReports: selectedCommittee?.receiveReports ?? false,
      onlineInvoiceApprover: selectedCommittee?.onlineInvoiceApprover ?? false,
      interimDeliveryMethod: selectedCommittee?.interimDeliveryMethod?.trim?.() ?? "",
    },
  });
  const receiveReports = watch("receiveReports");
  const fromUnitLot = watch("fromUnitLot");
  const dispatch = useDispatch();
  const formId = "addContact";

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  useEffect(() => {
    let newUnitLotList = lots?.filter(
      (lot: ILotInterface) =>
        lot?.bNonLotOwner !== "Y" &&
        !committeeList?.some((committee: ICommittee) => lot?.lLotID === committee?.lotId && lot?.lLotID !== selectedCommittee?.lotId)
    );

    setUnitLotList([
      {
        fromUnitLot: NONE,
      },
      ...newUnitLotList?.map?.((lot: ILotInterface) => {
        return {
          ...lot,
          fromUnitLot: getFromUnitLot({
            unitNumberPrefix: lot?.sUnitNumberPrefix,
            unitNumber: lot?.lUnitNumber,
            unitNumberSuffix: lot?.sUnitNumberSuffix,
            lotNumberprefix: lot?.sLotNumberPrefix,
            lotNumber: lot?.lLotNumber,
            lotNumberSuffix: lot?.sLotNumberSuffix,
          }),
        };
      }),
    ]);

    setNominatedByList([
      {
        nominatedBy: NONE,
      },
      ...lots
        ?.filter((lot: ILotInterface) => lot?.bNonLotOwner !== "Y")
        ?.map((item: ILotInterface) => {
          return {
            ...item,
            nominatedBy: getFromUnitLot({
              unitNumberPrefix: item?.sUnitNumberPrefix,
              unitNumber: item?.lUnitNumber,
              unitNumberSuffix: item?.sUnitNumberSuffix,
              lotNumberprefix: item?.sLotNumberPrefix,
              lotNumber: item?.lLotNumber,
              lotNumberSuffix: item?.sLotNumberSuffix,
            }),
          };
        }),
    ]);
  }, []);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    let body = {
      ...data,
      lotId: data?.fromUnitLot === NONE ? null : selectedCommittee?.lotId,
      executivePositionId: positions?.find?.((position: IPosition) => position?.positionName === data?.positionName)?.positionId,
      contactId: selectedCommittee?.contactId,
      nominatedByLotId: data?.nominatedBy === NONE ? null : selectedCommittee?.nominatedByLotId,
    };
    let response: any = selectedCommittee?.executiveMemberId
      ? await httpUpdate(`${EndPoints.corporation}${corpId}/${EndPoints.committee}/${selectedCommittee?.executiveMemberId}`, body)
      : await httpPost(`${EndPoints.corporation}${corpId}/${EndPoints.committee}`, body);
    if (response?.status === httpCode.SUCCESS) {
      toast?.current?.show({ severity: "success", summary: "Success", detail: Messages.SUCCESS_MESSAGE, life: 2000 });
      setShowSidebar(false);
      let committeeDetails: any = {
        fromUnitLot: data?.fromUnitLot,
        method:
          data?.interimDeliveryMethod === Labels.EMAIL_TENANT_NOTICE_ISSUES
            ? Labels.EMAIL_RADIOBUTTON_TEXT
            : data?.interimDeliveryMethod === Labels.PRINT_TENANT_NOTICE_ISSUES
            ? Labels.PRINT_RADIOBUTTON_TEXT
            : "",
        nominatedBy: data?.nominatedBy,
        executivePositionName: data?.positionName,
        name: data?.name,
        receiveReports: data?.receiveReports,
        onlineInvoiceApprover: data?.onlineInvoiceApprover,
        interimDeliveryMethod: data?.interimDeliveryMethod,
        lotId: selectedCommittee?.lotId,
        nominatedByLotId: selectedCommittee?.nominatedByLotId,
        contactId: selectedCommittee?.contactId,
        buildingName: selectedCommittee?.buildingName,
        streetNumber: selectedCommittee?.streetNumber,
        streetName: selectedCommittee?.streetName,
        poBox: selectedCommittee?.poBox,
        town: selectedCommittee?.town,
        state: selectedCommittee?.state,
        postcode: selectedCommittee?.postcode,
        country: selectedCommittee?.country,
        email: selectedCommittee?.email,
        committeeEmailAddress: selectedCommittee?.committeeEmailAddress,
        telephone1: selectedCommittee?.telephone1,
        telephone2: selectedCommittee?.telephone2,
        telephone3: selectedCommittee?.telephone3,
      };
      if (response?.data?.id) {
        dispatch(
          setCommitteeList([
            ...committeeList,
            {
              ...selectedCommittee,
              ...committeeDetails,
              executiveMemberId: response?.data?.id,
            },
          ])
        );
      } else {
        dispatch(
          setCommitteeList(
            committeeList?.map((committee: ICommittee) => {
              if (committee?.executiveMemberId === selectedCommittee?.executiveMemberId) {
                return {
                  ...committee,
                  ...committeeDetails,
                };
              } else {
                return { ...committee };
              }
            })
          )
        );
      }
    } else {
      toast?.current?.show({ severity: "error", summary: "Error", detail: Messages.ERROR_MESSAGE, life: 3000 });
    }
    setIsLoading(false);
  };

  const onDiscardSelectedContact = () => {
    setSelectContactModal(false);
    setSelectedRow({} as IContactDetail);
  };

  const footerContent = (
    <div className={`flex justify-end`}>
      <div className="flex gap-2 flex-row order-last mt-4 mr-4">
        <button type="button" className="transparent-button" onClick={() => onDiscardSelectedContact()}>
          {cancelButtonText}
        </button>
        <button
          type="button"
          className={`btn-primary ${selectedRow?.contactId ? "" : "opacity-50"}`}
          onClick={() => {
            if (selectedCommittee) {
              dispatch(
                setSelectedCommittee({
                  ...selectedCommittee,
                  contactId: selectedRow?.contactId,
                  buildingName: selectedRow?.buildingName,
                  streetNumber: selectedRow?.streetNumber,
                  streetName: selectedRow?.streetName,
                  poBox: selectedRow?.poBox,
                  town: selectedRow?.town,
                  state: selectedRow?.state,
                  postcode: selectedRow?.postcode,
                  country: selectedRow?.country,
                  email: selectedRow?.email,
                  committeeEmailAddress: selectedRow?.committeeEmailAddress,
                  telephone1: selectedRow?.telephone1,
                  telephone2: selectedRow?.telephone2,
                  telephone3: selectedRow?.telephone3,
                  additionalContactDetails: selectedRow?.additionalContactDetails,
                })
              );
              resetField("fromUnitLot", { keepError: false, defaultValue: fromUnitLot });
              setSelectContactModal(false);
            }
          }}
          disabled={selectedRow?.contactId ? false : true}
        >
          {selectButtonText}
        </button>
      </div>
    </div>
  );

  return (
    <>
      {isLoading && (
        <div className="flex fixed justify-center items-center z-50 lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} key={selectedCommittee?.contactId}>
        <div className="flex flex-col gap-1.5">
          <div className="flex items-center gap-4">
            <label className="required">
              FROM {Labels.UNIT?.substring?.(0, 4)} & {Labels.LOT}
            </label>
            {!selectedCommittee?.contactId && selectedCommittee?.fromUnitLot === NONE && (
              <div className="flex items-center gap-2">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    setShowContactCard(true);
                  }}
                >
                  <AddUser />
                  <Tooltip target="#user" content={Labels.ADD_CONTACT} position="bottom"></Tooltip>
                </span>
                <span className="cursor-pointer" onClick={() => setSelectContactModal(true)}>
                  <Binocular />
                  <Tooltip target="#binocular" content={Labels.SELECT_CONTACT} position="bottom"></Tooltip>
                </span>
              </div>
            )}
          </div>

          <SMDropDown
            control={control}
            options={unitLotList}
            name="fromUnitLot"
            optionLabel="fromUnitLot"
            optionValue="fromUnitLot"
            rules={{
              validate: (value: string) => {
                let committee = unitLotList?.find((lot: any) => {
                  return lot?.fromUnitLot === value;
                });
                return value && Boolean(committee?.OwnerContact?.[0]?.lContactID ?? selectedCommittee?.contactId);
              },
            }}
            onChange={(e: any) => {
              if (!selectedCommittee?.executiveMemberId) {
                setValue("nominatedBy", NONE);
                trigger("nominatedBy");
              }
              if (e === NONE && selectedCommittee) {
                dispatch(
                  setSelectedCommittee({
                    ...selectedCommittee,
                    lotId: 0,
                    contactId: 0,
                    fromUnitLot: e,
                  })
                );
                setValue("name", "");
                return;
              }
              let committee = unitLotList?.find((lot: any) => {
                return lot?.fromUnitLot === e;
              });
              if (committee && selectedCommittee) {
                let ownerInfo = { ...committee?.OwnerContact?.[0] };
                dispatch(
                  setSelectedCommittee({
                    ...selectedCommittee,
                    lotId: committee?.lLotID,
                    contactId: ownerInfo?.lContactID,
                    buildingName: ownerInfo?.sBuildingName,
                    streetNumber: ownerInfo?.sStreetNumber,
                    streetName: ownerInfo?.sStreetName,
                    town: ownerInfo?.sTown,
                    state: ownerInfo?.sState,
                    postcode: ownerInfo?.sPostcode,
                    country: ownerInfo?.sCountry,
                    email: ownerInfo?.sEmail,
                    committeeEmailAddress: ownerInfo?.sCommitteeEmailAddress,
                    telephone1: ownerInfo?.sTelephone1,
                    telephone2: ownerInfo?.sTelephone2,
                    telephone3: ownerInfo?.sTelephone3,
                  })
                );
                setValue("name", committee?.fromUnitLot === selectedCommittee?.fromUnitLot ? selectedCommittee?.name : "", {
                  shouldDirty: committee?.fromUnitLot === selectedCommittee?.fromUnitLot,
                });
                if (!ownerInfo?.sEmail) {
                  setValue("interimDeliveryMethod", receiveReports ? Labels.PRINT_TENANT_NOTICE_ISSUES : "");
                }
              }
            }}
          />
          {errors?.fromUnitLot && <Errors error={Messages.ADD_OR_SELECT_CONTACT} alignment="left" />}
        </div>
        <div className="flex flex-col gap-1.5 mt-5">
          <label className="required">{Labels.POSITION}</label>
          <SMDropDown
            control={control}
            options={positions}
            value="positionId"
            name="positionName"
            optionLabel="positionName"
            optionValue="positionName"
            rules={{ required: Messages.REQUIRED_MESSAGE }}
          />
          {errors?.positionName && <Errors error={errors?.positionName?.message} alignment="left" />}
        </div>
        <div className="flex flex-col gap-1.5 mt-5">
          <label className="required">{Labels.NAME}</label>
          <SMInputText name="name" control={control} maxlength={30} rules={{ required: Messages.REQUIRED_MESSAGE }} />
          {errors?.name && <Errors error={errors?.name?.message} alignment="left" />}
        </div>
        <div className="flex flex-col gap-1.5 mt-5">
          <label className="required">{Labels.NOMINATED_BY}</label>
          <SMDropDown
            control={control}
            options={nominatedByList}
            name="nominatedBy"
            optionLabel="nominatedBy"
            optionValue="nominatedBy"
            rules={{ required: Messages.REQUIRED_MESSAGE }}
            onChange={(e: any) => {
              let committee = nominatedByList?.find((lot: any) => {
                return lot?.nominatedBy === e;
              });

              if (committee && selectedCommittee) dispatch(setSelectedCommittee({ ...selectedCommittee, nominatedByLotId: committee?.lLotID }));
            }}
          />
          {errors?.nominatedBy && <Errors error={errors?.nominatedBy?.message} alignment="left" />}
        </div>
        <div className="flex items-center gap-10 mt-8">
          <div className="items-center gap-1.5">
            <SMCheckbox
              name="receiveReports"
              control={control}
              label={Labels.REPORTS}
              onChange={(value: boolean) => {
                let interimDeliveryMethod = value ? Labels.PRINT_TENANT_NOTICE_ISSUES : "";
                setValue("interimDeliveryMethod", interimDeliveryMethod);
              }}
            />
          </div>
          {onlineInvoiceApproval && (
            <div className="items-center gap-1.5">
              <SMCheckbox name="onlineInvoiceApprover" control={control} label={Labels.APPROVER} />
            </div>
          )}
        </div>
        <div className="w-1/3 flex flex-col mt-8 gap-1.5">
          <label>{Labels.METHOD}</label>
          <Controller
            name="interimDeliveryMethod"
            control={control}
            render={({ field }) => (
              <div className="flex">
                <div className="flex basis-full text-left items-center">
                  <SMRadioButton
                    id="print"
                    field={field}
                    value={Labels.PRINT_TENANT_NOTICE_ISSUES}
                    checked={field?.value === Labels.PRINT_TENANT_NOTICE_ISSUES}
                  />
                  <label htmlFor="Print" className={`ml-2 font-normal text-left text-sm`}>
                    {Labels.PRINT_RADIOBUTTON_TEXT}
                  </label>
                </div>
                <div className="flex basis-full text-left items-center">
                  <SMRadioButton
                    id="email"
                    field={field}
                    value={Labels.EMAIL_TENANT_NOTICE_ISSUES}
                    checked={field?.value === Labels.EMAIL_TENANT_NOTICE_ISSUES}
                    disabled={!selectedCommittee?.email}
                  />
                  <label htmlFor="Email" className={`ml-2 font-normal text-left text-sm ${!selectedCommittee?.email && "opacity-40"}`}>
                    {Labels.EMAIL_RADIOBUTTON_TEXT}
                  </label>
                </div>
              </div>
            )}
          />
        </div>
        {Boolean(selectedCommittee?.contactId) && (
          <div className="flex flex-col gap-2 mt-10 p-2 border-y-2">
            <div className="flex gap-1">
              <label>{Labels.ADDRESS}:</label>
              <div>
                {!selectedCommittee?.poBox && selectedCommittee?.buildingName} {!selectedCommittee?.poBox && selectedCommittee?.streetNumber}{" "}
                {!selectedCommittee?.poBox && selectedCommittee?.streetName} {selectedCommittee?.poBox} {selectedCommittee?.town} {selectedCommittee?.state}{" "}
                {selectedCommittee?.postcode} {selectedCommittee?.country}
              </div>
            </div>
            {(selectedCommittee?.email || selectedCommittee?.committeeEmailAddress) && (
              <div className="flex gap-1">
                <label>{Labels.EMAIL}</label>
                <div>{Boolean(selectedCommittee?.committeeEmailAddress) ? selectedCommittee?.committeeEmailAddress : selectedCommittee?.email}</div>
              </div>
            )}
            {selectedCommittee?.telephone3 && (
              <div className="flex gap-1">
                <label>{Labels.MOBILE}</label>
                <div>{selectedCommittee?.telephone3}</div>
              </div>
            )}
            {selectedCommittee?.telephone2 && (
              <div className="flex gap-1">
                <label>{Labels.WORK_PHONE}</label>
                <div>{selectedCommittee?.telephone2}</div>
              </div>
            )}
            {selectedCommittee?.telephone1 && (
              <div className="flex gap-1">
                <label>{Labels.HOME_PHONE}</label>
                <div>{selectedCommittee?.telephone1}</div>
              </div>
            )}
          </div>
        )}
        <div className="flex justify-end gap-2 flex-row order-last mt-5 mr-5">
          <button
            id="btnCancel"
            data-test-id="btnCancel"
            type="button"
            className="bg-white text-black py-1.5 px-4 text-md rounded-md items-center border-2"
            onClick={() => {
              if (isDirty) {
                setShowCancelModal(true);
              } else {
                setShowSidebar(false);
              }
            }}
          >
            {cancelButtonText}
          </button>
          <button
            id="btnSave"
            data-test-id="btnSave"
            type="submit"
            disabled={!isDirty}
            className={`bg-blue-500 text-white py-1.5 px-6 text-md rounded-md items-center ${!isDirty ? "opacity-60" : "opacity-100"}`}
          >
            {saveButtonText}
          </button>
        </div>
      </form>
      <Modal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        title="Warning"
        onAction={() => {
          setShowCancelModal(false);
          setShowSidebar(false);
        }}
      >
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
      <FormModal
        width="80vw"
        formId={formId}
        header={Labels.ADD_CONTACT}
        isOpen={showContactCard}
        onClose={() => {
          if (isFormDirty) {
            setShowCancelModal(true);
          } else {
            setShowContactCard(false);
          }
        }}
        isDirty={isFormDirty}
      >
        <div className="px-10">
          <ContactForm
            contactId={selectedCommittee?.contactId}
            title=""
            formId={formId}
            contactType={Labels.OWNER_CONTACT_TYPE}
            showCollapsible={false}
            lmcCommunicationChecks={false}
            showFullPortalAccess={true}
            showCancelModal={showCancelModal}
            setIsFormDirty={setIsFormDirty}
            setShowCancelModal={setShowCancelModal}
            setEditable={setShowContactCard}
            setData={(data: IContact, response: { Id: number }) => {
              if (selectedCommittee) {
                dispatch(
                  setSelectedCommittee({
                    ...selectedCommittee,
                    contactId: response?.Id,
                    buildingName: data?.buildingName,
                    streetNumber: data?.streetNumber,
                    streetName: data?.streetName,
                    poBox: data?.poBox,
                    town: data?.town,
                    state: data?.state,
                    postcode: data?.postcode,
                    country: data?.country,
                    email: data?.email,
                    committeeEmailAddress: data?.committeeEmailAddress,
                    telephone1: data?.telephone1,
                    telephone2: data?.telephone2,
                    telephone3: data?.telephone3,
                  })
                );
                resetField("fromUnitLot", { keepError: false, defaultValue: fromUnitLot });
              }
            }}
            toastRef={toast}
          />
        </div>
      </FormModal>
      <FormModal
        width="80vw"
        height="90vh"
        header={contactHeader}
        isOpen={selectContactModal}
        onClose={() => setSelectContactModal(false)}
        footer={footerContent}
      >
        <ContactDetail contactType={Labels.OWNER_CONTACT_TYPE} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
      </FormModal>
    </>
  );
};

export default CommitteeForm;
