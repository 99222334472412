import { Pencil, Plus, Trash } from "src/assets/icon";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { IOtherAddressList } from "src/interfaces/otherAddressList";
import { useMemo, useRef, useState } from "react";
import styles from "./otherAddresses.module.scss";
import ToastContainer from "src/components/ui/toast/toast";
import { Toast } from "primereact/toast";
import OtherAddressesForm from "./otherAddressesForm";
import FormModal from "src/components/ui/dialog/formModal";
import { checkZeroValue } from "src/utils";
import { comingSoon } from "src/constants/constant";

export const AddressTable: React.FC<{
  addressData: Array<IOtherAddressList>;
  corpId: string;
}> = ({ addressData, corpId }) => {
  const [addressTableData, setAddressTableData] = useState(addressData);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [addressRow, setAddressRow] = useState<any>({});
  const [rowIndex, setRowIndex] = useState(0);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);

  const columns = useMemo(() => {
    return [
      {
        field: "sBuildingName",
        header: "BUILDING NAME",
      },
      {
        field: "lStreetNumber",
        header: "NO.",
        body: (data: any) => {
          return <p>{data?.sStreetNumberPrefix}{checkZeroValue(data?.lStreetNumber)}{data?.sStreetNumberSuffix}</p>;
        },
      },
      {
        field: "sStreet",
        header: "STREET",
      },
      {
        field: "sTown",
        header: "TOWN",
      },
      {
        field: "sPostcode",
        header: "POSTCODE",
      },
      {
        field: "edit",
        header: "",
        body: (data: any, options: any) => {
          return (
            <span
              className="px-2 py-3 cursor-pointer"
              onClick={() => {
                setRowIndex(options?.rowIndex);
                setAddressRow(data);
                setShowModal(true);
              }}
            >
              <Pencil />
            </span>
          );
        },
      },
      // {
      //   field: "",
      //   header: "",
      //   body: () => {
      //     return (
      //       <span className="px-2 py-3 cursor-pointer">
      //         <Trash />
      //       </span>
      //     );
      //   },
      // },
    ];
  }, []);

  const handleChange = (rowIndex: number, addressRow: any) => {
    let newAddressTableData = addressTableData?.map?.(
      (address, addressRowIndex) => {
        if (addressRowIndex === rowIndex) {
          return {
            ...address,
            ...addressRow,
          };
        } else {
          return {
            ...address,
          };
        }
      }
    );
    setAddressTableData(newAddressTableData);
  };

  return (
    <div
      data-testid="otherAddressesCard"
      className={`flex-auto basis-full lg:ml-0 ${styles.card}`}
    >
      <div className="w-full flex justify-between">
        <label className="section-header">
          Other Addresses
        </label>
        <div className={`flex order-last items-center justify-center`}>
          <button
            onClick={() =>
              toastRef?.current?.show({
                severity: "info",
                summary: "Info",
                detail: comingSoon,
                life: 2000,
              })
            }
          >
            <Plus height={24} width={24} />
          </button>
        </div>
      </div>

      <div className="mt-10">
        {addressData?.length > 0 ? (
          <SMDataTableContainer
            tableData={addressTableData}
            tableColumns={columns}
            showEditIcon={false}
            showSelectableRowCheckbox={false}
            defaultPageSize={5}
          />
        ) : (
          <div className="px-2 py-2 text-sm">No record found.</div>
        )}
      </div>
      <ToastContainer toastReference={toastRef} />
      <FormModal
        header="Address Update"
        formId="otherAddressesForm"
        isOpen={showModal}
        onClose={() => {
          if (isFormDirty) {
            setShowCancelModal(true);
          } else {
            setShowModal(false);
          }
        }}
        isDirty={isFormDirty}
      >
        <OtherAddressesForm
          formData={addressRow}
          setShowModal={setShowModal}
          handleChange={handleChange}
          rowIndex={rowIndex}
          setIsLoading={setIsLoading}
          toastRef={toastRef}
          corpId={corpId}
          addressData={addressData}
          setAddressTableData={setAddressTableData}
          isLoading={isLoading ?? false}
          setIsFormDirty={setIsFormDirty}
          showCancelModal={showCancelModal}
          setShowCancelModal={setShowCancelModal}
        />
      </FormModal>
    </div>
  );
};
