import { useSelector } from "react-redux";
import { RootState } from "../../store/rootReducer";
import { Tooltip } from "../ui/tooltip/tooltip";
import { Calculator, CalendarBlank, Collection, CurrencyDollar, SVLogo, SCLogo, Mail2, DocumentDuplicate, Close, Filter, Line } from "../../assets/icon";
import { AutoCompleteSearch } from "../ui/search/autoCompleteSearch";
import { Toast } from "primereact/toast";
import { useRef, useState } from "react";
import { comingSoon, searchButtonText } from "src/constants/constant";
import ToastContainer from "../ui/toast/toast";
import { Drawer } from "../ui/drawer/drawer";
import AdvanceSearchDrawer from "../shared/globalSearch/advanceSearchDrawer";
import styles from "./navbar.module.scss";
import { useNavigate, useParams } from "react-router-dom";
import { strataConnectUrl, strataVoteUrl } from "src/constants/constant";

export const Navbar: React.FC = () => {
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);
  const { sideNavCollapsed } = useSelector((state: RootState) => state.userSettings);
  const { corpId } = useParams();
  const navigate = useNavigate();
  return (
    <>
      <header className={`fix-header-wrapper z-40 ${sideNavCollapsed && "collapsed"}`}>
        <div className="flex flex-none order-1 gap-3">
          <div className="flex basis-full">
            <AutoCompleteSearch placeholder={searchButtonText} />
          </div>
          <div className="flex items-center" onClick={() => setShowSidebar(true)}>
            <Filter />
          </div>
        </div>
        {corpId && (
          <div className={`flex order-2 gap-6 items-center justify-end ${styles.iconsMobile}`}>
            <Tooltip content="Diary" theme="dark">
              <span
                onClick={() =>
                  toastRef?.current?.show({
                    severity: "info",
                    summary: "Info",
                    detail: comingSoon,
                    life: 2000,
                  })
                }
              >
                <CalendarBlank />
              </span>
            </Tooltip>
            <Tooltip content="Budget" theme="dark">
              <span
                onClick={() => navigate(`corporations/${corpId}/budget`)}
              >
                <CurrencyDollar />
              </span>
            </Tooltip>
            <Tooltip content="Bulk email wizard" theme="dark">
              <span
                onClick={() =>
                  toastRef?.current?.show({
                    severity: "info",
                    summary: "Info",
                    detail: comingSoon,
                    life: 2000,
                  })
                }
              >
                <Mail2 />
              </span>
            </Tooltip>
            <Tooltip content="Registers" theme="dark">
              <span
                onClick={() =>
                  toastRef?.current?.show({
                    severity: "info",
                    summary: "Info",
                    detail: comingSoon,
                    life: 2000,
                  })
                }
              >
                <Collection />
              </span>
            </Tooltip>
            <Tooltip content="Management fees" theme="dark">
              <span
                onClick={() => navigate(`corporations/${corpId}/management-fees`)}
              >
                <Calculator />
              </span>
            </Tooltip>
            <Tooltip content="Document register" theme="dark">
              <span
                onClick={() =>
                  toastRef?.current?.show({
                    severity: "info",
                    summary: "Info",
                    detail: comingSoon,
                    life: 2000,
                  })
                }
              >
                <DocumentDuplicate />
              </span>
            </Tooltip>
            <Line />
            <Tooltip content="StrataVote" theme="dark">
              <a href={strataVoteUrl} target="_blank" rel="noopener noreferrer"><SVLogo /></a>
            </Tooltip>
            <Tooltip content="Strata Connect" theme="dark">              
              <a href={strataConnectUrl} target="_blank" rel="noopener noreferrer"><SCLogo /></a>
            </Tooltip>
            {/* <Line /> */}
            {/* <Close /> */}
          </div>
        )}
      </header>
      <ToastContainer toastReference={toastRef} position="top-center" />
      <Drawer
        title="Advanced Search"
        isOpen={showSidebar}
        onClose={() => {
          setShowSidebar(false);
        }}
        width="w-[85vw] lg:w-[40vw]"
      >
        <AdvanceSearchDrawer toastRef={toastRef} setShowSidebar={setShowSidebar} />
      </Drawer>
    </>
  );
};
