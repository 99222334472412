import React, { useRef, useState } from "react";
import { Labels } from "src/constants/labels";
import { useForm } from "react-hook-form";
import { Messages } from "src/constants/messages";
import { EndPoints } from "src/constants/endpoint";
import { httpUpdate } from "src/services/api";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import {
  cancelButtonText,
  comingSoon,
  editButtonText,
  gstFrequency,
  gstMethod,
  gstStatus,
  httpCode,
  interimReportingPeriod,
  modalDiscardMessageText,
  saveButtonText,
  toastErrorTimeLimit,
  toastSuccessTimeLimit,
} from "src/constants/constant";
import { findValue, FormatDate, toDate } from "src/utils";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import { Toast } from "primereact/toast";
import { IFinancialReporting } from "src/interfaces/financialReporting";
import ToastContainer from "src/components/ui/toast/toast";
import styles from "../financial.module.scss";
import { SMDatePicker } from "src/components/ui/datePicker/datePicker";
import { Errors } from "src/components/shared/error/error";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { Modal } from "src/components/ui/modal/modal";

export const FinancialReportingSection: React.FC<{
  financialReportingDetails: IFinancialReporting;
  corpId: string;
}> = ({ financialReportingDetails, corpId }) => {
  const [financialDetail, setFinancialDetail] = useState(financialReportingDetails);
  const [isEditableFinancialReporting, setIsEditableFinancialReporting] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isDirty, errors },
  } = useForm<IFinancialReporting>({
    defaultValues: {
      lastAGM: financialDetail?.lastAGM,
      lastEGM: financialDetail?.lastEGM,
      taxYearEnd: financialDetail?.taxYearEnd,
      interimReportingPeriod: financialDetail?.interimReportingPeriod,
      taxFileNumber: financialDetail?.taxFileNumber,
      abn: financialDetail?.abn ?? "",
      gstAccountingMethod: financialDetail?.gstAccountingMethod,
      gstFrequency: financialDetail?.gstFrequency,
      gstStatus: financialDetail?.gstStatus,
    },
    mode: "onTouched",
  });
  const gstStatusValue = watch("gstStatus");
  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  const FinancialReportingButtons = [
    {
      buttonName: isEditableFinancialReporting ? cancelButtonText : editButtonText,
      type: "normal",
      onClick: isEditableFinancialReporting ? onCancel : () => setIsEditableFinancialReporting(true),
    },
    {
      buttonName: isEditableFinancialReporting ? saveButtonText : "",
      type: isEditableFinancialReporting ? "save" : "",
      buttonType: "submit",
      formId: "financialReportingDetailForm",
    },
  ];

  function onCancel() {
    if (isDirty) {
      setShowModal(true);
    } else {
      setIsEditableFinancialReporting(false);
    }
  }

  function onDiscard() {
    reset(financialDetail);
    setShowModal(false);
    setIsEditableFinancialReporting(false);
  }

  async function onSubmit(data: IFinancialReporting) {
    let payload = {
      ...data,
      lastAGM: toDate(FormatDate(data?.lastAGM) ?? ""),
      lastEGM: toDate(FormatDate(data?.lastEGM) ?? ""),
      taxYearEnd: toDate(FormatDate(data?.taxYearEnd) ?? ""),
    };
    setIsLoading(true);
    const output = await httpUpdate(EndPoints.corporation + corpId + EndPoints.financial + "/financial-reporting", payload);
    if (output?.status === httpCode.SUCCESS) {
      setFinancialDetail(data);
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: toastSuccessTimeLimit,
      });
      reset({}, { keepDirty: false, keepValues: true });
      setIsEditableFinancialReporting(false);
    }
    if (output?.error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Error: ${output?.error}`,
        life: toastErrorTimeLimit,
      });
    }
    setIsLoading(false);
  }
  return (
    <>
      <CollapsibleCard id="FinancialReporting" title={Labels.FINANCIAL_REPORTING} expand={true} buttons={FinancialReportingButtons}>
        <div className="pt-4">
          {isLoading && (
            <div className="spinnerContainer">
              <div className="w-20 h-20">
                <SpinnerHorizontal />
              </div>
            </div>
          )}
          <form id="financialReportingDetailForm" onSubmit={handleSubmit(onSubmit)} noValidate>
            <div data-testid="financialReportingTab" className="basis-full text-sm mt-4">
              <div className={`${styles.section} ${isEditableFinancialReporting ? "border-remove" : "border-b"}`}>
                <label className={styles.label}>{Labels.LAST_AGM}</label>
                {isEditableFinancialReporting ? (
                  <span className="mb-2 basis-1/2 text-left">
                    <SMDatePicker
                      inputDate={FormatDate(financialDetail?.lastAGM) ?? ""}
                      control={control}
                      name="lastAGM"
                      disabled={!isEditableFinancialReporting}
                      width="w-full"
                    />
                  </span>
                ) : (
                  <span className={styles.value}>{FormatDate(financialDetail?.lastAGM)}</span>
                )}
              </div>
              <div className={`${styles.section} ${isEditableFinancialReporting ? "border-remove" : "border-b"}`}>
                <label className={styles.label}>{Labels.LAST_EGM}</label>
                {isEditableFinancialReporting ? (
                  <span className="mb-2 basis-1/2 text-left">
                    <SMDatePicker
                      inputDate={FormatDate(financialDetail?.lastEGM) ?? ""}
                      control={control}
                      name="lastEGM"
                      disabled={!isEditableFinancialReporting}
                      width="w-full"
                    />
                  </span>
                ) : (
                  <span className={styles.value}>{FormatDate(financialDetail?.lastEGM)}</span>
                )}
              </div>
              <div className={`${styles.section} ${isEditableFinancialReporting ? "border-remove" : "border-b"}`}>
                <label className={` ${styles.label}`}>{Labels.TAX_YEAR_END}</label>
                {isEditableFinancialReporting ? (
                  <span className="mb-2 basis-1/2 text-left">
                    <SMDatePicker
                      inputDate={FormatDate(financialDetail?.taxYearEnd) ?? ""}
                      control={control}
                      name="taxYearEnd"
                      disabled={!isEditableFinancialReporting}
                      width="w-full"
                    />
                  </span>
                ) : (
                  <span className={styles.value}>{FormatDate(financialDetail?.taxYearEnd)}</span>
                )}
              </div>
              <div
                className={`${styles.section} ${isEditableFinancialReporting ? "border-remove" : "border-b"} ${
                  errors.interimReportingPeriod ? "!h-15 my-2" : ""
                }`}
              >
                <label className={` ${styles.label} ${isEditableFinancialReporting ? "!w-1/2 required" : "!w-full"}`}>{Labels.INTERIM_REPORTING_PERIOD}</label>
                {isEditableFinancialReporting ? (
                  <span className="mb-2 basis-1/2 text-left">
                    <SMDropDown
                      id="interimReportingPeriod"
                      control={control}
                      name="interimReportingPeriod"
                      options={interimReportingPeriod}
                      optionLabel="name"
                      optionValue="id"
                      value={findValue(interimReportingPeriod, financialDetail?.interimReportingPeriod)}
                      rules={{ required: Messages.REQUIRED_MESSAGE }}
                    />
                    {errors.interimReportingPeriod && <Errors error={errors.interimReportingPeriod.message} alignment="left" />}
                  </span>
                ) : (
                  <span className={styles.value}>{findValue(interimReportingPeriod, financialDetail?.interimReportingPeriod)}</span>
                )}
              </div>
              <div className={`${styles.section} ${isEditableFinancialReporting ? "border-remove" : "border-b"}`}>
                <label className={styles.label}>{Labels.TFN}</label>
                {isEditableFinancialReporting ? (
                  <span className="mb-2 basis-1/2 text-left">
                    <SMInputText name="taxFileNumber" keyfilter={"alphanum"} control={control} maxlength={12} />
                  </span>
                ) : (
                  <span className={styles.value}>{financialDetail?.taxFileNumber}</span>
                )}
              </div>
              <div className={`${styles.section} ${isEditableFinancialReporting ? "border-remove" : "border-b"}`}>
                <label className={styles.label}>{Labels.ABN}</label>
                {isEditableFinancialReporting ? (
                  <span className="mb-2 basis-1/2 text-left">
                    <SMInputText name="abn" keyfilter={"int"} control={control} maxlength={11} />
                  </span>
                ) : (
                  <span className={styles.value}>{financialDetail?.abn}</span>
                )}
              </div>
              <div className={`${styles.section} ${isEditableFinancialReporting ? "border-remove" : "border-b"} ${errors.gstStatus ? "!h-15 my-2" : ""}`}>
                <label className={`${styles.label} ${isEditableFinancialReporting ? "!w-1/2 required" : "!w-full"}`}>{Labels.GST_STATUS}</label>
                {isEditableFinancialReporting ? (
                  <span className="mb-2 basis-1/2 text-left">
                    <SMDropDown
                      id="gstStatus"
                      control={control}
                      name="gstStatus"
                      options={gstStatus}
                      optionLabel="name"
                      optionValue="id"
                      value={findValue(gstStatus, financialDetail?.gstStatus)}
                      rules={{ required: Messages.REQUIRED_MESSAGE }}
                    />
                    {errors.gstStatus && <Errors error={errors.gstStatus.message} alignment="left" />}
                  </span>
                ) : (
                  <span className={styles.value}>{findValue(gstStatus, financialDetail?.gstStatus)}</span>
                )}
              </div>
              {gstStatusValue === "R" && (
                <>
                  <div
                    className={`${styles.section} ${isEditableFinancialReporting ? "border-remove" : "border-b"} ${
                      errors.gstAccountingMethod ? "!h-15 my-2" : ""
                    }`}
                  >
                    <label className={`${styles.label} ${isEditableFinancialReporting ? "!w-1/2 required" : "!w-full"}`}>{Labels.GST_METHOD}</label>
                    {isEditableFinancialReporting ? (
                      <span className="mb-2 basis-1/2 text-left">
                        <SMDropDown
                          id="gstAccountingMethod"
                          control={control}
                          name="gstAccountingMethod"
                          options={gstMethod}
                          optionLabel="name"
                          optionValue="id"
                          value={findValue(gstMethod, financialDetail?.gstAccountingMethod)}
                          rules={{ required: Messages.REQUIRED_MESSAGE }}
                        />
                        {errors.gstAccountingMethod && <Errors error={errors.gstAccountingMethod.message} alignment="left" />}
                      </span>
                    ) : (
                      <span className={styles.value}>{findValue(gstMethod, financialDetail?.gstAccountingMethod)}</span>
                    )}
                  </div>
                  <div
                    className={`${styles.section} ${isEditableFinancialReporting ? "border-remove" : "border-b"} ${errors.gstFrequency ? "!h-15 my-2" : ""}`}
                  >
                    <label className={`${styles.label} ${isEditableFinancialReporting ? "!w-1/2 required" : "!w-full"}`}>{Labels.GST_FREQUENCY}</label>
                    {isEditableFinancialReporting ? (
                      <span className="mb-2 basis-1/2 text-left">
                        <SMDropDown
                          id="gstFrequency"
                          control={control}
                          name="gstFrequency"
                          options={gstFrequency}
                          optionLabel="name"
                          optionValue="id"
                          value={findValue(gstFrequency, financialDetail?.gstFrequency)}
                          rules={{ required: Messages.REQUIRED_MESSAGE }}
                        />
                        {errors.gstFrequency && <Errors error={errors.gstFrequency.message} alignment="left" />}
                      </span>
                    ) : (
                      <span className={styles.value}>{findValue(gstFrequency, financialDetail?.gstFrequency)}</span>
                    )}
                  </div>
                </>
              )}
              <div className="flex justify-end mt-4 h-10">
                <button
                  className="grey-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    toastRef?.current?.show({
                      severity: "info",
                      summary: "Info",
                      detail: comingSoon,
                      life: toastSuccessTimeLimit,
                    });
                  }}
                >
                  {Labels.GENERATE_REPORTS_IN_MRI_STRATA_CONNECT}
                </button>
              </div>
            </div>
          </form>
          <ToastContainer toastReference={toastRef} />
        </div>
      </CollapsibleCard>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Warning" onAction={() => onDiscard()}>
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
      <ToastContainer toastReference={toastRef} />
    </>
  );
};
