import { useMemo, useRef, useState } from "react";
import { Clipboard, Pencil } from "src/assets/icon";
import { Drawer } from "src/components/ui/drawer/drawer";
import { IconYesNoNull } from "src/components/ui/iconYesNoNull";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { Labels } from "src/constants/labels";
import { IAgency } from "src/interfaces/agency";
import AgencyForm from "../agencyForm/agencyForm";
import { Toast } from "primereact/toast";
import ToastContainer from "src/components/ui/toast/toast";
import { Messages } from "src/constants/messages";
import { copyToClipboard } from "src/utils";

const AgencyTable = ({ agencies }: { agencies: Array<IAgency> }) => {
  const [agencyList, setAgencyList] = useState(agencies);
  const [agencyRowDetails, setAgencyRowDetails] = useState<any>({});
  const [rowIndex, setRowIndex] = useState(0);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const [createMode, setCreateMode] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const columns = useMemo(() => {
    return [
      {
        field: "agencyName",
        header: Labels.AGENCY_NAME,
      },
      {
        field: "agencyId",
        header: Labels.AGENCYID,
      },
      {
        field: "appBridgeId",
        header: Labels.BRIDGE_ID,
      },
      {
        field: "localPort",
        header: Labels.LOCALPORT,
      },
      {
        field: "clientKey",
        header: Labels.CLIENT_KEY,
      },
      {
        field: "apiKey",
        header: Labels.API_KEY,
      },
      {
        field: "isActive",
        header: <p className="uppercase">{Labels.ACTIVE}</p>,
        body: (data: any) => {
          return <IconYesNoNull type={data?.isActive} />;
        },
      },
      {
        field: "edit",
        header: "",
        body: (data: any, options: any) => {
          return (
            <span
              className="cursor-pointer"
              onClick={() => {
                setRowIndex(options?.rowIndex);
                setAgencyRowDetails(data);
                setShowSidebar(true);
              }}
            >
              <Pencil />
            </span>
          );
        },
      },
      {
        field: "copyToClipboard",
        header: "",
        body: (data: any) => {
          return (
            <span
              className="cursor-pointer"
              onClick={() => {
                let copyData = {
                  [Labels.AGENCY_NAME]: data?.agencyName,
                  PORT: data?.localPort,
                  [Labels.CLIENT_KEY]: data?.clientKey,
                  [Labels.API_KEY]: data?.apiKey,
                  [Labels.AZURE_ENDPOINT]: data?.azureEndPoint,
                };
                copyToClipboard(copyData, toast);
              }}
            >
              <Clipboard />
            </span>
          );
        },
      },
    ];
  }, []);

  const handleChange = (rowIndex: number, agencyRow: IAgency, createMode: boolean) => {
    if (createMode) {
      setAgencyList([...agencyList, agencyRow]);
    } else {
      let newAgencyList = agencyList?.map?.((agency, agencyRowIndex) => {
        if (agencyRowIndex === rowIndex) {
          return {
            ...agencyRow,
          };
        } else {
          return {
            ...agency,
          };
        }
      });
      setAgencyList(newAgencyList);
    }
  };

  return (
    <div className="relative p-4">
      <div className="mr-4 right-0 absolute z-30">
        <button
          className={"grey-btn"}
          onClick={() => {
            setShowSidebar(true);
            setAgencyRowDetails({});
            setCreateMode(true);
          }}
        >
          Create Agency
        </button>
      </div>
      <SMDataTableContainer
        tableData={agencyList}
        tableColumns={columns}
        showEditIcon={false}
        enableGlobalSearch
        globalFilterFields={["agencyName", "clientKey", "apiKey", "agencyId", "isActive"]}
      />
      <Drawer
        title={createMode ? "Add Agency Details" : "Edit Agency Details"}
        isOpen={showSidebar}
        onClose={() => {
          setShowSidebar(false);
          setCreateMode(false);
        }}
        width="w-[67vw]"
      >
        <AgencyForm
          formData={agencyRowDetails}
          rowIndex={rowIndex}
          createMode={createMode}
          setShowSidebar={setShowSidebar}
          setCreateMode={setCreateMode}
          toastRef={toast}
          handleChange={handleChange}
        />
      </Drawer>
      <ToastContainer toastReference={toast} />
    </div>
  );
};

export default AgencyTable;
