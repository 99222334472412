import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { SMRadioButton } from "src/components/ui/radioControl/radioButton";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";

export const NoticesAddress: React.FC<{ contactDetail: any; lotDetails: any; setContactDetail: any; control: any;}> = ({
  contactDetail,
  lotDetails,
  setContactDetail,
  control,
}) => {
  let contactType =
    contactDetail?.contactType === Labels.OWNER_CONTACT_TYPE
      ? contactDetail?.contactId === lotDetails?.ownerContactId
        ? contactDetail?.contactType
        : ""
      : contactDetail?.contactType;

  let otherType = 
    (contactType !== Labels.TENANT_CONTACT_TYPE || contactDetail?.contactId !== lotDetails?.tenantContactId) &&
    (contactType !== Labels.OWNER_CONTACT_TYPE || contactDetail?.contactId !== lotDetails?.ownerContactId) &&
    (contactType !== Labels.AGENT_CONTACT_TYPE || (contactType === Labels.AGENT_CONTACT_TYPE && lotDetails.agentContactId === 0) || (contactType === Labels.AGENT_CONTACT_TYPE && lotDetails.agentContactId !== contactDetail.contactId))
      ? true
      : false;

  const handleChange = (contactId: string, field?: any) => {
    field.onChange(contactId);
    let details = Object.assign({}, ...lotDetails?.lotContacts?.filter?.((contact: any) => contact?.contactId === contactId));
    setContactDetail(details);
  };  
  return (
    <div>
      <div className="flex justify-between h-10 items-center">
        <div className="flex basis-full text-left items-center">
          <Controller
            name="noticeContactID"
            control={control}
            rules={{ required: Messages.PROVIDE_CONTACT_MESSAGE_NOTICES, validate: (value: any) => Boolean(value) }}
            render={({ field }) => (
              <>
                <div className="flex basis-full text-left items-center">
                  <SMRadioButton
                    id="owner"
                    field={field}
                    value={lotDetails?.ownerContactId}
                    checked={field?.value === lotDetails?.ownerContactId}
                    onChange={(e: any) => {
                      handleChange(e?.value, field);
                    }}
                  />
                  <label htmlFor="Company" className={`ml-2 font-normal text-left text-sm`}>
                    {Labels.USE_OWNERS_ADDRESS}
                  </label>
                </div>
                <div className="flex basis-full text-left items-center">
                  <SMRadioButton
                    id="agent"
                    field={field}
                    value={lotDetails?.agentContactId}
                    checked={field?.value === lotDetails?.agentContactId}
                    onChange={(e: any) => {
                      handleChange(e?.value, field);
                    }}
                    disabled={lotDetails?.ownerOccupied || !lotDetails?.agentContactId}
                  />
                  <label htmlFor="Private" className={`ml-2 font-normal text-left text-sm ${
                    lotDetails?.ownerOccupied || (lotDetails?.agentContactId !== 0 ? false : true) ? "opacity-50" : ""
                  }`}>
                    {Labels.USE_AGENTS_ADDRESS}
                  </label>
                </div>
                <div className="flex basis-full text-left items-center">
                  <SMRadioButton 
                    id="other" 
                    value="" 
                    field={field} 
                    checked={otherType} 
                    onChange={(e: any) => handleChange(e.value, field)} 
                  />
                  <label className={`ml-2 font-normal text-left text-sm`}>
                    {Labels.DIFFERENT_CONTACT_FOR_NOTICES}
                  </label>
                </div>
              </>
            )}
          />
        </div>
      </div>
    </div>
  );
};
