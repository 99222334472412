import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SMDatePicker } from "src/components/ui/datePicker/datePicker";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Modal } from "src/components/ui/modal/modal";
import { modalDiscardMessageText } from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import { httpUpdate } from "src/services/api";
import { FormatDate, toDate } from "src/utils/utility";
import styles from "./leaseTableForm.module.scss";
import { ILease } from "src/interfaces/lease";
import { Errors } from "src/components/shared/error/error";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";

const LeaseTableForm = ({
  corpId,
  formData,
  formId,
  showCancelModal,
  rowIndex,
  handleChange,
  setShowCancelModal,
  setShowModal,
  setIsFormDirty,
  toastRef
}: {
  corpId: string;
  formData: ILease;
  formId: string;
  showCancelModal: boolean;
  rowIndex: number;
  handleChange: any;
  setShowCancelModal: any;
  setShowModal: any;
  setIsFormDirty: any;
  toastRef: any;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      entry: formData?.entry,
      lessee: formData?.lessee,
      noticeAddress: formData?.noticeAddress,
      term: formData?.term,
      commencement: formData?.commencement,
      termination: formData?.termination,
      lotId: formData?.lotId,
      leaseId: formData?.leaseId,
    },
  });

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty]);

  const onSubmit = async (data: any) => {
    let reqBody = {
      ...data,
      entry: toDate(FormatDate(data?.entry) ?? ""),
      commencement: toDate(FormatDate(data?.commencement) ?? ""),
      termination: toDate(FormatDate(data?.termination) ?? ""),
    };
    setIsLoading(true);
    const response = await httpUpdate(EndPoints.corporation + `${corpId}/lots/${data?.lotId}/leases`, reqBody);
    if (response?.status === 200) {
      toastRef?.current?.show({ severity: "success", summary: "Success", detail: Messages.SUCCESS_MESSAGE, life: 2000 });
      setShowModal(false);
      handleChange(rowIndex, data);
    }
    if (response?.error) {
      toastRef?.current?.show({ severity: "error", summary: "Error", detail: Messages.ERROR_MESSAGE, life: 3000 });
    }
    setIsLoading(false);
  };

  return (
    formData && (
      <>
        {isLoading && (
          <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-modal overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
            <div className="w-20 h-20">
              <SpinnerHorizontal />
            </div>
          </div>
        )}
        <form id={formId} onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="w-full pl-6 pr-6">
            <div className={styles.labelInput}>
              <label>{Labels.DATE_OF_ENTRY}</label>
              <SMDatePicker
                inputDate={FormatDate(formData?.entry) ?? ""}
                name="entry"
                control={control}
                rules={{ required: Messages.DATE_IS_REQUIRED }}
              />
              {errors?.entry && <Errors error={errors?.entry?.message} alignment="left" />}
            </div>

            <div className={styles.labelInput}>
              <label>{Labels.LESSEE}</label>
              <SMInputText name="lessee" control={control} maxlength={50} />
            </div>
            <div className={styles.labelInput}>
              <label>{Labels.NOTICE_ADDRESS}</label>
              <SMInputText name="noticeAddress" control={control} maxlength={128} />
            </div>
            <div className={styles.labelInput}>
              <label>{Labels.TERM}</label>
              <SMInputText name="term" control={control} maxlength={10} />
            </div>
            <div className={styles.labelInput}>
              <label>{Labels.COMMENCED}</label>
              <SMDatePicker
                inputDate={FormatDate(formData?.commencement) ?? ""}
                name="commencement"
                control={control}
                rules={{ required: Messages.DATE_IS_REQUIRED }}
              />
              {errors?.commencement && <Errors error={errors?.commencement?.message} alignment="left" />}
            </div>
            <div className={styles.labelInput}>
              <label>{Labels.TERMINATED}</label>
              <SMDatePicker inputDate={FormatDate(formData?.termination) ?? ""} name="termination" control={control} />
            </div>
          </div>
        </form>
        <Modal
          isOpen={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          title="Warning"
          onAction={() => {
            setShowCancelModal(false);
            setShowModal(false);
          }}
        >
          <div>
            <h2>{modalDiscardMessageText}</h2>
          </div>
        </Modal>
      </>
    )
  );
};

export default LeaseTableForm;
