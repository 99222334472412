import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserSettings } from "../../interfaces/userSettings";


const initialState: IUserSettings = {
  sideNavCollapsed: false,
  favoriteActive: false,
  theme: '',
};

const userSettingsSlice = createSlice({
  name: "variable",
  initialState,
  reducers: {
    setSideNavCollapsed: (state, action: PayloadAction<boolean>) => {
      state.sideNavCollapsed = action.payload;
    },
    setFavoriteActive: (state, action: PayloadAction<boolean>) => {
      state.favoriteActive = action.payload;
    },
  },
});

export const { setSideNavCollapsed, setFavoriteActive } = userSettingsSlice.actions;
export default userSettingsSlice.reducer;
