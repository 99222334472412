import { Card } from "src/components/ui/card/card";
import styles from "../../quickReports.module.scss";
import { Labels } from "src/constants/labels";
import { Control } from "react-hook-form";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";

export const ManagementFees = ({ control }: { control: Control<any, any> }) => {
  return (
    <Card>
      <div className="flex flex-col gap-4">
        <div className={`flex gap-4`}>
          <label className={`w-full border-b pt-2  font-semibold pb-4 ${styles.label}`}>{Labels.MANAGEMENT_FEES}</label>
        </div>
        <div className="flex items-center justify-between">
          <label className={`${styles.label}`}>{Labels.FEE_CODE_SERVICE}</label>
        </div>
        <div className="flex gap-1">
          <span className="basis-1/3">
            <SMDropDown control={control} options={[]} />
          </span>
          <SMDropDown control={control} options={[]} />
        </div>
      </div>
    </Card>
  );
};

export default ManagementFees;
