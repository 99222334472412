import { useMemo, useState } from "react";
import { TabViewPanel } from "src/components/ui/tabView/tabViewPanel";
import { Labels } from "src/constants/labels";
import { ITab } from "src/interfaces/tab";
import CorporationsReports from "./corporation";
import { useHttpGet } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";

const QuickReportsTabs = () => {
  const { data } = useHttpGet(`${EndPoints.corporation}manager-list`);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const quickReportsTabs: ITab[] = useMemo(() => {
    return [
      {
        title: Labels.CORPORATIONS,
        component: <CorporationsReports data={data} />,
      },
      {
        title: Labels.LOTS,
        component: <div></div>,
      },
      {
        title: Labels.REGISTERS,
        component: <div></div>,
      },
      {
        title: Labels.INSURANCE,
        component: <div></div>,
      },
      {
        title: Labels.R_M,
        component: <div></div>,
      },
      {
        title: Labels.RECEIPTS,
        component: <div></div>,
      },
      {
        title: Labels.PAYMENTS,
        component: <div></div>,
      },
      {
        title: Labels.CONTACTS,
        component: <div></div>,
      },
      {
        title: Labels.CREDITOR_INVOICES,
        component: <div></div>,
      },
      {
        title: Labels.CREDITORS,
        component: <div></div>,
      },
      {
        title: Labels.MEETINGS,
        component: <div></div>,
      },
    ];
  }, [data]);
  return (
    <div className="flex justify-center ml-10">
      <TabViewPanel tabs={quickReportsTabs} activeIndex={activeTabIndex} setActiveIndex={setActiveTabIndex}></TabViewPanel>
    </div>
  );
};

export default QuickReportsTabs;
