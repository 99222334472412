import React from "react";
import { Close } from "src/assets/icon";

interface IModal {
  isOpen: boolean;
  title?: string;
  onClose: any;
  onAction?: any;
  children: React.ReactNode;
  hideFooter?: boolean;
}

export const Modal: React.FC<IModal> = ({ isOpen = false, title, onClose, children, onAction, hideFooter = false }) => {
  if (!isOpen) return null;
  const handleClose = (e: any) => {
    if (e.target.id === "wrapper") onClose();
  };
  return (
    <div
      id="wrapper"
      className={`flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-modal h-screen overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer`}
      onClick={handleClose}
    >
      <div className="flex-wrap relative w-auto h-full p-4 lg:h-auto">
        <div className="relative rounded-lg bg-white shadow flex flex-col">
          {/* Modal header */}
          <div className="flex p-6 justify-between overflow-auto border-b">
            <label className="font-medium text-lg text-gray-900">{title}</label>
            <div className="flex order-last place-self-center">
              <span className="flex justify-end" data-testid="modalClose" onClick={() => onClose()}>
                <Close />
              </span>
            </div>
          </div>

          {/* Modal content */}
          <div className="p-10 border-b font-normal text-sm">{children}</div>

          {/* Modal footer */}
          {!hideFooter && (
            <div className="flex justify-end px-6 py-4">
              <div className="flex gap-2 flex-row order-last">
                <button data-testid="btnNoModal" className="bg-white text-black py-1.5 px-4 text-md rounded-md items-center border-2" onClick={() => onClose()}>
                  No
                </button>
                <button data-testid="btnYesModal" className="bg-blue-500 text-white py-1.5 px-4 text-md rounded-md items-center" onClick={() => onAction()}>
                  Yes
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
