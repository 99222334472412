import { useMountEffect } from "primereact/hooks";
import { Messages, MessagesMessage } from "primereact/messages";
import { useRef } from "react";

const MessageContainer = ({ messageProp, onClick }: { messageProp: MessagesMessage[]; onClick?: any;}) => {
  const messageRef = useRef<Messages>(null);

  useMountEffect(() => {
    messageRef?.current?.show(messageProp);
  });
  
  return(
    <>
      <Messages
          ref={messageRef}
          onClick={onClick}
      />
    </>
  )};

export default MessageContainer;
