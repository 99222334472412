import { Labels } from "src/constants/labels";
import styles from "../debtRecovery.module.scss";
import React, { useState } from "react";

export const DebtRecoveryChecks: React.FC<{ debtRecoveryDetails: any }> = ({ debtRecoveryDetails }) => {
  const [checks, setChecks] = useState({
    paymentPlanActive: debtRecoveryDetails?.paymentPlanActive,
    excludeDebtRecoveryNotice: debtRecoveryDetails?.excludeDebtRecoveryNotice,
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecks({ ...checks, [e?.target?.name]: e?.target?.checked });
  };

  return (
    <div className="flex flex-row mt-5">
      <div className="flex items-center mr-5">
        <input className={`${styles.checkbox} mr-2`} type="checkbox" name="paymentPlanActive" checked={checks?.paymentPlanActive} onChange={handleChange} disabled={true} />
        <label className="text">Payment Plan</label>
      </div>
      <div className="flex items-center">
        <input
          className={`${styles.checkbox} mr-2`}
          type="checkbox"
          name="excludeDebtRecoveryNotice"
          checked={checks?.excludeDebtRecoveryNotice}
          onChange={handleChange}
          disabled={true}
        />
        <label className="text">{Labels.EXCLUDE_FROM_DEBT_RECOVERY}</label>
      </div>
    </div>
  );
};
