import InsuranceHeader from "./insuranceHeader/insuranceHeader";
import Policies from "./policies/policies";

const InsuranceDetails = () => {
  return (
    <div className="page-container">
      <div className="mb-10">
        <Policies />
      </div>
      <div>
        <InsuranceHeader />
      </div>
    </div>
  );
};

export default InsuranceDetails;
