import React from "react";
import styles from "./inputControl.module.scss";
import { InputNumber } from "primereact/inputnumber";
import { checkZeroValue } from "src/utils/utility";
import { Controller } from "react-hook-form";
import { classNames } from "primereact/utils";

interface IModal {
  inputId?: string;
  field?: any;
  alignment?: string;
  value?: any;
  isEditable?: boolean;
  disabled?: boolean;
  min?: number;
  max?: number;
  maxLength?: number;
  width?: string;
  inputWidth?: string;
  suffix?: string;
  useGrouping?: boolean;
  control?: any;
  rules?: any;
  name?: string;
  minFractionDigits?: number;
  maxFractionDigits?: number;
  onChange?: Function;
  onBlur?: any;
  onKeyDown?: any;
}

export const SMInputNumber: React.FC<IModal> = ({
  inputId,
  field,
  alignment,
  isEditable,
  disabled = false,
  min,
  max,
  maxLength,
  value,
  width = "w-1/2",
  inputWidth = "",
  suffix = "",
  useGrouping = false,
  control,
  rules,
  name,
  minFractionDigits,
  maxFractionDigits,
  onChange,
  onBlur,
  onKeyDown,
}) => {
  return name && control ? (
    <Controller
      name={name ?? ""}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <InputNumber
          id={field.name}
          inputRef={field.ref}
          value={checkZeroValue(field?.value)}
          onBlur={field.onBlur}
          onKeyDown={onKeyDown}
          onChange={(e) => field.onChange(Number(e?.value))}
          pt={{
            root: { className: "w-full" },
            input: {
              root: { className: "w-full !rounded !basis-full !font-semibold !mt-0 h-10 !text-sm !outline-none" },
            },
          }}
          className={classNames({ "p-invalid": fieldState.error })}
          min={min}
          max={max}
          maxLength={maxLength}
          minFractionDigits={minFractionDigits}
          maxFractionDigits={maxFractionDigits}
          disabled={disabled}
          useGrouping={useGrouping}
          suffix={suffix}
        />
      )}
    />
  ) : (
    <InputNumber
      inputId={inputId || "inputNumber"}
      value={checkZeroValue(value)}
      onChange={(e) => {
        if (onChange) onChange(e?.value);
      }}
      onBlur={onBlur}
      onKeyDown={onKeyDown}
      pt={{
        root: { className: "w-full" },
        input: {
          root: { className: "w-full !rounded !basis-full !font-semibold !mt-0 h-10 !text-sm !outline-none" },
        },
      }}
      min={min}
      max={max}
      maxLength={maxLength}
      minFractionDigits={minFractionDigits}
      maxFractionDigits={maxFractionDigits}
      disabled={disabled}
      useGrouping={useGrouping}
      suffix={suffix}
    />
  );
};
