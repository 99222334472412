import { Toast } from "primereact/toast";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AddUser, DiagonalArrow, DocumentWord2, Line, MailMerge, Star, ViewColumn } from "src/assets/icon";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import ToastContainer from "src/components/ui/toast/toast";
import { comingSoon, httpCode, tradesmanStatus } from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import { ITradesmen } from "src/interfaces";
import { httpPost } from "src/services/api";
import { RootState } from "src/store/rootReducer";
import { addImportantContact, deleteImportantContact, setTradesmenContacts, updateTradesmenContacts } from "src/store/slice/contact";
import { findValue, mailToSend } from "src/utils/utility";

const TradesmenContactTable = ({ tradesmenContactList }: { tradesmenContactList: Array<ITradesmen> }) => {
  const { agency } = useSelector((state: RootState) => state?.agencySettingSlice);
  const { tradesmenContacts } = useSelector((state: RootState) => state?.contactSlice);
  const { corpId } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef<Toast>();
  const tradesmenContactTableButtons = [
    {
      buttonName: Labels.EDIT,
      type: "normal",
      buttonType: "button",
      onClick: () => {
        toast?.current?.show({
          severity: "info",
          summary: "Info",
          detail: comingSoon,
          life: 2000,
        });
      },
    },
    // { buttonName: <DocumentWord2 />, type: "word" },
    // { buttonName: <Line height={32} />, type: "line" },
    // { buttonName: <MailMerge />, type: "word" },
    // { buttonName: <Line height={32} />, type: "line" },
    // { buttonName: <ViewColumn /> },
    // { buttonName: <Line height={32} />, type: "line" },
    // { buttonName: <AddUser width={24} height={24} />, type: "word" },
  ];

  const checkTradesmenStatus = (isActive: boolean) => {
    return !isActive ? "text-red-500 font-semibold" : "";
  };

  const columns = useMemo(() => {
    return [
      {
        field: "creditorName",
        header: <p className="text !font-bold uppercase">{Labels.NAME}</p>,
        body: (data: any) => {
          return <p className={checkTradesmenStatus(data?.active)}>{data?.firstName} {data?.otherNames} {data?.name}</p>;
        },
        width: "10rem",
      },
      {
        field: "status",
        header: <p className="text !font-bold uppercase">{Labels.STATUS}</p>,
        body: (data: any) => {
          return <p className={checkTradesmenStatus(data?.active)}>{findValue(tradesmanStatus, data?.status)}</p>;
        },
      },
      {
        field: "description",
        header: <p className="text !font-bold uppercase">{Labels.TYPE}</p>,
        body: (data: any) => {
          return <p className={checkTradesmenStatus(data?.active)}>{data?.description}</p>;
        },
        width: "9rem",
      },
      {
        field: "workFrequency",
        header: <p className="text !font-bold uppercase">{Labels.FREQUENCY}</p>,
        body: (data: any) => {
          return <p className={checkTradesmenStatus(data?.active)}>{data?.workFrequency}</p>;
        },
      },
      {
        field: "workDays",
        header: <p className="text !font-bold uppercase">{Labels.DAYS}</p>,
        body: (data: any) => {
          return <p className={checkTradesmenStatus(data?.active)}>{data?.workDays}</p>;
        },
        width: "3rem",
      },
      {
        field: "telephone3",
        header: <p className="text !font-bold uppercase">{Labels.MOBILE?.substring?.(0, 6)}</p>,
        body: (data: any) => {
          return (
            <a
              className={`${checkTradesmenStatus(data?.active)} ${data?.active && "hyperlink"}`}
              href={data?.active ? `tel:${data?.telephone3}` : "javascript:void(0)"}
            >
              {data?.telephone3}
            </a>
          );
        },
        width: "6rem",
      },
      {
        field: "email2",
        header: <p className="text !font-bold uppercase">{Labels.EMAIL.substring?.(0, 5)}</p>,
        body: (data: any) => {
          return (
            data?.email2 && (
              <a
                className={`${checkTradesmenStatus(data?.active)} ${data?.active && "hyperlink"}`}
                onClick={() => {
                  if (data?.active)
                    mailToSend([{ ...data, email: data?.email2 }], toast, agency?.useAdditionalEmailAddresses, agency?.additionalEmailAddressOptions, false);
                }}
              >
                {data?.email2}
              </a>
            )
          );
        },
      },
      {
        field: "telephone1",
        header: <p className="text !font-bold uppercase">{Labels.TELEPHONE_1?.substring?.(0, 11)} (H)</p>,
        body: (data: any) => {
          return (
            <a
              href={data?.active ? `tel:${data?.telephone1}` : "javascript:void(0)"}
              className={`${checkTradesmenStatus(data?.active)} ${data?.active && "hyperlink"}`}
            >
              {data?.telephone1}
            </a>
          );
        },
        width: "7rem",
      },
      {
        field: "telephone2",
        header: <p className="text !font-bold uppercase">{Labels.TELEPHONE_2?.substring?.(0, 11)} (W)</p>,
        body: (data: any) => {
          return (
            <a
              href={data?.active ? `tel:${data?.telephone2}` : "javascript:void(0)"}
              className={`${checkTradesmenStatus(data?.active)} ${data?.active && "hyperlink"}`}
            >
              {data?.telephone2}
            </a>
          );
        },
        width: "7rem",
      },
      {
        field: "otherNames",
        header: <p className="text !font-bold uppercase">{Labels.CONTACT}</p>,
        body: (data: any) => {
          return <p className={checkTradesmenStatus(data?.active)}>{data?.otherNames}</p>;
        },
        width: "10rem",
      },
      {
        field: "notes",
        header: <p className="text !font-bold uppercase">{Labels.NOTES}</p>,
        body: (data: any) => {
          return <p className={checkTradesmenStatus(data?.active)}>{data?.notes}</p>;
        },
        width: "25rem",
      },
      {
        field: "option",
        header: <p className="text !font-bold uppercase"></p>,
        body: (data: any) => {
          return (
            <div className="flex gap-2 justify-end">
              {data?.active && (
                <span
                  onClick={() => {
                    markAsImportantContact(data);
                  }}
                >
                  <Star height={18} width={18} fill={data?.isImportantContact ? "#FFB433" : "none"} stroke={data?.isImportantContact ? "" : "#4B5563"} />
                </span>
              )}
              {/* <DiagonalArrow height={18} width={18} /> */}
            </div>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(setTradesmenContacts(tradesmenContactList));
  }, [tradesmenContactList]);

  const markAsImportantContact = async (tradesmen: any) => {
    setIsLoading(true);
    let res: any = await httpPost(`${EndPoints.contact}${EndPoints.plan}${corpId}/important-contacts`, {
      contactId: tradesmen?.contactId,
      isFavorite: !tradesmen?.isImportantContact,
    });
    if (res?.status === httpCode.SUCCESS) {
      toast?.current?.show({ severity: "success", summary: "Success", detail: Messages.SUCCESS_MESSAGE, life: 3000 });
      if (!tradesmen?.isImportantContact) {
        dispatch(
          addImportantContact({
            importantContactId: res?.data?.Id,
            ownersCorporationId: tradesmen?.ownersCorporationId,
            contactId: tradesmen?.contactId,
            contactType: tradesmen?.contactType,
            name: tradesmen?.name,
            firstName: tradesmen?.firstName,
            otherNames: tradesmen?.otherNames,
            homePhone: tradesmen?.telephone1,
            workPhone: tradesmen?.telephone2,
            mobile: tradesmen?.telephone3,
            email2: tradesmen?.email2,
          })
        );
      }
      if (tradesmen?.isImportantContact) {
        dispatch(deleteImportantContact(tradesmen?.contactId));
      }
      dispatch(updateTradesmenContacts({ contactId: tradesmen?.contactId, key: "isImportantContact", value: !tradesmen?.isImportantContact }));
    } else {
      toast?.current?.show({ severity: "error", summary: "Error", detail: Messages.ERROR_MESSAGE, life: 3000 });
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-modal overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <CollapsibleCard id="tradesmanTable" title={Labels.TRADESMEN} expand={true} buttons={tradesmenContactTableButtons}>
        <div className="w-full pt-4">
          <SMDataTableContainer tableData={tradesmenContacts} tableColumns={columns} showEditIcon={false} showSelectableRowCheckbox={false} />
          <ToastContainer toastReference={toast} />
        </div>
      </CollapsibleCard>
    </>
  );
};
export default TradesmenContactTable;
