import React from "react";
import styles from "./tabViewPanel.module.scss";
import { TabView, TabPanel } from "primereact/tabview";
import { ITab } from "../../../interfaces/tab";

interface TabViewPanelProps {
  tabs: ITab[];
  headerComponent?: React.JSX.Element;
  activeIndex: number;
  setActiveIndex: any;
}

export const TabViewPanel: React.FC<TabViewPanelProps> = ({ tabs, headerComponent, activeIndex, setActiveIndex }) => {
  return (
    <div className="w-full">
      <TabView
        className={styles.tabview}
        pt={{
          nav: { className: `${styles.navBackground}` },
        }}
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e?.index)}
      >
        {tabs.map(
          (tab, index) =>
            (tab?.visible ?? true) && (
              <TabPanel
                className={styles.activetab}
                key={index}
                header={tab.title}
                pt={{
                  header: {
                    className: "w-full",
                  },
                }}
              >
                {headerComponent}
                <div>{tab.component}</div>
              </TabPanel>
            )
        )}
      </TabView>
    </div>
  );
};
