import React from "react";
import { FormatDate } from 'src/utils/utility';
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Errors } from "src/components/shared/error/error";
import { httpCode, noDataFound } from "src/constants/constant";
import { Messages } from "src/constants/messages";
import { Labels } from "src/constants/labels";

export const LevySummary : React.FC<{data: any, error: any, status: any}> = ({data, error, status}) =>{
  if (error || httpCode.INTERNAL_SERVER_ERROR === status) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (httpCode.NOT_FOUND === status || httpCode.NO_CONTENT === status) {
    return <div className='flex w-full m-4'>{noDataFound}</div>;
  }

  if (!data) {
    return (
      <>
        <div className='flex w-full min-h-full items-center justify-center'>
          <div className='w-10 h-10'>
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    )
  }
  return(
    <div data-testid="levySummaryTab" className="basis-full text-sm">
        <div className="flex justify-between border-b">
            <label className="basis-full sm:px-3 py-2 text-left font-normal">{Labels.LAST_LEVY_NOTICE_PRINTED}</label>
            <span className="basis-full sm:px-3 py-2 text-right font-semibold">{FormatDate(data?.lastLevyNotice)}</span>
        </div>
        <div className="flex justify-between border-b">
            <label className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 text-left font-normal">{Labels.LAST_LEVY_NOTICE_EMAILED}</label>
            <span className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 text-right font-semibold">{FormatDate(data?.lastEmailedLevyNotice)}</span>
        </div>
    </div>
  )
}