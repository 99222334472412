'use client'
import { classNames } from "primereact/utils";
import { Controller,  } from "react-hook-form";
import { Checkbox } from "primereact/checkbox";
import styles from './checkbox.module.scss';

interface ICheckboxProps {
    id?: string,
    name?: string,
    label?: string;
    value?: boolean;
    disabled?: boolean;
    control?: any;
    rules?: object;
    width?: string;
    onChange?: Function;
    labelClassName?: string;
}

export const SMCheckbox: React.FC<ICheckboxProps> = ({id, name, label, value, disabled, control, rules, width, onChange, labelClassName}) => {
    return (
        name && control &&
            <Controller
                name={name}
                control={control}
                rules={rules}
                render={({ field, fieldState }) => (
                    <div className={`${width} flex gap-1 items-center`}>
                        <Checkbox 
                            inputId={field.name} 
                            checked={field.value || value}
                            inputRef={field.ref} 
                            className={classNames({ 'p-invalid': fieldState.error })} 
                            onChange={(e) => {
                                if(onChange) onChange(e.checked)
                                field.onChange(e.checked)
                            }}
                            pt ={{
                                root: { className: "flex !text-sm items-center"},
                                input: {className: `!w-4 !h-4 !rounded-sm !text-sm ${field.value || value ? styles.checkBoxInput : ''}`},
                                icon: { style: {stroke: "#e5e7eb"}}                                
                            }}
                            disabled={disabled} 
                        />
                        <label className={`text-sm ${labelClassName}`} htmlFor={field.name}>{label}</label>
                    </div>
                )}
            />
    )   
}