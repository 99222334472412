import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { EndPoints } from "src/constants/endpoint";
import { useHttpGet } from "src/services/api";
import { setPlanNumber } from "src/store/slice/planDetailSlice";

const usePlanNumber = (corpId: string) => {
  const { data } = useHttpGet(EndPoints.corporation + corpId ?? "");
  const [planNumberData, setPlanNumberData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(setPlanNumber(data?.planNumber));
      setPlanNumberData(data?.planNumber);
    }
  }, [data]);
  return planNumberData ?? "";
};

export default usePlanNumber;
