import { useEffect, useMemo, useRef, useState } from "react";
import { Pencil } from "src/assets/icon";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { Drawer } from "src/components/ui/drawer/drawer";
import { IconYesNoNull } from "src/components/ui/iconYesNoNull/iconYesNoNull";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { Labels } from "src/constants/labels";
import { IPolicy } from "src/interfaces";
import { FormatDate, toLocaleValue } from "src/utils/utility";
import PolicyTableDrawer from "./poilcyTableDrawer";
import { useDispatch } from "react-redux";
import { setPolicyList, setSelectedPolicy } from "src/store/slice/insurance";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";
import ToastContainer from "src/components/ui/toast/toast";
import Coverages from "../../coverages/coverages";

const PolicyTable = ({ policyData }: { policyData: Array<IPolicy> }) => {
  const { policyList } = useSelector((state: RootState) => state?.insuranceSlice);
  const dispatch = useDispatch();
  const [showActiveOnly, setShowActiveOnly] = useState(true);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const toastRef = useRef(null);
  const columns = useMemo(() => {
    return [
      {
        field: "expand",
        header: "",
        expander: true,
      },
      {
        field: "companyName",
        header: <p className="text !font-bold uppercase">{Labels.COMPANY}</p>,
      },
      {
        field: "policyNumber",
        header: <p className="text !font-bold">{Labels.POLICY_NUMBER}</p>,
      },
      {
        field: "policyStartDate",
        header: <p className="text !font-bold">{Labels.POLICY_START_DATE}</p>,
        body: (data: any) => {
          return <p>{FormatDate(data?.policyStartDate)}</p>;
        },
      },
      {
        field: "renewalDate",
        header: <p className="text !font-bold">{Labels.RENEWAL_DATE}</p>,
        body: (data: any) => {
          return <p>{FormatDate(data?.renewalDate)}</p>;
        },
      },
      {
        field: "lastPremium",
        header: <p className="text !font-bold">{Labels.LAST_PREMIUM}</p>,
        body: (data: any) => {
          return <p>${toLocaleValue(data?.lastPremium)}</p>;
        },
      },
      {
        field: "datePaid",
        header: <p className="text !font-bold">{Labels.DATE_PAID}</p>,
        body: (data: any) => {
          return <p>{FormatDate(data?.datePaid)}</p>;
        },
      },
      {
        field: "brokerName",
        header: <p className="text !font-bold">{Labels.BROKER}</p>,
      },
      {
        field: "description",
        header: <p className="text !font-bold">{Labels.TYPE}</p>,
      },
      {
        field: "notes",
        header: <p className="text !font-bold uppercase">{Labels.NOTES}</p>,
        body: (data: any) => {
          return <p className="break-all">{data?.notes}</p>;
        },
        width: "15rem",
      },
      {
        field: "active",
        header: <p className="text !font-bold uppercase">{Labels.ACTIVE}</p>,
        body: (data: any) => {
          return <IconYesNoNull type={data?.active} />;
        },
      },
      {
        field: "edit",
        header: "",
        body: (data: any) => {
          return (
            <span
              id="btnEdit"
              data-test-id="btnEdit"
              onClick={() => {
                setShowSidebar(true);
                dispatch(setSelectedPolicy(data));
              }}
            >
              <Pencil />
            </span>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(setPolicyList(policyData));
  }, []);

  const rowExpansionTemplate = (data: IPolicy) => {
    return <Coverages key={data?.insuranceId} insuranceId={data?.insuranceId} />;
  };

  return (
    <CollapsibleCard id="policyTable" title={Labels.POLICIES} expand={true} buttons={[]}>
      <div className="w-full pt-4">
        <div className="mt-5">
          <div className="flex items-center justify-end">
            <input
              type="checkbox"
              checked={showActiveOnly}
              onChange={(e) => {
                setShowActiveOnly(e?.target?.checked);
              }}
            />
            <label className="text-sm ml-2">Show Active Only</label>
          </div>
          <SMDataTableContainer
            tableData={showActiveOnly ? policyList?.filter?.((policy: IPolicy) => Boolean(policy?.active)) : policyList}
            tableColumns={columns}
            dataKey="insuranceId"
            showEditIcon={false}
            showSelectableRowCheckbox={false}
            rowExpansionTemplate={rowExpansionTemplate}
          />
          <Drawer
            title={Labels.EDIT_INSURANCE_POLICY}
            isOpen={showSidebar}
            onClose={() => {
              setShowSidebar(false);
            }}
            width="w-[50vw]"
          >
            <PolicyTableDrawer toastRef={toastRef} setShowSidebar={setShowSidebar} />
          </Drawer>
        </div>
      </div>
      <ToastContainer toastReference={toastRef} />
    </CollapsibleCard>
  );
};

export default PolicyTable;
