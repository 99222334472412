"use client";

import { PropsWithChildren } from "react";
import ReduxProvider from "./reduxProvider";
import PrimeReactRootProvider from "./primeReactProvider";

type P = PropsWithChildren;

export default function Providers({ children }: P) {
  return (
    <>
      <ReduxProvider>
        <PrimeReactRootProvider>
            {children}
        </PrimeReactRootProvider>
      </ReduxProvider>
    </>
  );
}
