import React from "react";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { IPolicy } from "src/interfaces";
import InsuranceSummarySection from "./insuranceSummarySection";
import { httpCode } from "src/constants/constant";
import { Errors } from "src/components/shared/error/error";
import { Messages } from "src/constants/messages";

export const InsuranceSummary: React.FC<{ data: Array<IPolicy>; error: any; statusCode: number; compareDates: any }> = ({
  data,
  error,
  statusCode,
  compareDates,
}) => {
  if (error || httpCode.INTERNAL_SERVER_ERROR === statusCode) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (statusCode === httpCode.NO_CONTENT || statusCode === httpCode.NOT_FOUND) {
    return <InsuranceSummarySection policyList={[]} />;
  }

  if (!data) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-10 h-10">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }

  return <InsuranceSummarySection policyList={data?.filter?.((policy: IPolicy) => Boolean(policy?.active))} compareDates={compareDates} />;
};
