import { useHttpGet } from "src/services/api";
import { Errors } from "src/components/shared/error/error";
import { Messages } from "src/constants/messages";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import UnitEntitlementSetForm from "./unitEntitlementSetForm";
import { httpCode, noDataFound } from "src/constants/constant";

const UnitEntitlementSetTableDrawer = ({ toastRef, setShowSidebar }: { toastRef: any; setShowSidebar: any; }) => {
  const { data: entitlementTypes, error: entitlementTypesError, statusCode: entitlementTypesStatus } = useHttpGet("unit-entitlement-types");

  if (entitlementTypesError || httpCode.INTERNAL_SERVER_ERROR === entitlementTypesStatus) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (httpCode.NOT_FOUND === entitlementTypesStatus || httpCode.NO_CONTENT === entitlementTypesStatus) {
    return <div className="flex w-full m-4">{noDataFound}</div>;
  }

  if (!entitlementTypes) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }
  return <UnitEntitlementSetForm entitlementTypes={entitlementTypes} toastRef={toastRef} setShowSidebar={setShowSidebar}/>;
};

export default UnitEntitlementSetTableDrawer;