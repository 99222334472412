import React, { useState } from "react";
import styles from "./datePicker.module.scss";
import { Calendar, CalendarChangeEvent, CalendarDateTemplateEvent } from "primereact/calendar";
import { dateFormat, datePickerPlaceHolder } from "../../../constants/constant";
import { CalendarWeek } from "../../../assets/icon";
import { FormatDate } from "../../../utils/utility";
import { minDate as defaultMinDate } from "../../../constants/constant";
import { Controller } from "react-hook-form";
import { classNames } from "primereact/utils";

interface IDatePickerProps {
  inputDate: string;
  disabled?: boolean;
  setState?: any;
  formatDate?: string;
  width?: string;
  minDate?: Date | null;
  required?: boolean;
  control?: any;
  name?: string;
  rules?: any;
  dateTemplate?: (event: CalendarDateTemplateEvent) => React.ReactNode;
}

export const SMDatePicker: React.FC<IDatePickerProps> = ({
  inputDate,
  disabled = false,
  setState,
  formatDate = dateFormat,
  width = "w-1/2",
  minDate,
  required = true,
  control,
  name,
  rules,
  dateTemplate,
}) => {
  let [day, month, year] = inputDate?.split?.("/");

  const [date, setDate] = useState<Date | Date[] | string | null>(new Date(Number(year), Number(month) - 1, Number(day)));

  const handleDateChange = (e: CalendarChangeEvent) => {
    if (e?.value) {
      setDate(e?.value);
      setState(FormatDate(e?.value as string));
    }
  };

  return (
    <div className={`${width}`}>
      {control ? (
        <Controller
          name={name ?? ""}
          control={control}
          rules={rules}
          render={({ field, fieldState }) => {
            return (
              <>
                <Calendar
                  pt={{
                    root: { className: `w-full h-10` },
                    input: { root: { className: `${styles.dateInput}` } },
                  }}
                  inputId={field.name}
                  showButtonBar
                  todayButtonClassName="p-secondary-button"
                  clearButtonClassName="p-secondary-button"
                  placeholder={datePickerPlaceHolder}
                  value={field?.value && FormatDate(field?.value) !== "" ? new Date(field.value) : ""}
                  onChange={field.onChange}
                  dateFormat={formatDate}
                  disabled={disabled}
                  minDate={minDate ?? defaultMinDate}
                  className={classNames({ "p-invalid": fieldState.error })}
                  dateTemplate={dateTemplate}
                />
              </>
            );
          }}
        />
      ) : (
        <Calendar
          pt={{
            root: { className: `w-full h-10` },
            input: { root: { className: `${styles.dateInput}` } },
          }}
          required={required}
          placeholder={datePickerPlaceHolder}
          dateFormat={formatDate}
          disabled={disabled}
          showButtonBar
          todayButtonClassName="p-secondary-button"
          clearButtonClassName="p-secondary-button"
          value={date}
          minDate={minDate ?? defaultMinDate}
          onChange={handleDateChange}
        />
      )}

      <div className={styles.calendarIconWrapper}>
        <div className={styles.calendarIcon}>
          <CalendarWeek />
        </div>
      </div>
    </div>
  );
};
