import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { Labels } from "src/constants/labels";
import styles from "../../../budget.module.scss";
import { Plus } from "src/assets/icon";
import { RootState } from "src/store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import { IAccounts, IBudgetExpenseRevenue } from "src/interfaces";
import { toLocaleValue } from "src/utils/utility";
import { setRevenueList } from "src/store/slice/budget";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import debounce from "lodash.debounce";
import { EndPoints } from "src/constants/endpoint";
import { httpPost } from "src/services/api";
import { useParams } from "react-router-dom";
import { SMInputNumber } from "src/components/ui/inputControl/inputNumber";
import ToastContainer from "src/components/ui/toast/toast";
import { httpCode, minNegativeInteger, toastErrorTimeLimit } from "src/constants/constant";
import { Toast } from "primereact/toast";
import { Messages } from "src/constants/messages";

const RevenueTable = ({ revenueData, accounts }: { revenueData: Array<IBudgetExpenseRevenue>; accounts: Array<IAccounts> }) => {
  const { corpId } = useParams();
  const { showActualForNonBudgetAccounts, selectedDate, revenueList } = useSelector((state: RootState) => state?.budgetSlice);
  const dispatch = useDispatch();
  const toastRef = useRef<Toast>(null);
  const revenueTableButtons = [
    {
      buttonName: (
        <div style={{ background: "#0093DD" }} className="p-1 rounded">
          <Plus stroke="#fff" />
        </div>
      ),
      type: "word",
      id: "btnAddRevenue",
      disabled: !selectedDate?.budgetId,
      onClick: () => {
        dispatch(
          setRevenueList([
            {
              budgetId: selectedDate?.budgetId,
              budgetDetailId: 0,
              accountId: 0,
              fundId: 0,
              accountName: "",
              accountType: "",
              accountNumber: "",
              previousYear: {
                budgetedAmount: 0,
                actualAmount: 0,
              },
              thisYear: {
                budgetedAmount: 0,
                actualAmount: 0,
              },
              nextYear: {
                budgetedAmount: 0,
                actualAmount: 0,
              },
            },
            ...revenueList,
          ])
        );
      },
    },
  ];
  const columns = useMemo(() => {
    return [
      {
        field: "accountName",
        header: "",
        body: (data: any, options: { rowIndex: number }) => {
          return (
            <p id={`revenue-accountName-${options?.rowIndex}`} className={`${styles.text}`}>
              {data?.accountNumber} {data?.accountName}
            </p>
          );
        },
        editor: (editorData: any) => accountNameEditor(editorData),
      },
      {
        field: "previousYear.budgetedAmount",
        header: "",
        body: (data: any) => {
          return <p className={`${styles.text}`}>{toLocaleValue(Math.abs(data?.previousYear?.budgetedAmount))}</p>;
        },
      },
      {
        field: "previousYear.actualAmount",
        header: "",
        body: (data: any) => {
          return <p className={`${styles.text}`}>{toLocaleValue(Math.abs(data?.previousYear?.actualAmount))}</p>;
        },
      },
      {
        field: "thisYear.budgetedAmount",
        header: "",
        body: (data: any, options: { rowIndex: number }) => {
          return (
            <p id={`revenue-budgetedAmount-${options?.rowIndex}`} className={`${styles.text}`}>
              {toLocaleValue(Math.abs(data?.thisYear?.budgetedAmount))}
            </p>
          );
        },
        editor: (data: any) => thisYearBudgetedAmountEditor(data),
      },
      {
        field: "thisYear.actualAmount",
        header: "",
        body: (data: any) => {
          return <p className={`${styles.text}`}>{toLocaleValue(Math.abs(data?.thisYear?.actualAmount))}</p>;
        },
      },
      {
        field: "nextYear.budgetedAmount",
        header: "",
        body: (data: any) => {
          return <p className={`${styles.text}`}>{toLocaleValue(data?.nextYear?.budgetedAmount)}</p>;
        },
      },
    ];
  }, [accounts, revenueList]);

  let revenueTotal = useMemo(() => {
    return revenueList
      ?.filter?.((revenue: IBudgetExpenseRevenue) => (showActualForNonBudgetAccounts ? true : revenue?.budgetId))
      ?.reduce?.(
        (accumulator, item) => {
          if (!showActualForNonBudgetAccounts) {
            return {
              ...accumulator,
              surplusDeficitBudgeted: {
                ...accumulator.surplusDeficitBudgeted,
                prevAdminBudgeted: accumulator?.surplusDeficitBudgeted?.prevAdminBudgeted + Math.abs(item?.previousYear?.budgetedAmount),
                prevAdminActual: accumulator?.surplusDeficitBudgeted?.prevAdminActual + Math.abs(item?.previousYear?.actualAmount),
                adminBudgeted: accumulator?.surplusDeficitBudgeted?.adminBudgeted + Math.abs(item?.thisYear?.budgetedAmount),
                adminActual: accumulator?.surplusDeficitBudgeted?.adminActual + Math.abs(item?.thisYear?.actualAmount),
                nextAdminBudgeted: accumulator?.surplusDeficitBudgeted?.nextAdminBudgeted + Math.abs(item?.nextYear?.budgetedAmount),
              },
            };
          } else {
            return {
              ...accumulator,
              surplusDeficitNonBudgeted: {
                ...accumulator.surplusDeficitNonBudgeted,
                prevAdminBudgeted: accumulator?.surplusDeficitNonBudgeted?.prevAdminBudgeted + Math.abs(item?.previousYear?.budgetedAmount),
                prevAdminActual: accumulator?.surplusDeficitNonBudgeted?.prevAdminActual + Math.abs(item?.previousYear?.actualAmount),
                adminBudgeted: accumulator?.surplusDeficitNonBudgeted?.adminBudgeted + Math.abs(item?.thisYear?.budgetedAmount),
                adminActual: accumulator?.surplusDeficitNonBudgeted?.adminActual + Math.abs(item?.thisYear?.actualAmount),
                nextAdminBudgeted: accumulator?.surplusDeficitNonBudgeted?.nextAdminBudgeted + Math.abs(item?.nextYear?.budgetedAmount),
              },
            };
          }
        },
        {
          surplusDeficitBudgeted: {
            prevAdminBudgeted: 0,
            prevAdminActual: 0,
            adminBudgeted: 0,
            adminActual: 0,
            nextAdminBudgeted: 0,
          },
          surplusDeficitNonBudgeted: {
            prevAdminBudgeted: 0,
            prevAdminActual: 0,
            adminBudgeted: 0,
            adminActual: 0,
            nextAdminBudgeted: 0,
          },
        }
      );
  }, [showActualForNonBudgetAccounts, selectedDate?.budgetId, revenueList]);

  useEffect(() => {
    dispatch(setRevenueList(revenueData));
  }, []);

  const debounceEventHandler = useCallback(
    debounce((data: { data: Array<IBudgetExpenseRevenue>; budgetDetailId: number; budgetId: number; accountId: number; amount: number }) => {
      handleRowDataSave(data);
    }, 500),
    []
  );

  useEffect(() => {
    return () => {
      debounceEventHandler?.cancel?.();
    };
  }, [debounceEventHandler]);

  const accountNameEditor = (editorData: any) => {
    return (
      <SMDropDown
        value={editorData?.rowData?.accountNumber + " " + editorData?.rowData?.accountName}
        options={accounts
          ?.filter(
            (account: IAccounts) =>
              !revenueList?.some(
                (revenue: IBudgetExpenseRevenue) => account?.accountId === revenue?.accountId && account?.accountId !== editorData?.rowData?.accountId
              )
          )
          ?.map((account: IAccounts) => {
            return { ...account, accountName: account?.accountNumber + " " + account?.name };
          })}
        optionValue="accountName"
        optionLabel="accountName"
        filter
        onChange={(e: any) => {
          if (
            editorData?.rowData?.thisYear?.actualAmount ||
            editorData?.rowData?.previousYear?.actualAmount ||
            editorData?.rowData?.previousYear?.budgetedAmount
          ) {
            toastRef?.current?.show({
              severity: "warn",
              summary: "Warning",
              detail: Messages.BUDGET_ACCOUNT_EDIT_VALIDATION,
              life: toastErrorTimeLimit,
            });
          } else {
            let account = accounts?.find?.((account: IAccounts) => account?.accountNumber + " " + account?.name === e?.value) as IAccounts;
            if (account?.accountId) {
              let newRevenueList = revenueList?.map?.((revenue: IBudgetExpenseRevenue, index: number) => {
                if (revenue?.accountId ? editorData?.rowData?.accountId === revenue?.accountId : index === editorData?.rowIndex) {
                  return {
                    ...revenue,
                    accountId: account?.accountId,
                    accountNumber: account?.accountNumber,
                    accountName: account?.name,
                    accountType: account?.accountType,
                    fundId: account?.fundId,
                  };
                } else {
                  return {
                    ...revenue,
                  };
                }
              });
              dispatch(setRevenueList(newRevenueList));
              if (editorData?.rowData?.thisYear?.budgetedAmount || editorData?.rowData?.budgetDetailId) {
                debounceEventHandler({
                  data: newRevenueList,
                  budgetDetailId: editorData?.rowData?.budgetDetailId,
                  budgetId: editorData?.rowData?.budgetId,
                  accountId: account?.accountId,
                  amount: editorData?.rowData?.thisYear?.budgetedAmount,
                });
              } else {
                toastRef?.current?.show({
                  severity: "warn",
                  summary: "Warning",
                  detail: Messages.BUDGETEDAMOUNT_EDIT_VALIDATION,
                  life: toastErrorTimeLimit,
                });
              }
            }
          }
        }}
      />
    );
  };

  const thisYearBudgetedAmountEditor = (data: any) => {
    return (
      <SMInputNumber
        inputId="revenueBudgetedAmount"
        value={data?.value}
        minFractionDigits={2}
        maxLength={17}
        min={minNegativeInteger}
        onChange={(value: number) => {
          if (!data?.rowData?.accountId) {
            toastRef?.current?.show({
              severity: "warn",
              summary: "Warning",
              detail: Messages.BUDGET_ACCOUNT_VALIDATION,
              life: toastErrorTimeLimit,
            });
          } else {
            let newRevenueList = revenueList?.map?.((revenue: IBudgetExpenseRevenue) => {
              if (data?.rowData?.budgetDetailId === revenue?.budgetDetailId && data?.rowData?.accountId === revenue?.accountId) {
                return {
                  ...revenue,
                  thisYear: {
                    ...revenue.thisYear,
                    budgetedAmount: value ?? 0,
                  },
                };
              } else {
                return {
                  ...revenue,
                };
              }
            });
            dispatch(setRevenueList(newRevenueList));
            debounceEventHandler({
              data: newRevenueList,
              budgetDetailId: data?.rowData?.budgetDetailId,
              budgetId: data?.rowData?.budgetId,
              accountId: data?.rowData?.accountId,
              amount: value ?? 0,
            });
          }
        }}
      />
    );
  };

  const handleRowDataSave = async (data: {
    data: Array<IBudgetExpenseRevenue>;
    budgetDetailId: number;
    budgetId: number;
    accountId: number;
    amount: number;
  }) => {
    let response: any = await httpPost(`${EndPoints.corporation}${corpId}/${EndPoints.budget}budget-detail/${data?.budgetDetailId}`, {
      budgetId: selectedDate?.budgetId,
      accountId: data?.accountId,
      amount: data?.amount,
    });
    if (response?.status === httpCode.SUCCESS && response?.data?.result?.[0]?.Id) {
      let updatedRevenueList = data?.data?.map?.((revenue: IBudgetExpenseRevenue) => {
        if (revenue?.accountId === data?.accountId) {
          return {
            ...revenue,
            budgetId: selectedDate?.budgetId,
            budgetDetailId: response?.data?.result?.[0]?.Id,
          };
        } else {
          return { ...revenue };
        }
      }) as Array<IBudgetExpenseRevenue>;
      dispatch(setRevenueList(updatedRevenueList));
    }
    if (response?.error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Error: ${response?.error}`,
        life: toastErrorTimeLimit,
      });
    }
  };

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column className="invisible !border-none" colSpan={1} />
        <Column className={`${styles.textStyle} ${styles.expenseColumnGroup}`} header={Labels.LAST_PERIOD} colSpan={2} />
        <Column className={`${styles.textStyle} ${styles.expenseColumnGroup}`} header={Labels.CURRENT_PERIOD} colSpan={2} />
        <Column className={`${styles.textStyle} ${styles.expenseColumnGroup}`} header={Labels.NEXT_PERIOD} colSpan={2} />
      </Row>
      <Row>
        <Column className={`${styles.textStyle} ${styles.accountColumn}`} header={Labels.ACCOUNT_NAME} />
        <Column className={`${styles.textStyle} ${styles.budgetedActualColumn}`} header={Labels.BUDGETED} />
        <Column className={`${styles.textStyle} ${styles.budgetedActualColumn}`} header={Labels.ACTUAL} />
        <Column className={`${styles.textStyle} ${styles.budgetedActualColumn}`} header={Labels.BUDGETED} />
        <Column className={`${styles.textStyle} ${styles.budgetedActualColumn}`} header={Labels.ACTUAL} />
        <Column className={`${styles.textStyle} ${styles.budgetedActualColumn}`} header={Labels.BUDGETED} />
      </Row>
    </ColumnGroup>
  );

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          className={`${styles.lotContributionFooter} ${styles.textStyle}`}
          footer={Labels.TOTALS}
          colSpan={1}
          footerStyle={{ textAlign: "right", textTransform: "uppercase" }}
        />
        <Column
          className={`${styles.lotContributionFooter} ${styles.textStyle}`}
          footer={toLocaleValue(
            showActualForNonBudgetAccounts
              ? revenueTotal?.surplusDeficitNonBudgeted?.prevAdminBudgeted
              : revenueTotal?.surplusDeficitBudgeted?.prevAdminBudgeted
          )}
        />
        <Column
          className={`${styles.lotContributionFooter} ${styles.textStyle}`}
          footer={toLocaleValue(
            showActualForNonBudgetAccounts ? revenueTotal?.surplusDeficitNonBudgeted?.prevAdminActual : revenueTotal?.surplusDeficitBudgeted?.prevAdminActual
          )}
        />
        <Column
          className={`${styles.lotContributionFooter} ${styles.textStyle}`}
          footer={toLocaleValue(
            showActualForNonBudgetAccounts ? revenueTotal?.surplusDeficitNonBudgeted?.adminBudgeted : revenueTotal?.surplusDeficitBudgeted?.adminBudgeted
          )}
        />
        <Column
          className={`${styles.lotContributionFooter} ${styles.textStyle}`}
          footer={toLocaleValue(
            showActualForNonBudgetAccounts ? revenueTotal?.surplusDeficitNonBudgeted?.adminActual : revenueTotal?.surplusDeficitBudgeted?.adminActual
          )}
        />
        <Column
          className={`${styles.lotContributionFooter} ${styles.textStyle}`}
          footer={toLocaleValue(
            showActualForNonBudgetAccounts
              ? revenueTotal?.surplusDeficitNonBudgeted?.nextAdminBudgeted
              : revenueTotal?.surplusDeficitBudgeted?.nextAdminBudgeted
          )}
        />
      </Row>
    </ColumnGroup>
  );

  return (
    <CollapsibleCard id="revenueTable" title={Labels.REVENUE} expand={true} buttons={revenueTableButtons}>
      <div className="w-full pt-4">
        <SMDataTableContainer
          tableData={showActualForNonBudgetAccounts ? revenueList : revenueList?.filter?.((revenue: IBudgetExpenseRevenue) => revenue?.budgetId)}
          tableColumns={columns}
          editMode="cell"
          showEditIcon={false}
          showSelectableRowCheckbox={false}
          headerGroup={headerGroup}
          footerGroup={footerGroup}
          showGridlines
          stripedRows
        />
      </div>
      <ToastContainer toastReference={toastRef} />
    </CollapsibleCard>
  );
};

export default RevenueTable;
