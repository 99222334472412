import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBudgetDate, IBudgetDatesData, IBudgetExpenseRevenue } from "src/interfaces";

interface IBudgetSlice {
  showActualForNonBudgetAccounts: boolean;
  selectedDate: IBudgetDate;
  budgetDates: IBudgetDatesData;
  expenseList: Array<IBudgetExpenseRevenue>;
  revenueList: Array<IBudgetExpenseRevenue>;
}

const initialState: IBudgetSlice = {
  showActualForNonBudgetAccounts: false,
  selectedDate: {} as IBudgetDate,
  budgetDates: {} as IBudgetDatesData,
  expenseList: [],
  revenueList: [],
};

const budgetSlice = createSlice({
  name: "budget",
  initialState,
  reducers: {
    setShowActualForNonBudgetAccounts: (state, action: PayloadAction<boolean>) => {
      state.showActualForNonBudgetAccounts = action.payload;
    },
    setBudgetDates: (state, action: PayloadAction<IBudgetDatesData>) => {
      state.budgetDates = action.payload;
    },
    setSelectedDate: (state, action: PayloadAction<IBudgetDate>) => {
      state.selectedDate = action.payload;
    },
    setExpenseList: (state, action: PayloadAction<Array<IBudgetExpenseRevenue>>) => {
      state.expenseList = action.payload;
    },
    setRevenueList: (state, action: PayloadAction<Array<IBudgetExpenseRevenue>>) => {
      state.revenueList = action.payload;
    },
  },
});

export const { setShowActualForNonBudgetAccounts, setSelectedDate, setBudgetDates, setExpenseList, setRevenueList } = budgetSlice.actions;
export default budgetSlice.reducer;
