import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IColumn } from "src/components/ui/table/tableContainer";

interface IQuickReportsSlice {
  tableColumns: Array<IColumn>;
}

const initialState: IQuickReportsSlice = {
  tableColumns: [],
};

const quickReportsSlice = createSlice({
  name: "quickReports",
  initialState,
  reducers: {
    setTableColumns: (state, action: PayloadAction<Array<IColumn>>) => {
      state.tableColumns = action.payload;
    },
  },
});

export const { setTableColumns } = quickReportsSlice.actions;
export default quickReportsSlice.reducer;
