import { Labels } from "src/constants/labels";
import styles from "../agent.module.scss";
import AdditionalContactDetails from "src/components/shared/contact/additionalContactDetails/additionalContactDetails";

const AgentContact = ({ agentContact, agentContactId }: { agentContact: any; agentContactId: any; }) => {
  return (
    agentContactId !== 0 && agentContact?.contactId && (
      <>
        <div className="mt-5 mb-4">
          <label className={`text !font-bold ${styles.textStyle}`}>
            {agentContact?.businessContact
              ? Labels.BUSINESS_CONTACT
              : Labels.PRIVATE_RESIDENTIAL_CONTACT}
          </label>
        </div>
        <div
          className={`pt-4 pb-2 flex flex-col items-start self-stretch !h-auto ${styles.borderTop}`}
        >
        {
          agentContact?.businessContact && agentContact?.otherNames &&
            <div className={`text !h-auto ${styles.textContainer}`}>
              <p className={`text ${styles.textStyle}`}>
                {Labels.CONTACT_PERSON}
              </p>
              <p className="ml-1">
                {agentContact?.otherNames}{" "}
                {agentContact?.salutation &&
                  `(${Labels.SALUTATION}${agentContact?.salutation})`}
              </p>
            </div>
        }
        {
          !agentContact?.businessContact && agentContact?.salutation && 
            <div data-test-id='salutation' className={`text !h-auto ${styles.textContainer}`}>
              <p data-test-id='lblSalution' className={`text ${styles.textStyle}`}>{Labels.SALUTATION}</p>
              <p className="ml-1">{agentContact?.salutation}</p>
            </div>
        }
          <div className={`text !h-auto ${styles.textContainer}`}>
            {agentContact?.businessContact ? (
              <p>{agentContact?.name}</p>
            ) : (
              <p>
                {agentContact?.title} {agentContact?.firstName}{" "}
                {agentContact?.otherNames} {agentContact?.name}
              </p>
            )}
          </div>
          {agentContact?.poBox && (
            <div className={`text !h-auto ${styles.textContainer}`}>
              <p>{agentContact?.poBox}</p>
            </div>
          )}
          {!agentContact?.poBox && agentContact?.buildingName && (
            <div className={`text !h-auto ${styles.textContainer}`}>
              <p>{agentContact?.buildingName}</p>
            </div>
          )}
          {!agentContact?.poBox && (
            <div className={`text !h-auto ${styles.textContainer}`}>
              <p>
                {agentContact?.streetNumber} {agentContact?.streetName}
              </p>
            </div>
          )}

          <div className={`text !h-auto ${styles.textContainer}`}>
            <p>
              {agentContact?.town} {agentContact?.state}{" "}
              {agentContact?.postcode} {agentContact?.country}
            </p>
          </div>
        </div>
        <div
          className={`pt-4 flex flex-col items-start self-stretch ${styles.borderTop}`}
        >
          {agentContact?.telephone1 && (
            <div className={`text ${styles.textContainer}`}>
              <p className={`text ${styles.textStyle}`}>
                {agentContact?.businessContact
                  ? Labels.TELEPHONE_1
                  : Labels.HOME_PHONE}
              </p>
              <a
                href={`tel:${agentContact?.telephone1}`}
                className="ml-1 underline"
              >
                {agentContact?.telephone1}
              </a>
            </div>
          )}
          {agentContact?.telephone2 && (
            <div className={`text ${styles.textContainer}`}>
              <p className={`text ${styles.textStyle}`}>
                {agentContact?.businessContact
                  ? Labels.TELEPHONE_2
                  : Labels.WORK_PHONE}
              </p>
              <a
                href={`tel:${agentContact?.telephone2}`}
                className="ml-1 underline"
              >
                {agentContact?.telephone2}
              </a>
            </div>
          )}
          {agentContact?.telephone3 && (
            <div className={`text ${styles.textContainer}`}>
              <p className={`text ${styles.textStyle}`}>{Labels.MOBILE}</p>
              <a
                href={`tel:${agentContact?.telephone3}`}
                className="ml-1 underline"
              >
                {agentContact?.telephone3}
              </a>
            </div>
          )}
          {agentContact?.fax && (
            <div className={`text ${styles.textContainer}`}>
              <p className={`text ${styles.textStyle}`}>{Labels.FAX}</p>
              <p className="ml-1">{agentContact?.fax}</p>
            </div>
          )}
          {agentContact?.email && (
            <div className={`text ${styles.textContainer}`}>
              <p className={`text ${styles.textStyle}`}>{Labels.EMAIL}</p>
              <a
                href={`mailto:${agentContact?.email}`}
                className="ml-1 underline hyperlink"
              >
                {agentContact?.email}
              </a>
            </div>
          )}
          {agentContact?.levyEmailAddress && (
            <div className={`text ${styles.textContainer}`}>
              <p className={`text ${styles.textStyle}`}>
                {Labels.LEVY_EMAIL_ADDRESS}
              </p>
              <a
                href={`mailto:${agentContact?.levyEmailAddress}`}
                className="ml-1 underline hyperlink"
              >
                {agentContact?.levyEmailAddress}
              </a>
            </div>
          )}
          {agentContact?.website && (
            <div className={`text ${styles.textContainer}`}>
              <p className={`text ${styles.textStyle}`}>{Labels.WEBSITE}</p>
              <a
                href={`https://${agentContact?.website}`}
                target="_blank"
                className="ml-1 underline hyperlink"
                rel="noreferrer"
              >
                {agentContact?.website}
              </a>
            </div>
          )}
        </div>
        <AdditionalContactDetails additionalContactDetails={agentContact?.additionalContactDetails} isBusiness={agentContact?.businessContact} />
      </>
    )
  );
};

export default AgentContact;
