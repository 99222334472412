import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Errors } from "src/components/shared/error/error";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { SMInputNumber } from "src/components/ui/inputControl/inputNumber";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { SMInputTextArea } from "src/components/ui/inputControl/inputTextarea";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Modal } from "src/components/ui/modal/modal";
import { cancelButtonText, httpCode, modalDiscardMessageText, saveButtonText, toastErrorTimeLimit, toastSuccessTimeLimit } from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import { IEntitlemetType } from "src/interfaces/entitlementType";
import { IUnitEntitlementSet } from "src/interfaces/unitentitlementset";
import { httpUpdate } from "src/services/api";
import { RootState } from "src/store/rootReducer";
import { setEntitlementSetList } from "src/store/slice/entitlementSlice";
import { findValue, validateDecimal } from "src/utils";

const UnitEntitlementSetForm = ({
  entitlementTypes,
  toastRef,
  setShowSidebar,
}: {
  entitlementTypes: Array<IEntitlemetType>;
  toastRef: any;
  setShowSidebar: any;
}) => {
  const { corpId } = useParams();
  const dispatch = useDispatch();
  const { selectedEntitlementSet, entitlementSetList } = useSelector((state: RootState) => state?.entitlementSlice);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      unitEntitlementSetId: selectedEntitlementSet?.unitEntitlementSetId,
      ownersCorporationId: selectedEntitlementSet?.ownersCorporationId,
      unitEntitlementName: selectedEntitlementSet?.unitEntitlementName,
      totalEntitlements: selectedEntitlementSet?.totalEntitlements ?? 0,
      entitlementTypeId: selectedEntitlementSet?.entitlementTypeId,
      description: selectedEntitlementSet?.description,
      showOnPortals: selectedEntitlementSet?.showOnPortals,
      entitlementName: selectedEntitlementSet?.entitlementName,
    },
  });

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });
 
  function onDiscard() {
    setShowModal(false);
    setShowSidebar(false);
  }

  function onCancel() {
    if (isDirty) {
      setShowModal(true);
    } else {
      setShowSidebar(false);
    }
  }

  const entitlementTypesList = entitlementTypes?.map((entitlement: { entitlementTypeId: number; name: string;}) => ({
    id: entitlement.entitlementTypeId,
    name: entitlement.name,
  }));

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    let response = await httpUpdate(EndPoints.corporation + corpId + "/entitlement-sets", [data]);
    if (response?.status === httpCode.SUCCESS) {
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: toastSuccessTimeLimit,
      });
      dispatch(
        setEntitlementSetList(
          entitlementSetList?.map((entitlement: IUnitEntitlementSet) => {
            if (entitlement?.unitEntitlementSetId === selectedEntitlementSet?.unitEntitlementSetId) {
              return {
                ...entitlement,
                ...data,
                entitlementName: findValue(entitlementTypesList, data?.entitlementTypeId)
              };
            } else {
              return {
                ...entitlement,
              };
            }
          })
        )
      );
      reset({}, { keepDirty: false, keepValues: true });
      setShowSidebar(false);
    }
    if (response?.error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Error: ${response?.error}`,
        life: toastErrorTimeLimit,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div className="flex fixed justify-center items-center z-50 lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-1.5 mt-5">
          <label className="required">{Labels.NAME.charAt(0).toUpperCase() + Labels.NAME.substring(1).toLowerCase()}</label>
          <SMInputText name="unitEntitlementName" control={control} maxlength={20} rules={{ required: Messages.REQUIRED_MESSAGE }} />
          {errors?.unitEntitlementName && <Errors error={errors?.unitEntitlementName?.message} alignment="left" />}
        </div>
        <div className="flex flex-col gap-1.5 mt-5">
          <label>
            {Labels.TOTAL} {Labels.ENTITLEMENTS}
          </label>
          <SMInputNumber
            name="totalEntitlements"
            control={control}
            maxLength={9}
            useGrouping={true}
            minFractionDigits={0}
            maxFractionDigits={2}
            rules={{
              max: { value: 999999999, message: Messages.TOTAL_ENTITLEMENT_VALIDATION },
              validate: (value: string) => {
                return validateDecimal(value, 2) || Messages.RESERVE_FUND_VALIDATION;
              },
            }}
          />
          {errors?.totalEntitlements && <Errors error={errors?.totalEntitlements?.message} alignment="left" />}
        </div>
        <div className="flex flex-col gap-1.5 mt-5">
          <label className="required">{Labels.ENTITLEMENT_TYPE}</label>
          <SMDropDown
            name="entitlementTypeId"
            value={findValue(entitlementTypesList, selectedEntitlementSet?.entitlementTypeId)}
            control={control}
            options={entitlementTypesList}
            optionLabel="name"
            optionValue="id"
            rules={{ required: Messages.REQUIRED_MESSAGE }}
          />
          {errors?.entitlementTypeId && <Errors error={errors?.entitlementTypeId?.message} alignment="left" />}
        </div>
        <div className="flex flex-col gap-1.5 mt-5">
          <label>{Labels.DESCRIPTION}</label>
          <SMInputTextArea name="description" control={control} />
        </div>
        <div className="flex flex-col gap-1.5 mt-5">
          <div className="items-center gap-1.5">
            <SMCheckbox name="showOnPortals" control={control} label={Labels.SHOW_ON_PORTALS} />
          </div>
        </div>
        <div className="flex justify-end gap-2 flex-row order-last mt-5 mr-5">
          <button type="button" onClick={onCancel} className="bg-white text-black py-1.5 px-4 text-md rounded-md items-center border-2">
            {cancelButtonText}
          </button>
          <button
            type="submit"
            disabled={!isDirty}
            className={`bg-blue-500 text-white py-1.5 px-6 text-md rounded-md items-center ${!isDirty ? "opacity-60" : "opacity-100"}`}
          >
            {saveButtonText}
          </button>
        </div>
      </form>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Warning" onAction={() => onDiscard()}>
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
    </>
  );
};

export default UnitEntitlementSetForm;
