import React, { useMemo } from "react";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { Labels } from "src/constants/labels";
import { IUnpaidDebit } from "src/interfaces/unpaidDebts";
import { FormatDate } from "src/utils/utility";

export const UnpaidDebitsTable: React.FC<{ unpaidDebitDetails: IUnpaidDebit[] }> = ({ unpaidDebitDetails }) => {
  function dueDateDebits(due: any){
    const dueDate = new Date(due);
    const currentDate = new Date();
    dueDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    const isOverdue = dueDate < currentDate;
    const textStyle = isOverdue ? "text-red-500" : "";  
    return textStyle;
  }
  const columns = useMemo(() => {
    return [
      {
        field: "due",
        header: <p className="text !font-bold">{Labels.DATE.toUpperCase()}</p>,
        body: (data: any) => {
          const textStyle = dueDateDebits(data.due);
          return <p className={textStyle}>{FormatDate(data?.due.toString())}</p>;
        },
      },
      {
        field: "description",
        header: <p className="text !font-bold">{Labels.DESCRIPTION.toUpperCase()}</p>,
        body: (data: any) => {
          const textStyle = dueDateDebits(data.due);
          return <p className={`${textStyle} font-semibold`}>{data?.description}</p>;
        },
      },
      {
        field: "adminDebt",
        header: <p className="text !font-bold">{Labels.ADMIN_DUE.toUpperCase()}</p>,
        body: (data: any) => {
          const textStyle = dueDateDebits(data.due);
          return <p className={` ${textStyle}`}>{"$"+ data?.adminDebt.toFixed(2)}</p>;
        },
      },
      {
        field: "adminPaid",
        header: <p className="text !font-bold">{Labels.PAID.toUpperCase()}</p>,
        body: (data: any) => {
          const textStyle = dueDateDebits(data.due);
          return <p className={` ${textStyle}`}>{"$"+ data?.adminPaid.toFixed(2)}</p>;
        },
      },
      {
        field: "sinkDebt",
        header: <p className="text !font-bold">{Labels.CAPITAL_WORKS_DUE.toUpperCase()}</p>,
        body: (data: any) => {
          const textStyle = dueDateDebits(data.due);
          return <p className={` ${textStyle}`}>{"$"+ data?.sinkDebt.toFixed(2)}</p>;
        },
      },
      {
        field: "sinkPaid",
        header: <p className="text !font-bold">{Labels.PAID.toUpperCase()}</p>,
        body: (data: any) => {
          const textStyle = dueDateDebits(data.due);
          return <p className={` ${textStyle}`}>{"$"+ data?.sinkPaid.toFixed(2)}</p>;
        },
      },
      {
        field: "otherDebt",
        header: <p className="text !font-bold">{Labels.OTHER_DUE.toUpperCase()}</p>,
        body: (data: any) => {
          const textStyle = dueDateDebits(data.due);
          return <p className={` ${textStyle}`}>{"$"+ data?.otherDebt.toFixed(2)}</p>;
        },
      },
      {
        field: "otherPaid",
        header: <p className="text !font-bold">{Labels.PAID.toUpperCase()}</p>,
        body: (data: any) => {
          const textStyle = dueDateDebits(data.due);
          return <p className={` ${textStyle}`}>{"$"+ data?.otherPaid.toFixed(2)}</p>;
        },
      },
      {
        field: "outstanding",
        header: <p className="text !font-bold">{Labels.OUTSTANDING.toUpperCase()}</p>,
        body: (data: any) => {
          const textStyle = dueDateDebits(data.due);
          return <p className={`${textStyle}`}>{"$"+ data?.outstanding.toFixed(2)}</p>;
        },
      },
    ];
  }, []);

  return (
    unpaidDebitDetails?.length > 0 ? 
        (
            <div className="w-full pt-4">
                <SMDataTableContainer tableData={unpaidDebitDetails} tableColumns={columns} showEditIcon={false} showSelectableRowCheckbox={false} showPaginator={true} defaultPageSize={7} />
            </div>
        ) : 
        (
        <div className={`w-full rounded-lg text-center shadow-lg flex-row p-10 mt-5`}>
          <p>{Labels.NO_DATA_AVAILABLE}</p>
        </div>
        )
    )
};
