import { Control, Controller } from "react-hook-form";
import { Card } from "src/components/ui/card/card";
import { SMRadioButton } from "src/components/ui/radioControl/radioButton";
import { Corporations } from "src/constants/enum";
import { Labels } from "src/constants/labels";
import styles from "../../quickReports.module.scss";
import { useDispatch } from "react-redux";
import { setTableColumns } from "src/store/slice/quickReports";

const OutputOptions = ({ control }: { control: Control<any, any> }) => {
  const dispatch = useDispatch();
  return (
    <Card>
      <div className="flex flex-col gap-4">
        <div className={`flex gap-4`}>
          <label className="w-full border-b pt-2 text-sm font-semibold text-gray-500 pb-4">{Labels.OUTPUT_OPTIONS}</label>
        </div>
        <div>
          <Controller
            name="outputOptionValue"
            control={control}
            render={({ field }) => (
              <div className="flex flex-col gap-3">
                <div className="flex items-center">
                  <SMRadioButton
                    id="showOwnersCorporation"
                    field={field}
                    value={Corporations.OwnersCorporations}
                    checked={field?.value === Corporations.OwnersCorporations}
                    onChange={() => {
                      dispatch(
                        setTableColumns([
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.lOwnersCorpId}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.PLANNO}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.BODY_CORPORATE_NAME}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.ADDRESS_FOR_SORTING}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.SUBURB_FOR_SORTING}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.TAX_YEAR_END}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.FINANCIAL_YEAR_END}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.LAST_LEVY_NOTICE_PRINTED}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.LAST_LEVY_NOTICE_EMAILED}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.ASSOCIATION_TYPE}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.JURISDICTION}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.DATE_BUILT}</p>,
                          },
                          {
                            field: "",
                            header: (
                              <p className="text !font-bold">
                                {Labels.DATE} {Labels.REGISTERED}
                              </p>
                            ),
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.INITIAL_PERIOD}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.ADDRESS}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.LOTS}</p>,
                          },
                          {
                            field: "",
                            header: (
                              <p className="text !font-bold">
                                {Labels.PRIMARY} {Labels.LOTS}
                              </p>
                            ),
                          },
                          {
                            field: "",
                            header: (
                              <p className="text !font-bold">
                                {Labels.UTILITY} {Labels.LOTS}
                              </p>
                            ),
                          },
                          {
                            field: "",
                            header: (
                              <p className="text !font-bold">
                                {Labels.RESIDENTIAL} {Labels.LOTS}
                              </p>
                            ),
                          },
                          {
                            field: "",
                            header: (
                              <p className="text !font-bold">
                                {Labels.COMMERCIAL} {Labels.LOTS}
                              </p>
                            ),
                          },
                          {
                            field: "",
                            header: (
                              <p className="text !font-bold">
                                {Labels.RETIREMENT} {Labels.LOTS}
                              </p>
                            ),
                          },
                          {
                            field: "",
                            header: (
                              <p className="text !font-bold">
                                {Labels.OTHER} {Labels.LOTS}
                              </p>
                            ),
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.MANAGER}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.ABN}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.TFN}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.GST_STATUS}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.GST_FREQUENCY}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.INTERIM_REPORTING_PERIOD}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.FIRST_AGM}</p>,
                          },
                        ])
                      );
                    }}
                  />
                  <label htmlFor="managed" className={`ml-2 ${styles.label}`}>
                    {Labels.SHOW_OWNERS_CORPORATION}
                  </label>
                </div>
                <div className="flex items-center">
                  <SMRadioButton
                    id="showManagementFees"
                    field={field}
                    value={Corporations.ManagementFees}
                    checked={field?.value === Corporations.ManagementFees}
                    onChange={() => {
                      dispatch(
                        setTableColumns([
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.PLANNO}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.ADDRESS_FOR_SORTING}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.SUBURB_FOR_SORTING}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.LOTS}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.MANAGER}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.FEE_CODE_SERVICE}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.CURRENT_RATE}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.NEW_RATE}</p>,
                          },
                          {
                            field: "",
                            header: <p className="text !font-bold">{Labels.AUTO_BILL}</p>,
                          },
                        ])
                      );
                    }}
                  />
                  <label htmlFor="all" className={`ml-2 ${styles.label}`}>
                    {Labels.SHOW_MANAGEMENT_FEES}
                  </label>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </Card>
  );
};

export default OutputOptions;
