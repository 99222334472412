import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import { IPolicy } from "src/interfaces/policyList";
import { FormatDate } from "src/utils/utility";

const InsuranceSummarySection = ({ policyList, compareDates }: { policyList: Array<IPolicy>; compareDates?: any }) => {
  return (
    <div data-testid="insuranceSummaryTab" className="basis-full text-sm">
      {policyList?.length > 0 ? (
        policyList?.map?.((policy: IPolicy) => (
          <div key={policy?.insuranceId}>
            <div className="flex justify-between border-b">
              <label className="basis-full sm:px-3 py-2 text-left font-normal">
                {policy?.description} {Labels.POLICY}
              </label>
              <span className="basis-full sm:px-3 py-2 text-left font-normal">{policy?.description}</span>
            </div>
            <div className="w-full flex justify-between border-b">
              <label className="w-1/2 flex-auto sm:px-3 py-2 text-left font-normal">
                {policy?.description} {Labels.POLICY_DATE}
              </label>
              <div className="w-1/2 flex">
                <span className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 text-left font-semibold">
                  {FormatDate(policy?.policyStartDate)} - {FormatDate(policy?.renewalDate)}
                </span>
                <div className="flex items-center">{compareDates(policy?.renewalDate)}</div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="flex m-4">{Messages.NO_ACTIVE_POLICIES}</p>
      )}
    </div>
  );
};

export default InsuranceSummarySection;
