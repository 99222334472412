import { useHttpGet } from "src/services/api";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { IUnitEntitlementSet } from "src/interfaces/unitentitlementset";
import { EndPoints } from "src/constants/endpoint";
import { Messages } from "src/constants/messages";
import { Errors } from "src/components/shared/error/error";
import UnitEntitlementTable from "./unitEntitlementTable";
import { httpCode } from "src/constants/constant";

export const UnitEntitlement: React.FC<{ headerEntitlement: IUnitEntitlementSet[] | null; corpId: any }> = ({ headerEntitlement, corpId }) => {
  const { data, error, statusCode } = useHttpGet(EndPoints.corporation + `${corpId}/unit-entitlements`);

  if (error) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (statusCode === httpCode.NOT_FOUND || statusCode === httpCode.NO_CONTENT || statusCode === httpCode.INTERNAL_SERVER_ERROR) {
    return <UnitEntitlementTable unitEntitlementData={[]} headerEntitlement={headerEntitlement} />;
  }

  if (!data || !headerEntitlement) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }

  return <UnitEntitlementTable unitEntitlementData={data} headerEntitlement={headerEntitlement} />;
};
