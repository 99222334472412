import { useForm } from "react-hook-form";
import { IOtherAddressList } from "src/interfaces/otherAddressList";
import { errorText, modalDiscardMessageText, successText } from "src/constants/constant";
import { Errors } from "src/components/shared/error/error";
import { httpUpdate } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import styles from "./otherAddressesForm.module.scss";
import { Modal } from "src/components/ui/modal/modal";
import { useEffect } from "react";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import { checkZeroValue, seprateStreetNumber } from "src/utils/utility";

const OtherAddressesForm = ({
  formData,
  setShowModal,
  handleChange,
  rowIndex,
  setIsLoading,
  toastRef,
  corpId,
  addressData,
  setAddressTableData,
  isLoading,
  setIsFormDirty,
  showCancelModal,
  setShowCancelModal,
}: {
  formData: IOtherAddressList;
  setShowModal: any;
  handleChange: any;
  rowIndex: number;
  setIsLoading: any;
  toastRef: any;
  corpId: any;
  addressData: any;
  setAddressTableData: any;
  isLoading: boolean;
  setIsFormDirty: any;
  showCancelModal: boolean;
  setShowCancelModal: any;
}) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      sBuildingName: formData?.sBuildingName ?? "",
      streetNumber: (formData?.sStreetNumberPrefix ?? "") + (checkZeroValue(formData?.lStreetNumber) ?? "") + (formData?.sStreetNumberSuffix ?? ""),
      sStreet: formData?.sStreet ?? "",
      sTown: formData?.sTown ?? "",
      sPostcode: formData?.sPostcode ?? "",
    },
  });

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty]);

  const onSubmit = async (data: any) => {
    const street = seprateStreetNumber(data?.streetNumber);
    let reqBody = {
      ...data,
      lStreetAddressID: formData?.lStreetAddressID,
      lStreetNumber: Number(street?.number),
      sStreetNumberPrefix: street?.prefix,
      sStreetNumberSuffix: street?.suffix,
    };
    delete reqBody.streetNumber;
    setIsLoading(true);
    const response = await httpUpdate(EndPoints.corporation + `${corpId}/structure/addresses`, [reqBody]);
    if (response?.status === 200) {
      toastRef?.current?.show({ severity: "success", summary: "Success", detail: successText, life: 3000 });
      setShowModal(false);
      handleChange(rowIndex, reqBody);
    }
    if (response?.error) {
      toastRef?.current?.show({ severity: "error", summary: "Error", detail: errorText, life: 3000 });
      setAddressTableData(addressData);
    }
    setIsLoading(false);
  };

  return (
    formData && (
      <>
        {isLoading && (
          <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-modal overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
            <div className="w-20 h-20">
              <SpinnerHorizontal />
            </div>
          </div>
        )}
        <form id="otherAddressesForm" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="w-full pl-6 pr-6">
            <div className="flex flex-col mt-5">
              <label className={styles.labelMargin}>BUILDING NAME</label>
              <SMInputText name="sBuildingName" control={control} maxlength={30} />
            </div>
            <div className="flex flex-col mt-5">
              <label className={styles.labelMargin}>NO.</label>
              <SMInputText name="streetNumber" control={control} maxlength={10} />
            </div>
            <div className="flex flex-col mt-5">
              <label className={styles.labelMargin}>STREET</label>
              <SMInputText name="sStreet" control={control} />
            </div>
            <div className="flex flex-col mt-5">
              <label className={styles.labelMargin}>TOWN</label>
              <SMInputText name="sTown" control={control} />
            </div>
            <div className="flex flex-col mt-5 mb-5">
              <label className={styles.labelMargin}>POSTCODE</label>
              <SMInputText name="sPostcode" control={control} maxlength={10} />
            </div>
          </div>
        </form>
        <Modal
          isOpen={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          title="Warning"
          onAction={() => {
            setShowCancelModal(false);
            setShowModal(false);
          }}
        >
          <div>
            <h2>{modalDiscardMessageText}</h2>
          </div>
        </Modal>
      </>
    )
  );
};

export default OtherAddressesForm;
