import { Toast } from "primereact/toast";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DiagonalArrow, DocumentWord2, Line, MailMerge, Star, ViewColumn } from "src/assets/icon";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import ToastContainer from "src/components/ui/toast/toast";
import { comingSoon, httpCode, type } from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import { IBuildingManager } from "src/interfaces";
import { httpPost } from "src/services/api";
import { RootState } from "src/store/rootReducer";
import { addImportantContact, deleteImportantContact, setBuildingManager, updateBuildingManager } from "src/store/slice/contact";
import { ConvertRTFToPlainText, findValue, mailToSend } from "src/utils/utility";

const BuildingManagerTable = ({ buildingManagerList }: { buildingManagerList: Array<IBuildingManager> }) => {
  const { agency } = useSelector((state: RootState) => state?.agencySettingSlice);
  const { buildingManager } = useSelector((state: RootState) => state?.contactSlice);
  const { corpId } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef<Toast>();
  const buildingMangerTableButtons = [
    {
      buttonName: Labels.EDIT,
      type: "normal",
      buttonType: "button",
      onClick: () => {
        toast?.current?.show({
          severity: "info",
          summary: "Info",
          detail: comingSoon,
          life: 2000,
        });
      },
    },
    // { buttonName: <DocumentWord2 />, type: "word" },
    // { buttonName: <Line height={32} />, type: "line" },
    // { buttonName: <MailMerge />, type: "word" },
    // { buttonName: <Line height={32} />, type: "line" },
    // { buttonName: <ViewColumn /> },
  ];
  const columns = useMemo(() => {
    return [
      {
        field: "name",
        header: <p className="text !font-bold uppercase">{Labels.NAME}</p>,
        body: (data: any) => {
          return (
            <p>
              {data?.firstName} {data?.otherNames} {data?.name}
            </p>
          );
        },
      },
      {
        field: "type",
        header: <p className="text !font-bold uppercase">{Labels.TYPE}</p>,
        body: (data: any) => {
          return <p>{findValue(type, data?.contactType)}</p>;
        },
      },
      {
        field: "telephone3",
        header: <p className="text !font-bold uppercase">{Labels.MOBILE?.substring?.(0, 6)}</p>,
        body: (data: any) => {
          return (
            <a href={`tel:${data?.telephone3}`} className="hyperlink">
              {data?.telephone3}
            </a>
          );
        },
      },
      {
        field: "email",
        header: <p className="text !font-bold uppercase">{Labels.EMAIL.substring?.(0, 5)}</p>,
        body: (data: any) => {
          return (
            data?.email && (
              <a
                className="hyperlink"
                onClick={() => {
                  mailToSend([data], toast, agency?.useAdditionalEmailAddresses, agency?.additionalEmailAddressOptions, false);
                }}
              >
                {data?.email}
              </a>
            )
          );
        },
      },
      {
        field: "telephone1",
        header: <p className="text !font-bold uppercase">{Labels.TELEPHONE_1?.substring?.(0, 11)} (H)</p>,
        body: (data: any) => {
          return (
            <a href={`tel:${data?.telephone1}`} className="hyperlink">
              {data?.telephone1}
            </a>
          );
        },
      },
      {
        field: "telephone2",
        header: <p className="text !font-bold uppercase">{Labels.TELEPHONE_2?.substring?.(0, 11)} (W)</p>,
        body: (data: any) => {
          return (
            <a href={`tel:${data?.telephone2}`} className="hyperlink">
              {data?.telephone2}
            </a>
          );
        },
      },
      {
        field: "notes",
        header: <p className="text !font-bold uppercase">{Labels.NOTES}</p>,
        body: (data: any) => {
          return <p>{ConvertRTFToPlainText(data?.buildingManagerNotes)}</p>;
        },
        width: "15rem",
      },
      {
        field: "option",
        header: <p className="text !font-bold uppercase"></p>,
        body: (data: any) => {
          return (
            <div className="flex justify-end">
              <span onClick={() => markAsImportantContact(data)}>
                <Star height={18} width={18} fill={data?.isImportantContact ? "#FFB433" : "none"} stroke={data?.isImportantContact ? "" : "#4B5563"} />
              </span>
              {/* <DiagonalArrow height={18} width={18} /> */}
            </div>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(setBuildingManager(buildingManagerList));
  }, [buildingManagerList]);

  const markAsImportantContact = async (buildingManager: any) => {
    setIsLoading(true);
    let res: any = await httpPost(`${EndPoints.contact}${EndPoints.plan}${corpId}/important-contacts`, {
      contactId: buildingManager?.contactId,
      isFavorite: !buildingManager?.isImportantContact,
    });
    if (res?.status === httpCode.SUCCESS) {
      toast?.current?.show({ severity: "success", summary: "Success", detail: Messages.SUCCESS_MESSAGE, life: 3000 });
      if (!buildingManager?.isImportantContact) {
        dispatch(
          addImportantContact({
            importantContactId: res?.data?.Id,
            ownersCorporationId: buildingManager?.ownersCorporationId,
            contactId: buildingManager?.contactId,
            contactType: buildingManager?.contactType,
            name: buildingManager?.name,
            firstName: buildingManager?.firstName,
            otherNames: buildingManager?.otherNames,
            homePhone: buildingManager?.telephone1,
            workPhone: buildingManager?.telephone2,
            mobile: buildingManager?.telephone3,
            email: buildingManager?.email,
          })
        );
      }
      if (buildingManager?.isImportantContact) {
        dispatch(deleteImportantContact(buildingManager?.contactId));
      }
      dispatch(updateBuildingManager({ contactId: buildingManager?.contactId, key: "isImportantContact", value: !buildingManager?.isImportantContact }));
    } else {
      toast?.current?.show({ severity: "error", summary: "Error", detail: Messages.ERROR_MESSAGE, life: 3000 });
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-modal overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <CollapsibleCard id="buildingManagerTable" title={Labels.BUILDING_MANAGER} expand={true} buttons={buildingMangerTableButtons}>
        <div className="w-full pt-4">
          <SMDataTableContainer tableData={buildingManager} tableColumns={columns} showEditIcon={false} showSelectableRowCheckbox={false} />
          <ToastContainer toastReference={toast} />
        </div>
      </CollapsibleCard>
    </>
  );
};
export default BuildingManagerTable;
