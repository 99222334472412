import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { DotsVertical, Pencil } from "src/assets/icon";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { Labels } from "src/constants/labels";
import { IBankList } from "src/interfaces";
import { FormatDate } from "src/utils/utility";

export const InvestmentAccTable: React.FC<{ investmentAccDetails: IBankList[], corpId: any }> = ({ investmentAccDetails, corpId }) => {
  const columns = useMemo(() => {
    return [
      {
        field: "bankAccount",
        header: <p className="text !font-bold">{Labels.BANK_ACCOUNT}</p>,
        body: (data: any) => {
          return <Link to={`/bank/${data.sAccountName}`} data-testid='planLink'>  
                      {data.sAccountName}
                  </Link>
        },
      },
      {
        field: "expiry",
        header: <p className="text !font-bold">{Labels.EXPIRY}</p>,
        body: (data: any) => {
          return <p>{FormatDate(data?.dDueDate.toString())}</p>;
        },
      },
      {
        field: "rate",
        header: <p className="text !font-bold">{Labels.RATE}</p>,
        body: (data: any) => {
          return <p>{data?.mInterestRate}</p>;
        },
      },
      {
        field: "glAccount",
        header: <p className="text !font-bold">{Labels.G_L_ACCOUNT}</p>,
      },
      {
        field: "bsb",
        header: <p className="text !font-bold">{Labels.BSB}</p>,
        body: (data: any) => {
          return <p>{data?.sBSB}</p>;
        },
      },
      {
        field: "accountNumber",
        header: <p className="text !font-bold">{Labels.ACCOUNT_NUMBER}</p>,
        body: (data: any) => {
          return <p>{data?.sAccountNumber}</p>;
        },
      },
      {
        field: "group",
        header: <p className="text !font-bold">{Labels.GROUP}</p>,
      },
      {
        field: "verticalDots",
        header: <p className="text !font-bold"><DotsVertical /></p>,
        body: (data: any) => {
          return <p><Pencil width={14} height={14} /></p>;
        },
      },
    ];
  }, []);

  return (
        investmentAccDetails?.length > 0 ? 
        (
            <div className="w-full pt-4">
                <SMDataTableContainer tableData={investmentAccDetails} tableColumns={columns} showEditIcon={false} showSelectableRowCheckbox={false} />
            </div>
        ) : 
        (
        <div className={`w-full rounded-lg text-center shadow-lg flex-row p-10 mt-5`}>
          <p>{Labels.NO_DATA_AVAILABLE}</p>
        </div>
        )
    )
};
