import { useHttpGet } from 'src/services/api'
import { IBankList } from 'src/interfaces/'
import { SpinnerHorizontal } from 'src/components/ui/loading/spinnerHorizontal'
import { DropdownOptions } from 'src/components/ui/dropdown';
import { SetOptionKeys } from 'src/utils/utility';
import { EndPoints } from 'src/constants/endpoint';
import { errorMessage } from 'src/constants/constant';


export const MainAccountList: React.FC<{corpId: any}> = ({corpId}) => {

  const { data, error } = useHttpGet(EndPoints.bankaccount + `plan/${corpId}/main-accounts`) as { data: IBankList[], error: any};

    if (error) {
      return (
        <>
          <div className="text-red-500 m-4">{errorMessage}</div>
        </>
      )
    }

    if (!data) {
      return (
        <>
          <div className='flex w-full min-h-full items-center justify-center'>
            <div className='w-10 h-10'>
              <SpinnerHorizontal />
            </div>
          </div>
        </>
      )
    }

    return (
              <div className='bg-white rounded-lg'>
                <DropdownOptions 
                  id="mainAccounts"
                  options={SetOptionKeys(data, 'lBankAccountID', 'sAccountName', 'sAccountName')}
                  defaultLabel="Please select main bank account"
                />
              </div>
            
    )
}
