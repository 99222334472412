"use client";

import { useRef, useState } from "react";
import { NoticesAddress } from "./noticesAddress/noticesAddress";
import { NoticesMinutes } from "./noticesMinute/noticesMinutes";
import { RejectedLevy } from "./rejectedLevy/rejectedLevy";
import { NoticePreview } from "./noticesPreview/noticePreview";
import { NoticeContact } from "./noticesContact/noticeContact";
import { AddUser, Binocular, DocumentWord2, Edit, Line, Mail } from "src/assets/icon";
import { Card } from "src/components/ui/card/card";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { Labels } from "src/constants/labels";
import { useForm } from "react-hook-form";
import { cancelButtonText, contactHeader, modalDiscardMessageText, saveButtonText, selectButtonText } from "src/constants/constant";
import { Modal } from "src/components/ui/modal/modal";
import ToastContainer from "src/components/ui/toast/toast";
import { Toast } from "primereact/toast";
import { httpUpdate } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { Messages } from "src/constants/messages";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";
import { mailToSend } from "src/utils";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import { Errors } from "src/components/shared/error/error";
import FormModal from "src/components/ui/dialog/formModal";
import { ContactDetail } from "../../shared/contactDetail";

export default function Notices({ noticeDetails, lotDetails, corpId, lotId }: { noticeDetails: any; lotDetails: any; corpId: string; lotId: string }) {
  const [lotDetail, setLotDetail]= useState<any>(lotDetails);
  const [noticeDetail, setNoticeDetail] = useState<any>(noticeDetails);
  const [contactDetail, setContactDetail] = useState(
    Object.assign({}, ...lotDetail?.lotContacts?.filter?.((contact: any) => contact?.contactId === noticeDetail?.noticeContactID))
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAddContactModal, setShowAddContactModal] = useState<boolean>(false);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [openContactModal, setOpenContactModal] = useState<any>(null);
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      ownerID: noticeDetail?.ownerID,
      lotID: noticeDetail?.lotID,
      noticeContactID: noticeDetail?.noticeContactID,
      noticesIssue: noticeDetail?.noticesIssue,
      noticeCorrespondenceIssue: noticeDetail?.noticeCorrespondenceIssue,
      receiveExecCommNotices: noticeDetail?.receiveExecCommNotices,
      excludeFromNoticesAndMinutes: noticeDetail?.excludeFromNoticesAndMinutes,
      useOwnersNoticeEmail: noticeDetail?.useOwnersNoticeEmail,
      useAgentsNoticeEmail: noticeDetail?.useAgentsNoticeEmail,
      useTenantsNoticeEmail: noticeDetail?.useTenantsNoticeEmail,
      useOwnersCorrespondenceEmail: noticeDetail?.useOwnersCorrespondenceEmail,
      useAgentsCorrespondenceEmail: noticeDetail?.useAgentsCorrespondenceEmail,
      useTenantsCorrespondenceEmail: noticeDetail?.useTenantsCorrespondenceEmail,
    },
  });
  const toastRef = useRef<Toast>(null);
  const noticesIssue = watch("noticesIssue");
  const noticeCorrespondenceIssue = watch("noticeCorrespondenceIssue");

  const { agency } = useSelector(
    (state: RootState) => state?.agencySettingSlice
  );
  useUnsavedChangesPrompt({ unsavedChanges: isDirty });
  let otherType =
    contactDetail?.contactId === 0 ||
    (contactDetail?.contactId !== lotDetail?.ownerContactId &&
    contactDetail?.contactId !== lotDetail?.tenantContactId &&    
    contactDetail?.contactId !== lotDetail?.agentContactId)
      ? true
      : false;

  const leviesDetailButtons = [
    { buttonName: otherType ? <AddUser height={24} width={24} /> : "", 
      type:  "",
      onClick: (e: any) => {
        e.preventDefault();
        setShowAddContactModal(true);
        setOpenContactModal(true);
      }
    },
    { buttonName: otherType ? <Edit fill="#464B4F" /> : "",
      type:  "",
      onClick: (e: any) => {
        e.preventDefault();
        if(Object.values(contactDetail).length > 0){ 
          if(contactDetail?.contactType === Labels.AGENT_CONTACT_TYPE){
            toastRef?.current?.show({
              severity: "warn",
              summary: "Warning",
              detail: Messages.NO_CHANGE_FOR_REAL_ESTATE_AGENT,
              life: 2000,
            });
          }
          else {
            setShowAddContactModal(true);
            setOpenContactModal(false);
          }
 
        }
        else {
          toastRef?.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: Messages.NO_CONTACT_SELECTED,
            life: 2000,
          });        
        }
      }
    },
    { buttonName: otherType ? <Binocular /> : "", 
      type:  "next",
      onClick: (e: any) => {
        e.preventDefault();
        setShowContactModal(true);
      }
    },
    // { buttonName: <DocumentWord2 height={24} width={24} />, type: "word", buttonType: "button" },
    // { buttonName: <Line height={32} />, type: "line", buttonType: "button" },
    { buttonName: <Mail height={24} width={24} />, type: "next", buttonType: "button", onClick: (e: any) => {
      e.preventDefault();
      mailToSend(
        [
          {
          ...contactDetail,
          committeeEmailAddress: null,
          }
        ],
        toastRef,
        agency?.useAdditionalEmailAddresses,
        agency?.additionalEmailAddressOptions
      );
    },},
  ];

  const setData = (data: any) => {
    setNoticeDetail(setValues(data));
  };

  async function onSubmit(data: any) {
    setIsLoading(true);
    const output = await httpUpdate(EndPoints.corporation + corpId + "/lots/" + lotId + "/notice", data);
    if (output?.status === 200) {
      setIsLoading(false);
      setData(data);
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: 2000,
      });
      reset({}, { keepDirty: false, keepValues: true });
    }
    if (output?.error) {
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `error:${output?.error}`,
        life: 3000,
      });
    }
  }

  function onDiscard() {
    setShowModal(false);
    reset(setValues(noticeDetail));
    setNoticeDetail(noticeDetail);
    let othertypeConatct = otherType && contactDetail?.contactId && !Boolean(lotDetail?.lotContacts?.find?.((contact: any) => contact?.contactId === noticeDetail?.noticeContactID));
    if(!othertypeConatct){
      setContactDetail(Object.assign({}, ...lotDetail?.lotContacts?.filter?.((contact: any) => contact?.contactId === noticeDetail?.noticeContactID)));
    }
  }

  function setValues(data: any){
    let updatedData ={
      ownerID: data?.ownerID,
      lotID: data?.lotID,
      noticeContactID: data?.noticeContactID,
      noticesIssue: data?.noticesIssue,
      noticeCorrespondenceIssue: data?.noticeCorrespondenceIssue,
      receiveExecCommNotices: data?.receiveExecCommNotices,
      excludeFromNoticesAndMinutes: data?.excludeFromNoticesAndMinutes,
      useOwnersNoticeEmail: data?.useOwnersNoticeEmail,
      useAgentsNoticeEmail: data?.useAgentsNoticeEmail,
      useTenantsNoticeEmail: data?.useTenantsNoticeEmail,
      useOwnersCorrespondenceEmail: data?.useOwnersCorrespondenceEmail,
      useAgentsCorrespondenceEmail: data?.useAgentsCorrespondenceEmail,
      useTenantsCorrespondenceEmail: data?.useTenantsCorrespondenceEmail,
    }
    return updatedData;
  }

  function setNoticeContactId(contactId: any){
    setValue("noticeContactID", contactId, { shouldDirty: true, shouldValidate: true});
  }

  const footerContent = (
    <div className={`flex justify-end`}>
      <div className="flex gap-2 flex-row order-last mt-4 mr-4">
        <button type="button" className="transparent-button" onClick={() => onDiscardSelectedContact()}>
          {cancelButtonText}
        </button>
        <button
          type="button"
          className={`btn-primary ${selectedRow ? "" : "opacity-50"}`}
          onClick={() => onDiffrentContactSelect(selectedRow)}
          disabled={selectedRow ? false : true}
        >
          {selectButtonText}
        </button>
      </div>
    </div>
  );

  function onDiffrentContactSelect(row : any){
    setContactDetail(row);
    setShowContactModal(false);
    setSelectedRow(null);
    setValue("noticeContactID",row?.contactId, { shouldDirty: true, shouldValidate: true });
  }

  function onDiscardSelectedContact(){
    setShowContactModal(false);
    setSelectedRow(null);
  }
  return (
    <>
      {isLoading && (
        <div className="flex w-full bg-gray-900 fixed  bg-opacity-0 justify-center items-center z-50 h-auto lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      {isDirty && (
        <>
          {errors?.noticeContactID && (
            <Errors
              error={errors?.noticeContactID?.message}
              alignment="left"
            />
          )}
          <div className={`flex justify-end`}>
            <div className="flex gap-3 flex-row order-last mt-5 mr-5">
              <button
                type="button"
                className="transparent-button"
                onClick={() => {
                  setShowModal(true);
                }}
              >
                {cancelButtonText}
              </button>
              <button type="submit" form="noticeEditForm" className={`btn-primary !ml-0`}>
                {saveButtonText}
              </button>
            </div>
          </div>
        </>
      )}
      <form id="noticeEditForm" className={`flex flex-wrap`} onSubmit={handleSubmit(onSubmit)}>
        <div className="basis-full lg:basis-1/2">
          <Card id="noticeAddress">
            <NoticesAddress
              contactDetail={contactDetail}
              lotDetails={lotDetail}
              setContactDetail={setContactDetail}
              control={control}
            />
          </Card>
          <CollapsibleCard id="leviesDetail" title={Labels.CONTACT_FOR_NOTICES} expand={true} buttons={leviesDetailButtons}>
            <NoticeContact contactDetail={contactDetail} setShowAddContactModal={setShowAddContactModal} showAddContactModal={showAddContactModal} setContactDetail={setContactDetail} setNoticeContactId={setNoticeContactId} openContactModal={openContactModal} setLotDetail={setLotDetail} lotDetail={lotDetail} />
          </CollapsibleCard>
        </div>
        <div className="basis-full lg:basis-1/2">
          <Card id="rejectLevyReceipt">
            <RejectedLevy control={control} />
          </Card>
          <Card id="notice&Correspondence">
            <NoticesMinutes
              lotDetails={lotDetail}
              contactDetail={contactDetail}
              control={control}
              noticesIssue={noticesIssue}
              noticeCorrespondenceIssue={noticeCorrespondenceIssue}
            />
          </Card>
          <NoticePreview
            ownerContactDetail={Object.assign({}, ...lotDetail?.lotContacts?.filter?.((contact: any) => contact?.contactId === lotDetail?.ownerContactId))}
            contactDetail={contactDetail}
          />
        </div>
      </form>
      <FormModal
        width="80vw"
        height="90vh"
        header={contactHeader}
        isOpen={showContactModal}
        onClose={() => setShowContactModal(false)}
        footer={footerContent}
      >
        <ContactDetail contactType="O" selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
      </FormModal>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Warning" onAction={() => onDiscard()}>
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
      <ToastContainer toastReference={toastRef} />
    </>
  );
}
