import React, { useState } from "react";
import styles from "src/styles/texts.module.scss";
import { FormatDate, checkZeroValue, toLocaleValue, toDate } from "src/utils/utility";
import { Labels } from "src/constants/labels";
import { SMDatePicker } from "src/components/ui/datePicker/datePicker";
import { useForm } from "react-hook-form";
import { modalDiscardMessageText } from "src/constants/constant";
import { Modal } from "src/components/ui/modal/modal";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { httpUpdate } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { Messages } from "src/constants/messages";
import { IOwnerDetails } from "src/interfaces/ownerDetails";
import { Errors } from "src/components/shared/error/error";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";

export const OwnerDetails: React.FC<{
  lotDetails: any;
  selectedOwner: any;
  isEditableOwnerDetail: any;
  setEditableOwnerDetail: any;
  setLotDetail: any;
  setSelectedOwner: any;
  corpId: any;
  lotId: any;
  toastRef: any;
}> = ({
  lotDetails,
  selectedOwner,
  isEditableOwnerDetail,
  setEditableOwnerDetail,
  setLotDetail,
  setSelectedOwner,
  corpId,
  lotId,
  toastRef
}) => {
  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm<IOwnerDetails>({
    defaultValues: {
      ownerId: selectedOwner?.ownerId,
      lotId: Number(lotId),
      ownersCorporationId: Number(corpId),
      lotNumber: Number(lotDetails?.lotNumber),
      lotNumberPrefix: lotDetails?.lotNumberPrefix ?? "",
      lotNumberSuffix: lotDetails?.lotNumberSuffix ?? "",
      lotPlanNumber: lotDetails?.planNumber?.trim(),
      dateOfEntry: selectedOwner?.dateOfEntry,
      purchase: selectedOwner?.purchase,
      statusCertificateIssued: lotDetails?.statusCertificateIssued,
      statusCertificateUpdated: lotDetails?.statusCertificateUpdated,
    },
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  function onDiscard() {
    setEditableOwnerDetail(false);
    setShowModal(false);
  }

  const setData = (data: any) => {
    setSelectedOwner({
      ...selectedOwner,
      dateOfEntry: data.dateOfEntry,
      purchase: data.purchase,
    });
    setLotDetail({
      ...lotDetails,
      statusCertificateIssued: data.statusCertificateIssued,
      statusCertificateUpdated: data.statusCertificateUpdated,
    });
  };
  async function onSubmit(data: IOwnerDetails) {
    let payload = {
      ...data,
      dateOfEntry: toDate(FormatDate(data?.dateOfEntry) ?? ""),
      purchase: toDate(FormatDate(data?.purchase) ?? ""),
      statusCertificateIssued: toDate(
        FormatDate(data?.statusCertificateIssued) ?? ""
      ),
      statusCertificateUpdated: toDate(
        FormatDate(data?.statusCertificateUpdated) ?? ""
      ),
    };
    setIsLoading(true);
    const output = await httpUpdate(
      EndPoints.corporation + corpId + "/lots/" + lotId,
      payload
    );
    if (output?.status === 200) {
      setData(data);
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: 2000,
      });
      setEditableOwnerDetail(false);
    }
    if (output?.error) {
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `error:${output?.error}`,
        life: 3000,
      });
    }
  }
  return (
    <div className="pt-4">
      {isLoading && (
        <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-auto overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <form id="ownerDetailForm" onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className={styles.header}>
          <label className={styles.label}>{Labels.PLANNO}</label>
          <span className={`${styles.value} opacity-60`}>
            {lotDetails?.planNumber?.trim()}
          </span>
        </div>
        <div className={`${styles.header} mt-2`}>
          <label className={styles.label}>{Labels.LOT_NO}</label>
          <span className={`${styles.value} opacity-60`}>
            {lotDetails?.lotNumberPrefix}
            {checkZeroValue(lotDetails?.lotNumber)}
            {lotDetails?.lotNumberSuffix}
          </span>
        </div>
        <div className={`${styles.header} !border-none mt-2 !h-auto`}>
          <label className={styles.label}>{Labels.ADDRESS}</label>
          <div className={`${styles.value} opacity-60`}>
            <div>{lotDetails?.buildingName}</div>
            <div>
              {lotDetails?.streetNumberPrefix}
              {checkZeroValue(lotDetails?.streetNumber)}
              {lotDetails?.streetNumberSuffix} {lotDetails?.street}
            </div>
            <div>
              {lotDetails?.town} {lotDetails?.state} {lotDetails?.postcode}
            </div>
          </div>
        </div>
        <div className="mt-3 border-b border-current" />
        <div
          className={`${
            isEditableOwnerDetail
              ? "flex justify-between h-10 items-center"
              : styles.header
          } mt-5`}
        >
          <label className={styles.label}>{Labels.DATE_OF_ENTRY_LOT}</label>
          {isEditableOwnerDetail ? (
            <span className="mb-2 basis-full text-left">
              <SMDatePicker
                inputDate={FormatDate(selectedOwner?.dateOfEntry) ?? ""}
                control={control}
                name="dateOfEntry"
                disabled={!isEditableOwnerDetail}
                width="w-full"
                rules={{ required: Messages.REQUIRED_MESSAGE }}
              />
              {errors.dateOfEntry && (
                <Errors error={errors.dateOfEntry.message} alignment="left" />
              )}
            </span>
          ) : (
            <span className={styles.value}>
              {FormatDate(selectedOwner?.dateOfEntry)}
            </span>
          )}
        </div>
        <div
          className={`${
            isEditableOwnerDetail
              ? "flex justify-between h-10 items-center"
              : styles.header
          } mt-2`}
        >
          <label className={styles.label}>{Labels.DATE_OF_PURCHASE}</label>
          {isEditableOwnerDetail ? (
            <span className="mb-2 basis-full text-left">
              <SMDatePicker
                inputDate={FormatDate(selectedOwner?.purchase) ?? ""}
                control={control}
                name="purchase"
                disabled={!isEditableOwnerDetail}
                width="w-full"
                rules={{ required: Messages.REQUIRED_MESSAGE }}
              />
              {errors.purchase && (
                <Errors error={errors.purchase.message} alignment="left" />
              )}
            </span>
          ) : (
            <span className={styles.value}>
              {FormatDate(selectedOwner?.purchase)}
            </span>
          )}
        </div>
        <div className={`${styles.header} mt-2`}>
          <label className={styles.label}>{Labels.PAID_TO}</label>
          <span className={`${styles.value} opacity-60`}>
            {FormatDate(selectedOwner?.paidTo)}
          </span>
        </div>
        <div className={`${styles.header} mt-2`}>
          <label className={styles.label}>{Labels.ARREARS}</label>
          <span className={`${styles.value} opacity-60`}>
            ${toLocaleValue(lotDetails?.arrears)}
          </span>
        </div>
        <div className={`${styles.header} mt-2`}>
          <label className={styles.label}>{Labels.INTEREST_DUE_TO_DATE}</label>
          <span className={`${styles.value} opacity-60`}>${toLocaleValue(lotDetails?.interestDueToDate)}</span>
        </div>
        <div className={`${styles.header} mt-2`}>
          <label className={styles.label}>{Labels.LAST_RECEIPT}</label>
          <span className={`${styles.value} opacity-60`}>
            {FormatDate(selectedOwner?.lastReceipt)}
          </span>
        </div>
        <div
          className={`${
            isEditableOwnerDetail
              ? "flex justify-between h-10 items-center"
              : styles.header
          } mt-2`}
        >
          <label className={styles.label}>
            {Labels.STATUS_CERTIFICATE_ISSUED}
          </label>
          {isEditableOwnerDetail ? (
            <span className="mb-2 basis-full text-left">
              <SMDatePicker
                inputDate={
                  FormatDate(lotDetails?.statusCertificateIssued) ?? ""
                }
                control={control}
                name="statusCertificateIssued"
                disabled={!isEditableOwnerDetail}
                width="w-full"
                rules={{ required: Messages.REQUIRED_MESSAGE }}
              />
              {errors.statusCertificateIssued && (
                <Errors
                  error={errors.statusCertificateIssued.message}
                  alignment="left"
                />
              )}
            </span>
          ) : (
            <span className={styles.value}>
              {FormatDate(lotDetails?.statusCertificateIssued)}
            </span>
          )}
        </div>
        <div
          className={`${
            isEditableOwnerDetail
              ? "flex justify-between h-10 items-center"
              : styles.header
          } mt-2`}
        >
          <label className={styles.label}>
            {Labels.STATUS_CERTIFICATE_UPDATED}
          </label>
          {isEditableOwnerDetail ? (
            <span className="mb-2 basis-full text-left">
              <SMDatePicker
                inputDate={
                  FormatDate(lotDetails?.statusCertificateUpdated) ?? ""
                }
                control={control}
                name="statusCertificateUpdated"
                disabled={!isEditableOwnerDetail}
                width="w-full"
                rules={{ required: Messages.REQUIRED_MESSAGE }}
              />
              {errors.statusCertificateUpdated && (
                <Errors
                  error={errors.statusCertificateUpdated.message}
                  alignment="left"
                />
              )}
            </span>
          ) : (
            <span className={styles.value}>
              {FormatDate(lotDetails?.statusCertificateUpdated)}
            </span>
          )}
        </div>
        {/* <div className="flex justify-end h-10 mt-2 items-center">
          <button className="transparent-button bg-neutral-200">
            {Labels.UPDATE_CERTIFICATE}
          </button>
        </div> */}
      </form>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Warning"
        onAction={() => onDiscard()}
      >
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
    </div>
  );
};
