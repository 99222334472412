import { useEffect, useState } from "react";
import { EndPoints } from "src/constants/endpoint";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { EditableContact } from "./editableContact";
import { Labels } from "src/constants/labels";
import { httpCode } from "src/constants/constant";
import { axiosInstance } from "src/services/interceptors";

const ContactForm = ({
  contactId,
  setEditable,
  title,
  showCollapsible = true,
  lmcCommunicationChecks = true,
  showFullPortalAccess = false,
  setData,
  setAdditionalContactData,
  formId,
  setIsFormDirty,
  showCancelModal,
  setShowCancelModal,
  contactType = Labels.OWNER_CONTACT_TYPE,
  committeeMember = false,
  toastRef,
}: {
  contactId: any;
  setEditable?: any;
  title: string;
  showCollapsible?: boolean;
  lmcCommunicationChecks?: boolean;
  showFullPortalAccess?: boolean;
  setData?: any;
  setAdditionalContactData?: any;
  formId?: string;
  setIsFormDirty?: any;
  showCancelModal?: any;
  setShowCancelModal?: any;
  contactType?: string;
  committeeMember?: boolean;
  toastRef: any;
}) => {
  const [contactDetails, setContactDetails] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getContactData = async (contactId: number) => {
    setIsLoading(true);
    try {
      let response = await axiosInstance.get(`${EndPoints.contact}${contactId}`);
      if (response?.status === httpCode.SUCCESS) {
        setContactDetails(response?.data);
      }
    } catch (err) {}
    setIsLoading(false);
  };

  useEffect(() => {
    if (Boolean(contactId)) getContactData(contactId);
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-20 h-20 m-5">
            <SpinnerHorizontal />
          </div>
        </div>
      ) : (
        <EditableContact
          contactDetail={contactId ? contactDetails : {}}
          additionalDetails={contactId ? contactDetails?.additionalContactDetails : []}
          showCollapsible={showCollapsible}
          lmcCommunicationChecks={lmcCommunicationChecks}
          showCancelModal={showCancelModal}
          setIsFormDirty={setIsFormDirty}
          setShowCancelModal={setShowCancelModal}
          setEditable={setEditable}
          setData={setData}
          toastRef={toastRef}
          setAdditionalContactData={setAdditionalContactData}
          contactType={contactType}
          title={title}
          committeeMember={committeeMember}
          formId={formId}
          showFullPortalAccess={showFullPortalAccess}
        />
      )}
    </>
  );
};

export default ContactForm;
