import { useParams } from "react-router-dom";
import { Errors } from "src/components/shared/error/error";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { EndPoints } from "src/constants/endpoint";
import { Messages } from "src/constants/messages";
import { useHttpGet } from "src/services/api";
import CommitteeForm from "./committeeForm";

const CommitteeTableDrawer = ({ setShowSidebar, toast, onlineInvoiceApproval }: { setShowSidebar: any; toast: any; onlineInvoiceApproval: boolean }) => {
  const { corpId } = useParams();
  const { data: lotlist, error: lotListError, statusCode: lotListStatus } = useHttpGet(EndPoints.corporation + `${corpId}/lots`);
  const { data: committeePositions, error: committeePositionsError, statusCode: committeePositionsStatus } = useHttpGet(EndPoints.committee + "/positions");

  if (lotListError || committeePositionsError) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (!lotlist || !committeePositions) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }
  return (
    <CommitteeForm
      lots={lotlist?.lotList}
      positions={committeePositions}
      setShowSidebar={setShowSidebar}
      toast={toast}
      onlineInvoiceApproval={onlineInvoiceApproval}
    />
  );
};

export default CommitteeTableDrawer;
