import { ChevronDown1, ChevronUp1 } from "../../../assets/icon";
import React, { useState } from "react";
import styles from "./collapsibleCard.module.scss";
import { ICardButtons } from "../../../interfaces/cardButtons";

interface ICard {
  id: string;
  title: string | React.ReactNode;
  expand: boolean;
  buttons?: ICardButtons[];
  icon?: React.JSX.Element;
  children: React.ReactNode;
  isCollapsible?: boolean;
}

export const CollapsibleCard: React.FC<ICard> = ({ id, title, expand = true, children, buttons, icon, isCollapsible = true }) => {
  const [isActive, setIsActive] = useState(expand);
  return (
    <div id={id} className={`flex-auto basis-full ${styles.card}`}>
      <div className="w-full flex justify-between items-center overflow-auto py-2">
        <div className="flex items-center self-stretch gap-4">
          <label className="section-header">{title}</label>
          {icon}
        </div>

        <div className="flex order-last items-center justify-center">
          {buttons?.map((button, index) => (
            <button
              key={index}
              id={button?.id}
              type={button.buttonType ?? ""}
              form={button.formId}
              disabled={button?.disabled}
              className={`
                ${
                  button.type === "normal"
                    ? "transparent-button mr-3"
                    : button.type === "line"
                    ? styles.line
                    : button.type === "save"
                    ? styles.save
                    : button.type === "next"
                    ? styles.next
                    : ""
                } ${button?.disabled ? "opacity-50" : ""}`}
              onClick={
                button?.onClick
                  ? (e) => {
                      e.preventDefault();
                      button.onClick(e);
                      setIsActive(true);
                    }
                  : () => {}
              }
            >
              {button.buttonName}
            </button>
          ))}
          {isCollapsible && (
            <span className="ml-4 p-2" onClick={() => setIsActive(!isActive)}>
              {isActive ? <ChevronUp1 height={16} width={16} stroke="#626262" /> : <ChevronDown1 height={16} width={16} stroke="#626262" />}
            </span>
          )}
        </div>
      </div>
      {isActive && <div>{children}</div>}
    </div>
  );
};
