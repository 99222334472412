import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IButton } from "src/interfaces";

interface IBreadCrumdData {
  breadCrumb: Array<{ title: string; url: string }>;
  icons: Array<IButton>;
}

const initialState: IBreadCrumdData = {
  breadCrumb: [],
  icons: [],
};

const breadCrumbSlice = createSlice({
  name: "breadCrumbData",
  initialState,
  reducers: {
    setBreadCrumbs: (state, action: PayloadAction<Array<{ title: string; url: string }>>) => {
      state.breadCrumb = action.payload;
    },
    setIcons: (state, action: PayloadAction<Array<IButton>>) => {
      state.icons = action.payload;
    },
  },
});

export const { setBreadCrumbs, setIcons } = breadCrumbSlice.actions;
export default breadCrumbSlice.reducer;
