"use client";

import { setBreadCrumbs, setIcons } from "src/store/slice/breadCrumbSlice";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { MainAcc } from "src/pages/bankAccount/mainAccount/mainAcc";
import { InvestmentAcc } from "src/pages/bankAccount/investmentAccount/investmentAcc";
import { useParams } from "react-router-dom";
import usePlanNumber from "src/hooks/usePlanNumber";

export default function BankAccount() {
  const { corpId } = useParams();
  const plaNumber = usePlanNumber(corpId ?? "")
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { title: "Corporations", url: "/corporations" },
        { title: `${plaNumber}`, url: `/corporations/${corpId}` },
        { title: "Bank Account", url: `/corporations/${corpId}/financial` },
      ])
    );

    dispatch(
      setIcons([
        { name: "Financial", route: `${corpId}/financial`, isSelected: false },
        { name: "Bank Account", route: `${corpId}/bank-account`, isSelected: true },
        { name: "Budget", route: `${corpId}/budget`, isSelected: false },
        { name: "Management Fees", route: `${corpId}/management-fees`, isSelected: false },
      ])
    );
  }, []);

  return (
    <div className="px-8">
      <div className={`flex-auto basis-full p-8 mt-2 bg-white rounded-lg shadow-md`}>
          <MainAcc corpId={corpId} />
      </div>
      <div className={`flex-auto basis-full p-8 my-2 bg-white rounded-lg shadow-md`}>
          <InvestmentAcc corpId={corpId} />
      </div>
    </div>
  );
}
