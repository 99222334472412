import { Plus } from "src/assets/icon";
import { Labels } from "src/constants/labels";
import { CorpNotes } from "src/pages/corporation/corpNotes/corpNotes";
import { ConvertRTFToPlainText } from "src/utils/utility";

const DebtRecoveryNotes = ({ corpId, debtRecoveryDetails }: { corpId: string; debtRecoveryDetails: any }) => {
  let notes = {
    note: debtRecoveryDetails?.paymentPlanNotes,
  };
  return (
    <div className="flex flex-col w-full">
      {/* <div className="w-full flex justify-between mt-5 pl-3 pb-2" style={{ borderBottom: "1px solid #D1D5D9" }}>
        <label>{Labels.ADD_NOTE}</label>
        <div className="pr-5">
          <Plus stroke="#626262" />
        </div>
      </div> */}
      {ConvertRTFToPlainText(debtRecoveryDetails?.paymentPlanNotes) && <CorpNotes isEditable={false} corpId={corpId} data={notes} />}
    </div>
  );
};

export default DebtRecoveryNotes;
