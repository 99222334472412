import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { useCallback, useEffect, useMemo, useRef } from "react";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { Labels } from "src/constants/labels";
import styles from "../../../budget.module.scss";
import { Plus } from "src/assets/icon";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";
import { IAccounts, IBudgetExpenseRevenue } from "src/interfaces";
import { toLocaleValue } from "src/utils";
import { useDispatch } from "react-redux";
import { setExpenseList } from "src/store/slice/budget";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { SMInputNumber } from "src/components/ui/inputControl/inputNumber";
import { httpPost } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { useParams } from "react-router-dom";
import debounce from "lodash.debounce";
import ToastContainer from "src/components/ui/toast/toast";
import { Toast } from "primereact/toast";
import { httpCode, minNegativeInteger, toastErrorTimeLimit } from "src/constants/constant";
import { Messages } from "src/constants/messages";

const ExpenseTable = ({ expenseData, accounts }: { expenseData: Array<IBudgetExpenseRevenue>; accounts: Array<IAccounts> }) => {
  const { corpId } = useParams();
  const { showActualForNonBudgetAccounts, selectedDate, expenseList } = useSelector((state: RootState) => state?.budgetSlice);
  const dispatch = useDispatch();
  const toastRef = useRef<Toast>(null);
  const expenseTableButtons = [
    {
      buttonName: (
        <div style={{ background: "#0093DD" }} className="p-1 rounded">
          <Plus stroke="#fff" />
        </div>
      ),
      id: "btnAddExpense",
      type: "word",
      disabled: !selectedDate?.budgetId,
      onClick: () => {
        dispatch(
          setExpenseList([
            {
              budgetId: selectedDate?.budgetId,
              budgetDetailId: 0,
              accountId: 0,
              fundId: 0,
              accountName: "",
              accountType: "",
              accountNumber: "",
              previousYear: {
                budgetedAmount: 0,
                actualAmount: 0,
              },
              thisYear: {
                budgetedAmount: 0,
                actualAmount: 0,
              },
              nextYear: {
                budgetedAmount: 0,
                actualAmount: 0,
              },
            },
            ...expenseList,
          ])
        );
      },
    },
  ];
  const columns = useMemo(() => {
    return [
      {
        field: "accountName",
        header: "",
        body: (data: any, options: { rowIndex: number }) => {
          return (
            <p id={`expense-accountName-${options?.rowIndex}`} className={`${styles.text}`}>
              {data?.accountNumber} {data?.accountName}
            </p>
          );
        },
        editor: (editorData: any) => accountNameEditor(editorData),
      },
      {
        field: "previousYear.budgetedAmount",
        header: "",
        body: (data: any) => {
          return <p className={`${styles.text}`}>{toLocaleValue(data?.previousYear?.budgetedAmount)}</p>;
        },
      },
      {
        field: "previousYear.actualAmount",
        header: "",
        body: (data: any) => {
          return <p className={`${styles.text}`}>{toLocaleValue(data?.previousYear?.actualAmount)}</p>;
        },
      },
      {
        field: "thisYear.budgetedAmount",
        header: "",
        body: (data: any, options: { rowIndex: number }) => {
          return (
            <p id={`expense-budgetedAmount-${options?.rowIndex}`} className={`${styles.text}`}>
              {toLocaleValue(data?.thisYear?.budgetedAmount)}
            </p>
          );
        },
        editor: (data: any) => thisYearBudgetedAmountEditor(data),
      },
      {
        field: "thisYear.actualAmount",
        header: "",
        body: (data: any) => {
          return <p className={`${styles.text}`}>{toLocaleValue(data?.thisYear?.actualAmount)}</p>;
        },
      },
      {
        field: "nextYear.budgetedAmount",
        header: "",
        body: (data: any) => {
          return <p className={`${styles.text}`}>{toLocaleValue(data?.nextYear?.budgetedAmount)}</p>;
        },
      },
    ];
  }, [accounts, expenseList]);

  let expenseTotal = useMemo(() => {
    return expenseList
      ?.filter?.((expense: IBudgetExpenseRevenue) => (showActualForNonBudgetAccounts ? true : expense?.budgetId))
      ?.reduce?.(
        (accumulator, item) => {
          if (!showActualForNonBudgetAccounts) {
            return {
              ...accumulator,
              surplusDeficitBudgeted: {
                ...accumulator.surplusDeficitBudgeted,
                prevAdminBudgeted: accumulator?.surplusDeficitBudgeted?.prevAdminBudgeted + item?.previousYear?.budgetedAmount,
                prevAdminActual: accumulator?.surplusDeficitBudgeted?.prevAdminActual + item?.previousYear?.actualAmount,
                adminBudgeted: accumulator?.surplusDeficitBudgeted?.adminBudgeted + item?.thisYear?.budgetedAmount,
                adminActual: accumulator?.surplusDeficitBudgeted?.adminActual + item?.thisYear?.actualAmount,
                nextAdminBudgeted: accumulator?.surplusDeficitBudgeted?.nextAdminBudgeted + item?.nextYear?.budgetedAmount,
              },
            };
          } else {
            return {
              ...accumulator,
              surplusDeficitNonBudgeted: {
                ...accumulator.surplusDeficitNonBudgeted,
                prevAdminBudgeted: accumulator?.surplusDeficitNonBudgeted?.prevAdminBudgeted + item?.previousYear?.budgetedAmount,
                prevAdminActual: accumulator?.surplusDeficitNonBudgeted?.prevAdminActual + item?.previousYear?.actualAmount,
                adminBudgeted: accumulator?.surplusDeficitNonBudgeted?.adminBudgeted + item?.thisYear?.budgetedAmount,
                adminActual: accumulator?.surplusDeficitNonBudgeted?.adminActual + item?.thisYear?.actualAmount,
                nextAdminBudgeted: accumulator?.surplusDeficitNonBudgeted?.nextAdminBudgeted + item?.nextYear?.budgetedAmount,
              },
            };
          }
        },
        {
          surplusDeficitBudgeted: {
            prevAdminBudgeted: 0,
            prevAdminActual: 0,
            adminBudgeted: 0,
            adminActual: 0,
            nextAdminBudgeted: 0,
          },
          surplusDeficitNonBudgeted: {
            prevAdminBudgeted: 0,
            prevAdminActual: 0,
            adminBudgeted: 0,
            adminActual: 0,
            nextAdminBudgeted: 0,
          },
        }
      );
  }, [showActualForNonBudgetAccounts, selectedDate?.budgetId, expenseList]);

  useEffect(() => {
    dispatch(setExpenseList(expenseData));
  }, []);

  const debounceEventHandler = useCallback(
    debounce((data: { data: Array<IBudgetExpenseRevenue>; budgetDetailId: number; budgetId: number; accountId: number; amount: number }) => {
      handleRowDataSave(data);
    }, 500),
    []
  );

  useEffect(() => {
    return () => {
      debounceEventHandler?.cancel?.();
    };
  }, [debounceEventHandler]);

  const thisYearBudgetedAmountEditor = (data: any) => {
    return (
      <SMInputNumber
        inputId="expenseBudgetedAmount"
        value={data?.value}
        minFractionDigits={2}
        maxLength={17}
        min={minNegativeInteger}
        onChange={(value: number) => {
          if (!data?.rowData?.accountId) {
            toastRef?.current?.show({
              severity: "warn",
              summary: "Warning",
              detail: Messages.BUDGET_ACCOUNT_VALIDATION,
              life: toastErrorTimeLimit,
            });
          } else {
            let newExpenseList = expenseList?.map?.((expense: IBudgetExpenseRevenue) => {
              if (data?.rowData?.budgetDetailId === expense?.budgetDetailId && data?.rowData?.accountId === expense?.accountId) {
                return {
                  ...expense,
                  thisYear: {
                    ...expense.thisYear,
                    budgetedAmount: value ?? 0,
                  },
                };
              } else {
                return {
                  ...expense,
                };
              }
            });
            dispatch(setExpenseList(newExpenseList));
            debounceEventHandler({
              data: newExpenseList,
              budgetDetailId: data?.rowData?.budgetDetailId,
              budgetId: data?.rowData?.budgetId,
              accountId: data?.rowData?.accountId,
              amount: value ?? 0,
            });
          }
        }}
      />
    );
  };

  const accountNameEditor = (editorData: any) => {
    return (
      <SMDropDown
        value={editorData?.rowData?.accountNumber + " " + editorData?.rowData?.accountName}
        options={accounts
          ?.filter(
            (account: IAccounts) =>
              !expenseList?.some(
                (expense: IBudgetExpenseRevenue) => account?.accountId === expense?.accountId && account?.accountId !== editorData?.rowData?.accountId
              )
          )
          ?.map((account: IAccounts) => {
            return { ...account, accountName: account?.accountNumber + " " + account?.name };
          })}
        optionValue="accountName"
        optionLabel="accountName"
        filter
        onChange={(e: any) => {
          if (
            editorData?.rowData?.thisYear?.actualAmount ||
            editorData?.rowData?.previousYear?.actualAmount ||
            editorData?.rowData?.previousYear?.budgetedAmount
          ) {
            toastRef?.current?.show({
              severity: "warn",
              summary: "Warning",
              detail: Messages.BUDGET_ACCOUNT_EDIT_VALIDATION,
              life: toastErrorTimeLimit,
            });
          } else {
            let account = accounts?.find?.((account: IAccounts) => account?.accountNumber + " " + account?.name === e?.value) as IAccounts;
            if (account?.accountId) {
              let newExpenseList = expenseList?.map?.((expense: IBudgetExpenseRevenue, index: number) => {
                if (expense?.accountId ? editorData?.rowData?.accountId === expense?.accountId : index === editorData?.rowIndex) {
                  return {
                    ...expense,
                    accountId: account?.accountId,
                    accountNumber: account?.accountNumber,
                    accountName: account?.name,
                    accountType: account?.accountType,
                    fundId: account?.fundId,
                  };
                } else {
                  return {
                    ...expense,
                  };
                }
              });
              dispatch(setExpenseList(newExpenseList));
              if (editorData?.rowData?.thisYear?.budgetedAmount || editorData?.rowData?.budgetDetailId) {
                debounceEventHandler({
                  data: newExpenseList,
                  budgetDetailId: editorData?.rowData?.budgetDetailId,
                  budgetId: editorData?.rowData?.budgetId,
                  accountId: account?.accountId,
                  amount: editorData?.rowData?.thisYear?.budgetedAmount,
                });
              } else {
                toastRef?.current?.show({
                  severity: "warn",
                  summary: "Warning",
                  detail: Messages.BUDGETEDAMOUNT_EDIT_VALIDATION,
                  life: toastErrorTimeLimit,
                });
              }
            }
          }
        }}
      />
    );
  };

  const handleRowDataSave = async (data: {
    data: Array<IBudgetExpenseRevenue>;
    budgetDetailId: number;
    budgetId: number;
    accountId: number;
    amount: number;
  }) => {
    let response: any = await httpPost(`${EndPoints.corporation}${corpId}/${EndPoints.budget}budget-detail/${data?.budgetDetailId}`, {
      budgetId: selectedDate?.budgetId,
      accountId: data?.accountId,
      amount: data?.amount,
    });
    if (response?.status === httpCode.SUCCESS && response?.data?.result?.[0]?.Id) {
      let updatedExpenseList = data?.data?.map?.((expense: IBudgetExpenseRevenue) => {
        if (expense?.accountId === data?.accountId) {
          return {
            ...expense,
            budgetId: selectedDate?.budgetId,
            budgetDetailId: response?.data?.result?.[0]?.Id,
          };
        } else {
          return { ...expense };
        }
      }) as Array<IBudgetExpenseRevenue>;
      dispatch(setExpenseList(updatedExpenseList));
    }
    if (response?.error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Error: ${response?.error}`,
        life: toastErrorTimeLimit,
      });
    }
  };

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column className="invisible !border-none" colSpan={1} />
        <Column className={`${styles.textStyle} ${styles.expenseColumnGroup}`} header={Labels.LAST_PERIOD} colSpan={2} />
        <Column className={`${styles.textStyle} ${styles.expenseColumnGroup}`} header={Labels.CURRENT_PERIOD} colSpan={2} />
        <Column className={`${styles.textStyle} ${styles.expenseColumnGroup}`} header={Labels.NEXT_PERIOD} colSpan={2} />
      </Row>
      <Row>
        <Column className={`${styles.textStyle} ${styles.accountColumn}`} header={Labels.ACCOUNT_NAME} />
        <Column className={`${styles.textStyle} ${styles.budgetedActualColumn}`} header={Labels.BUDGETED} />
        <Column className={`${styles.textStyle} ${styles.budgetedActualColumn}`} header={Labels.ACTUAL} />
        <Column className={`${styles.textStyle} ${styles.budgetedActualColumn}`} header={Labels.BUDGETED} />
        <Column className={`${styles.textStyle} ${styles.budgetedActualColumn}`} header={Labels.ACTUAL} />
        <Column className={`${styles.textStyle} ${styles.budgetedActualColumn}`} header={Labels.BUDGETED} />
      </Row>
    </ColumnGroup>
  );

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          className={`${styles.lotContributionFooter} ${styles.textStyle}`}
          footer={Labels.TOTALS}
          colSpan={1}
          footerStyle={{ textAlign: "right", textTransform: "uppercase" }}
        />
        <Column
          className={`${styles.lotContributionFooter} ${styles.textStyle}`}
          footer={toLocaleValue(
            showActualForNonBudgetAccounts
              ? expenseTotal?.surplusDeficitNonBudgeted?.prevAdminBudgeted
              : expenseTotal?.surplusDeficitBudgeted?.prevAdminBudgeted
          )}
        />
        <Column
          className={`${styles.lotContributionFooter} ${styles.textStyle}`}
          footer={toLocaleValue(
            showActualForNonBudgetAccounts ? expenseTotal?.surplusDeficitNonBudgeted?.prevAdminActual : expenseTotal?.surplusDeficitBudgeted?.prevAdminActual
          )}
        />
        <Column
          className={`${styles.lotContributionFooter} ${styles.textStyle}`}
          footer={toLocaleValue(
            showActualForNonBudgetAccounts ? expenseTotal?.surplusDeficitNonBudgeted?.adminBudgeted : expenseTotal?.surplusDeficitBudgeted?.adminBudgeted
          )}
        />
        <Column
          className={`${styles.lotContributionFooter} ${styles.textStyle}`}
          footer={toLocaleValue(
            showActualForNonBudgetAccounts ? expenseTotal?.surplusDeficitNonBudgeted?.adminActual : expenseTotal?.surplusDeficitBudgeted?.adminActual
          )}
        />
        <Column
          className={`${styles.lotContributionFooter} ${styles.textStyle}`}
          footer={toLocaleValue(
            showActualForNonBudgetAccounts
              ? expenseTotal?.surplusDeficitNonBudgeted?.nextAdminBudgeted
              : expenseTotal?.surplusDeficitBudgeted?.nextAdminBudgeted
          )}
        />
      </Row>
    </ColumnGroup>
  );

  return (
    <CollapsibleCard id="expenseTable" title={Labels.EXPENSE} expand={true} buttons={expenseTableButtons}>
      <div className="w-full pt-4">
        <SMDataTableContainer
          tableData={showActualForNonBudgetAccounts ? expenseList : expenseList?.filter?.((expense: IBudgetExpenseRevenue) => expense?.budgetId)}
          tableColumns={columns}
          editMode="cell"
          showEditIcon={false}
          showSelectableRowCheckbox={false}
          headerGroup={headerGroup}
          footerGroup={footerGroup}
          showGridlines
          stripedRows
        />
      </div>
      <ToastContainer toastReference={toastRef} />
    </CollapsibleCard>
  );
};

export default ExpenseTable;
