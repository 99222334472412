import { Dialog } from "primereact/dialog";
import React from "react";
import { cancelButtonText, saveButtonText } from "src/constants/constant";

interface IDialog {
  header: string;
  isOpen: boolean;
  children: React.ReactNode;
  onClose: any;
  width?: string;
  height?: string;
  isDirty?: boolean;
  formId?: string;
  footer?: any;
}

const FormModal = ({ header, formId, isOpen, children, onClose, width = "50vw", height, isDirty, footer }: IDialog) => {
  const footerContent = (
    <div className={`flex justify-end`}>
      <div className="flex gap-2 flex-row order-last mt-5 mr-5">
        <button
          type="button"
          id="btnCancel"
          data-test-id="btnCancel"
          className="bg-white text-black py-1.5 px-4 text-md rounded-md items-center border-2"
          onClick={onClose}
        >
          {cancelButtonText}
        </button>
        <button
          id="btnSave"
          data-test-id="btnSave"
          type="submit"
          form={formId}
          disabled={!isDirty}
          className={`bg-blue-500 text-white py-1.5 px-6 text-md rounded-md items-center ${!isDirty ? "opacity-60" : "opacity-100"}`}
        >
          {saveButtonText}
        </button>
      </div>
    </div>
  );

  return (
    <Dialog
      header={header}
      pt={{
        content: {
          className: "!p-0 !overflow-auto",
        },
        footer: {
          style: {
            borderTop: "1px solid rgb(203 213 225)",
          },
        },
      }}
      headerStyle={{
        borderBottom: "1px solid rgb(203 213 225)",
      }}
      closable={false}
      draggable={false}
      visible={isOpen}
      style={{ width: width, height: height ?? "" }}
      onHide={onClose}
      footer={ footer ?? footerContent}
    >
      {children}
    </Dialog>
  );
};

export default FormModal;
