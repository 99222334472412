import React, { useRef, useState } from "react";
import styles from "../../../../../../styles/texts.module.scss";
import { Labels } from "src/constants/labels";
import AdditionalContactDetails from "src/components/shared/contact/additionalContactDetails/additionalContactDetails";
import FormModal from "src/components/ui/dialog/formModal";
import { Toast } from "primereact/toast";
import { IAdditionalContact } from "src/interfaces/additionalContact";
import { IContact } from "src/interfaces/ownerContact";
import ContactForm from "../../../shared/contact";

export const NoticeContact : React.FC<{contactDetail: any, showAddContactModal: boolean, setShowAddContactModal: any, setContactDetail: any, setNoticeContactId: any, openContactModal: any, setLotDetail: any, lotDetail: any}> = ({contactDetail, showAddContactModal, setShowAddContactModal, setContactDetail, setNoticeContactId, openContactModal, setLotDetail, lotDetail}) =>{
    const isBusiness = contactDetail?.businessContact;
    const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
    const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
    const toastRef = useRef<Toast>(null);
    let formId = openContactModal ? "addNewContactNotice" : "editContactNotice";
    const newContacts = {} as IContact;
    let AdditionalContact: IAdditionalContact[] = [];

    const setData = (data : any, response?: any) => {
        if(response){
            setNoticeContactId(response?.Id);
            setContactDetail({
                ...data,
                contactId: response?.Id,
            });
        }
        else {
            setContactDetail(data);
            let lotContacts: any = lotDetail?.lotContacts?.map((contact: any) => 
                    contact.contactId === data?.contactId ? {...contact, ...data} : contact
                );
            setLotDetail({
                ...lotDetail,
                lotContacts: lotContacts
            });
        }
    };
    const setAdditionalContactData = (data : any) => {
        setContactDetail({
            ...contactDetail,
            additionalContactDetails: data,
        });
    };

    return(
    <>
        <div className="pt-4">
            <div className="flex justify-between h-10 border-b items-center">
                <label className="basis-full text-left text-sm font-bold">
                    {
                        isBusiness ? Labels.BUSINESS_CONTACT : Labels.PRIVATE_RESIDENTIAL_CONTACT
                    }
                </label>
            </div> 
            <div className="pt-1 border-t border-gray-400">
                <div className="py-4 border-b border-gray-400">
                    {
                            isBusiness && contactDetail?.otherNames &&
                                <div className={styles.address}><label className="font-semibold">{Labels.CONTACT_PERSON}</label>{contactDetail?.otherNames}</div>                        
                    }
                    {
                            contactDetail?.salutation && 
                                <div className={styles.address}><label className="font-semibold">{Labels.SALUTATION}</label>{contactDetail?.salutation}</div>
                    }
                    {
                            isBusiness ? <div className={styles.address}>{contactDetail?.name}</div> : <div className={styles.address}>{contactDetail?.title} {contactDetail?.firstName} {contactDetail?.otherNames} {contactDetail?.name}</div>                           
                    }
                    {
                            contactDetail?.poBox && 
                                <div className={styles.address}>{contactDetail?.poBox}</div>
                    }
                    {
                            !contactDetail?.poBox && contactDetail?.buildingName && 
                                <div className={styles.address}>{contactDetail?.buildingName}</div>
                    }
                    {
                            !contactDetail?.poBox && contactDetail?.streetName &&
                                <div className={styles.address}>{contactDetail?.streetNumber} {contactDetail?.streetName}</div>
                    }
                    <div className={styles.address}>{contactDetail?.town} {contactDetail?.state} {contactDetail?.postcode} {contactDetail?.country}</div>
                </div>
                <div className="py-4 border-gray-400">
                {
                        contactDetail?.telephone1 &&
                            <div className={styles.address}>
                                <label className="font-semibold">{ isBusiness ? Labels.TELEPHONE_1 : Labels.HOME_PHONE }</label>
                                <a href={`tel:${contactDetail?.telephone1}`} className="underline">
                                    {contactDetail?.telephone1}
                                </a>
                            </div>                        
                    }
                    {
                        contactDetail?.telephone2 && 
                            <div className={styles.address}>
                                <label className="font-semibold">{ isBusiness ? Labels.TELEPHONE_2 : Labels.WORK_PHONE }</label>
                                <a href={`tel:${contactDetail?.telephone2}`} className="underline">
                                    {contactDetail?.telephone2}
                                </a>
                            </div>                        
                    }
                    {
                        contactDetail?.telephone3 && 
                            <div className={styles.address}>
                                <label className="font-semibold">{Labels.MOBILE}</label>
                                <a href={`tel:${contactDetail?.telephone3}`} className="underline">
                                    {contactDetail?.telephone3}
                                </a>
                            </div>
                    }
                    {
                        contactDetail?.fax &&
                            <div className={styles.address}><label className="font-semibold">{Labels.FAX} </label>{contactDetail?.fax}</div>
                    }
                    {
                        contactDetail?.email &&
                            <div className={styles.address}>
                                <label className="font-semibold">{Labels.EMAIL}</label>
                                <a href={`mailto:${contactDetail?.email}`} className="underline hyperlink">
                                    {contactDetail?.email}
                                </a>
                            </div>
                    }
                    {
                        contactDetail?.levyEmailAddress &&
                            <div className={styles.address}>
                                <label className="font-semibold">{Labels.LEVY_EMAIL_ADDRESS}</label>
                                <a href={`mailto:${contactDetail?.levyEmailAddress}`} className="underline hyperlink">
                                    {contactDetail?.levyEmailAddress}
                                </a>
                            </div>
                    }
                    {
                        isBusiness && contactDetail?.website &&
                            <div className={styles.address}>
                                <label className="font-semibold">{Labels.WEBSITE}</label>
                                <a href={`https://${contactDetail?.website}`} target="_blank" className="underline hyperlink">
                                    {contactDetail?.website}
                                </a>
                            </div>
                    }

                </div>
            </div>
            <AdditionalContactDetails additionalContactDetails={contactDetail?.additionalContactDetails} borderTop="border-t border-gray-400" isBusiness={isBusiness} />
        </div> 
        <FormModal
            width="80vw"
            formId={formId}
            header={openContactModal ? Labels.ADD_CONTACT : Labels.EDIT_CONTACT}
            isOpen={showAddContactModal}
            onClose={() => {
            if (isFormDirty) {
                setShowCancelModal(true);
            } else {
                setShowAddContactModal(false);
            }
            }}
            isDirty={isFormDirty}
        >
            <div className="pl-10 pr-10">
            <ContactForm
                contactId={openContactModal ? 0 : contactDetail?.contactId}
                title=""
                formId={formId}
                contactType={Labels.OWNER_CONTACT_TYPE}
                showCollapsible={false}
                lmcCommunicationChecks={false}
                showCancelModal={showCancelModal}
                setIsFormDirty={setIsFormDirty}
                setShowCancelModal={setShowCancelModal}
                setEditable={setShowAddContactModal}
                setData={setData}
                toastRef={toastRef}
                setAdditionalContactData={setAdditionalContactData}
            />
            </div>
        </FormModal>
    </> 
);         
}
