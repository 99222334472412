import { useHttpGet } from "src/services/api";
import CoverageForm from "./coverageForm";
import { EndPoints } from "src/constants/endpoint";
import { Messages } from "src/constants/messages";
import { Errors } from "src/components/shared/error/error";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { httpCode } from "src/constants/constant";

const CoverageTableDrawer = ({ setShowSidebar, toastRef }: { setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>; toastRef: any }) => {
  const { data, error, statusCode } = useHttpGet(EndPoints.agency + "/coverage-types");

  if (error || statusCode === httpCode.INTERNAL_SERVER_ERROR) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (!data) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }
  return <CoverageForm setShowSidebar={setShowSidebar} coverageTypes={data} toastRef={toastRef}/>;
};

export default CoverageTableDrawer;
