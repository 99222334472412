"use client";
import { useEffect } from "react";
import { StructureDetail } from "src/pages/structure/structureDetail/structureDetail";
import { OtherAddresses } from "src/pages/structure/otherAddresses/otherAddresses";
import { OwnerContact } from "src/pages/structure/ownerContact/ownerContact";
import { setBreadCrumbs, setIcons } from "src/store/slice/breadCrumbSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import usePlanNumber from "src/hooks/usePlanNumber";

export default function Structure() {
  const { corpId } = useParams();
  const dispatch = useDispatch();
  const plaNumber = usePlanNumber(corpId ?? "")

  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { title: "Corporations", url: "/corporations" },
        { title: `${plaNumber}`, url: `/corporations/${corpId}` },
        { title: "Structure", url: `/corporations/${corpId}/structure` },
      ])
    );

    dispatch(
      setIcons([
        { name: "Plan Details", route: `${corpId}/plan`, isSelected: false },
        { 
          name: "Insurance", 
          route: `${corpId}/insurance`, 
          isSelected: false 
        },
        { name: "Structure", route: `${corpId}/structure`, isSelected: true },
      ])
    );
  }, [plaNumber]);

  return (
    <>
      <div className={`px-8 flex`}>
        <div className="p-1 w-1/2">
          <StructureDetail corpId={corpId} />
        </div>
        <div className="p-1 w-1/2">
          <OtherAddresses corpId={corpId} />
          <OwnerContact corpId={corpId} />
        </div>
      </div>
    </>
  );
}
