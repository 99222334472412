import { useSelector } from "react-redux";
import { Card } from "src/components/ui/card/card";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { RootState } from "src/store/rootReducer";

const ReportTable = () => {
  const { tableColumns } = useSelector((state: RootState) => state?.quickReports);
  return (
    <Card>
      <SMDataTableContainer tableData={[]} tableColumns={tableColumns} showEditIcon={false} showSelectableRowCheckbox={false} />
    </Card>
  );
};

export default ReportTable;
