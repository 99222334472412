import { IBudgetDatesData, IBudgetDate } from "src/interfaces/budgetList";
import ExpenseTable from "./expenseTable";
import { useParams } from "react-router-dom";
import { useHttpGet } from "src/services/api";
import { budgetType, httpCode } from "src/constants/constant";
import { Messages } from "src/constants/messages";
import { Errors } from "src/components/shared/error/error";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { EndPoints } from "src/constants/endpoint";
import RevenueTable from "../revenue/revenueTable";
import { useEffect } from "react";
import { IBudgetExpenseRevenue } from "src/interfaces";

const Expense = ({
  budgetDates,
  selectedDate,
  setBalance,
  fundType,
}: {
  budgetDates: IBudgetDatesData;
  selectedDate: IBudgetDate;
  setBalance: any;
  fundType?: number;
}) => {
  const { corpId } = useParams();
  const {
    data: expenseRevenueData,
    error: expenseRevenueError,
    statusCode: expenseRevenueStatus,
  } = useHttpGet(`${EndPoints.corporation}${corpId}/${EndPoints.budget}expense-revenue`, {
    budgetId: selectedDate?.budgetId,
    groupId: selectedDate?.groupCodeId,
    fundId: fundType,
    gst: budgetDates?.gsTstatus,
  });
  const { data: expenseAccounts } = useHttpGet(`${EndPoints.agency}/accounts`, {
    fundId: fundType,
    accountType: budgetType.expense,
  });
  const { data: revenueAccounts } = useHttpGet(`${EndPoints.agency}/accounts`, {
    fundId: fundType,
    accountType: budgetType.revenue,
  });

  useEffect(() => {
    if (expenseRevenueData) {
      setBalance(expenseRevenueData);
    }
  }, [expenseRevenueData]);

  if (expenseRevenueError || httpCode.INTERNAL_SERVER_ERROR === expenseRevenueStatus) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (httpCode.NOT_FOUND === expenseRevenueStatus || httpCode.NO_CONTENT === expenseRevenueStatus) {
    return (
      <>
        <ExpenseTable expenseData={[]} accounts={[]} />
        <RevenueTable revenueData={[]} accounts={[]} />
      </>
    );
  }

  if (!expenseRevenueData || !expenseAccounts || !revenueAccounts) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-20 h-20 mt-10">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <ExpenseTable
        expenseData={expenseRevenueData?.budgetDetail?.filter?.((budget: IBudgetExpenseRevenue) => budget?.accountType === budgetType.expense)}
        accounts={expenseAccounts}
      />
      <RevenueTable
        revenueData={expenseRevenueData?.budgetDetail?.filter?.((budget: IBudgetExpenseRevenue) => budget?.accountType === budgetType.revenue)}
        accounts={revenueAccounts}
      />
    </>
  );
};

export default Expense;
