import { Line, Link, ViewColumn } from "src/assets/icon";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { Labels } from "src/constants/labels";
import LinkedLotsTable from "../linkedLotsTable/linkedLotsTable";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Toast } from "primereact/toast";
import ToastContainer from "src/components/ui/toast/toast";
import { comingSoon } from "src/constants/constant";

const LinkedLots = ({ linkedLotsData }: { linkedLotsData: any }) => {
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const navigate = useNavigate();
  const toastRef = useRef<Toast>(null);

  const handleLinkClick = () => {
    if (!selectedRow?.lotId) {
      toastRef?.current?.show({
        severity: "warn",
        summary: "Warning",
        detail: "Please select a row before navigating through link or directly navigate by double clicking on the row",
        life: 5000,
      });
      return;
    }
    navigate(`/corporations/${selectedRow?.ownersCorporationId}/lots`, {
      state: selectedRow,
    });
    setSelectedRow(null);
  };

  const mortgageTableButtons = [
    { buttonName: <Link height={24} />, type: "word", onClick: handleLinkClick },
    { buttonName: <Line height={32} />, type: "line" },
    { buttonName: <ViewColumn />, onClick: () => toastRef?.current?.show({
      severity: "info",
      summary: "Info",
      detail: comingSoon,
      life: 2000,
    }) },
  ];

  return (
    <>
      <CollapsibleCard id="linkedLotsTable" title={Labels.LINKED_LOTS_HEADER} expand={true} buttons={mortgageTableButtons}>
        {linkedLotsData?.length > 0 ? (
          <div className="w-full pt-4">
            <LinkedLotsTable linkedLotsData={linkedLotsData} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
          </div>
        ) : (
          <div className={`w-full rounded-lg text-center shadow-lg flex-row p-10 mt-5`}>
            <p>{Labels.NO_LINKED_LOTS_AVAILABLE}</p>
          </div>
        )}
      </CollapsibleCard>
      <ToastContainer toastReference={toastRef} />
    </>
  );
};

export default LinkedLots;
