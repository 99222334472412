import UserList from "./userList/userList";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setBreadCrumbs, setIcons } from "src/store/slice/breadCrumbSlice";

const Users = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadCrumbs([{ title: "Users", url: "users" }]));
    dispatch(setIcons([]));
  }, []);

  return <UserList />;
};

export default Users;
