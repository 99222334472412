"use client";
import styles from "./tabHeader.module.scss";
import {
  CalendarWeek,
  ChevronRight,
  DocumentSearch,
  Line,
  Search,
} from "../../../../assets/icon";
import { comingSoon } from "src/constants/constant";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import ToastContainer from "src/components/ui/toast/toast";

export default function TabHeader({ lotName }: { lotName: string }) {
  const toastRef = useRef<Toast>(null);
  return (
    <>
      <div className="flex pt-6 pb-4 items-center w-full justify-between">
        <div className="flex basis-2/5">
          <label className={styles.label}>{lotName}</label>
        </div>
        <div className="flex basis-3/5 gap-4 items-center justify-end pr-2">
          {/* <span className={`flex py-1.5 pl-2 pr-3 ${styles.inputField}`}>
            <input placeholder="Search...." />
            <Search />
          </span> */}
          <button
            className={`${styles.headerBtn}`}
            onClick={() =>
              toastRef?.current?.show({
                severity: "info",
                summary: "Info",
                detail: comingSoon,
                life: 2000,
              })
            }
          >
            New Owner
          </button>
          {/* <CalendarWeek width={24} height={24} />
          <DocumentSearch />
          <Line height={40} />
          <ChevronRight width={24} height={24} rotate={180} />
          <ChevronRight width={24} height={24} /> */}
        </div>
      </div>
      <ToastContainer toastReference={toastRef} />
    </>
  );
}