import { SMInputNumber } from "src/components/ui/inputControl/inputNumber";
import { entitlementObjId, entitlementObjkey, entitlementObjSetId, lotNumberObjKey } from "src/constants/constant";
import { Labels } from "src/constants/labels";
import { checkZeroValue } from "src/utils";

export const ensureEntitlementProperties = (obj: { [x: string]: number }, maxEntitlement: number, headerEntitlement: any) => {
  const newObj: any = {};
  for (let i = 0; i <= maxEntitlement; i++) {
    if (Object.keys(obj)?.[i] !== lotNumberObjKey) {
      newObj[`${entitlementObjkey}${i}`] = obj[`${entitlementObjkey}${i}`] || 0.0;
      newObj[`${entitlementObjkey}${i}${entitlementObjId}`] = obj[`${entitlementObjkey}${i}${entitlementObjId}`] || 0;
      newObj[`${entitlementObjkey}${i}${entitlementObjSetId}`] =
        obj[`${entitlementObjkey}${i}${entitlementObjSetId}`] ?? headerEntitlement?.[i - 1]?.unitEntitlementSetId;
    } else {
      newObj[lotNumberObjKey] = obj[lotNumberObjKey];
    }
  }
  return newObj;
};

export const findEntitlementDetails = (obj: any, prefix: string) => {
  const filteredObj: any = {};
  const keyMappings: { [key: string]: string } = {
    [prefix]: "entitlement",
    [prefix + "Id"]: "unitEntitlementId",
    [prefix + "SetId"]: "unitEntitlementSetId",
    lotNo: "lotId",
  };

  Object.keys(obj).forEach((key) => {
    if (keyMappings[key]) {
      filteredObj[keyMappings[key]] = obj[key];
    }
  });
  return filteredObj;
};

export const entitlementEditor = (data: any, handleCellDataSave: (data: any) => void, isEditable: boolean) => {
  if (!isEditable) return <span>{data?.value}</span>;
  const onBlur = (e: any) => {
    const newValue = e.target.value;
    const newRowData = { ...data.rowData, [data.field]: newValue };
    handleCellDataSave({ ...data, newRowData });
  };
  return (
    <SMInputNumber
      inputId="entitlementValue"
      value={data?.value}
      onChange={(e: any)=> data?.editorCallback(e)}
      minFractionDigits={2}
      maxFractionDigits={2}
      maxLength={17}
      min={0}
      onBlur={onBlur}
      onKeyDown={(e: any) => {
        if (e.key === "Enter") {
          onBlur(e);
        }
      }}
    />
  );
};

export const generateTableData = (unitEntitlementList: Array<any>, headerEntitlement: any, handleCellDataSave: any) => {
  if (!unitEntitlementList?.length) return { tableData: [], tableColumns: [] };

  let tableData: any = [];
  let tableColumns: any = [
    {
      field: lotNumberObjKey,
      header: <p className="text !font-bold uppercase">{Labels.LOTS} #</p>,
      body: (data: any) => {
        const lot = data?.lotNo?.split("|");
        return <div className="!font-bold text">{lot[0] ?? data?.lotNo}</div>;
      },
      width: "10rem",
    },
  ];
  let total: any = {};

  for (let index = 0; index < unitEntitlementList.length; index++) {
    tableData.push({
      [lotNumberObjKey]:
        unitEntitlementList[index]?.lotNumberPrefix +
          (checkZeroValue(unitEntitlementList[index]?.lotNumber) ?? "") +
          unitEntitlementList[index]?.lotNumberSuffix +
          "|" +
          checkZeroValue(unitEntitlementList[index]?.lotId) ?? "",
    });

    for (let innerIndex = 0; innerIndex < headerEntitlement?.length; innerIndex++) {
      let entitlementNumber =
        unitEntitlementList[index]?.unitEntitlements?.[innerIndex]?.name !== undefined &&
        unitEntitlementList[index]?.unitEntitlements?.[innerIndex]?.name !== ""
          ? unitEntitlementList[index]?.unitEntitlements?.[innerIndex]?.name === headerEntitlement?.[innerIndex]?.unitEntitlementName
            ? innerIndex + 1
            : headerEntitlement?.findIndex(
                (entitlement: any) => entitlement?.unitEntitlementName === unitEntitlementList[index]?.unitEntitlements?.[innerIndex]?.name
              ) + 1
          : innerIndex + 1;

      let unitentitlementIndex =
        unitEntitlementList[index]?.unitEntitlements?.length === 1
          ? 0
          : unitEntitlementList[index]?.unitEntitlements?.[innerIndex]?.name === headerEntitlement?.[innerIndex]?.unitEntitlementName
          ? innerIndex
          : headerEntitlement?.findIndex(
              (entitlement: any) => entitlement?.unitEntitlementName === unitEntitlementList[index]?.unitEntitlements?.[innerIndex]?.name
            ) - 1;

      if (unitEntitlementList[index]?.unitEntitlements?.[innerIndex]?.name !== undefined) {
        tableData[index][`${entitlementObjkey}${entitlementNumber}`] =
          unitEntitlementList[index]?.unitEntitlements?.[unitentitlementIndex]?.entitlement?.toFixed(2);
        tableData[index][`${entitlementObjkey}${entitlementNumber}${entitlementObjId}`] =
          unitEntitlementList[index]?.unitEntitlements?.[unitentitlementIndex]?.unitEntitlementId;
        tableData[index][`${entitlementObjkey}${entitlementNumber}${entitlementObjSetId}`] =
          unitEntitlementList[index]?.unitEntitlements?.[unitentitlementIndex]?.unitEntitlementSetId === 0 ||
          unitEntitlementList[index]?.unitEntitlements?.[unitentitlementIndex]?.unitEntitlementSetId === undefined
            ? headerEntitlement?.[innerIndex]?.unitEntitlementSetId
            : unitEntitlementList[index]?.unitEntitlements?.[unitentitlementIndex]?.unitEntitlementSetId;
      } else {
        tableData[index][`${entitlementObjkey}${entitlementNumber}${entitlementObjSetId}`] = headerEntitlement?.[innerIndex]?.unitEntitlementSetId;
      }
    }
  }

  for (let innerIndex = 0; innerIndex < headerEntitlement?.length; innerIndex++) {
    total[`${entitlementObjkey}${innerIndex + 1}`] = tableData
      .reduce((a: number, b: any) => a + parseFloat(b?.[`${entitlementObjkey}${innerIndex + 1}`] || 0), 0)
      ?.toLocaleString(undefined, { minimumFractionDigits: 2 });

    tableColumns.push({
      field: `${entitlementObjkey}${innerIndex + 1}`,
      header: <p className="text !font-bold uppercase">{headerEntitlement?.[innerIndex]?.unitEntitlementName}</p>,
      width: "10rem",
      editor: (data: any) => entitlementEditor(data, handleCellDataSave, data?.rowData[lotNumberObjKey] !== "Total"),
    });
  }

  const newTableData = tableData.map((item: any) => ensureEntitlementProperties(item, headerEntitlement?.length, headerEntitlement));
  const finalTableData = [{ [lotNumberObjKey]: "Total", ...total }, ...newTableData];

  return { tableData: finalTableData, tableColumns };
};