import { Toast } from "primereact/toast";
import { useMemo, useRef, useState } from "react";
import { DotsVertical, Folder, Mail, Mobile, Pencil } from "src/assets/icon";
import FormModal from "src/components/ui/dialog/formModal";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import ToastContainer from "src/components/ui/toast/toast";
import { Labels } from "src/constants/labels";
import { ILease } from "src/interfaces/lease";
import { FormatDate } from "src/utils/utility";
import LeaseTableForm from "./leaseTableForm";

const LeaseTable = ({ leaseDetails, corpId }: { leaseDetails: any; corpId: string }) => {
  const [leaseData, setLeaseData] = useState<Array<ILease>>(leaseDetails);
  const [leaseRowDetails, setLeaseRowDetails] = useState<any>({});
  const [rowIndex, setRowIndex] = useState(0);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);
  let formId = "leaseUpdateForm";

  const columns = useMemo(() => {
    return [
      {
        field: "entry",
        header: <p className="text !font-bold">{Labels.DATE_OF_ENTRY}</p>,
        body: (data: any) => {
          return <p>{FormatDate(data?.entry)}</p>;
        },
      },
      {
        field: "lessee",
        header: <p className="text !font-bold">{Labels.LESSEE}</p>,
        body: (data: any) => {
          return (
            <p className={`font-semibold`} data-testid="lesseeLink">
              {data?.lessee}
            </p>
          );
        },
      },
      {
        field: "noticeAddress",
        header: <p className="text !font-bold">{Labels.NOTICE_ADDRESS}</p>,
      },
      {
        field: "term",
        header: <p className="text !font-bold">{Labels.TERM}</p>,
      },
      {
        field: "commencement",
        header: <p className="text !font-bold">{Labels.COMMENCED}</p>,
        body: (data: any) => {
          return <p>{FormatDate(data?.commencement)}</p>;
        },
      },
      {
        field: "termination",
        header: <p className="text !font-bold">{Labels.TERMINATED}</p>,
        body: (data: any) => {
          return <p>{FormatDate(data?.termination)}</p>;
        },
      },
      // {
      //   field: "",
      //   header: (
      //     <span>
      //       <DotsVertical />
      //     </span>
      //   ),
      //   body: (data: any) => {
      //     return (
      //       <div className="flex justify-between">
      //         <Mail width={16} height={16} />
      //         <Mobile width={16} height={16} />
      //       </div>
      //     );
      //   },
      // },
      {
        field: "edit",
        header: "",
        body: (data: any, options: any) => {
          return (
            <span
              className="cursor-pointer"
              onClick={() => {
                setRowIndex(options?.rowIndex);
                setLeaseRowDetails(data);
                setShowModal(true);
              }}
            >
              <Pencil />
            </span>
          );
        },
      },
    ];
  }, []);

  const handleChange = (rowIndex: number, leaseRow: ILease) => {
    let newLeaseTableData = leaseData?.map?.((lease, leaseRowIndex) => {
      if (leaseRowIndex === rowIndex) {
        return {
          ...lease,
          ...leaseRow,
        };
      } else {
        return {
          ...lease,
        };
      }
    });
    setLeaseData(newLeaseTableData);
  };

  return (
    <>
      <SMDataTableContainer tableData={leaseData} tableColumns={columns} showEditIcon={false} showSelectableRowCheckbox={false} />
      <FormModal
        header="Lease Update"
        formId={formId}
        isOpen={showModal}
        onClose={() => {
          if (isFormDirty) {
            setShowCancelModal(true);
          } else {
            setShowModal(false);
          }
        }}
        isDirty={isFormDirty}
      >
        <LeaseTableForm
          corpId={corpId}
          formData={leaseRowDetails}
          formId={formId}
          showCancelModal={showCancelModal}
          rowIndex={rowIndex}
          handleChange={handleChange}
          setShowCancelModal={setShowCancelModal}
          setShowModal={setShowModal}
          setIsFormDirty={setIsFormDirty}
          toastRef={toastRef}
        />
      </FormModal>
      <ToastContainer toastReference={toastRef} />
    </>
  );
};

export default LeaseTable;
