import React from "react";
import { RadioButton } from "primereact/radiobutton";
import styles from "./radioButton.module.scss";

interface IModal {
  id: any;
  field?: any;
  value?: any;
  disabled?: boolean;
  checked?: boolean;
  onChange?: Function;
}

export const SMRadioButton: React.FC<IModal> = ({ id, field, disabled = false, value, checked, onChange }) => {
  return (
    <RadioButton
      {...field}
      inputId={id}
      inputRef={field.ref}
      value={value}
      checked={checked}
      disabled={disabled}
      onChange={(e) => {
        if (onChange) onChange(e);
        field.onChange(e.value);
      }}
      pt={{
        input: {
          className: `!w-4.5 !h-4.5  ${checked ? styles.radioBtnInput : ""}`,
        },
        hiddenInputWrapper: { className: "!relative" },
      }}
    />
  );
};
