import React from "react";
import { Close } from "../../../assets/icon";
import styles from "./drawer.module.scss";

interface IDrawer {
  title?: string;
  panelMode?: boolean;
  width?: string;
  isOpen: boolean;
  onClose: any;
  children: React.ReactNode;
  closeOnOutsideClick?: boolean;
}

export const Drawer: React.FC<IDrawer> = ({ title, panelMode = false, width = "w-[65vw]", isOpen = false, onClose, children, closeOnOutsideClick }) => {
  if (!isOpen) return null;
  const handleClose = (e: any) => {
    if (closeOnOutsideClick) {
      if (e.target.id === "wrapper") {
        onClose();
      }
    }
  };

  return (
    <div
      id="wrapper"
      className="flex bg-zinc-900 bg-opacity-25 fixed z-50 h-screen overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full"
      onClick={handleClose}
    >
      <div
        className={`flex-wrap overflow-y-scroll top-0 right-0 z-40 fixed p-8 bg-white h-full shadow-md ${width}
          ${isOpen ? "translate-x-0" : "translate-x-full"} ease-in-out duration-500 ${styles.drawer}`}
      >
        {title && (
          <div className="flex justify-between">
            <label className="text-lg font-bold text-gray-900">{title}</label>
            <div id="drawerCloseBtnSection" className={`flex order-last ${panelMode ? "place-self-start p-4" : "place-self-center"}`}>
              <span id="btnClose" data-test-id="btnClose" className="flex justify-end" onClick={() => onClose()}>
                <Close />
              </span>
            </div>
          </div>
        )}
        {panelMode ? (
          <div className="flex ">
            <div className="w-full">{children} </div>
            <div className="place-self-start pt-4">
              <span id="btnClose" data-test-id="btnClose" onClick={() => onClose()}>
                <Close />
              </span>
            </div>
          </div>
        ) : (
          <div className="pt-6 font-normal text-sm">{children}</div>
        )}
      </div>
    </div>
  );
};
