import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { SMRadioButton } from "src/components/ui/radioControl/radioButton";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";

export const LeviesAddress: React.FC<{
  contactDetail: any;
  control: any;
  setContactDetail: any;
  lotDetails: any;
  setSelectedAddress: any;
}> = ({
  contactDetail,
  control,
  setContactDetail,
  lotDetails,
  setSelectedAddress,
}) => {
  const handleOnChange = (value: any, field?: any) => {
    field.onChange(value);
    setContactDetail(
      Object.assign(
        {},
        ...lotDetails?.lotContacts?.filter?.(
          (contact: any) => contact?.contactId === value
        )
      )
    );
    if(value === ""){
      setSelectedAddress("");
    }
    else {
      setSelectedAddress(contactDetail?.contactType);
    }
  };

  let ownerOccupied = lotDetails?.ownerOccupied;
  const contactType =
    contactDetail?.contactType === Labels.OWNER_CONTACT_TYPE
      ? contactDetail?.contactId === lotDetails?.ownerContactId
        ? contactDetail?.contactType
        : ""
      : contactDetail?.contactType;

  useEffect(() => {
    setSelectedAddress(contactType);
  }, [contactType]);

  let otherType =
    (contactType !== Labels.TENANT_CONTACT_TYPE || contactDetail?.contactId !== lotDetails?.tenantContactId) &&
    (contactType !== Labels.OWNER_CONTACT_TYPE || contactDetail?.contactId !== lotDetails?.ownerContactId) &&
    (contactType !== Labels.AGENT_CONTACT_TYPE || (contactType === Labels.AGENT_CONTACT_TYPE && lotDetails.agentContactId === 0) || (contactType === Labels.AGENT_CONTACT_TYPE && lotDetails.agentContactId !== contactDetail.contactId))
      ? true
      : false;

    const validateId = (value: any) => {
      return Boolean(value);
    };

  return (
    <div>
      <Controller
        name="levyContactId"
        control={control}
        rules={{ required: Messages.PROVIDE_CONTACT_MESSAGE, validate: validateId }}
        render={({ field }) => (
          <>
            <div className="flex justify-between h-10 items-center">
              <div className="flex basis-full text-left items-center">
                <SMRadioButton
                  id="ownerField"
                  field={field}
                  value={lotDetails?.ownerContactId}
                  checked={field?.value === lotDetails?.ownerContactId}
                  onChange={(e: any) => handleOnChange(e.value, field)}
                />
                <label
                  htmlFor="ownerAddress"
                  className={`ml-2 font-normal text-left text-sm`}
                >
                  {Labels.USE_OWNERS_ADDRESS}
                </label>
              </div>
              <div className="flex basis-full text-left items-center">
                <SMRadioButton
                  id="agentField"
                  field={field}
                  value={lotDetails?.agentContactId}
                  checked={field?.value === lotDetails?.agentContactId}
                  onChange={(e: any) => handleOnChange(e.value, field)}
                  disabled={ownerOccupied || (lotDetails?.agentContactId !== 0 ? false : true)}
                />
                <label
                  htmlFor="agentAddress"
                  className={`ml-2 font-normal text-left text-sm ${
                    ownerOccupied || (lotDetails?.agentContactId !== 0 ? false : true) ? "opacity-50" : ""
                  }`}
                >
                  {Labels.USE_AGENTS_ADDRESS}
                </label>
              </div>
            </div>
            <div className="flex justify-between h-10 items-center">
              <div className="flex basis-full text-left items-center">
                <SMRadioButton
                  id="tenantField"
                  field={field}
                  value={lotDetails?.tenantContactId}
                  checked={field?.value === lotDetails?.tenantContactId}
                  onChange={(e: any) => handleOnChange(e.value, field)}
                  disabled={lotDetails?.tenantContactId !== 0 ? false : true}
                />
                <label
                  htmlFor="tenantAdress"
                  className={`ml-2 font-normal text-left text-sm
                  ${lotDetails?.tenantContactId === 0 ? "opacity-50" : ""}`}
                >
                  {Labels.USE_TENANTS_ADDRESS}
                </label>
              </div>
              <div className="flex basis-full text-left items-center">
                <SMRadioButton
                  id="otherField"
                  field={field}
                  value=""
                  checked={otherType}
                  onChange={(e: any) => handleOnChange(e.value, field)}
                />
                <label
                  htmlFor="differentAddress"
                  className={`ml-2 font-normal text-left text-sm`}
                >
                  {Labels.DIFFERENT_CONTACT_FOR_LEVIES}
                </label>
              </div>
            </div>
          </>
        )}
      />
    </div>
  );
};
