import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { Labels } from "src/constants/labels";
import { IAssetReplacement, IAssetReplacementData } from "src/interfaces/assetReplacement";
import styles from "../../../budget.module.scss";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { Column } from "primereact/column";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { FormatDate, checkZeroValue, convertToCurrency, toLocaleValue } from "src/utils";
import { IBudgetDate } from "src/interfaces";
import { RootState } from "src/store/rootReducer";
import { useSelector } from "react-redux";
import { NA, toastErrorTimeLimit } from "src/constants/constant";
import { SMInputNumber } from "src/components/ui/inputControl/inputNumber";
import debounce from "lodash.debounce";
import { httpUpdate } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { useParams } from "react-router-dom";
import ToastContainer from "src/components/ui/toast/toast";
import { Toast } from "primereact/toast";

const AssetReplacementTable = ({ data }: { data: IAssetReplacementData }) => {
  const { corpId } = useParams();
  const { selectedDate, budgetDates } = useSelector((state: RootState) => state?.budgetSlice);
  const [assetReplacements, setAssetReplacements] = useState<Array<IAssetReplacement>>(data.assetReplacements);
  const [assetContingency, setAssetContingency] = useState<number>(data?.currentContingency);
  const toastRef = useRef<Toast>(null);
  let valueToDisplayIfBudgetExists = useMemo(() => {
    let indexOfSelectedBudget = budgetDates?.budgetDates?.findIndex((budget: IBudgetDate) => budget?.budgetId === selectedDate?.budgetId);
    return {
      previous: budgetDates?.budgetDates?.find(
        (budget: IBudgetDate, index: number) => index > indexOfSelectedBudget && budget?.groupCodeId === selectedDate?.groupCodeId
      )?.budgetId
        ? 0
        : NA,
      current: budgetDates?.budgetDates?.length > 0 ? 0 : NA,
    };
  }, [selectedDate?.budgetId]);

  const total = useMemo(() => {
    return assetReplacements?.reduce(
      (accumulator, current) => {
        return {
          ...accumulator,
          lastYearBudgetTotal: (accumulator?.lastYearBudgetTotal ?? 0) + (current?.lastYearBudget ?? 0),
          budgetedAmountTotal: (accumulator?.budgetedAmountTotal ?? 0) + (current?.budgetedAmount ?? 0),
        };
      },
      {
        lastYearBudgetTotal: 0,
        budgetedAmountTotal: 0,
      }
    );
  }, [assetReplacements]);

  const checkAssetStatus = (isActive: boolean) => {
    return isActive ? styles.text : "text-sm text-magenta font-semibold";
  };

  const columns = useMemo(() => {
    return [
      {
        field: "assetName",
        header: "",
        body: (data: any) => {
          return <p className={checkAssetStatus(data?.isActive)}>{data?.assetName}</p>;
        },
      },
      {
        field: "replacementCost",
        header: "",
        body: (data: any) => {
          return <p className={checkAssetStatus(data?.isActive)}>{toLocaleValue(data?.replacementCost)}</p>;
        },
      },
      {
        field: "expectedLife",
        header: "",
        body: (data: any) => {
          return <p className={checkAssetStatus(data?.isActive)}>{data?.expectedLife}</p>;
        },
      },
      {
        field: "lastReplaced",
        header: "",
        body: (data: any) => {
          return <p className={checkAssetStatus(data?.isActive)}>{FormatDate(data?.lastReplaced)}</p>;
        },
      },
      {
        field: "lastYearBudget",
        header: "",
        body: (data: any) => {
          return (
            <p className={checkAssetStatus(data?.isActive)}>
              {data?.lastYearBudget ? toLocaleValue(data?.lastYearBudget) : checkZeroValue(data?.lastYearBudget)}
            </p>
          );
        },
      },
      {
        field: "budgetedAmount",
        header: "",
        body: (data: any, options: { rowIndex: number }) => {
          return (
            <p id={`assetBudgetedAmount-${options?.rowIndex}`} className={checkAssetStatus(data?.isActive)}>
              {toLocaleValue(data?.budgetedAmount)}
            </p>
          );
        },
        editor: (data: any) => budgetedAmountEditor(data),
      },
    ];
  }, []);

  const debounceEventHandler = useCallback(
    debounce((data: { assetReplacementBudgetId: number; contingency: number; budgetedAmount: number; budgetId: number }) => {
      handleRowDataSave(data);
    }, 500),
    []
  );

  useEffect(() => {
    return () => {
      debounceEventHandler?.cancel?.();
    };
  }, [debounceEventHandler]);

  const budgetedAmountEditor = (data: any) => {
    return (
      <SMInputNumber
        inputId="budgetedAmount"
        value={data?.value}
        minFractionDigits={2}
        maxLength={17}
        disabled={!data?.rowData?.isActive}
        onChange={(value: number) => {
          let updatedAssets = assetReplacements?.map?.((asset: IAssetReplacement) => {
            if (data?.rowData?.assetReplacementBudgetId === asset?.assetReplacementBudgetId) {
              return {
                ...asset,
                budgetedAmount: value,
              };
            } else {
              return {
                ...asset,
              };
            }
          });
          setAssetReplacements(updatedAssets);
          debounceEventHandler({
            assetReplacementBudgetId: data?.rowData?.assetReplacementBudgetId,
            contingency: assetContingency,
            budgetedAmount: value ?? 0,
            budgetId: 0,
          });
        }}
      />
    );
  };

  const handleRowDataSave = async (data: { assetReplacementBudgetId: number; contingency: number; budgetedAmount: number; budgetId: number }) => {
    let response: any = await httpUpdate(
      `${EndPoints.corporation}${corpId}/${EndPoints.budget}${data?.budgetId}/asset-replacement/${data?.assetReplacementBudgetId}`,
      {
        contingency: data?.contingency,
        budgetedAmount: data?.budgetedAmount,
      }
    );
    if (response?.error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Error: ${response?.error}`,
        life: toastErrorTimeLimit,
      });
    }
  };

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column className={`${styles.textStyle} ${styles.expenseColumnGroup}`} header="Asset Register" colSpan={6} />
      </Row>
      <Row>
        <Column className={`w-80 ${styles.textStyle} ${styles.lotContributionColumns}`} header={Labels.NAME} />
        <Column className={`${styles.textStyle} ${styles.lotContributionColumns}`} header={Labels.REPLACEMENT_COST} />
        <Column className={`${styles.textStyle} ${styles.lotContributionColumns}`} header={Labels.EXPECTED_LIFE} />
        <Column className={`${styles.textStyle} ${styles.lotContributionColumns}`} header={Labels.LAST_REPLACED} />
        <Column className={`${styles.textStyle} ${styles.lotContributionColumns}`} header={Labels.LAST_YEARS_BUDGET} />
        <Column className={`${styles.textStyle} ${styles.lotContributionColumns}`} header={Labels.BUDGETED_AMOUNT} />
      </Row>
    </ColumnGroup>
  );
  return (
    <div className="w-auto h-full p-6 mt-6 shadow-lg rounded-md bg-white">
      <div className="w-full mb-5">
        <div className="flex justify-between">
          <div>
            <p className="text-base font-bold">{Labels.ASSET_REPLACEMENT}</p>
          </div>
        </div>
        <div className="w-full flex justify-end">
          <div className="w-1/2 items-center grid grid-cols-3 gap-4 [&_*]:text-right [&_div]:pr-4">
            <label className={`${styles.text} pt-2.5`}>{Labels.ASSET_REPLACEMENT_CONTINGENCY}</label>
            <div className={`${styles.text} pt-2.5`}>{valueToDisplayIfBudgetExists?.previous ? NA : convertToCurrency(data?.previousContingency)}</div>
            <div className={`${styles.text} pt-2.5`}>
              <SMInputNumber
                inputId="assetContingency"
                value={assetContingency}
                minFractionDigits={2}
                maxLength={17}
                disabled={!selectedDate?.budgetId}
                onChange={(value: number) => {
                  setAssetContingency(value);
                  debounceEventHandler({
                    assetReplacementBudgetId: 0,
                    contingency: value,
                    budgetedAmount: 0,
                    budgetId: selectedDate?.budgetId,
                  });
                }}
              />
            </div>
            <label className={`${styles.text} pb-4`}>{Labels.TOTALS}</label>
            <div className={`${styles.text} pb-4 border-b-2`}>
              {valueToDisplayIfBudgetExists?.previous ? NA : convertToCurrency(total?.lastYearBudgetTotal + data?.previousContingency)}
            </div>
            <div className={`${styles.text} pb-4 border-b-2`}>
              {valueToDisplayIfBudgetExists?.current ? NA : convertToCurrency(total?.budgetedAmountTotal + assetContingency)}
            </div>
          </div>
        </div>
      </div>
      <SMDataTableContainer
        tableData={assetReplacements}
        tableColumns={columns}
        showEditIcon={false}
        showSelectableRowCheckbox={false}
        headerGroup={headerGroup}
        showGridlines
        stripedRows
      />
      <ToastContainer toastReference={toastRef} />
    </div>
  );
};

export default AssetReplacementTable;
