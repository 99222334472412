import { Card } from "src/components/ui/card/card";
import { Labels } from "src/constants/labels";
import { IOwner } from "src/interfaces/ownerContact";
import ContactForm from "../../../shared/contact";

export const EditMainContact : React.FC<{selectedOwner : IOwner,  setEditableContact: any, setSelectedOwner: any, toastRef: any}> = ({selectedOwner, setEditableContact, setSelectedOwner, toastRef}) =>{
    const setData = (data : any) => {
        setSelectedOwner({
            ...selectedOwner,
            contact:{ 
                ...selectedOwner.contact,
                ...data
            }
        });
    };
    const setAdditionalContactData = (data : any) => {
        setSelectedOwner({
            ...selectedOwner,
            contactDetails: data,
        });
    };
    return(
            <Card id="editableContact">
                <ContactForm
                    contactId={selectedOwner?.contact?.contactId} 
                    title={Labels.MAIN_CONTACT_FOR_OWNER} 
                    setEditable={setEditableContact} 
                    setData={setData}
                    toastRef={toastRef}
                    setAdditionalContactData={setAdditionalContactData} 
                    committeeMember={selectedOwner?.executivePosition ? true : false}
                />
            </Card>
        )
    }