import { IBreadCrumb } from "../../../interfaces/breadCrumb";
import { ChevronRight } from "../../../assets/icon";
import styles from "./breadCrumb.module.scss";
import { useNavigate } from "react-router-dom";

export const BreadCrumb: React.FC<{links: IBreadCrumb[]}> = ({ links }) => {
    const navigate =  useNavigate();
    return (
        <nav className="flex gap-2 xl:gap-3">
            {links.map((link, index) => (
                <ol key={index} className="inline-flex items-center space-x-1 lg:space-x-3">
                    <li className="inline-flex items-center cursor-pointer">
                    <div onClick={() => navigate(link?.url)} className={`${ index === 0 ? styles.header : styles.txt_wrapper}`}>
                        {link.title}
                    </div>
                    </li>
                    {index !== links.length - 1 && 
                    <li className={styles.icon_wrapper}>
                        <ChevronRight />
                    </li>
                    }
                </ol>
            ))}
        </nav>
    );
};
