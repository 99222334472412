import styles from "./pageHeader.module.scss";
import { IPageHeader } from "src/interfaces/pageHeader";
import { BreadCrumb } from "src/components/ui/breadCrumb";
import { ArrowLeft, LoadIcon } from "src/assets/icon";
import { Link, useNavigate } from "react-router-dom";
import { comingSoon } from "src/constants/constant";
import { useRef } from "react";
import { Toast } from "primereact/toast";
import ToastContainer from "src/components/ui/toast/toast";

export const PageHeader: React.FC<IPageHeader> = ({ breadCrumb, setSearch, headerData }) => {
  const navigate = useNavigate();
  const baseUrl = `corporations`;
  const toastRef = useRef<Toast>(null);
  return (
    <div className={`${styles.header_container}`}>
      <div className={`flex h-full w-full justify-between items-center ${styles.breadCrumbMobileView}`}>
        <div className="flex order-1 items-center gap-4">
          {breadCrumb?.length > 0 && (
            <span className="justify-start items-center pl-1.5 pt-1" onClick={() => navigate(-1)}>
              <ArrowLeft />
            </span>
          )}
          <BreadCrumb links={breadCrumb} />
        </div>
        <div className="flex gap-5 items-center order-2 pr-2">
          {headerData?.map((x, index) => (
            <div key={index}>
              {x?.button && <>{x?.button}</>}
              {x?.icon && (
                <span
                  key={index}
                  onClick={() =>
                    toastRef?.current?.show({
                      severity: "info",
                      summary: "Info",
                      detail: comingSoon,
                      life: 2000,
                    })
                  }
                >
                  {LoadIcon(x.icon)}
                </span>
              )}
              {x?.name && (
                <button
                  data-testid={`${x.name}_breadcrumb`}
                  key={index}
                  className={`${x.isSelected ? styles.btnPrimary : styles.btn} h-8`}
                  onClick={() => {
                    if (x?.route) {
                      navigate(`${baseUrl}/${x?.route}`);
                    } else {
                      toastRef?.current?.show({
                        severity: "info",
                        summary: "Info",
                        detail: comingSoon,
                        life: 2000,
                      });
                    }
                  }}
                >
                  {x.name}
                </button>
              )}
            </div>
          ))}
        </div>
      </div>
      <ToastContainer toastReference={toastRef} />
    </div>
  );
};
