import { SpinnerHorizontal } from 'src/components/ui/loading/spinnerHorizontal'
import { useHttpGet } from 'src/services/api'
import { Messages } from 'src/constants/messages'
import ContactDetailTable from './contactDetailTable'
import { IContactDetail } from 'src/interfaces/contactDetail'

export const ContactDetail: React.FC<{ contactType: string, selectedRow: any, setSelectedRow: any }> = ({contactType, selectedRow, setSelectedRow}) =>  {
  const { data, error } = useHttpGet(`contact?contactType=${contactType}`) as { data: IContactDetail[], error: any};

  if (error) {
    return (
      <>
        <div className="text-red-500 m-4">{Messages.SOMETHING_WENT_WRONG}</div>
      </>
    );
  }

  if (!data) {
    return (
      <>
        <div className="flex items-center justify-center py-10">
          <div className="w-20 h-20 items-center">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }

  return <ContactDetailTable contactList={data} selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
}




