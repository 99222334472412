import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { Toast } from "primereact/toast";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { IconYesNoNull } from "src/components/ui/iconYesNoNull";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Modal } from "src/components/ui/modal/modal";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import ToastContainer from "src/components/ui/toast/toast";
import { AccessRight, FunctionsList } from "src/constants/enum";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import { IAgency } from "src/interfaces/agency";
import { IUser } from "src/interfaces/users";
import { httpPost, httpUpdate } from "src/services/api";
import { axiosInstance } from "src/services/interceptors";
import { RootState } from "src/store/rootReducer";

const UserTable = ({ users, agencies }: { users: Array<IUser>; agencies: Array<IAgency> }) => {
  const { user, userInfo } = useSelector((state: RootState) => state?.userSlice);
  let userRights = userInfo?.accessRights?.find?.((user: any) => Number(user?.id) === FunctionsList.ConfigUser);
  let adminRights = userInfo?.accessRights?.find?.((user: any) => Number(user?.id) === FunctionsList.SysAdmin);
  const [agency, setAgency] = useState(agencies?.find?.((agency: IAgency) => agency?.agencyId === user?.agencyId));
  const [userList, setUserList] = useState(users);
  const [isLoading, setIsLoading] = useState(false);
  const [userListLoading, setUserListLoading] = useState<any>(false);
  const [showModal, setShowModal] = useState<any>({ visible: false });
  const toast = useRef<Toast>(null);
  const columns = useMemo(() => {
    return [
      {
        field: "name",
        header: Labels.NAME,
      },
      {
        field: "email",
        header: <p className="uppercase">{Labels.EMAIL.substring?.(0, 5)}</p>,
        editor: (data: any) => {
          return (
            <InputText
              type="email"
              value={data.value}
              disabled={
                user?.agencyId !== agency?.agencyId ? true : userRights?.rights !== AccessRight.ReadWrite ? true : data?.rowData?.hasCloudAccess ? true : false
              }
              onChange={(e) => data.editorCallback(e.target.value)}
              maxLength={100}
              pt={{
                root: {
                  className: "!h-10",
                },
              }}
            />
          );
        },
        onCellEditComplete: async (data: any) => {
          if (data?.value !== data?.newValue) {
            let { rowData, newValue, field, originalEvent: event } = data;
            event?.preventDefault?.();
            setIsLoading(true);
            let res = await httpUpdate(`users/${data?.rowData?.userId}`, {
              email: data?.newValue,
              agencyId: agency?.agencyId,
            });
            if (res?.status === 200) {
              rowData[field] = newValue;
            }
            if (res?.error) {
              toast?.current?.show({ severity: "error", summary: "Error", detail: Messages.ERROR_MESSAGE, life: 5000 });
            }
            setIsLoading(false);
          }
        },
      },
      {
        field: "manager",
        header: <p className="uppercase">{Labels.MANAGER}</p>,
        body: (data: any) => {
          return <IconYesNoNull type={data?.manager} />;
        },
      },
      {
        field: "hasCloudAccess",
        header: <p className="uppercase">{Labels.CLOUD_ACCESS}</p>,
        body: (data: any) => {
          return <InputSwitch checked={data?.hasCloudAccess} />;
        },
        editor: (data: any) => {
          return (
            <InputSwitch
              checked={data?.value}
              onChange={(e) => {
                if (!data?.rowData?.email) {
                  toast?.current?.show({ severity: "warn", summary: "Warning", detail: "Please enter an email first", life: 3000 });
                } else {
                  if (!data?.value) {
                    data.editorCallback(e.value);
                    activateUser({ ...data, newValue: true });
                  } else setShowModal({ visible: true, userData: data });
                }
              }}
            />
          );
        },
      },
    ];
  }, [agency?.agencyId]);

  const deactivateUser = async () => {
    setIsLoading(true);
    let userData = { ...showModal?.userData?.rowData };
    let res: any = await httpUpdate("users/deactivate", {
      email: userData?.email,
      userId: userData?.userId,
      agencyId: agency?.agencyId,
    });
    if (res?.data) {
      if (res?.data?.isSuccessful) {
        toast?.current?.show({ severity: "success", summary: "Success", detail: "User has been deactivated succesfully", life: 3000 });
        updateUser({ ...showModal?.userData, newValue: false }, "hasCloudAccess");
      } else {
        toast?.current?.show({ severity: "error", summary: "Error", detail: res?.data?.failureReason, life: 5000 });
      }
    }
    if (res?.error) {
      toast?.current?.show({ severity: "error", summary: "Error", detail: Messages.ERROR_MESSAGE, life: 5000 });
    }
    setIsLoading(false);
  };

  const activateUser = async (data: any) => {
    setIsLoading(true);
    let userData: IUser = { ...data?.rowData };
    let name = userData?.name?.split?.(" ");
    try {
      let res: any = await httpPost("users/user-provision", {
        users: [
          {
            userId: userData?.userId,
            firstName: name?.length === 1 ? "" : name?.[0] ?? "",
            lastName: name?.length === 1 ? name?.[0] ?? "" : name?.length > 1 ? name.slice(1).join(" ") ?? "" : "",
            email: userData?.email,
            agencyId: agency?.agencyId,
          },
        ],
      });
      if (res?.data?.[0]?.isSuccessful) {
        toast?.current?.show({ severity: "success", summary: "Success", detail: Messages.USER_ACCESS_GRANTED, life: 3000 });
        updateUser(data, "hasCloudAccess");
      } else {
        data.editorCallback(false);
        toast?.current?.show({ severity: "error", summary: "Error", detail: res?.data?.[0]?.failureReason, life: 5000 });
      }
    } catch (err) {}
    setIsLoading(false);
  };

  const updateUser = (data: any, key: string) => {
    setUserList((prevUserList: Array<IUser>) => {
      let newData = prevUserList.map?.((user: IUser, rowIndex: number) => {
        if (user?.userId === data?.rowData?.userId) {
          return {
            ...user,
            [key]: data?.newValue,
          };
        } else {
          return {
            ...user,
          };
        }
      });
      return newData;
    });
  };

  const handleAgencyChange = (e: any) => {
    if (e?.value) {
      let agency = agencies?.find?.((agency) => agency?.agencyName === e?.value);
      setAgency(agency);
      setUserListLoading(true);
      axiosInstance.get(`users/${agency?.agencyId}`).then((res) => {
        if (res?.data) {
          setUserList(res?.data);
        } else {
          setUserList([]);
        }
        setUserListLoading(false);
      });
    }
  };

  return (
    <div className="relative p-4">
      {isLoading && (
        <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-modal overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <div className={`flex w-1/4 mr-4 right-0 items-center absolute ${isLoading || userListLoading ? "z-10" : "z-30"}`}>
        <p className="mr-2 font-semibold">{Labels.AGENCY}:</p>
        <SMDropDown
          options={agencies?.filter?.((agency: IAgency) => Boolean(agency?.isActive))}
          optionLabel="agencyName"
          optionValue="agencyName"
          value={agency?.agencyName}
          disabled={userListLoading ? true : !Boolean(adminRights?.rights)}
          onChange={handleAgencyChange}
        />
      </div>
      <SMDataTableContainer
        key={userListLoading}
        tableData={userList}
        tableColumns={columns}
        showEditIcon={true}
        editMode="cell"
        loading={userListLoading}
        enableGlobalSearch
        globalFilterFields={["name", "email"]}
      />
      <Modal
        isOpen={showModal?.visible}
        onClose={() => setShowModal({ visible: false })}
        title="Warning"
        onAction={() => {
          setShowModal({ visible: false });
          deactivateUser();
        }}
      >
        <div>
          <h2>{Messages.DEACTIVATE_USER_CONFIRMATION}</h2>
        </div>
      </Modal>
      <ToastContainer toastReference={toast} />
    </div>
  );
};

export default UserTable;
