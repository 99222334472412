import { AddUser, DocumentText, DocumentWord2, Line, MailMerge, ViewColumn } from "src/assets/icon";
import { CommitteeTable } from "../committeeTable/committeeTable";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { Labels } from "src/constants/labels";
import { CommitteeDetails } from "../committeeDetails/committeeDetails";
import { useRef, useState } from "react";
import ToastContainer from "src/components/ui/toast/toast";
import { Toast } from "primereact/toast";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";
import { mailToSend } from "src/utils/utility";
import { comingSoon } from "src/constants/constant";
import { ICommittee, ICommitteeData } from "src/interfaces";
import { useDispatch } from "react-redux";
import { setSelectedCommittee } from "src/store/slice/committeeSlice";
import { ILots } from "src/interfaces/lotList";

const CommitteeCard = ({ data, lotData }: { data: ICommitteeData; lotData: ILots }) => {
  const { agency } = useSelector((state: RootState) => state?.agencySettingSlice);
  const { committeeList } = useSelector((state: RootState) => state?.committeeSlice);
  const toast = useRef<Toast>(null);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const dispatch = useDispatch();
  const mortgageTableButtons = [
    {
      buttonName: "Edit",
      type: "normal",
      onClick: () =>
        toast?.current?.show({
          severity: "info",
          summary: "Info",
          detail: comingSoon,
          life: 2000,
        }),
    },
    // { buttonName: <DocumentText />, type: "word" },
    // { buttonName: <Line height={32} />, type: "line" },
    // { buttonName: <DocumentWord2 />, type: "word" },
    // { buttonName: <Line height={32} />, type: "line" },
    {
      buttonName: <MailMerge />,
      type: "word",
      onClick: () => {
        mailToSend(committeeList, toast, agency?.useAdditionalEmailAddresses, 0);
      },
    },
    // { buttonName: <Line height={32} />, type: "line" },
    // { buttonName: <ViewColumn /> },
    { buttonName: <Line height={32} />, type: "line" },
    {
      buttonName: <AddUser width={24} height={24} />,
      type: "word",
      onClick: () => {
        setShowSidebar(true);
        dispatch(setSelectedCommittee({} as ICommittee));
      },
    },
  ];

  return (
    <div className="pl-10 pr-2">
      <CollapsibleCard id="committee" title={Labels.COMMITTEE} expand={true} buttons={mortgageTableButtons} isCollapsible={false}>
        <CommitteeDetails data={data} />
        <CommitteeTable data={data} lotData={lotData} toast={toast} showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      </CollapsibleCard>
      <ToastContainer toastReference={toast} />
    </div>
  );
};

export default CommitteeCard;
