import "primereact/resources/themes/lara-light-indigo/theme.css";
import Main from "./main";
import { BrowserRouter as Router } from "react-router-dom";
import ConfigService from "./services/configService";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setAuthConfig } from "./store/slice/authSlice";
import LoadingPage from "./components/shared/loading/loadingPage";
import { appInsightsInit } from "./appInsights";
import { Messages } from "./constants/messages";
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";

export default function App() {
  const dispatch = useDispatch();
  const configService = new ConfigService();
  const [isConfig, setIsConfig] = useState(false);
  const [appInsights, setAppInsights] = useState<any>();

  useEffect(() => {
    configService.getInstance();
    configService
      .loadConfigData()
      .then((res) => {
        if (res) {
          setIsConfig(true);
          dispatch(setAuthConfig(res));
          let { reactPlugin } = appInsightsInit();
          setAppInsights(reactPlugin);
        }
      })
      .catch((err) => {});
  }, []);

  return isConfig ? (
    <Router>
      <AppInsightsErrorBoundary appInsights={appInsights} onError={() => <h1>{Messages.SOMETHING_WENT_WRONG}</h1>}>
        <Main />
      </AppInsightsErrorBoundary>
    </Router>
  ) : (
    <LoadingPage />
  );
}
