import React from "react";
import { useHttpGet } from "src/services/api";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { EndPoints } from "src/constants/endpoint";
import { httpCode, noDataFound } from "src/constants/constant";
import { Errors } from "src/components/shared/error/error";
import { Messages } from "src/constants/messages";
import { LevyDetailSection } from "./levyDetailsSection";

export const LevyDetails: React.FC<{ corpId: any }> = ({ corpId }) => {
  const { data, error, statusCode } = useHttpGet(
    EndPoints.corporation + `${corpId}/financial/levy-details`
  );

  if (error || httpCode.INTERNAL_SERVER_ERROR === statusCode) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (httpCode.NOT_FOUND === statusCode || httpCode.NO_CONTENT === statusCode) {
    return <div className="flex w-full m-4">{noDataFound}</div>;
  }

  if (!data) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-10 h-10">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }
  return <LevyDetailSection corpId={corpId} financialLevyDetails={data} />;
};
