import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICommittee } from "src/interfaces";

interface ICommitteeSlice {
  committeeList: Array<ICommittee>;
  selectedCommittee: ICommittee | null;
}

const initialState: ICommitteeSlice = {
  selectedCommittee: null,
  committeeList: [],
};

const committeeSlice = createSlice({
  name: "committee",
  initialState,
  reducers: {
    setCommitteeList: (state, action: PayloadAction<Array<ICommittee>>) => {
      state.committeeList = action.payload;
    },
    setSelectedCommittee: (state, action: PayloadAction<ICommittee>) => {
      state.selectedCommittee = action.payload;
    },
    updateCommitteeList: (state, action: PayloadAction<ICommittee>) => {
      let updatedCommitteeList = state?.committeeList?.map?.((committee: ICommittee) => {
        if (committee?.executiveMemberId === action?.payload?.executiveMemberId) {
          return {
            ...action?.payload,
          };
        } else {
          return committee;
        }
      });
      state.committeeList = updatedCommitteeList;
    },
  },
});

export const { setSelectedCommittee, setCommitteeList, updateCommitteeList } = committeeSlice.actions;
export default committeeSlice.reducer;
