import axios from "axios";
import { httpCode } from "src/constants/constant";
import store from "src/store/store";
import { AuthService } from "./authService";
import { removeTokensFromLocalStorage } from "src/utils";

export const axiosInstance = axios.create();

axiosInstance.interceptors.request.use(
  (config) => {
    config.baseURL = store?.getState?.().authSlice?.authConfig?.apiServer?.strataApiUrl + "/api/mri/";
    const token = localStorage.getItem("access_token");
    const agencyId = store?.getState?.().userSlice?.user?.agencyId;
    if (token) {
      config.headers["Accept"] = "application/json";
      config.headers["Content-Type"] = "application/json";
      config.headers["Authorization"] = "Bearer " + token;
      config.headers["AgencyId"] = agencyId;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response: any) => {
    if (response?.status === httpCode.UNAUTHORIZED) {
      handleUnauthorizedRepsonse();
    } else {
      return response;
    }
  },
  (error) => {
    if (error?.response?.status === httpCode.UNAUTHORIZED) {
      handleUnauthorizedRepsonse();
    }
    throw(error);
  }
);

const handleUnauthorizedRepsonse = () => {
  const auth = new AuthService();
  auth?.removeUser?.();
  removeTokensFromLocalStorage();
  window.location.href = window?.location?.origin;
};
