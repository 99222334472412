import AgencyList from "./agencyList/agencyList";
import { useDispatch, useSelector } from "react-redux";
import { setBreadCrumbs, setIcons } from "src/store/slice/breadCrumbSlice";
import { useEffect } from "react";
import { RootState } from "src/store/rootReducer";
import { FunctionsList } from "src/constants/enum";
import UnauthorizedAccess from "src/components/shared/unauthorizedAccess/unauthorizedAccess";

const Agencies = () => {
  const { userInfo } = useSelector((state: RootState) => state?.userSlice);
  const dispatch = useDispatch();
  let agencyRights = userInfo?.accessRights?.find?.((user: any) => user?.id === FunctionsList.SysAdmin);

  useEffect(() => {
    dispatch(setBreadCrumbs([{ title: "Agencies", url: "agencies" }]));
    dispatch(setIcons([]));
  }, []);

  if (!agencyRights) {
    return (
      <div className="flex items-center">
        <UnauthorizedAccess showMessage={false} />
      </div>
    );
  }

  return <AgencyList />;
};

export default Agencies;
