import { useHttpGet } from "src/services/api";
import CommitteeCard from "./committeeCard/committeeCard";
import { EndPoints } from "src/constants/endpoint";
import { errorMessage } from "src/constants/constant";
import { Errors } from "src/components/shared/error/error";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";

const Committee = ({ corpId }: { corpId: string }) => {
  const { data, error } = useHttpGet(EndPoints.corporation + `${corpId}/committee-list`);
  const { data: lotData } = useHttpGet(EndPoints.corporation + `${corpId}/lots`);

  if (!data || !lotData) {
    return (
      <div className="flex w-full min-h-full items-center justify-center">
        <div className="w-20 h-20">
          <SpinnerHorizontal />
        </div>
      </div>
    );
  }

  if (error) {
    return <Errors error={errorMessage} alignment="left" />;
  }
  return <CommitteeCard data={data} lotData={lotData} />;
};

export default Committee;
