import { useState } from "react";
import styles from './inlineEdit.module.scss';

interface InlineEditProp{
    index?: number,
    value: any,
    setValue?: any,
    onEdit?: any,
}

export const InlineEdit : React.FC<InlineEditProp> = ({ index, value, setValue, onEdit }) => {
    const [editingValue, setEditingValue] = useState(value);

    const onChange = (event: any) => setEditingValue(event.target.value);

    const onKeyDown = (event: any) => {
      if (event.key === "Enter" || event.key === "Escape") {
        event.target.blur();
      }
    }

    const onBlur = (event: any) => {
      if(index !== undefined && index !== null){
        onEdit(index, event.target.value);
      }
      if(setValue){
        setValue(event.target.value);
      }
    }
  
    return (
      <input className={`${styles.input} flex basis-full sm:px-3 py-2 text-left text-sm font-normal `}
        type="text"
        aria-label="Field name"
        value={editingValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
      />
    )
  }