import styles from "./tableContainer.module.scss";
import React, { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column, ColumnBodyOptions, ColumnEvent } from "primereact/column";
import { Ripple } from "primereact/ripple";
import { DotsVertical, Pencil, Search, ViewColumn } from "../../../assets/icon";
import { Dropdown } from "primereact/dropdown";
import { classNames } from "primereact/utils";
import { SpinnerHorizontal } from "../loading/spinnerHorizontal";
import { Labels } from "src/constants/labels";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { MultiSelect, MultiSelectChangeEvent } from "primereact/multiselect";
import { getCacheData, setCacheData } from "src/hooks/localStorage";

export interface IColumn {
  field: string;
  header: string | React.ReactNode;
  body?: (data: any, options: ColumnBodyOptions) => React.JSX.Element;
  editor?: (data: any) => React.JSX.Element;
  width?: string;
  onCellEditComplete?: (data: ColumnEvent) => void;
  hidden?: boolean;
  sortable?: boolean;
  expander?: boolean | ((data: any, options: ColumnBodyOptions) => boolean);
}

interface IDataTableContainerProps {
  id?: any;
  tableData: Array<any>;
  tableColumns: Array<IColumn>;
  dataKey?: string | undefined;
  showEditIcon: boolean;
  showSelectableRowCheckbox?: boolean;
  showPaginator?: boolean;
  handleDoubleRowClick?: any;
  handleRowClick?: any;
  selectedRow?: any;
  setSelectedRow?: any;
  defaultPageSize?: number;
  editMode?: string | undefined;
  loading?: boolean | undefined;
  enableGlobalSearch?: boolean;
  enableColumnConfig?: boolean;
  filter?: any;
  globalFilterFields?: string[] | undefined;
  headerGroup?: React.ReactNode | undefined;
  footerGroup?: React.ReactNode | undefined;
  showGridlines?: boolean | undefined;
  stripedRows?: boolean | undefined;
  stateKey?: string | undefined;
  sortField?: string | undefined;
  resizableColumns?: boolean | undefined;
  rowExpansionTemplate?: (data: any) => React.ReactNode;
}

const paginationDropdownOptions = [
  { label: 5, value: 5 },
  { label: 7, value: 7 },
  { label: 10, value: 10 },
  { label: 25, value: 25 },
  { label: 50, value: 50 },
];

const rowsPerPage = [5, 10, 25, 50];

export const SMDataTableContainer = ({
  id,
  tableData,
  tableColumns,
  dataKey,
  showEditIcon,
  showSelectableRowCheckbox = true,
  showPaginator = true,
  handleDoubleRowClick,
  handleRowClick,
  selectedRow,
  setSelectedRow,
  defaultPageSize = 10,
  editMode = undefined,
  loading = false,
  enableGlobalSearch = false,
  enableColumnConfig = false,
  filter = {},
  globalFilterFields,
  headerGroup,
  footerGroup,
  showGridlines = false,
  stripedRows = false,
  stateKey,
  sortField,
  resizableColumns,
  rowExpansionTemplate,
}: IDataTableContainerProps) => {
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({ ...filter, global: { value: null, matchMode: FilterMatchMode.CONTAINS } });
  const [expandedRows, setExpandedRows] = useState<any>(null);
  const [visibleColumns, setVisibleColumns] = useState(
    getCacheData(`${stateKey ?? ""}/${"columnConfig"}`, "columnConfig")?.map?.((column: IColumn) => {
      let columnBody = tableColumns?.find?.((columns: IColumn) => columns?.header === column?.header)?.body;
      if (columnBody) {
        return {
          ...column,
          body: columnBody,
        };
      } else {
        return {
          ...column,
        };
      }
    }) ?? tableColumns
  );

  const template = {
    layout: " FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown",
    PageLinks: (options: any) => {
      if (
        (options.view.startPage === options.page && options.view.startPage !== 0) ||
        (options.view.endPage === options.page && options.page + 1 !== options.totalPages)
      ) {
        const className = classNames(options.className, { "p-disabled": true });

        return (
          <span className={className} style={{ userSelect: "none" }}>
            ...
          </span>
        );
      }

      return (
        <button type="button" className={options.className} onClick={options.onClick}>
          {options.page + 1}
          <Ripple />
        </button>
      );
    },
    RowsPerPageDropdown: (options: { value: any; onChange: any }) => {
      const dropdownOptions = paginationDropdownOptions;

      return (
        <React.Fragment>
          <Dropdown value={options.value} options={dropdownOptions} onChange={options.onChange} />
        </React.Fragment>
      );
    },
    CurrentPageReport: (options: any) => {
      return (
        <span style={{ fontSize: "14px", fontWeight: "400", color: "var(--text-color)", userSelect: "none", width: "250px", textAlign: "center" }}>
          Showing {options.first} to {options.last} of {options.totalRecords} Entries
        </span>
      );
    },
  };

  const onGlobalFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const onColumnToggle = (event: MultiSelectChangeEvent) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = tableColumns?.filter?.((col) => selectedColumns.some((sCol: { field: string }) => sCol.field === col.field));
    setCacheData(`${stateKey ?? ""}/${"columnConfig"}`, "columnConfig", orderedSelectedColumns);
    setVisibleColumns(orderedSelectedColumns);
  };

  const renderHeader = () => {
    return (
      <div className={`w-full flex ${!enableGlobalSearch ? "justify-end" : "justify-between"}`}>
        {enableGlobalSearch && (
          <span className="p-input-icon-right">
            <Search />
            <InputText
              placeholder="Search"
              pt={{
                root: {
                  className: "h-10",
                },
              }}
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
            />
          </span>
        )}
        {enableColumnConfig && (
          <div className="flex relative justify-end">
            <MultiSelect
              className="w-full h-10 !rounded !border-none sm:w-20rem"
              optionLabel="header"
              value={visibleColumns}
              options={tableColumns}
              onChange={onColumnToggle}
              pt={{
                labelContainer: { className: "hidden" },
                item: { className: "!bg-white !text-black focus:!shadow-none [&_span]:text-xs !font-bold" },
                checkbox: { className: "!rounded" },
                triggerIcon: { className: "z-30 opacity-0" },
                checkboxContainer: {
                  onClick: (e: any) => {
                    e?.stopPropagation?.();
                    e?.target?.parentNode?.click?.();
                  },
                },
              }}
            />
            <div className="flex absolute justify-center items-center gap-1 right-0 left-0 top-0 bottom-0">
              <ViewColumn />
            </div>
          </div>
        )}
      </div>
    );
  };

  const customRestoreState = (): object | undefined => {
    const restoredState = localStorage.getItem(stateKey || "");
    if (restoredState) {
      return JSON.parse(localStorage.getItem(stateKey || "") || "");
    }
  };

  return (
    <DataTable
      id={id}
      dataKey={dataKey}
      value={tableData}
      editMode={editMode}
      selectionMode="single"
      stateStorage="custom"
      stateKey={stateKey}
      sortField={sortField}
      sortOrder={1}
      selection={selectedRow}
      loading={loading}
      loadingIcon={
        <div className="w-20 h-20">
          <SpinnerHorizontal />
        </div>
      }
      emptyMessage={Labels.NO_DATA_AVAILABLE}
      header={(enableGlobalSearch || enableColumnConfig) && renderHeader}
      filters={filters}
      globalFilterFields={globalFilterFields}
      onSelectionChange={(e) => {
        if (setSelectedRow) setSelectedRow(e?.value);
      }}
      customRestoreState={customRestoreState}
      customSaveState={(state: any) => {
        delete state?.filters;
        localStorage.setItem(stateKey as string, JSON.stringify(state));
      }}
      showGridlines={showGridlines}
      stripedRows={stripedRows}
      resizableColumns={resizableColumns}
      columnResizeMode="fit"
      metaKeySelection={false}
      headerColumnGroup={headerGroup}
      footerColumnGroup={footerGroup}
      expandedRows={expandedRows}
      onRowExpand={(e) => {
        setExpandedRows({ [e?.data?.[dataKey as string]]: true });
      }}
      onRowCollapse={(e) => {
        setExpandedRows(null);
      }}
      rowExpansionTemplate={rowExpansionTemplate}
      paginator={showPaginator}
      paginatorTemplate={showPaginator ? template : undefined}
      pt={{
        root: { style: { width: "100%" } },
        paginator: {
          root: { className: `${showPaginator ? styles.paginatorStyle : ""}` },
        },
        thead: {
          className: `${!showGridlines && styles.theadStyle} `,
        },
        table: {
          className: `${!showGridlines && styles.tbodyStyle}`,
        },
        loadingOverlay: {
          className: "!bg-white",
        },
        header: {
          className: "!bg-white !border-none !pt-0 !pl-0 !pr-0 overflow-auto",
        },
      }}
      paginatorClassName={styles.paginator}
      rows={defaultPageSize}
      rowsPerPageOptions={rowsPerPage}
      onRowDoubleClick={handleDoubleRowClick}
      onRowClick={handleRowClick}
    >
      {showSelectableRowCheckbox && (
        <Column className={styles.checkbox} selectionMode="multiple" frozen reorderable={false} headerStyle={{ width: "3rem" }}></Column>
      )}
      {(enableColumnConfig ? visibleColumns : tableColumns)?.map((col: IColumn) => {
        return (
          <Column
            key={col?.field}
            columnKey={col?.field}
            field={col?.field}
            header={col?.header}
            hidden={col?.hidden}
            sortable={col?.sortable}
            expander={col?.expander}
            body={col?.body}
            editor={col?.editor}
            onCellEditComplete={col?.onCellEditComplete}
            className="text-xs font-normal"
            pt={{
              headerContent: { style: { width: col?.width } },
            }}
          ></Column>
        );
      })}
      {showEditIcon && <Column body={<Pencil />} header={<DotsVertical />}></Column>}
    </DataTable>
  );
};
