"use client";
import { useHttpGet } from "src/services/api";
import Notices from "./notices";
import { EndPoints } from "src/constants/endpoint";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Errors } from "src/components/shared/error/error";
import { errorMessage } from "src/constants/constant";

export default function NoticesTab({ corpId, lotId }: { corpId: string; lotId: string }) {
  const { data: noticeDetails, error: noticeDetailsError } = useHttpGet(EndPoints.corporation + corpId + "/lots/" + lotId + "/notice");
  const { data: lotDetails, error: lotDetailsError } = useHttpGet(EndPoints.corporation + corpId + "/lots/" + lotId);

  if (!noticeDetails || !lotDetails) {
    return (
      <div className="flex w-full min-h-full items-center justify-center">
        <div className="w-20 h-20">
          <SpinnerHorizontal />
        </div>
      </div>
    );
  }

  if (noticeDetailsError || lotDetailsError) {
    return <Errors error={errorMessage} alignment="left" />;
  }

  return <Notices noticeDetails={noticeDetails} lotDetails={lotDetails} corpId={corpId} lotId={lotId} />;
}
