import { EndPoints } from "src/constants/endpoint";
import { useHttpGet } from "src/services/api";
import LeaseTable from "./leaseTable/leaseTable";
import { Labels } from "src/constants/labels";
import styles from "../agent.module.scss";

const Lease = ({ lotDetails, corpId, lotId }: { lotDetails: any; corpId: string; lotId: string }) => {
  const { data: leaseDetails, error: leaseDetailsError, statusCode: leaseStatus } = useHttpGet(EndPoints.corporation + corpId + "/lots/" + lotId + "/leases");
  
  if(leaseStatus === 404 ||leaseStatus === 204 ){
    return(
      <div className={`w-full rounded-lg justify-center !flex-row p-10 mt-5 ${styles.card}`}>
        <p>{Labels.NO_LEASE_AVAILABLE}</p>
      </div>
    );
  }
  return (
    <>
      {leaseDetails?.length > 0 ? (
        <div className="w-full pb-10 pt-10">
          <LeaseTable leaseDetails={leaseDetails} corpId={corpId} />
        </div>
      ) : (
        <div className={`w-full rounded-lg justify-center !flex-row p-10 mt-5 ${styles.card}`}>
          <p>{Labels.NO_LEASE_AVAILABLE}</p>
        </div>
      )}
    </>
  );
};

export default Lease;
