import LotContributionsCard from "./lotContributionsCard/lotContributionsCard";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";

const LotContributions = () => {
  const { selectedDate } = useSelector((state: RootState) => state?.budgetSlice);
  return (
    <div>
      <LotContributionsCard key={selectedDate?.budgetId} />
    </div>
  );
};

export default LotContributions;
