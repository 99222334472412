import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICoverage, IPolicy } from "src/interfaces";

interface IInsuranceSlice {
  policyList: Array<IPolicy>;
  coverageList: Array<ICoverage>;
  selectedPolicy: IPolicy;
  selectedCoverage: ICoverage;
}

const initialState: IInsuranceSlice = {
  policyList: [],
  coverageList: [],
  selectedPolicy: {} as IPolicy,
  selectedCoverage: {} as ICoverage,
};

const insuranceSlice = createSlice({
  name: "insurance",
  initialState,
  reducers: {
    setPolicyList: (state, action: PayloadAction<Array<IPolicy>>) => {
      state.policyList = action.payload;
    },
    setSelectedPolicy: (state, action: PayloadAction<IPolicy>) => {
      state.selectedPolicy = action.payload;
    },
    setCoverageList: (state, action: PayloadAction<Array<ICoverage>>) => {
      state.coverageList = action.payload;
    },
    setSelectedCoverage: (state, action: PayloadAction<ICoverage>) => {
      state.selectedCoverage = action.payload;
    },
  },
});

export const { setSelectedPolicy, setPolicyList, setCoverageList, setSelectedCoverage } = insuranceSlice.actions;
export default insuranceSlice.reducer;
