import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import usePlanNumber from "src/hooks/usePlanNumber";
import { setBreadCrumbs, setIcons } from "src/store/slice/breadCrumbSlice";
import { BuildingManagerContact } from "./buildingManager/buildingManager";
import { ImportantContact } from "./importantContact/importantContact";
import { TradesmenContact } from "./tradesmenContact/tradesmenContact";

export default function Contacts() {
  const { corpId } = useParams();
  const plaNumber = usePlanNumber(corpId ?? "");
  const dispatch = useDispatch();
  const [importantContact, setImportantContact] = useState([]);

  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { title: "Corporations", url: "/corporations" },
        { title: `${plaNumber}`, url: `/corporations/${corpId}` },
        { title: "Important Contacts", url: `/corporations/${corpId}/contact` },
      ])
    );
    dispatch(
      setIcons([
        { name: "Important Contacts", route: `${corpId}/contact`, isSelected: true },
        { name: "Lots", route: `${corpId}/lots`, isSelected: false },
        { name: "Committee", route: `${corpId}/committee`, isSelected: false },
        { name: "Entitlements", route: `${corpId}/unitentitlements`, isSelected: false },
      ])
    );
  }, [plaNumber]);

  return (
    <div className="pl-7">
      <ImportantContact corpId={corpId} />
      <BuildingManagerContact corpId={corpId} />
      {/* <CommitteeContact corpId={corpId} /> */}
      <TradesmenContact corpId={corpId} />
      {/* <CreditorContact corpId={corpId} />  */}
    </div>
  );
}
