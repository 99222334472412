import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import usePlanNumber from "src/hooks/usePlanNumber";
import { setBreadCrumbs, setIcons } from "src/store/slice/breadCrumbSlice";
import InsuranceDetails from "./insuranceDetails";

export default function Insurance() {
  const { corpId } = useParams();
  const plaNumber = usePlanNumber(corpId ?? "");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { title: "Corporations", url: "/corporations" },
        { title: `${plaNumber}`, url: `/corporations/${corpId}` },
        { title: "Insurance", url: `/corporations/${corpId}/insurance` },
      ])
    );
    dispatch(
      setIcons([
        { name: "Plan Details", route: `${corpId}/plan`, isSelected: false },
        { name: "Insurance", route: `${corpId}/insurance`, isSelected: true },
        { name: "Structure", route: `${corpId}/structure`, isSelected: false },
      ])
    );
  }, [plaNumber]);

  return (
    <InsuranceDetails />
  );
}
