import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Errors } from "src/components/shared/error/error";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import { SMDatePicker } from "src/components/ui/datePicker/datePicker";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { SMInputTextArea } from "src/components/ui/inputControl/inputTextarea";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Modal } from "src/components/ui/modal/modal";
import { NIL, cancelButtonText, httpCode, modalDiscardMessageText, saveButtonText, toastErrorTimeLimit, toastSuccessTimeLimit } from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import { IPolicy, IPolicyType } from "src/interfaces";
import { ICreditors } from "src/interfaces/creditors";
import { httpUpdate } from "src/services/api";
import { RootState } from "src/store/rootReducer";
import { setPolicyList } from "src/store/slice/insurance";
import { FormatDate, toDate, toLocaleValue, validateDecimal } from "src/utils";

const PolicyForm = ({
  policyTypes,
  brokers,
  companies,
  toastRef,
  setShowSidebar,
}: {
  policyTypes: Array<IPolicyType>;
  brokers: Array<ICreditors>;
  companies: Array<ICreditors>;
  toastRef: any;
  setShowSidebar: any;
}) => {
  const { corpId } = useParams();
  const dispatch = useDispatch();
  const { selectedPolicy, policyList } = useSelector((state: RootState) => state?.insuranceSlice);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      renewalDate: selectedPolicy?.renewalDate,
      policyTypeId: selectedPolicy?.policyTypeId,
      creditorId: selectedPolicy?.creditorId,
      lastPremium: toLocaleValue(selectedPolicy?.lastPremium)?.replaceAll?.(",", ""),
      policyNumber: selectedPolicy?.policyNumber,
      brokerId: selectedPolicy?.brokerId,
      datePaid: selectedPolicy?.datePaid,
      notes: selectedPolicy?.notes ?? "",
      active: selectedPolicy?.active,
      policyStartDate: selectedPolicy?.policyStartDate,
      recordCommission: selectedPolicy?.recordCommission,
      commissionDue: Boolean(selectedPolicy?.commissionDue) ? toLocaleValue(selectedPolicy?.commissionDue)?.replaceAll?.(",", "") : NIL,
      commissionReceived: Boolean(selectedPolicy?.commissionReceived) ? toLocaleValue(selectedPolicy?.commissionReceived)?.replaceAll?.(",", "") : NIL,
      estimatedCommission: Boolean(selectedPolicy?.estimatedCommission) ? toLocaleValue(selectedPolicy?.estimatedCommission)?.replaceAll?.(",", "") : NIL,
    },
  });

  const recordCommission = watch("recordCommission");

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    let commissionDue = recordCommission ? Number(data?.commissionDue) : 0;
    let commissionReceived = recordCommission
      ? !Boolean(Number(data?.commissionReceived)) || data?.commissionReceived === NIL
        ? 0
        : Number(data?.commissionReceived)
      : 0;
    let estimatedCommission = recordCommission
      ? !Boolean(Number(data?.estimatedCommission)) || data?.estimatedCommission === NIL
        ? 0
        : Number(data?.estimatedCommission)
      : 0;
    let lastPremium = !Boolean(Number(data?.lastPremium)) ? 0 : Number(data?.lastPremium);
    let brokerId = brokers?.find((broker: ICreditors) => broker?.creditorId === data?.brokerId)?.creditorId ? data?.brokerId : 0;
    let payload = {
      ...data,
      brokerId: brokerId,
      datePaid: toDate(FormatDate(data?.datePaid)),
      policyStartDate: toDate(FormatDate(data?.policyStartDate)),
      renewalDate: toDate(FormatDate(data?.renewalDate)),
      lastPremium: lastPremium,
      commissionDue: commissionDue,
      commissionReceived: commissionReceived,
      estimatedCommission: estimatedCommission,
    };
    let response = await httpUpdate(`${EndPoints.corporation}${corpId}/${EndPoints.insurance}/${selectedPolicy?.insuranceId}`, payload);
    if (response?.status === httpCode.SUCCESS) {
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: toastSuccessTimeLimit,
      });
      dispatch(
        setPolicyList(
          policyList?.map((policy: IPolicy) => {
            if (policy?.insuranceId === selectedPolicy?.insuranceId) {
              return {
                ...policy,
                ...data,
                companyName: companies?.find?.((company: ICreditors) => company?.creditorId === data?.creditorId)?.creditorName,
                brokerName: brokers?.find?.((broker: ICreditors) => broker?.creditorId === data?.brokerId)?.creditorName,
                description: policyTypes?.find?.((policyType: IPolicyType) => policyType?.policyTypeId === data?.policyTypeId)?.description,
                brokerId: brokerId,
                lastPremium: lastPremium,
                commissionDue: commissionDue,
                commissionReceived: commissionReceived,
                estimatedCommission: estimatedCommission,
              };
            } else {
              return {
                ...policy,
              };
            }
          })
        )
      );
      reset({}, { keepDirty: false, keepValues: true });
      setShowSidebar(false);
    }
    if (response?.error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Error: ${response?.error}`,
        life: toastErrorTimeLimit,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div className="flex fixed justify-center items-center z-50 lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-1.5">
          <label className="required uppercase">{Labels.COMPANY}</label>
          <SMDropDown
            name="creditorId"
            control={control}
            options={companies}
            optionLabel="creditorName"
            optionValue="creditorId"
            rules={{
              validate: (value: number) => {
                return Boolean(companies?.find?.((company: ICreditors) => company?.creditorId === value)?.creditorId);
              },
            }}
          />
          {errors?.creditorId && <Errors error={Messages.REQUIRED_MESSAGE} alignment="left" />}
        </div>
        <div className="flex flex-col gap-1.5 mt-5">
          <label className="required">{Labels.POLICY_NUMBER}</label>
          <div>
            <SMInputText name="policyNumber" control={control} maxlength={20} rules={{ required: Messages.REQUIRED_MESSAGE }} />
          </div>
          {errors?.policyNumber && <Errors error={errors?.policyNumber?.message} alignment="left" />}
        </div>
        <div className="flex">
          <div className="w-1/2 flex flex-col gap-1.5 mt-5">
            <label className="required">{Labels.POLICY_START_DATE}</label>
            <div>
              <SMDatePicker
                inputDate={FormatDate(selectedPolicy?.policyStartDate ?? "")}
                name="policyStartDate"
                control={control}
                width="w-full"
                rules={{ required: Messages.REQUIRED_MESSAGE }}
              />
              {errors?.policyStartDate && <Errors error={errors?.policyStartDate?.message} alignment="left" />}
            </div>
          </div>
          <div className="w-1/2 flex flex-col gap-1.5 mt-14 pl-5 uppercase">
            <SMCheckbox name="recordCommission" control={control} label={Labels.RECORD_COMMISSION} />
          </div>
        </div>
        <div className="flex">
          <div className="w-1/2 flex flex-col gap-1.5 mt-5">
            <label className="required">{Labels.RENEWAL_DATE}</label>
            <div>
              <SMDatePicker
                inputDate={FormatDate(selectedPolicy?.renewalDate ?? "")}
                name="renewalDate"
                control={control}
                width="w-full"
                rules={{ required: Messages.REQUIRED_MESSAGE }}
              />
              {errors?.renewalDate && <Errors error={errors?.renewalDate?.message} alignment="left" />}
            </div>
          </div>
          {recordCommission && (
            <div className="w-1/2 flex flex-col gap-1.5 mt-5 pl-5">
              <label className="uppercase">{Labels.ESTIMATE}</label>
              <div>
                <SMInputText
                  name="estimatedCommission"
                  control={control}
                  maxlength={17}
                  keyfilter={"num"}
                  autoComplete="off"
                  rules={{
                    validate: (value: string) => {
                      return validateDecimal(value, 2);
                    },
                  }}
                />
                {errors.estimatedCommission && <Errors error={Messages.RESERVE_FUND_VALIDATION} alignment="left" />}
              </div>
            </div>
          )}
        </div>
        <div className="flex">
          <div className="w-1/2 flex flex-col gap-1.5 mt-5">
            <label>{Labels.LAST_PREMIUM}</label>
            <div>
              <SMInputText
                name="lastPremium"
                control={control}
                maxlength={17}
                keyfilter={"num"}
                autoComplete="off"
                rules={{
                  validate: (value: string) => {
                    return validateDecimal(value, 2);
                  },
                }}
              />
              {errors.lastPremium && <Errors error={Messages.RESERVE_FUND_VALIDATION} alignment="left" />}
            </div>
          </div>
          {recordCommission && (
            <div className="w-1/2 flex flex-col gap-1.5 mt-5 pl-5">
              <label className="required uppercase">{Labels.DUE}</label>
              <div>
                <SMInputText
                  name="commissionDue"
                  control={control}
                  maxlength={17}
                  keyfilter={"num"}
                  autoComplete="off"
                  rules={{
                    required: Messages.REQUIRED_MESSAGE,
                    validate: (value: string) => {
                      return value !== NIL && Boolean(value) && validateDecimal(value, 2);
                    },
                  }}
                />
                {errors?.commissionDue && <Errors error={errors?.commissionDue?.message || Messages.RESERVE_FUND_VALIDATION} alignment="left" />}
              </div>
            </div>
          )}
        </div>
        <div className="flex">
          <div className="w-1/2 flex flex-col gap-1.5 mt-5">
            <label className="required">{Labels.DATE_PAID}</label>
            <div>
              <SMDatePicker
                inputDate={FormatDate(selectedPolicy?.datePaid ?? "")}
                name="datePaid"
                control={control}
                width="w-full"
                rules={{ required: Messages.REQUIRED_MESSAGE }}
              />
              {errors?.datePaid && <Errors error={errors?.datePaid?.message} alignment="left" />}
            </div>
          </div>
          {recordCommission && (
            <div className="w-1/2 flex flex-col gap-1.5 mt-5 pl-5">
              <label className="uppercase">{Labels.RECEIVED}</label>
              <div>
                <SMInputText
                  name="commissionReceived"
                  control={control}
                  maxlength={17}
                  keyfilter={"num"}
                  autoComplete="off"
                  rules={{
                    validate: (value: string) => {
                      return validateDecimal(value, 2);
                    },
                  }}
                />
                {errors.commissionReceived && <Errors error={Messages.RESERVE_FUND_VALIDATION} alignment="left" />}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col gap-1.5 mt-5">
          <label>{Labels.BROKER}</label>
          <SMDropDown
            name="brokerId"
            control={control}
            options={brokers}
            optionLabel="creditorName"
            optionValue="creditorId"
            showClear
            onChange={(value: number) => {
              setValue("brokerId", value ? value : 0);
            }}
          />
        </div>
        <div className="flex flex-col gap-1.5 mt-5">
          <label className="required">{Labels.TYPE}</label>
          <SMDropDown
            name="policyTypeId"
            control={control}
            options={policyTypes}
            optionLabel="description"
            optionValue="policyTypeId"
            rules={{
              validate: (value: number) => {
                return Boolean(policyTypes?.find?.((policyType: IPolicyType) => policyType?.policyTypeId === value)?.policyTypeId);
              },
            }}
          />
          {errors?.policyTypeId && <Errors error={Messages.REQUIRED_MESSAGE} alignment="left" />}
        </div>
        <div className="flex flex-col gap-1.5 mt-5">
          <label className="uppercase">{Labels.NOTES}</label>
          <div className="h-auto">
            <SMInputTextArea name="notes" control={control} autoResize={true} />
          </div>
        </div>
        <div className="flex flex-col gap-1.5 mt-5">
          <div className="items-center gap-1.5 uppercase">
            <SMCheckbox name="active" control={control} label={Labels.ACTIVE} />
          </div>
        </div>
        <div className="flex justify-end gap-2 flex-row order-last mt-5 mr-5">
          <button
            type="button"
            className="bg-white text-black py-1.5 px-4 text-md rounded-md items-center border-2"
            onClick={() => {
              if (isDirty) {
                setShowCancelModal(true);
              } else {
                setShowSidebar(false);
              }
            }}
          >
            {cancelButtonText}
          </button>
          <button
            type="submit"
            disabled={!isDirty}
            className={`bg-blue-500 text-white py-1.5 px-6 text-md rounded-md items-center ${!isDirty ? "opacity-60" : "opacity-100"}`}
          >
            {saveButtonText}
          </button>
        </div>
      </form>
      <Modal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        title="Warning"
        onAction={() => {
          setShowCancelModal(false);
          setShowSidebar(false);
        }}
      >
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
    </>
  );
};

export default PolicyForm;
