"use client";

import { Errors } from "src/components/shared/error/error";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { errorMessage } from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import { useHttpGet } from "src/services/api";
import LinkedLots from "./linkedLotsCard/linkedLotsCard";

export default function LinkedLotsTab({ contactId, lotId }: { contactId: string; lotId: string }) {
  const { data: linkedLotsDetails, error: linkedLotsDetailsError } = useHttpGet(EndPoints.owners + contactId + "/linked-lots");

  if (linkedLotsDetails === null) {
    return (
      <div className="flex w-full min-h-full items-center justify-center">
        <div className="w-20 h-20">
          <SpinnerHorizontal />
        </div>
      </div>
    );
  }

  if (linkedLotsDetailsError) {
    return <Errors error={errorMessage} alignment="left" />;
  }

  let linkedLostList = linkedLotsDetails?.filter?.((linkedLot: any) => linkedLot?.lotId !== Number(lotId));

  return <LinkedLots linkedLotsData={linkedLostList} />;
}
