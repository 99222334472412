export const planDetail = "planDetail";
export const dateFormat = "dd/mm/yy";
export const datePickerPlaceHolder = "DD/MM/YYYY";
export const errorMessage = "Something went wrong!";
export const editButtonText = "Edit";
export const saveButtonText = "Save";
export const cancelButtonText = "Cancel";
export const closeButtonText = "Close";
export const selectButtonText = "Select";
export const resetButtonText = "Reset";
export const searchButtonText = "Search";
export const viewMoreButtonText = "View More";
export const modalDiscardMessageText = "Are you sure you want to discard changes ?";
export const modalTotalexceedMessage = "Your entitlement total does not match your entitlement set, please adjust before saving.";
export const successText = "Data saved successfully.";
export const errorText = "We have encountered an error. Please try again.";
export const lotAgentTabRadioButtonsText = ["Print", "Email"];
export const minDate = new Date(1900, 0, 1);
export const minLimit = 0;
export const maxLimit = 9999;
export const lot_MaxLength = 4;
export const alignment_Right = "right";
export const defaultDateTimeString = "1900-01-01T00:00:00";
export const defaultDateString = "01/01/1900";
export const noDataFound = "No data found!";
export const lineBreak = "%0D%0A";
export const comingSoon = "This feature is coming soon.";
export const toastPosition = "top-right";
export const strataVoteUrl = "https://app.stratavote.com";
export const strataConnectUrl = "https://my.smata.com";
export const contactHeader = "Contact Selector - select existing contact";
export const NIL = "NIL";
export const NA = "n/a";
export const locale = "en-AU";
export const currency = "AUD";
export const NONE = "None";
export const toastSuccessTimeLimit = 2000;
export const toastErrorTimeLimit = 3000;
export const noResultFound = "No result found";
export const minNegativeInteger = -9007199254740991;
export const entitlementObjSetId = "SetId";
export const entitlementObjkey = "entitlement";
export const lotNumberObjKey = "lotNo";
export const entitlementObjId = "Id";

export const financialKeys = {
  cashAtBank: "Cash at Bank",
  receivableLevies: "Receivable--Levies",
  receivableLeviesSpecial: "Receivable--Levies (Special)",
  creditorsOther: "Creditors--Other",
  fundsReserve: "Funds Reserve",
  investment: "Investment",
} as const;

export const contactType = {
  homePhone: "Home phone",
  workPhone: "Work phone",
  mobile: "Mobile",
  fax: "Fax",
  emailAddress: "Email address",
  phone1: "Phone 1",
  phone2: "Phone 2",
  creditor: "C",
} as const;

export const creditorType = {
  company: "I",
  broker: "B",
};

export const budgetType = {
  expense: "E",
  revenue: "R",
};

export const advanceSearch ={
  corporation: "Corporation",
  owner: "Owner",
  agent: "Real Estate Agent",
  tenant: "Tenant",
  committee: "Committee Member",
  creditor: "Creditor",
  buildingManager: "Building Manager",
};

export const titleList = [
  { id: 0, name: "Mr" },
  { id: 1, name: "Mrs" },
  { id: 2, name: "Ms" },
  { id: 3, name: "Miss" },
  { id: 4, name: "Dr" },
  { id: 5, name: "Mr & Mrs" },
  { id: 6, name: "Prof." },
  { id: 7, name: "Rev." },
];

export const typeList1 = [
  { id: 0, name: "Home phone" },
  { id: 1, name: "Work phone" },
  { id: 2, name: "Mobile" },
  { id: 3, name: "Fax" },
  { id: 4, name: "Email address" },
];

export const typeList2 = [
  { id: 0, name: "Phone 1" },
  { id: 1, name: "Phone 2" },
  { id: 2, name: "Mobile" },
  { id: 3, name: "Fax" },
  { id: 4, name: "Email address" },
];

export const mortgageeTypeList = [
  { id: 0, name: "Mortgagee" },
  { id: 1, name: "Caveator" },
  { id: 2, name: "Covenant Chargee" },
];

export const gstMethod = [
  { id: "C", name: "Cash" },
  { id: "A", name: "Accrual" },
];

export const gstStatus = [
  { id: "U", name: "Unregistered" },
  { id: "R", name: "Registered" },
];

export const gstFrequency = [
  { id: "M", name: "Monthly" },
  { id: "B", name: "Bi-monthly" },
  { id: "Q", name: "Quarterly" },
  { id: "S", name: "Six-monthly" },
  { id: "A", name: "Annually" },
];

export const interimReportingPeriod = [
  { id: "M", name: "Monthly" },
  { id: "Q", name: "Quarterly" },
  { id: "S", name: "Six-monthly" },
];

export const levyFrequency = [
  { id: "M", name: "Monthly" },
  { id: "Q", name: "Quarterly" },
  { id: "F", name: "Four-monthly" },
  { id: "S", name: "Six-monthly" },
  { id: "Y", name: "Annual" },
];

export const interestFreePeriod = [
  { id: "D", name: "Day" },
  { id: "M", name: "Month" },
  { id: "Q", name: "Quarter" },
  { id: "Y", name: "Year" },
];

export const type = [
  { id: "A", name: "Agent" },
  { id: "C", name: "Creditor" },
  { id: "O", name: "Owner" },
  { id: "R", name: "Real Estate Agent" },
  { id: "T", name: "Tenant" },
  { id: "B", name: "Building Manager" },
];

export const tradesmanStatus = [
  { id: "C", name: "Contracted" },
  { id: "P", name: "Preferred" },
  { id: "E", name: "Excluded" },
];

export const corporationLayout = [
  {
    id: 0,
    name: "left",
    items: [
      {
        id: "plan",
        index: 0,
        name: "PlanDetail",
        expand: true,
      },
      {
        id: "lot",
        index: 1,
        name: "Lots",
        expand: true,
      },
      {
        id: "levy",
        index: 2,
        name: "Levy",
        expand: true,
      },
      {
        id: "notes",
        index: 3,
        name: "Notes",
        expand: true,
      },
      {
        id: "contact",
        index: 4,
        name: "Important Contacts",
        expand: true,
      },
    ],
  },
  {
    id: 1,
    name: "right",
    items: [
      {
        id: "finance",
        index: 0,
        name: "Financial",
        expand: true,
      },
      {
        id: "insurance",
        index: 1,
        name: "Insurance",
        expand: true,
      },
      {
        id: "structure",
        index: 2,
        name: "Structure",
        expand: true,
      },
    ],
  },
];

export const httpCode = {
  UNAUTHORIZED: 401,
  NO_CONTENT: 204,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500,
  SUCCESS: 200,
} as const;

export const advanceSearchCatrgories = [
  { id: "Corporation", name: "Corporation" },
  { id: "Owner", name: "Owner" },
  { id: "Committee Member", name: "Committee Member" },
  { id: "Building Manager", name: "Building Manager" },
  { id: "Real Estate Agent", name: "Real Estate Agent" },
  { id: "Tenant", name: "Tenant" },
  { id: "Creditor", name: "Creditor" },
];
