import { useHttpGet } from "src/services/api";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { useParams } from "react-router-dom";
import InsuranceSection from "./insuranceSection";
import { EndPoints } from "src/constants/endpoint";
import { Messages } from "src/constants/messages";
import { Errors } from "src/components/shared/error/error";
import { httpCode } from "src/constants/constant";

const InsuranceHeader = () => {
  const { corpId } = useParams();
  const { data, error, statusCode } = useHttpGet(`${EndPoints.corporation}${corpId}/${EndPoints.insurance}/policy-header`);
  const { data: creditorData, error: creditorError } = useHttpGet(EndPoints.creditors);

  if (error || creditorError) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (statusCode === httpCode.NO_CONTENT) {
    return <InsuranceSection data={null} creditorData={[]} />;
  }

  if (!data) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-10 h-10">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }

  return <InsuranceSection data={data} creditorData={creditorData} />;
};

export default InsuranceHeader;
