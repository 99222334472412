import styles from "./corpDetails.module.scss";
import { Accordion } from "src/components/ui/accordion/accordion";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";
import { httpUpdate, useHttpGet } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { ICorpsNotes } from "src/interfaces/corpNotes";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ContactSummary } from "src/pages/contact/contactSummary";
import { CorpNotes } from "src/pages/corporation/corpNotes/corpNotes";
import { StructureSummary } from "src/pages/structure/structureSummary/structureSummary";
import { LotSummary } from "src/pages/lot/lotSummary";
import { BankBalanceSummary } from "src/pages/financial/bankBalance/bankBalanceSummary";
import { LevySummary } from "src/pages/levy/levySummary";
import { InsuranceSummary } from "src/pages/insurance/insuranceSummary/insuranceSummary";
import { CorpSummary } from "src/pages/corporation/corpSummary";
import { setBreadCrumbs, setIcons } from "src/store/slice/breadCrumbSlice";
import { useNavigate, useParams } from "react-router-dom";
import ToastContainer from "src/components/ui/toast/toast";
import { Toast } from "primereact/toast";
import {
  cancelButtonText,
  closeButtonText,
  corporationLayout,
  editButtonText,
  modalDiscardMessageText,
  saveButtonText,
  viewMoreButtonText,
} from "src/constants/constant";
import { Messages } from "src/constants/messages";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Labels } from "src/constants/labels";
import { FormatDate, NullOREmptyString } from "src/utils/utility";
import { setUserInfo } from "src/store/slice/userSlice";
import usePlanNumber from "src/hooks/usePlanNumber";
import { Modal } from "src/components/ui/modal/modal";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import { IPolicy } from "src/interfaces/policyList";
import { Warning } from "src/assets/icon";
import { Tooltip } from "primereact/tooltip";

export default function CorpDetails() {
  const { corpId } = useParams();
  const { data: planDetails, error: planDetailsError, statusCode: planDetailsStatus } = useHttpGet(EndPoints.corporation + corpId);
  const { data: levyDetails, error: levyDetailsError, statusCode: levyDetailsStatus } = useHttpGet(EndPoints.corporation + `${corpId}`);
  const { data: lotDetails, error: lotDetailsError, statusCode: lotDetailsStatus } = useHttpGet(EndPoints.corporation + `${corpId}/lots/summary`);
  const {
    data: structureDetails,
    error: structureDetailsError,
    statusCode: structureDetailsStatus,
  } = useHttpGet(EndPoints.corporation + `${corpId}/structure/details`);
  const {
    data: importantContacts,
    error: importantContactsError,
    statusCode: importantContactsStatus,
  } = useHttpGet(EndPoints.contact + `plan/${corpId}/important-contacts`);
  const {
    data: policyList,
    error: policyListError,
    statusCode: policyListStatus,
  } = useHttpGet(`${EndPoints.corporation}${corpId}/${EndPoints.insurance}/policy-list`);
  const { user, userInfo } = useSelector((state: RootState) => state?.userSlice);
  const { data, error } = useHttpGet(EndPoints.corporation + corpId + `/notes`) as { data: ICorpsNotes; error: any };
  const plaNumber = usePlanNumber(corpId ?? "");
  const navigate = useNavigate();
  const toastRef = useRef<Toast>(null);
  const layout = userInfo?.layoutPreference ? Object.values(JSON.parse(userInfo?.layoutPreference)) : userInfo?.layoutPreference;

  const initialColumns =
    NullOREmptyString(layout) === "-"
      ? corporationLayout
      : layout?.map((column: any) => ({
          ...column,
          items: column.items.slice().sort((a: any, b: any) => a.index - b.index),
        }));

  const initializeLayout = initialColumns.map((column: any) => ({
    ...column,
    items: column.items.map((item: any) => ({
      ...item,
      expand: item.hasOwnProperty("expand") ? item.expand : true,
    })),
  }));
  const [columns, setColumns] = useState(initializeLayout);
  const [isNotesEditable, setNotesEditable] = useState(false);
  const [totalLots, setTotalLots] = useState(0);
  const [associationType, setAssociationType] = useState("");
  const [corpName, setCorpName] = useState("");
  const [levyDueDate, setLevyDueDate] = useState("");
  const [insuranceDetails, setInsuranceDetails] = useState<any>({
    renewalDate: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editableTiles, setEditableTiles] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { title: "Corporations", url: "/corporations" },
        { title: `${plaNumber}`, url: `/corporations/${corpId}` },
      ])
    );
  }, [plaNumber]);

  useEffect(() => {
    dispatch(
      setIcons([
        {
          button: (
            <div className={`flex justify-end  ${styles.editBtnMobile}`}>
              <div className="flex gap-2 flex-row order-last pr-2">
                <Tooltip target="#editBtn" content={editableTiles ? "" : "Change layout"} position="left"></Tooltip>
                <button
                  id="editBtn"
                  type="button"
                  className="secondaryBtn"
                  onClick={() => (editableTiles ? (isDirty ? setShowModal(true) : setEditableTiles(false)) : setEditableTiles(true))}
                >
                  {editableTiles ? cancelButtonText : editButtonText}
                </button>
                {editableTiles && (
                  <button type="submit" className={`btn-primary !ml-0`} onClick={onSave}>
                    {saveButtonText}
                  </button>
                )}
              </div>
            </div>
          ),
        },
        { icon: "bell", route: "/" },
      ])
    );
  }, [plaNumber, editableTiles, isDirty]);

  useEffect(() => {
    if (planDetails?.bodyCorporateName) {
      setCorpName(planDetails.bodyCorporateName);
    }
  }, [planDetails?.bodyCorporateName]);

  useEffect(() => {
    if (levyDetails?.levyDueDate) {
      setLevyDueDate(FormatDate(levyDetails?.levyDueDate));
    }
  }, [levyDetails?.levyDueDate]);

  useEffect(() => {
    if (lotDetails?.totalLots) {
      setTotalLots(lotDetails.totalLots);
    }
  }, [lotDetails?.totalLots]);

  useEffect(() => {
    if (structureDetails?.sName && structureDetails?.sState) {
      let associationType = structureDetails?.sName + "-" + structureDetails?.sState;
      setAssociationType(associationType);
    }
  }, [structureDetails?.sName, structureDetails?.sState]);

  useEffect(() => {
    let policyDetails: IPolicy = getPolicy(policyList);
    if (policyDetails) {
      setInsuranceDetails((prevState: any) => {
        return { ...prevState, renewalDate: FormatDate(policyDetails?.renewalDate), warningIcon: compareDates(policyDetails?.renewalDate) };
      });
    }
  }, [policyList]);

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  const onDragEnd = (result: any, columns: any, setColumns: any) => {
    const { source, destination } = result;
    if (!destination || destination?.index === source?.index || !editableTiles) return;
    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      sourceItems.forEach((item, index) => {
        item.index = index.toString();
      });
      destItems.forEach((item, index) => {
        item.index = index.toString();
      });
      const updatedColumns = {
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      };
      const updatedColumnsArray = Object.values(updatedColumns).map((value) => value);
      setColumns(updatedColumnsArray);
      setIsDirty(true);
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      copiedItems.forEach((item, index) => {
        item.index = index.toString();
      });
      const updatedColumns = {
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      };
      const updatedColumnsArray = Object.values(updatedColumns).map((value) => value);
      setColumns(updatedColumnsArray);
      setIsDirty(true);
    }
  };

  const onSave = () => {
    onLayoutChange(columns);
    setIsDirty(false);
  };

  const onDiscard = () => {
    setColumns(initialColumns);
    setEditableTiles(false);
    setIsDirty(false);
    setShowModal(false);
  };

  async function onLayoutChange(data: any) {
    if (!editableTiles) return;
    setIsLoading(true);
    const output = await httpUpdate(EndPoints.userSetting + user.userId + "/layout-preference", { layoutPreference: JSON.stringify(data) });
    if (output?.status === 200) {
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: 2000,
      });
      dispatch(
        setUserInfo({
          ...userInfo,
          layoutPreference: JSON.stringify(data),
        })
      );
      setEditableTiles(false);
    }
    if (output?.error) {
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `error:${output?.error}`,
        life: 3000,
      });
    }
  }

  function accordionDetails(name: string, id: any, expand: boolean) {
    switch (name) {
      case "PlanDetail":
        return (
          <Accordion
            id={id}
            title={`${Labels.PLAN_DETAILS} - ${corpName}`}
            expand={expand}
            btnId="Plan_Details"
            btnName={viewMoreButtonText}
            onClick={() => navigate(`plan`)}
            route={`corporations/${corpId}/plan`}
            onToggle={handleAccordionToggle}
          >
            <CorpSummary data={planDetails} error={planDetailsError} status={planDetailsStatus} />
          </Accordion>
        );
      case "Levy":
        return (
          <Accordion
            id={id}
            title="Levy"
            label={`${levyDueDate ? `${Labels.NEXT_LEVY_DUE_DATE} ${levyDueDate}` : ""}`}
            expand={expand}
            btnName={viewMoreButtonText}
            onClick={() => navigate(`financial`)}
            route={`corporations/${corpId}/financial`}
            onToggle={handleAccordionToggle}
          >
            <LevySummary data={levyDetails} error={levyDetailsError} status={levyDetailsStatus} />
          </Accordion>
        );
      case "Notes":
        return (
          <Accordion
            id={id}
            title={Labels.NOTES}
            expand={expand}
            btnName={isNotesEditable ? closeButtonText : Labels.ADD_EDIT_NOTE}
            onClick={isNotesEditable ? () => setNotesEditable(false) : () => setNotesEditable(true)}
            onToggle={handleAccordionToggle}
          >
            <CorpNotes isEditable={isNotesEditable} corpId={corpId} data={data} error={error} />
          </Accordion>
        );
      case "Lots":
        return (
          <Accordion
            id={id}
            title={Labels.LOTS}
            label={`${Labels.TOTAL_LOTS} ${totalLots}`}
            expand={expand}
            btnName={viewMoreButtonText}
            onClick={() => navigate(`lots`)}
            route={`corporations/${corpId}/lots`}
            onToggle={handleAccordionToggle}
          >
            <LotSummary data={lotDetails} error={lotDetailsError} status={lotDetailsStatus} />
          </Accordion>
        );
      case "Important Contacts":
        return (
          <Accordion
            id={id}
            title={Labels.IMPORTANT_CONTACTS}
            expand={expand}
            btnName={viewMoreButtonText}
            onClick={() => navigate(`contact`)}
            route={`corporations/${corpId}/contact`}
            onToggle={handleAccordionToggle}
          >
            <ContactSummary data={importantContacts} error={importantContactsError} status={importantContactsStatus} />
          </Accordion>
        );
      case "Financial":
        return (
          <Accordion
            id={id}
            title={Labels.FINANCIAL}
            expand={expand}
            btnName={viewMoreButtonText}
            onClick={() => navigate(`financial`)}
            route={`corporations/${corpId}/financial`}
            onToggle={handleAccordionToggle}
          >
            <BankBalanceSummary corpId={corpId} />
          </Accordion>
        );
      case "Insurance":
        return (
          <Accordion
            id={id}
            title={Labels.INSURANCE}
            label={insuranceDetails?.renewalDate ? `Expiring ${insuranceDetails?.renewalDate}` : ""}
            icon={insuranceDetails?.warningIcon}
            expand={expand}
            btnName={viewMoreButtonText}
            onClick={() => navigate(`insurance`)}
            route={`corporations/${corpId}/insurance`}
            onToggle={handleAccordionToggle}
          >
            <InsuranceSummary data={policyList} error={policyListError} statusCode={policyListStatus as number} compareDates={compareDates} />
          </Accordion>
        );
      case "Structure":
        return (
          <Accordion
            id={id}
            title={Labels.STRUCTURE}
            label={associationType}
            expand={expand}
            btnName={viewMoreButtonText}
            onClick={() => navigate(`structure`)}
            route={`corporations/${corpId}/structure`}
            onToggle={handleAccordionToggle}
          >
            <StructureSummary data={structureDetails} error={structureDetailsError} status={structureDetailsStatus} />
          </Accordion>
        );
      default:
        return "";
    }
  }

  const getPolicy = (policyList: Array<IPolicy>) => {
    if (policyList?.length === 1) {
      return policyList?.[0];
    } else {
      let buildingPolicy = policyList?.find?.((policy) => policy?.description === "Building");
      if (policyList?.every?.((policy) => policy?.renewalDate === policyList?.[0]?.renewalDate) && buildingPolicy) {
        return buildingPolicy;
      } else {
        let closestToCurrentDate = policyList?.reduce?.((closestDateObj: any, policy: any) => {
          let renewalDate: any = new Date(policy?.dRenewal);
          let closestDate: any = new Date(closestDateObj?.dRenewal);
          let currentDate: any = new Date();

          let diffInCurrentAndRenewalDate = Math?.abs?.(currentDate - renewalDate);
          let diffInCurrentAndClosestDate = Math?.abs?.(currentDate - closestDate);

          return diffInCurrentAndRenewalDate < diffInCurrentAndClosestDate ? policy : closestDateObj;
        }, policyList?.[0]);

        return closestToCurrentDate;
      }
    }
  };

  const compareDates = (date: string): JSX.Element | null => {
    let renewalDate = new Date(date)?.getTime?.();
    let currentDate = new Date()?.getTime?.();
    let oneMonthAgo = new Date();
    oneMonthAgo?.setMonth?.(oneMonthAgo?.getMonth?.() + 1);
    let oneMonthAgoTime = oneMonthAgo?.getTime?.();
    if (renewalDate < currentDate) return <Warning stroke="red" />;
    if (renewalDate > currentDate && renewalDate < oneMonthAgoTime) return <Warning />;
    return null;
  };

  const handleAccordionToggle = (itemId: string, expand: boolean) => {
    const updatedColumns = columns?.map((column: any) => ({
      ...column,
      items: column.items?.map((item: any) => (item.id === itemId ? { ...item, expand } : item)),
    }));

    setColumns(updatedColumns);
    if (editableTiles) setIsDirty(true);
  };
  return (
    <>
      {isLoading && (
        <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-auto overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      {editableTiles ? (
        <DragDropContext onDragEnd={(result) => onDragEnd(result, columns, setColumns)}>
          <div className={`${styles.container}`}>
            {Object.values(columns)?.map((col: any) => (
              <div key={col.id} className={`p-1 basis-full lg:basis-1/2 ${styles.page_container}`}>
                <Droppable droppableId={col.id.toString()} key={col.id}>
                  {(provided) => (
                    <div className="flex-grow" {...provided.droppableProps} ref={provided.innerRef}>
                      {col.items.map((item: any, index: any) => {
                        return (
                          <Draggable key={item?.id} draggableId={item?.id} index={index}>
                            {(provided) => (
                              <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                {accordionDetails(item.name, item.id, item?.expand ?? true)}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </div>
            ))}
          </div>
        </DragDropContext>
      ) : (
        <div className={`${styles.container} ${styles.pageContainerMobileView}`}>
          {Object.values(columns)?.map((col: any) => (
            <div key={col.id} className={`p-1 basis-full lg:basis-1/2 ${styles.page_container}`}>
              <div className="flex-grow">
                {col.items.map((item: any, index: any) => (
                  <div key={item.id}>{accordionDetails(item.name, item.id, item.expand)}</div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
      <ToastContainer toastReference={toastRef} />
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Warning" onAction={() => onDiscard()}>
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
    </>
  );
}
