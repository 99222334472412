import { useCallback, useContext, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UNSAFE_NavigationContext as NavigationContext, useBeforeUnload } from "react-router-dom";
import { Messages } from "src/constants/messages";
import { RootState } from "src/store/rootReducer";
import { setIsFormDirty } from "src/store/slice/dirtyFormSlice";

function useUnsavedChangesPrompt({ message = Messages.UNSAVED_CHANGES, unsavedChanges }: { message?: string; unsavedChanges: boolean }) {
  const { isDirty } = useSelector((state: RootState) => state?.dirtyFormSlice);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsFormDirty(unsavedChanges));
  }, [unsavedChanges]);

  useBeforeUnload(
    useCallback(
      (e: BeforeUnloadEvent) => {
        if (unsavedChanges && isDirty) {
          e?.preventDefault?.();
          return (e.returnValue = message);
        }
      },
      [unsavedChanges, message, isDirty]
    )
  );

  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message);
    return confirm;
  }, [message]);

  useConfirmExit(confirmExit, unsavedChanges);
}

function useConfirmExit(confirmExit: () => boolean, when = true) {
  const { navigator } = useContext(NavigationContext);

  useEffect(() => {
    if (!when) {
      return;
    }

    const push = navigator.push;

    navigator.push = (...args: Parameters<typeof push>) => {
      const result = confirmExit();
      if (result !== false) {
        push(...args);
      }
    };

    return () => {
      navigator.push = push;
    };
  }, [navigator, confirmExit, when]);
}

export default useUnsavedChangesPrompt;
