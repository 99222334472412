import React from "react";
import { useHttpGet } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import StructureDetailSection from "./structureDetailSection";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Errors } from "src/components/shared/error/error";
import { errorMessage } from "src/constants/constant";

export const StructureDetail: React.FC<{ corpId: any }> = ({ corpId }) => {
  const { data, error } = useHttpGet(EndPoints.corporation + `${corpId}/structure/details`);
  const { data: associationTypeData, error: associationTypeError } = useHttpGet(`association-types`);

  if (error || associationTypeError) {
    return <Errors error={errorMessage} alignment="left" />;
  }

  if (!data || !associationTypeData) {
    return (
      <>
        <div className="flex w-full items-center justify-center">
          <div className="w-10 h-10">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }

  return data && associationTypeData && <StructureDetailSection corpId={corpId} data={data} error={error} associationTypeData={associationTypeData} />;
};
