import { DebtRecoveryTable } from "./debtRecoveryTable/debtRecoveryTable";
import DebtRecoveryNotes from "./debtRecoveryNotes/debtRecoveryNotes";
import { DebtRecoveryChecks } from "./debtRecoveryChecks/debtRecoveryChecks";
import { Line, Print, ViewColumn } from "src/assets/icon";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import ToastContainer from "src/components/ui/toast/toast";
import { comingSoon } from "src/constants/constant";

const DebtRecoverySection: React.FC<{ debtRecoveryDetails: any; corpId: string }> = ({ debtRecoveryDetails, corpId }) => {
  let debtRecoveryStatus = debtRecoveryDetails?.debtRecoveries?.find?.((a: any) => !a?.clearedDate);
  const toastRef = useRef<Toast>(null);
  const debtRecoveryTableButtons = [
    {
      buttonName: "Clear Debt Recovery",
      type: "normal",
      onClick: () =>
        toastRef?.current?.show({
          severity: "info",
          summary: "Info",
          detail: comingSoon,
          life: 2000,
        }),
    },
    {
      buttonName: "Create action",
      type: "normal",
      onClick: () =>
        toastRef?.current?.show({
          severity: "info",
          summary: "Info",
          detail: comingSoon,
          life: 2000,
        }),
    },
    // { buttonName: <Print height={24} width={24} />, type: "word" },
    // { buttonName: <Line height={32} />, type: "line" },
    {
      buttonName: <ViewColumn />,
      onClick: () =>
        toastRef?.current?.show({
          severity: "info",
          summary: "Info",
          detail: comingSoon,
          life: 2000,
        }),
    },
  ];
  //const notesButton = [{ buttonName: "Edit", type: "normal" }];
  return (
    <div>
      <div className="w-full">
        <CollapsibleCard
          id="debtRecoveryTable"
          title={
            <div className="flex">
              <p className="mr-5">Status:</p>{" "}
              <p
                style={{
                  color:
                    debtRecoveryStatus?.debtRecoveryStage === Labels.STAGE_3 ||
                    debtRecoveryStatus?.debtRecoveryStage === Labels.STAGE_2 ||
                    debtRecoveryStatus?.debtRecoveryStage === Labels.STAGE_1
                      ? "red"
                      : "",
                }}
              >
                {!debtRecoveryStatus
                  ? Messages.NOT_SUBJECT_TO_DEBT_RECOVERY
                  : `${Messages.CURRENTLY_IN_DEBT_RECOVERY} : ${debtRecoveryStatus?.debtRecoveryStage}`}
              </p>
            </div>
          }
          expand={true}
          buttons={debtRecoveryTableButtons}
        >
          <DebtRecoveryChecks debtRecoveryDetails={debtRecoveryDetails} />
          {debtRecoveryDetails?.debtRecoveries.length > 0 ? (
            <div className="w-full pb-10 pt-5">
              <DebtRecoveryTable debtRecoveryDetails={debtRecoveryDetails?.debtRecoveries} debtRecoveryStatus={debtRecoveryStatus?.debtRecoveryStage} />
            </div>
          ) : (
            <div className={`w-full rounded-lg text-center shadow-lg flex-row p-10 mt-5`}>
              <p>{Labels.NO_DEBT_AVAILABLE}</p>
            </div>
          )}
        </CollapsibleCard>
      </div>
      <div className="w-full">
        <CollapsibleCard id="notes" title={Labels.NOTES} expand={true}>
          <div className="w-full flex mt-5">
            <DebtRecoveryNotes corpId={corpId} debtRecoveryDetails={debtRecoveryDetails} />
          </div>
        </CollapsibleCard>
      </div>
      <ToastContainer toastReference={toastRef} />
    </div>
  );
};

export default DebtRecoverySection;
