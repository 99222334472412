import styles from "./bankBalanceSummary.module.scss";
import { Labels } from "src/constants/labels";
import { financialKeys } from "src/constants/constant";
import { toLocaleValue } from "src/utils/utility";

const BankBalanceDetail = ({ data }: { data: any }) => {
  var types = Object.keys(
    data.reduce(function (acc: any, item: any) {
      if (item.fundType && item.fundType !== "") {
        acc[item.fundType] = true;
      }
      return acc;
    }, {})
  ).sort();

  const cashAtBankAdmin = Object.assign({}, ...data.filter((x: any) => x.name?.includes(financialKeys.cashAtBank) && x.fundType === types[0]));
  const cashAtBankCapWorks = Object.assign({}, ...data.filter((x: any) => x.name?.includes(financialKeys.cashAtBank) && x.fundType === types[1]));
  const totalCashAtBank = (cashAtBankAdmin?.amount ?? 0) + (cashAtBankCapWorks?.amount ?? 0);

  const receivableLeviesAdmin = Object.assign(
    {},
    ...data.filter((x: any) => x.name?.includes(financialKeys.receivableLevies) && !x.name?.includes("Special") && x.fundType === types[0])
  );
  const receivableLeviesCapWorks = Object.assign(
    {},
    ...data.filter((x: any) => x.name?.includes(financialKeys.receivableLevies) && !x.name?.includes("Special") && x.fundType === types[1])
  );
  const totalReceivableLevies = (receivableLeviesAdmin?.amount ?? 0) + (receivableLeviesCapWorks?.amount ?? 0);

  const receivableLeviesSpecialAdmin = Object.assign(
    {},
    ...data.filter((x: any) => x.name?.includes(financialKeys.receivableLeviesSpecial) && x.fundType === types[0])
  );
  const receivableLeviesSpecialCapWorks = Object.assign(
    {},
    ...data.filter((x: any) => x.name?.includes(financialKeys.receivableLeviesSpecial) && x.fundType === types[1])
  );
  const totalReceivableSpecialLevies = (receivableLeviesSpecialAdmin?.amount ?? 0) + (receivableLeviesSpecialCapWorks?.amount ?? 0);

  const creditorsOtherAdmin = Object.assign({}, ...data.filter((x: any) => x.name?.includes(financialKeys.creditorsOther) && x.fundType === types[0]));
  const creditorsOtherCapWorks = Object.assign({}, ...data.filter((x: any) => x.name?.includes(financialKeys.creditorsOther) && x.fundType === types[1]));
  const totalCreditorsOther = (creditorsOtherAdmin?.amount ?? 0) + (creditorsOtherCapWorks?.amount ?? 0);

  const fundsReserve = Object.assign({}, ...data.filter((x: any) => x.name === financialKeys.fundsReserve));
  const netCashAdmin = (cashAtBankAdmin?.amount ?? 0) + (creditorsOtherAdmin?.amount ?? 0);
  const netCashCapWorks = (cashAtBankCapWorks?.amount ?? 0) + (creditorsOtherCapWorks?.amount ?? 0);
  const netCashTotal = totalCashAtBank + totalCreditorsOther;
  const totalAvailableCash = (netCashTotal ?? 0) - (fundsReserve?.amount ?? 0);
  let totalInvestmentAdminAmount = 0;
  let totalInvestmentCapWorksAmount = 0;

  const investmentType1 = data?.filter((item: any) => item.name.includes(financialKeys.investment) && item.fundType === types[0]);
  for (const item of investmentType1) {
    totalInvestmentAdminAmount += item?.amount;
  }

  const investmentType2 = data?.filter((item: any) => item.name.includes(financialKeys.investment) && item.fundType === types[1]);
  for (const item of investmentType2) {
    totalInvestmentCapWorksAmount += item?.amount;
  }
  const totalInvestment = totalInvestmentAdminAmount + totalInvestmentCapWorksAmount;
  return (
    <div data-testid="levyDetailsTab" className="basis-full text-sm mt-4">
      <div className={`${styles.header} !border-b-2`}>
        <label className={`${styles.fieldLabel} font-normal`}></label>
        <span className={`${styles.fieldValue} uppercase`}>{types[0]}</span>
        <span className={`${styles.fieldValue} uppercase`}>{types[1]}</span>
        <span className={styles.fieldValue}>{Labels.TOTAL_}</span>
      </div>
      <div className={styles.header}>
        <label className={`${styles.fieldLabel} font-normal`}>{Labels.CASH_AT_BANK}</label>
        <span className={styles.fieldValue}>{isNaN(cashAtBankAdmin?.amount) ? toLocaleValue(0) : toLocaleValue(cashAtBankAdmin?.amount)}</span>
        <span className={styles.fieldValue}>{isNaN(cashAtBankCapWorks?.amount) ? toLocaleValue(0) : toLocaleValue(cashAtBankCapWorks?.amount)}</span>
        <span className={styles.fieldValue}>{toLocaleValue(totalCashAtBank)}</span>
      </div>
      <div className={`${styles.header} !border-b-2`}>
        <label className={`${styles.fieldLabel} font-normal`}>{Labels.O_S_INVOICES}</label>
        <span className={styles.fieldValue}>{isNaN(creditorsOtherAdmin?.amount) ? toLocaleValue(0) : toLocaleValue(creditorsOtherAdmin?.amount)}</span>
        <span className={styles.fieldValue}>{isNaN(creditorsOtherCapWorks?.amount) ? toLocaleValue(0) : toLocaleValue(creditorsOtherCapWorks?.amount)}</span>
        <span className={styles.fieldValue}>{toLocaleValue(totalCreditorsOther)}</span>
      </div>
      <div className={`${styles.header} !border-b-2`}>
        <label className={`${styles.fieldLabel} font-semibold`}>{Labels.NET_CASH}</label>
        <span className={styles.fieldValue}>{toLocaleValue(netCashAdmin)}</span>
        <span className={styles.fieldValue}>{toLocaleValue(netCashCapWorks)}</span>
        <span className={styles.fieldValue}>{toLocaleValue(netCashTotal)}</span>
      </div>
      <div className={styles.header}>
        <label className={`${styles.fieldLabel} font-normal`}>{Labels.RESERVE_FUNDS}</label>
        <span className={styles.fieldValue}>{toLocaleValue(fundsReserve?.amount)}</span>
      </div>
      <div className={`${styles.header} !border-b-2`}>
        <label className={`${styles.fieldLabel} font-normal`}>{Labels.AVAILABLE_CASH}</label>
        <span className={styles.fieldValue}>{toLocaleValue(netCashAdmin)}</span>
        <span className={styles.fieldValue}>{toLocaleValue(netCashCapWorks)}</span>
        <span className={styles.fieldValue}>{toLocaleValue(totalAvailableCash)}</span>
      </div>
      <div>
        <br />
      </div>
      <div className={styles.header}>
        <label className={`${styles.fieldLabel} font-normal`}>{Labels.RECEIVABLE_STD}</label>
        <span className={styles.fieldValue}>{isNaN(receivableLeviesAdmin?.amount) ? toLocaleValue(0) : toLocaleValue(receivableLeviesAdmin?.amount)}</span>
        <span className={styles.fieldValue}>
          {isNaN(receivableLeviesCapWorks?.amount) ? toLocaleValue(0) : toLocaleValue(receivableLeviesCapWorks?.amount)}
        </span>
        <span className={styles.fieldValue}>{toLocaleValue(totalReceivableLevies)}</span>
      </div>
      <div className={styles.header}>
        <label className={`${styles.fieldLabel} font-normal`}>{Labels.RECEIVABLE_SPECIAL}</label>
        <span className={styles.fieldValue}>
          {isNaN(receivableLeviesSpecialAdmin?.amount) ? toLocaleValue(0) : toLocaleValue(receivableLeviesSpecialAdmin?.amount)}
        </span>
        <span className={styles.fieldValue}>
          {isNaN(receivableLeviesSpecialCapWorks?.amount) ? toLocaleValue(0) : toLocaleValue(receivableLeviesSpecialCapWorks?.amount)}
        </span>
        <span className={styles.fieldValue}>{toLocaleValue(totalReceivableSpecialLevies)}</span>
      </div>
      <div className={styles.header}>
        <label className={`${styles.fieldLabel} font-semibold`}>{Labels.TOTAL_INVESTMENTS}</label>
        <span className={` ${styles.fieldValue} !p-0`}></span>
        <span className={styles.fieldValue}></span>
        <span className={styles.fieldValue}>{toLocaleValue(totalInvestment)}</span>
      </div>
    </div>
  );
};

export default BankBalanceDetail;
