import AdminFund from "./adminFund/adminFund";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";

const AdministrativeFund = ({ fundType }: { fundType: number }) => {
  const { budgetDates } = useSelector((state: RootState) => state?.budgetSlice);
  return (
    <div className="pt-4 pb-4">
      <AdminFund budgetDates={budgetDates} fundType={fundType} />
    </div>
  );
};

export default AdministrativeFund;
