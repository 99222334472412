import { useHttpGet } from "src/services/api";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { EndPoints } from "src/constants/endpoint";
import { Errors } from "src/components/shared/error/error";
import { Messages } from "src/constants/messages";
import UnitEntitlementSetTable from "./unitEntitlementsetTable";
import { useParams } from "react-router-dom";
import { httpCode } from "src/constants/constant";

const UnitEntitlementSet = ({ setHeaderEntitlement }: { setHeaderEntitlement: any }) => {
  const { corpId } = useParams();
  const { data, error, statusCode } = useHttpGet(EndPoints.corporation + `${corpId}/unit-entitlement-sets`);

  if (error) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (statusCode === httpCode.NOT_FOUND || statusCode === httpCode.NO_CONTENT || statusCode === httpCode.INTERNAL_SERVER_ERROR) {
    return <UnitEntitlementSetTable unitEntitlementSetData={[]} setHeaderEntitlement={setHeaderEntitlement} />;
  }

  if (!data) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }

  return <UnitEntitlementSetTable unitEntitlementSetData={data} setHeaderEntitlement={setHeaderEntitlement} />;
};

export default UnitEntitlementSet;
