import { useState } from "react";
import { useForm } from "react-hook-form";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Labels } from "src/constants/labels";
import styles from "./agencyForm.module.scss";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import { cancelButtonText, modalDiscardMessageText, saveButtonText } from "src/constants/constant";
import { Modal } from "src/components/ui/modal/modal";
import { httpPost, httpUpdate } from "src/services/api";
import { Messages } from "src/constants/messages";
import { Errors } from "src/components/shared/error/error";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";

const AgencyForm = ({
  formData,
  rowIndex,
  createMode,
  setShowSidebar,
  setCreateMode,
  toastRef,
  handleChange,
}: {
  formData: any;
  rowIndex: number;
  createMode: boolean;
  setShowSidebar: any;
  setCreateMode: any;
  toastRef: any;
  handleChange: any;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      agencyId: formData?.agencyId ?? "",
      requester: formData?.requester,
      agencyName: formData?.agencyName ?? "",
      appBridgeId: formData?.appBridgeId,
      dbOnCloud: formData?.dbOnCloud ?? false,
      dbVersion: formData?.dbVersion ?? "",
      configToolVersion: formData?.configToolVersion ?? "",
      clientKey: formData?.clientKey ?? "",
      localPort: formData?.localPort ?? "",
      azureEndPoint: formData?.azureEndPoint ?? "",
      isActive: createMode ? true : formData?.isActive,
      apiKey: formData?.apiKey ?? "",
      mriClientId: formData?.mriClientId ?? "",
    },
  });

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  const onSubmit = async (data: any) => {
    let reqBody = {
      appBridgeId: Number(data?.appBridgeId),
      dbOnCloud: data?.dbOnCloud,
      azureEndPoint: data?.azureEndPoint,
      isActive: data?.isActive,
      apiKey: data?.apiKey,
      mriClientId: data?.mriClientId,
    };
    setIsLoading(true);
    const response = createMode
      ? await httpPost("agencies", {
          requester: data?.requester,
          agencyName: data?.agencyName,
          appBridgeId: data?.appBridgeId,
          dbOnCloud: data?.dbOnCloud,
          localPort: data?.localPort,
          azureEndPoint: data?.azureEndPoint,
          isActive: data?.isActive,
          mriClientId: data?.mriClientId,
        })
      : await httpUpdate(`agencies/${formData?.agencyId}`, reqBody);
    if (response?.status === 200) {
      toastRef?.current?.show({ severity: "success", summary: "Success", detail: Messages.SUCCESS_MESSAGE, life: 2000 });
      setShowSidebar(false);
      setCreateMode(false);
      handleChange(rowIndex, createMode ? response?.data : data, createMode);
    }
    if (response?.error) {
      toastRef?.current?.show({ severity: "error", summary: "Error", detail: Messages.ERROR_MESSAGE, life: 3000 });
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div className="flex fixed justify-center items-center z-50 lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="w-full mt-5">
          <div className="flex justify-between ">
            <div className={`${styles.labelInput} w-1/2 mr-5`}>
              <label>{Labels.AGENCY_NAME}</label>
              <SMInputText
                name="agencyName"
                control={control}
                maxlength={50}
                disabled={createMode ? false : true}
                rules={{ required: Messages.REQUIRED_MESSAGE }}
              />
              {createMode && errors?.agencyName && <Errors error={errors?.agencyName?.message} alignment="left" />}
            </div>
            <div className={`${styles.labelInput} w-1/2 mr-5`}>
              <label>{Labels.AGENCYID}</label>
              <SMInputText name="agencyId" control={control} maxlength={50} disabled={true} />
              {createMode && errors?.agencyName && <Errors error={errors?.agencyName?.message} alignment="left" />}
            </div>
          </div>

          <div className="flex justify-between">
            <div className={`${styles.labelInput} w-1/3 mr-5`}>
              <label>{Labels.LOCALPORT}</label>
              <SMInputText
                name="localPort"
                control={control}
                maxlength={10}
                keyfilter="pnum"
                autoComplete="off"
                rules={{ required: Messages.REQUIRED_MESSAGE }}
              />
              {createMode && errors?.localPort && <Errors error={errors?.localPort?.message} alignment="left" />}
            </div>
            <div className={`${styles.labelInput} w-1/3 mr-5`}>
              <label>{Labels.REQUESTER}</label>
              <SMInputText
                name="requester"
                control={control}
                maxlength={128}
                keyfilter="pnum"
                autoComplete="off"
                rules={{ required: Messages.REQUIRED_MESSAGE }}
                disabled={createMode ? false : true}
              />
              {createMode && errors?.requester && <Errors error={errors?.requester?.message} alignment="left" />}
            </div>
            <div className={`${styles.labelInput} w-1/3 mr-5`}>
              <label>{Labels.BRIDGE_ID}</label>
              <SMInputText
                name="appBridgeId"
                control={control}
                maxlength={10}
                keyfilter="pnum"
                autoComplete="off"
                rules={{ required: Messages.REQUIRED_MESSAGE }}
              />
              {errors?.appBridgeId && <Errors error={errors?.appBridgeId?.message} alignment="left" />}
            </div>
          </div>

          <div className={`${styles.labelInput} mr-5`}>
            <label>{Labels.AZURE_ENDPOINT}</label>
            <SMInputText name="azureEndPoint" control={control} maxlength={300} rules={{ required: Messages.REQUIRED_MESSAGE }} />
            {errors?.azureEndPoint && <Errors error={errors?.azureEndPoint?.message} alignment="left" />}
          </div>
          <div className="flex justify-between">
            {!createMode && (
              <div className={`${styles.labelInput} w-1/2 mr-5`}>
                <label>{Labels.CLIENT_KEY}</label>
                <SMInputText name="clientKey" control={control} maxlength={10} disabled />
              </div>
            )}
            {!createMode && (
              <div className={`${styles.labelInput} w-1/2 mr-5`}>
                <label>{Labels.API_KEY}</label>
                <SMInputText name="apiKey" control={control} maxlength={255} />
              </div>
            )}
          </div>
          <div className="flex items-center">
            <div className={`${styles.labelInput} w-1/3 mr-5`}>
              <label>{Labels.MRI_CLIENT_ID}</label>
              <SMInputText name="mriClientId" control={control} maxlength={10} rules={{ required: Messages.REQUIRED_MESSAGE }} />
              {errors?.mriClientId && <Errors error={errors?.mriClientId?.message} alignment="left" />}
            </div>
            
            <div className="flex mt-5">
              <div className={`${styles.labelInput} mr-5`}>
                <SMCheckbox name="isActive" control={control} label={Labels.ACTIVE?.toUpperCase?.()} />
              </div>
              <div className={`${styles.labelInput} mr-5`}>
                <SMCheckbox name="dbOnCloud" control={control} label={Labels.CLOUD} />
              </div>
            </div>
          </div>
        </div>
        <div className={`flex justify-end`}>
          <div className="flex gap-2 flex-row order-last mt-5 mr-5">
            <button
              type="button"
              className="bg-white text-black py-1.5 px-4 text-md rounded-md items-center border-2"
              onClick={() => {
                if (isDirty) {
                  setShowCancelModal(true);
                } else {
                  setCreateMode(false);
                  setShowSidebar(false);
                }
              }}
            >
              {cancelButtonText}
            </button>
            <button
              type="submit"
              disabled={!isDirty}
              className={`bg-blue-500 text-white py-1.5 px-6 text-md rounded-md items-center ${!isDirty ? "opacity-60" : "opacity-100"}`}
            >
              {saveButtonText}
            </button>
          </div>
        </div>
      </form>
      <Modal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        title="Warning"
        onAction={() => {
          setShowCancelModal(false);
          setShowSidebar(false);
          setCreateMode(false);
        }}
      >
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
    </>
  );
};

export default AgencyForm;
