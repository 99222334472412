import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { useHttpGet } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { Messages } from "src/constants/messages";
import BuildingManagerDetails from "./buildingManagerDetail";
import { httpCode } from "src/constants/constant";
import { Errors } from "src/components/shared/error/error";

export const BuildingManagerContact: React.FC<{ corpId: any }> = ({ corpId }) => {
  const { data, error, statusCode } = useHttpGet(EndPoints.contact + `${EndPoints.corporation}${corpId}/building-manager`);

  if (error || httpCode.INTERNAL_SERVER_ERROR === statusCode) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (httpCode.NOT_FOUND === statusCode || httpCode.NO_CONTENT === statusCode) {
    return <BuildingManagerDetails buildingManagerList={[]} />;
  }

  if (!data) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-10 h-10">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }

  return <BuildingManagerDetails buildingManagerList={[data]} />;
};
