import { useMemo, useState, useRef } from "react";
import { ViewColumn } from "src/assets/icon";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { IconYesNoNull } from "src/components/ui/iconYesNoNull/iconYesNoNull";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { Labels } from "src/constants/labels";
import { IManagementFeesData, IManagementFees } from "src/interfaces/managementfees";
import ManagementFeeSelected from "../managementFeeSelected/managementFeeSelected";
import { NIL, modalDiscardMessageText } from "src/constants/constant";
import { toLocaleValue } from "src/utils/utility";
import ToastContainer from "src/components/ui/toast/toast";
import { Modal } from "src/components/ui/modal/modal";

const ManagementFeesTable = ({ data }: { data: IManagementFeesData }) => {
  const [managementFeesData, setManagementFeesData] = useState({
    ...data,
    managementFeeDetails: data?.managementFeeDetails?.map((fee: IManagementFees) => {
      return {
        ...fee,
        rate: fee?.feeId ? (fee?.rate ? toLocaleValue(Number(fee?.rate)) : NIL) : fee?.defaultRate ? toLocaleValue(fee?.defaultRate) : NIL,
        newRate: fee?.feeId ? (fee?.newRate ? toLocaleValue(Number(fee?.newRate)) : NIL) : fee?.defaultRate ? toLocaleValue(fee?.defaultRate) : NIL,
      };
    }),
  });
  const [selectedFee, setSelectedFee] = useState({} as IManagementFees);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const toastRef = useRef<any>(null);
  const managementFeesButtons = [{ buttonName: <ViewColumn /> }];
  const columns = useMemo(() => {
    return [
      {
        field: "code",
        header: <p className="text uppercase !font-bold">{Labels.CODE}</p>,
      },
      {
        field: "description",
        header: <p className="text uppercase !font-bold">{Labels.DESCRIPTION_OF_SERVICE}</p>,
      },
      {
        field: "rate",
        header: <p className="text uppercase !font-bold">{Labels.CURRENT_RATE}</p>,
      },
      {
        field: "newRate",
        header: <p className="text uppercase !font-bold">{Labels.NEW_RATE}</p>,
      },
      {
        field: "billingUnit",
        header: <p className="text uppercase !font-bold">{Labels.COST_UNIT}</p>,
      },
      {
        field: "bAutoBill",
        header: <p className="text uppercase !font-bold">{Labels.AUTO_BILL}</p>,
        body: (data: any) => {
          return <IconYesNoNull type={data?.feeType === "M" ? "Y" : "N"} />;
        },
      },
      {
        field: "bScheduled",
        header: <p className="text uppercase !font-bold">{Labels.SCHEDULED}</p>,
        body: (data: any) => {
          return <IconYesNoNull type={data?.feeId ? "Y" : "N"} />;
        },
      },
      {
        field: "bAutoIncrement",
        header: <p className="text uppercase !font-bold">{Labels.AUTO_INCREMENT}</p>,
        body: (data: any) => {
          return <IconYesNoNull type={data?.autoIncrement} />;
        },
      },
    ];
  }, []);

  return (
    <div className="ml-10">
      <CollapsibleCard id="managementFeesTable" title={Labels.MANAGEMENT_FEES} expand={true} isCollapsible={false} buttons={[]}>
        <div className="w-full pt-4">
          <SMDataTableContainer
            tableData={managementFeesData?.managementFeeDetails}
            tableColumns={columns}
            showEditIcon={false}
            showSelectableRowCheckbox={false}
            selectedRow={selectedFee}
            setSelectedRow={(data: IManagementFees) => {
              if (isFormDirty) {
                setShowModal(true);
              } else {
                setSelectedFee(data);
              }
            }}
          />
        </div>
      </CollapsibleCard>
      <ManagementFeeSelected
        key={selectedFee?.feeCodeId}
        selectedFee={selectedFee}
        managementFeesData={managementFeesData}
        setManagementFeesData={setManagementFeesData}
        setSelectedFee={setSelectedFee}
        toastRef={toastRef}
        setIsFormDirty={setIsFormDirty}
      />
      <ToastContainer toastReference={toastRef} />
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Warning"
        onAction={() => {
          setSelectedFee({} as IManagementFees);
          setShowModal(false);
        }}
      >
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
    </div>
  );
};

export default ManagementFeesTable;
