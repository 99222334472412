'use client';
import React from 'react';
import { useState } from 'react';
import styles from './editNotes.module.scss';
import { Modal } from 'src/components/ui/modal/modal';
import { Check, Close, Delete } from 'src/assets/icon';
import { InlineEdit } from 'src/components/ui/inlineEdit/inlineEdit';

export const EditNotes: React.FC<{notes: string[], dispatch: any}> = ({notes=[], dispatch}) =>{
  const [note, setNote] = useState<string>('');
  const [showModal, setShowModal] = useState(false);
  const [id, setId] = useState();
    
  function handleChange(e:any){
    setNote(e.target.value);
  }
  
  function handleSubmit(e:any){
    if(note !== undefined && note !== null && note?.trim() !== "")
    {
      dispatch({type:'ADD', payload:note});
      dispatch({type:'API', payload:''});
    }
    reset();
  }

  function reset(){
    setNote('');
  }

  function onEdit(index:any, note:any){
    if(note !== undefined && note !== null && note?.trim() !== "")
    {
      dispatch({type:'UPDATE', payload:{index: index, value: note}});
      dispatch({type:'API', payload:''});
    }
  }

  function onDelete(index: any){
    setShowModal(false);
    dispatch({type:'DELETE', payload:index});
    dispatch({type:'API', payload:''});
  }

  function deleteConfirmation(index:any){
  setShowModal(true);
  setId(index);
  }

  return (
    <div className={`basis-full text-sm cursor-pointer ${styles.scrollMax}`}>
        <div className="flex justify-between border-b px-0.5">
              <input data-testid ="addNotes" value={note} placeholder="Add a note" className='flex text-sm  my-1 basis-4/5 w-full sm:px-3 py-2 rounded-md border focus:ring-blue-300 focus:border-blue-300'type="text" onChange={handleChange}/>
              <div className="flex basis-1/5 sm:px-3 py-2 justify-end place-items-center gap-3">
                  <span data-testid ="submitNote" onClick={handleSubmit}><Check height={22} width={22}/></span>
                  <span data-testid ="reset" onClick={reset}><Close height={20} width={20}/></span>
              </div>
        </div>
        { 
          notes?.length > 0 ?
          [...notes].reverse().map((note:any,index:any) =>{
          return(
                  <div key={note+"_"+index} className="flex justify-between border-b text-sm p-0.5">
                    <InlineEdit index={(notes.length-1 - index)} value={note} onEdit={onEdit} />
                    <div className="flex basis-1/4 sm:px-3 py-2 justify-end place-items-center gap-3">
                        <span data-testid ="deleteNotes" onClick={()=>deleteConfirmation((notes.length-1)-index)}><Delete/></span>
                    </div>
                  </div>
                )
            })
          : null
        }
        <Modal isOpen={showModal} onClose={() => setShowModal(false)} title='Warning' onAction={()=> onDelete(id)}>
          <div>
                <h2>Are you sure you want to delete Note(s) ?</h2>
          </div>
        </Modal>
    </div>
  )
}
