import { combineReducers } from "@reduxjs/toolkit";
import userSettingsSlice from "./slice/userSettingsSlice";
import planDetailSlice from "./slice/planDetailSlice";
import breadCrumbSlice from "./slice/breadCrumbSlice";
import authSlice from "./slice/authSlice";
import userSlice from "./slice/userSlice";
import agencySettingSlice from "./slice/agencySlice";
import dirtyFormSlice from "./slice/dirtyFormSlice";
import contactSlice from "./slice/contact";
import budgetSlice from "./slice/budget";
import committeeSlice from "./slice/committeeSlice";
import insuranceSlice from "./slice/insurance";
import entitlementSlice from "./slice/entitlementSlice";
import quickReports from "./slice/quickReports";

export const RootReducer = combineReducers({
  userSettings: userSettingsSlice,
  breadCrumbs: breadCrumbSlice,
  planDetail: planDetailSlice,
  authSlice: authSlice,
  userSlice: userSlice,
  agencySettingSlice: agencySettingSlice,
  dirtyFormSlice: dirtyFormSlice,
  contactSlice: contactSlice,
  budgetSlice: budgetSlice,
  committeeSlice: committeeSlice,
  insuranceSlice: insuranceSlice,
  entitlementSlice: entitlementSlice,
  quickReports: quickReports,
});

export type RootState = ReturnType<typeof RootReducer>;
