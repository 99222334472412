import React from "react";
import { Controller } from "react-hook-form";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import { SMRadioButton } from "src/components/ui/radioControl/radioButton";
import { Labels } from "src/constants/labels";

export const LeviesDelivery: React.FC<{
  contactDetail: any;
  lotContacts: any;
  selectedAddress: any;
  control: any;
  lotDetails: any;
}> = ({ contactDetail, lotContacts, selectedAddress, control, lotDetails }) => {
  let tenantEmailAvailable = Object.assign(
    {},
    ...lotContacts?.filter?.(
      (contact: any) => contact?.contactId === lotDetails?.tenantContactId
    )
  );
  let agentEmailAvailable = Object.assign(
    {},
    ...lotContacts?.filter?.(
      (contact: any) => contact?.contactId === lotDetails?.agentContactId
    )
  );
  let ownerEmailAvailable = Object.assign(
    {},
    ...lotContacts?.filter?.(
      (contact: any) => contact?.contactId === lotDetails?.ownerContactId
    )
  );
  let ownerOccupied = lotDetails?.ownerOccupied;
  const disableEmail =
    contactDetail?.email
      ? false
      : true;
  return (
    <div>
      <div className="flex justify-between h-10 items-center">
        <Controller
          name="levyIssue"
          control={control}
          render={({ field }) => (
            <>
              <div className="basis-full text-left items-center">
                <label
                  htmlFor="Company"
                  className={`font-bold text-left text-sm`}
                >
                  {Labels.LEVIES_DELIVERY}
                </label>
              </div>
              <div className="flex basis-full text-left items-center">
                <SMRadioButton
                  id="print"
                  field={field}
                  value="P"
                  checked={
                    field?.value === "E" && disableEmail
                      ? true
                      : field?.value === "P"
                      ? true
                      : false
                  }
                />
                <label
                  htmlFor="Print"
                  className={`ml-2 font-normal text-left text-sm`}
                >
                  {Labels.LEVY_ISSUE_PRINT}
                </label>
              </div>
              <div className="flex basis-full text-left items-center">
                <SMRadioButton
                  id="email"
                  field={field}
                  value="E"
                  checked={
                    field?.value === "E" && disableEmail
                      ? false
                      : field?.value === "E"
                      ? true
                      : false
                  }
                  disabled={disableEmail}
                />
                <label
                  htmlFor="Email"
                  className={`ml-2 font-normal text-left text-sm ${
                    disableEmail ? "opacity-50" : ""
                  }`}
                >
                  {Labels.LEVY_ISSUE_EMAIL}
                </label>
              </div>
            </>
          )}
        />
      </div>
      <div className="flex justify-between h-10  border-b items-center">
        <div className="text-left items-center">
          <label htmlFor="Company" className={`font-bold text-left text-sm`}>
            {Labels.ADDITIONAL_EMAILS}
          </label>
        </div>
        <div
          className={`text-left items-center ${
            (contactDetail?.contactType === Labels.OWNER_CONTACT_TYPE  && contactDetail?.contactId === lotDetails?.ownerContactId) ||
            (ownerEmailAvailable?.email ? false : true)
              ? "opacity-50"
              : ""
          }`}
        >
          <SMCheckbox
            name="useOwnersLevyEmail"
            control={control}
            label={Labels.OWNER}
            disabled={
              (contactDetail?.contactType === Labels.OWNER_CONTACT_TYPE && contactDetail?.contactId === lotDetails?.ownerContactId) ||
              (ownerEmailAvailable?.email ? false : true)
            }
          />
        </div>
        <div
          className={`text-left items-center ${
            contactDetail?.contactType === Labels.AGENT_CONTACT_TYPE ||
            ownerOccupied ||
            (agentEmailAvailable?.email ? false : true)
              ? "opacity-50"
              : ""
          }`}
        >
          <SMCheckbox
            name="useAgentsLevyEmail"
            control={control}
            label={Labels.AGENT}
            disabled={
              contactDetail?.contactType === Labels.AGENT_CONTACT_TYPE ||
              ownerOccupied ||
              (agentEmailAvailable?.email ? false : true)
            }
          />
        </div>
        <div
          className={`text-left items-center ${
            contactDetail?.contactType === Labels.TENANT_CONTACT_TYPE ||
            (tenantEmailAvailable?.email ? false : true)
              ? "opacity-50"
              : ""
          }`}
        >
          <SMCheckbox
            name="useTenantsLevyEmail"
            control={control}
            label={Labels.TENANT}
            disabled={
              contactDetail?.contactType === Labels.TENANT_CONTACT_TYPE ||
              (tenantEmailAvailable?.email ? false : true)
            }
          />
        </div>
      </div>
      {/* <div className="h-8 py-2">
        <label className="text-sm font-bold ">
          {Labels.MACQUARIE_DIRECT_DEBIT}
        </label>
      </div>
      <div className="flex pt-2 gap-4">
        <button className="transparent-button">{Labels.CHECK_STATUS}</button>
        <button className="transparent-button">
          {Labels.SEND_DIRECT_DEBIT_INVITATION}
        </button>
      </div> */}
    </div>
  );
};
