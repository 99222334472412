import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { planDetail } from "src/constants/constant";
import { getLocalStorage, setLocalStorage } from "src/utils/utility";

const plaNumber = getLocalStorage(planDetail) ?? 0;

const initialState = {
  plaNumber: plaNumber,
};

const planDetailSlice = createSlice({
  name: planDetail,
  initialState,
  reducers: {
    setPlanNumber: (state, action: PayloadAction<string>) => {
      setLocalStorage(planDetail, action.payload);
      state.plaNumber = action.payload;
    },
  },
});

export const { setPlanNumber } = planDetailSlice.actions;
export default planDetailSlice.reducer;
