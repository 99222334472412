import React, { useRef, useState } from "react";
import { httpUpdate } from "src/services/api";
import styles from "../planDetails/planDetails.module.scss";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import { Modal } from "src/components/ui/modal/modal";
import { ILotSummary } from "src/interfaces/lotSummary";
import { EndPoints } from "src/constants/endpoint";
import { useForm } from "react-hook-form";
import { lot_MaxLength, maxLimit, minLimit } from "src/constants/constant";
import { SMInputNumber } from "src/components/ui/inputControl/inputNumber";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import { Toast } from "primereact/toast";
import ToastContainer from "src/components/ui/toast/toast";
import { checkZeroValue } from "src/utils";

export const LotSummaryDetail: React.FC<{
  corpId: string;
  setIsLoading: Function;
  lotDetails: any;
}> = ({ corpId, setIsLoading, lotDetails }) => {
  const [lotDetail, setLotDetail] = useState(lotDetails);
  const [lotEditable, setLotEditable] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);

  const {
    handleSubmit,
    reset,
    control,
    formState: { isDirty },
  } = useForm<ILotSummary>({
    defaultValues: {
      commercialLots: lotDetail?.commercialLots,
      otherLots: lotDetail?.otherLots,
      primaryLots: lotDetail?.primaryLots,
      residentialLots: lotDetail?.residentialLots,
      retirementLots: lotDetail?.retirementLots,
      utilityLots: lotDetail?.utilityLots,
      registeredLots: lotDetail?.registeredLots,
      totalLots: lotDetail?.totalLots,
    },
  });

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  function onDiscard() {
    setLotEditable(false);
    reset({
      residentialLots: lotDetail?.residentialLots,
      commercialLots: lotDetail?.commercialLots,
      primaryLots: lotDetail?.primaryLots,
      utilityLots: lotDetail?.utilityLots,
      retirementLots: lotDetail?.retirementLots,
      otherLots: lotDetail?.otherLots,
    });
    setShowModal(false);
  }

  function onCancel() {
    if (isDirty) {
      setShowModal(true);
    } else {
      setLotEditable(false);
    }
  }

  const setData = (data: any) => {
    setLotDetail({
      ...lotDetail,
      residentialLots: data?.residentialLots,
      commercialLots: data?.commercialLots,
      primaryLots: data?.primaryLots,
      utilityLots: data?.utilityLots,
      retirementLots: data?.retirementLots,
      otherLots: data?.otherLots,
    });
  };

  async function onSubmit(data: ILotSummary) {
    setIsLoading(true);
    const output = await httpUpdate(EndPoints.corporation + `${corpId}/lots/summary`, data);
    if (output?.status === 200) {
      setIsLoading(false);
      setLotEditable(false);
      setData(data);
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: 2000,
      });
      reset({}, { keepDirty: false, keepValues: true });
    }
    if (output?.error) {
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `error:${output?.error}`,
        life: 3000,
      });
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <div className="flex justify-between mt-5">
          <label className={`section-header pl-2.5`}>{Labels.LOTS}</label>
          {lotEditable ? (
            <div className="gap-1">
              <button type="button" className={`order-last edit-btn`} onClick={onCancel}>
                {Labels.CANCEL}
              </button>
              <button id="submit" className={`order-last edit-btn btn-primary ${!isDirty ? "opacity-60" : "opacity-100"}`} type="submit" disabled={!isDirty}>
                {Labels.SAVE}
              </button>
            </div>
          ) : (
            <button type="button" className={`order-last edit-btn`} onClick={() => setLotEditable(true)}>
              {Labels.EDIT}
            </button>
          )}
        </div>
        <div className={`flex justify-between items-center mt-4 text-gray-400 ${lotEditable ? "border-remove" : "border-bottom"}`}>
          <label className={`${styles.key} ${lotEditable ? "border-bottom" : "border-remove"}`}>{Labels.REGISTERED}</label>
          {lotEditable ? (
            <div className="w-full pt-3">
              <SMInputNumber name="registeredLots" control={control} disabled />
            </div>
          ) : (
            <span className="pt-3 mr-2.5 font-bold">{checkZeroValue(lotDetail?.registeredLots)}</span>
          )}
        </div>
        <div className={`flex justify-between items-center text-gray-400 ${lotEditable ? "border-remove" : "border-bottom"}`}>
          <label className={`${styles.key} ${lotEditable ? "border-bottom" : "border-remove"}`}>{Labels.TOTAL}</label>
          {lotEditable ? (
            <div className="w-full pt-3">
              <SMInputNumber control={control} name="totalLots" disabled />
            </div>
          ) : (
            <span className="pt-3 mr-2.5 font-bold">{checkZeroValue(lotDetail?.totalLots)}</span>
          )}
        </div>

        <div className={`flex justify-between items-center ${lotEditable ? "border-remove" : "border-bottom"}`}>
          <label className={`${styles.key} ${lotEditable ? "border-bottom" : "border-remove"}`}>{Labels.RESIDENTIAL}</label>
          {lotEditable ? (
            <div className="w-full pt-3">
              <SMInputNumber name="residentialLots" control={control} min={minLimit} max={maxLimit} maxLength={lot_MaxLength} disabled={!lotEditable} />
            </div>
          ) : (
            <span className="pt-3 mr-2.5 font-bold">{checkZeroValue(lotDetail?.residentialLots)}</span>
          )}
        </div>
        <div className={`flex justify-between items-center ${lotEditable ? "border-remove" : "border-bottom"}`}>
          <label className={`${styles.key} ${lotEditable ? "border-bottom" : "border-remove"}`}>{Labels.COMMERCIAL}</label>
          {lotEditable ? (
            <div className="w-full pt-3">
              <SMInputNumber name="commercialLots" control={control} min={minLimit} max={maxLimit} maxLength={lot_MaxLength} disabled={!lotEditable} />
            </div>
          ) : (
            <span className="pt-3 mr-2.5 font-bold">{checkZeroValue(lotDetail?.commercialLots)}</span>
          )}
        </div>
        <div className={`flex justify-between items-center ${lotEditable ? "border-remove" : "border-bottom"}`}>
          <label className={`${styles.key} ${lotEditable ? "border-bottom" : "border-remove"}`}>{Labels.PRIMARY}</label>
          {lotEditable ? (
            <div className="w-full pt-3">
              <SMInputNumber name="primaryLots" control={control} min={minLimit} max={maxLimit} maxLength={lot_MaxLength} disabled={!lotEditable} />
            </div>
          ) : (
            <span className="pt-3 mr-2.5 font-bold">{checkZeroValue(lotDetail?.primaryLots)}</span>
          )}
        </div>
        <div className={`flex justify-between items-center ${lotEditable ? "border-remove" : "border-bottom"}`}>
          <label className={`${styles.key} ${lotEditable ? "border-bottom" : "border-remove"}`}>{Labels.UTILITY}</label>
          {lotEditable ? (
            <div className="w-full pt-3">
              <SMInputNumber name="utilityLots" control={control} min={minLimit} max={maxLimit} maxLength={lot_MaxLength} disabled={!lotEditable} />
            </div>
          ) : (
            <span className="pt-3 mr-2.5 font-bold">{checkZeroValue(lotDetail?.utilityLots)}</span>
          )}
        </div>
        <div className={`flex justify-between items-center ${lotEditable ? "border-remove" : "border-bottom"}`}>
          <label className={`${styles.key} ${lotEditable ? "border-bottom" : "border-remove"}`}>{Labels.RETIREMENT}</label>
          {lotEditable ? (
            <div className="w-full pt-3">
              <SMInputNumber name="retirementLots" control={control} min={minLimit} max={maxLimit} maxLength={lot_MaxLength} disabled={!lotEditable} />
            </div>
          ) : (
            <span className="pt-3 mr-2.5 font-bold">{checkZeroValue(lotDetail?.retirementLots)}</span>
          )}
        </div>
        <div className={`flex justify-between items-center ${lotEditable ? "border-remove" : "border-bottom"}`}>
          <label className={`${styles.key} ${lotEditable ? "border-bottom" : "border-remove"}`}>{Labels.OTHER}</label>
          {lotEditable ? (
            <div className="w-full pt-3">
              <SMInputNumber name="otherLots" control={control} min={minLimit} max={maxLimit} maxLength={lot_MaxLength} disabled={!lotEditable} />
            </div>
          ) : (
            <span className="pt-3 mr-2.5 font-bold">{checkZeroValue(lotDetail?.otherLots)}</span>
          )}
        </div>
      </form>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Warning" onAction={() => onDiscard()}>
        <div>
          <h2>{Messages.DISCARD_MESSAGE}</h2>
        </div>
      </Modal>
      <ToastContainer toastReference={toastRef} />
    </>
  );
};
