import { IInsuranceHeader } from "src/interfaces/insuranceHeader";
import styles from "./insuranceHeader.module.scss";
import { Labels } from "src/constants/labels";
import { SMDatePicker } from "src/components/ui/datePicker/datePicker";
import { findCode, findValue, FormatDate, toDate, toLocaleValue } from "src/utils/utility";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import {
  cancelButtonText,
  editButtonText,
  httpCode,
  modalDiscardMessageText,
  saveButtonText,
  toastErrorTimeLimit,
  toastSuccessTimeLimit,
} from "src/constants/constant";
import { useMemo, useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { useForm } from "react-hook-form";
import { EndPoints } from "src/constants/endpoint";
import { httpUpdate } from "src/services/api";
import { Messages } from "src/constants/messages";
import { Modal } from "src/components/ui/modal/modal";
import ToastContainer from "src/components/ui/toast/toast";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { ICreditors } from "src/interfaces/creditors";
import { useParams } from "react-router-dom";
import { Delete } from "src/assets/icon";

const InsuranceSection = ({ data, creditorData }: { data: IInsuranceHeader | null; creditorData: ICreditors[] }) => {
  const { corpId } = useParams();
  const [insuaranceValidation, setInsuaranceValidation] = useState<any>(data);
  const [isEditableValidation, setIsEditableValidation] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);

  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm<IInsuranceHeader>({
    defaultValues: {
      ownersCorporationId: insuaranceValidation?.ownersCorporationId,
      valuationAmount: insuaranceValidation?.valuationAmount,
      valuation: insuaranceValidation?.valuation,
      valuationDue: insuaranceValidation?.valuationDue,
      valuerCode: insuaranceValidation?.valuerCode,
      valuerName: insuaranceValidation?.valuerName,
      insuranceValuerId: insuaranceValidation?.insuranceValuerId,
    },
    mode: "onTouched",
  });

  const policyTableButtons = [
    {
      buttonName: isEditableValidation ? cancelButtonText : editButtonText,
      type: "normal",
      onClick: isEditableValidation ? onCancel : () => setIsEditableValidation(true),
    },
    {
      buttonName: isEditableValidation ? saveButtonText : "",
      type: isEditableValidation ? "save" : "",
      buttonType: "submit",
      formId: "insuaranceValidationForm",
    },
  ];

  const creditorList = useMemo(() => {
    return creditorData
      ?.filter((x: ICreditors) => String(x.active) === "Y")
      ?.map((creditor: { creditorId: number; creditorCode: string; creditorName: string; active: boolean }) => ({
        id: creditor.creditorId,
        code: creditor.creditorCode,
        name: creditor.creditorName,
        active: creditor.active,
      }));
  }, [creditorData]);

  const handleOnChangeName = (value: any) => {
    let creditor = creditorList?.find((x) => x.name === value);
    if (creditor) {
      setValue("valuerCode", creditor?.code);
      setValue("valuerName", creditor?.name);
      setValue("insuranceValuerId", creditor?.id);
    }
  };

  const handleOnChangeCode = (value: any) => {
    let creditor = creditorList?.find((x) => x.code === value);
    if (creditor) {
      setValue("valuerName", creditor?.name);
      setValue("valuerCode", creditor?.code);
      setValue("insuranceValuerId", creditor?.id);
    }
  };

  function handleOnRemove() {
      setValue("valuerName", "");
      setValue("valuerCode", "");
      setValue("insuranceValuerId", 0, {shouldDirty: true});
  };

  function onCancel() {
    if (isDirty) {
      setShowModal(true);
    } else {
      setIsEditableValidation(false);
    }
  }

  function onDiscard() {
    if (insuaranceValidation) {
      reset(insuaranceValidation);
    }
    setShowModal(false);
    setIsEditableValidation(false);
  }

  async function onSubmit(data: IInsuranceHeader) {
    let payload = {
      ...data,
      valuation: toDate(FormatDate(data?.valuation) ?? ""),
      valuationDue: toDate(FormatDate(data?.valuationDue) ?? ""),
    };
    setIsLoading(true);
    const output = await httpUpdate(EndPoints.corporation + corpId + "/" + EndPoints.insurance + "/policy-header", payload);
    if (output?.status === httpCode.SUCCESS) {
      setInsuaranceValidation({
        ...insuaranceValidation,
        ...data,
      });
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: toastSuccessTimeLimit,
      });
      reset({}, { keepDirty: false, keepValues: true });
      setIsEditableValidation(false);
    }
    if (output?.error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Error: ${output?.error}`,
        life: toastErrorTimeLimit,
      });
    }
    setIsLoading(false);
  }
  return (
    <>
      <CollapsibleCard id="policyTable" title={Labels.VALUATION} expand={true} buttons={policyTableButtons}>
        {data ? (
          <>
            {isLoading && (
              <div className="spinnerContainer">
                <div className="w-20 h-20">
                  <SpinnerHorizontal />
                </div>
              </div>
            )}
            <form id="insuaranceValidationForm" onSubmit={handleSubmit(onSubmit)} noValidate>
              <div className="w-full flex flex-col pt-8 gap-4">
                <div className="flex gap-8">
                  <div className={`w-1/2 flex justify-between items-center ${isEditableValidation ? "" : styles.lineBreak}`}>
                    <label className="text-sm">{Labels.VALUATION} Done</label>
                    {isEditableValidation ? (
                      <SMDatePicker
                        inputDate={insuaranceValidation?.valuation ? FormatDate(insuaranceValidation?.valuation) : ""}
                        control={control}
                        name="valuation"
                        disabled={!isEditableValidation}
                        width="w-1/2"
                      />
                    ) : (
                      <div className="text-sm font-bold pr-4">{insuaranceValidation?.valuation ? FormatDate(insuaranceValidation?.valuation) : ""}</div>
                    )}
                  </div>
                  <div className={`w-1/2 flex justify-between items-center ${isEditableValidation ? "" : styles.lineBreak}`}>
                    <label className="text-sm">{Labels.VALUATION}</label>
                    {isEditableValidation ? (
                      <span className="mb-2 basis-1/2 text-right mr-8">
                        <SMInputText name="valuationAmount" keyfilter={"alphanum"} control={control} disabled={!isEditableValidation} />
                      </span>
                    ) : (
                      <div className="text-sm font-bold pr-4">{toLocaleValue(insuaranceValidation?.valuationAmount ?? 0)}</div>
                    )}
                  </div>
                </div>
                <div className="flex gap-8">
                  <div className="w-1/2 flex justify-between items-center">
                    <label className="text-sm">{Labels.VALUATION} Due</label>
                    {isEditableValidation ? (
                      <SMDatePicker
                        inputDate={FormatDate(insuaranceValidation?.valuationDue ?? null) ?? ""}
                        control={control}
                        name="valuationDue"
                        disabled={!isEditableValidation}
                        width="w-1/2"
                      />
                    ) : (
                      <div className="text-sm font-bold pr-4">{insuaranceValidation?.valuationDue ? FormatDate(insuaranceValidation?.valuationDue) : ""}</div>
                    )}
                  </div>
                  <div className="w-1/2 flex justify-between items-center">
                    <label className="text-sm">Valuer</label>
                    {isEditableValidation ? (
                      <div className="flex basis-4/5 gap-1">
                        <span className="basis-1/3">
                          <SMDropDown
                            filter={true}
                            id="valuerCode"
                            control={control}
                            name="valuerCode"
                            options={creditorList}
                            optionLabel="code"
                            optionValue="code"
                            value={findCode(creditorList, insuaranceValidation?.valuerCode) ?? ""}
                            disabled={!isEditableValidation}
                            onChange={(e: any) => handleOnChangeCode(e)}
                          />
                        </span>
                        <SMDropDown
                          filter={true}
                          id="valuerName"
                          control={control}
                          name="valuerName"
                          options={creditorList}
                          optionLabel="name"
                          optionValue="name"
                          value={findValue(creditorList, insuaranceValidation?.valuerName)}
                          disabled={!isEditableValidation}
                          onChange={(e: any) => handleOnChangeName(e)}
                        />
                        <div className="flex items-center px-1" onClick={handleOnRemove}>
                          <Delete />
                        </div>
                      </div>
                    ) : (
                      <>
                        <div className="text-sm font-bold pr-4">{findCode(creditorList, insuaranceValidation?.insuranceValuerId ?? 0)}</div>
                        <div className="text-sm font-bold pr-4">{findValue(creditorList, insuaranceValidation?.insuranceValuerId ?? 0)}</div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </>
        ) : (
          <p>{Labels.NO_DATA_AVAILABLE}</p>
        )}
      </CollapsibleCard>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Warning" onAction={() => onDiscard()}>
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
      <ToastContainer toastReference={toastRef} />
    </>
  );
};

export default InsuranceSection;
