import React from "react";
import { FormatDate } from "src/utils/utility";
import styles from './structureSummary.module.scss'
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { httpCode, noDataFound } from "src/constants/constant";
import { Errors } from "src/components/shared/error/error";
import { Messages } from "src/constants/messages";
import { Labels } from "src/constants/labels";

export const StructureSummary : React.FC<{data: any, error: any, status: any}> = ({data, error, status}) =>{
    
    if (error || httpCode.INTERNAL_SERVER_ERROR === status) {
      return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
    }

    if (httpCode.NOT_FOUND === status || httpCode.NO_CONTENT === status) {
      return <div className='flex w-full m-4'>{noDataFound}</div>;
    }
  
    if (!data) {
      return (
        <>
          <div className='flex w-full min-h-full items-center justify-center'>
            <div className='w-10 h-10'>
              <SpinnerHorizontal />
            </div>
          </div>
        </>
      )
    }
    return(
        <div data-testid="structureSummary" className={`flex-auto basis-full`}>
            <table data-testid="structSummaryTab1" className={`table-auto ${styles.table}`}>
                <tbody>
                    <tr className="flex flex-wrap border-b">
                        <th className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 text-left font-normal">{Labels.ASSOCIATION_TYPE}</th>
                        <td className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 font-semibold">{data?.sName+"-"+data?.sState}</td>
                    </tr>
                    <tr className="flex flex-wrap border-b">
                        <th className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 text-left font-normal">{Labels.BUILD_DATE}</th>
                        <td className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 font-semibold">{FormatDate(data?.dBuilt)}</td>
                    </tr>
                    <tr className="flex flex-wrap border-b">
                        <th className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 text-left font-normal">{Labels.PLAN_REGISTERED}</th>
                        <td className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 font-semibold">{FormatDate(data?.dRegistered)}</td>
                    </tr>
                    <tr className="flex flex-wrap border-b">
                        <th className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 text-left font-normal">{Labels.FIRST_AGM}</th>
                        <td className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 font-semibold">{FormatDate(data?.dFirstAGM)}</td>
                    </tr>
                    <tr className="flex flex-wrap border-b">
                        <th className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 text-left font-normal">{Labels.MANAGEMENT_COMMENCED}</th>
                        <td className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 font-semibold">{FormatDate(data?.dManagementCommencement)}</td>
                    </tr>
                    <tr className="flex flex-wrap border-b">
                        <th className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 text-left font-normal">{Labels.MANAGEMENT_END}</th>
                        <td className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 font-semibold">{FormatDate(data?.dManagementEnded)}</td>
                    </tr>
                    <tr className="flex flex-wrap border-b">
                        <th className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 text-left font-normal">{Labels.NUMBER_OF_STOREYS_ABOVE_GROUND}</th>
                        <td className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 font-semibold">{data?.sStoreyAboveGround}</td>
                    </tr>
                    <tr className="flex flex-wrap border-b">
                        <th className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 text-left font-normal">{Labels.OCCUPATION_CERTIFICATE_DATE}</th>
                        <td className="flex-auto basis-full lg:basis-1/2 sm:px-3 py-2 font-semibold">{FormatDate(data?.dOccupationCertDate)}</td>
                    </tr>
                    <tr className="flex flex-wrap border-b">
                        <th className="w-1/2 sm:px-3 py-2 text-left font-normal">{Labels.DEVELOPER}</th>
                        <td className="w-1/2 sm:px-3 py-2 font-semibold break-words">{data?.sDeveloper}</td>
                    </tr>
                    <tr className="flex flex-wrap border-b">
                        <th className="w-1/2 sm:px-3 py-2 text-left font-normal">{Labels.BUILDER}</th>
                        <td className="w-1/2 sm:px-3 py-2 font-semibold break-words">{data?.sBuilder}</td>
                    </tr>
                    <tr className="flex flex-wrap border-b">
                        <th className="w-1/2 sm:px-3 py-2 text-left font-normal">{Labels.DEVELOPER_CONTRACT}</th>
                        <td className="w-1/2 sm:px-3 py-2 font-semibold break-words">{data?.sDeveloperContractWorksInsurance}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}