import React from "react";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import { Labels } from "src/constants/labels";

export const RejectedLevy: React.FC<{ control: any; }> = ({ control }) => {
  return (
    <div>
      <div className="items-center text-sm">
        <SMCheckbox
          name="receiveExecCommNotices"
          control={control}
          label={Labels.RECEIVE_EXECUTIVE_COMMITTEE_NOTICE_MINUTES}
        />
      </div>
      <div className="pt-4 text-sm items-center">
        <SMCheckbox
          name="excludeFromNoticesAndMinutes"
          control={control}
          label={Labels.EXCLUDE_FROM_NOTICES_AND_MINUTES}
        />
      </div>
    </div>
  );
};
