import React from "react";
import { Controller } from "react-hook-form";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import { SMRadioButton } from "src/components/ui/radioControl/radioButton";
import { Labels } from "src/constants/labels";

export const NoticesMinutes: React.FC<{
  lotDetails: any;
  contactDetail: any;
  control: any;
  noticesIssue: any;
  noticeCorrespondenceIssue: any;
}> = ({ lotDetails, contactDetail, control, noticesIssue , noticeCorrespondenceIssue}) => {
  let isAgentEmailExists =
    contactDetail?.contactType === Labels.AGENT_CONTACT_TYPE || lotDetails?.ownerOccupied || !Boolean(lotDetails?.lotContacts?.find?.((contact: any) => contact?.contactId === lotDetails?.agentContactId)?.email);
  let isOwnerEmailExists = (contactDetail?.contactType === Labels.OWNER_CONTACT_TYPE && contactDetail?.contactId === lotDetails?.ownerContactId) || !Boolean(lotDetails?.lotContacts?.find?.((contact: any) => contact?.contactId === lotDetails?.ownerContactId)?.email)
  let isTenantEmailExists =
    contactDetail?.contactType === Labels.TENANT_CONTACT_TYPE || !Boolean(lotDetails?.lotContacts?.find?.((contact: any) => contact?.contactId === lotDetails?.tenantContactId)?.email);

  const disableEmail =
    contactDetail?.email
      ? false
      : true;
  return (
    <div>
      <div className="flex justify-between h-10 items-center">
        <div className="basis-full text-left items-center">
          <label htmlFor="Company" className={`font-bold text-left text-sm`}>
            {Labels.NOTICES_MINUTES}
          </label>
        </div>
        <Controller
          name="noticesIssue"
          control={control}
          render={({ field }) => (
            <>
              <div className="flex basis-full text-left items-center">
                <SMRadioButton
                  id="print"
                  field={field}
                  value={Labels.PRINT_TENANT_NOTICE_ISSUES}
                  checked={
                    field?.value === Labels.EMAIL_TENANT_NOTICE_ISSUES && disableEmail
                      ? true
                      : field?.value === Labels.PRINT_TENANT_NOTICE_ISSUES
                      ? true
                      : false
                  }
                />
                <label className={`font-bold text-left text-sm ml-2`}>{Labels.PRINT_RADIOBUTTON_TEXT}</label>
              </div>
              <div className={`flex basis-full text-left items-center ${!Boolean(contactDetail?.email) ? "opacity-50" : ""}`}>
                <SMRadioButton
                  id="email"
                  field={field}
                  value= {Labels.EMAIL_TENANT_NOTICE_ISSUES}
                  checked={
                    field?.value === Labels.EMAIL_TENANT_NOTICE_ISSUES && disableEmail
                      ? false
                      : field?.value === Labels.EMAIL_TENANT_NOTICE_ISSUES
                      ? true
                      : false
                  }
                  disabled={disableEmail}
                />
                <label className={`font-bold text-left text-sm ml-2`}>{Labels.EMAIL_RADIOBUTTON_TEXT}</label>
              </div>
            </>
          )}
        />
      </div>
      {noticesIssue === Labels.EMAIL_TENANT_NOTICE_ISSUES && Boolean(contactDetail?.email) && (
        <div className="flex justify-between h-10 border-b items-center">
          <div className="text-left items-center">
            <label htmlFor="Company" className={`font-bold text-left text-sm`}>
              {Labels.ADDITIONAL_EMAILS}
            </label>
          </div>
          <div className={`text-left items-center ${isOwnerEmailExists ? "opacity-50" : ""}`}>
            <SMCheckbox
              name="useOwnersNoticeEmail"
              control={control}
              label={Labels.OWNER}
              disabled={isOwnerEmailExists}
            />
          </div>
          <div className={`text-left items-center ${isAgentEmailExists ? "opacity-50" : ""}`}>
            <SMCheckbox
              name="useAgentsNoticeEmail"
              control={control}
              label={Labels.AGENT}
              disabled={isAgentEmailExists}
            />
          </div>
          <div className={`text-left items-center ${isTenantEmailExists ? "opacity-50" : ""}`}>
            <SMCheckbox
              name="useTenantsNoticeEmail"
              control={control}
              label={Labels.TENANT}
              disabled={isTenantEmailExists}
            />
          </div>
        </div>
      )}
      <div className="flex justify-between h-10 items-center">
        <div className="basis-full text-left items-center">
          <label htmlFor="Company" className={`font-bold text-left text-sm`}>
            {Labels.CORRESPONDENCE}
          </label>
        </div>
        <Controller
          name="noticeCorrespondenceIssue"
          control={control}
          render={({ field }) => (
            <>
              <div className="flex basis-full text-left items-center">
                <SMRadioButton
                  id="print"
                  field={field}
                  value={Labels.PRINT_TENANT_NOTICE_ISSUES}
                  checked={
                    field?.value === Labels.EMAIL_TENANT_NOTICE_ISSUES && disableEmail
                      ? true
                      : field?.value === Labels.PRINT_TENANT_NOTICE_ISSUES
                      ? true
                      : false
                  }
                />
                <label className={`font-bold text-left text-sm ml-2`}>{Labels.PRINT_RADIOBUTTON_TEXT}</label>
              </div>
              <div className={`flex basis-full text-left items-center ${!Boolean(contactDetail?.email) ? "opacity-50" : ""}`}>
                <SMRadioButton
                  id="email"
                  field={field}
                  value={Labels.EMAIL_TENANT_NOTICE_ISSUES}
                  checked={
                    field?.value === Labels.EMAIL_TENANT_NOTICE_ISSUES && disableEmail
                      ? false
                      : field?.value === Labels.EMAIL_TENANT_NOTICE_ISSUES
                      ? true
                      : false
                  }
                  disabled={disableEmail}
                />
                <label className={`font-bold text-left text-sm ml-2`}>{Labels.EMAIL_RADIOBUTTON_TEXT}</label>
              </div>
            </>
          )}
        />
      </div>
      {noticeCorrespondenceIssue === Labels.EMAIL_TENANT_NOTICE_ISSUES && Boolean(contactDetail?.email) && (
        <div className="flex justify-between h-10 items-center">
          <div className="text-left items-center">
            <label htmlFor="Company" className={`font-bold text-left text-sm`}>
              {Labels.ADDITIONAL_EMAILS}
            </label>
          </div>
          <div className={`text-left items-center ${isOwnerEmailExists ? "opacity-50" : ""}`}>
            <SMCheckbox
              name="useOwnersCorrespondenceEmail"
              control={control}
              label={Labels.OWNER}
              disabled={isOwnerEmailExists}
            />
          </div>
          <div className={`text-left items-center ${isAgentEmailExists ? "opacity-50" : ""}`}>
            <SMCheckbox
              name="useAgentsCorrespondenceEmail"
              control={control}
              label={Labels.AGENT}
              disabled={isAgentEmailExists}
            />
          </div>
          <div className={`text-left items-center ${isTenantEmailExists ? "opacity-50" : ""}`}>
            <SMCheckbox
              name="useTenantsCorrespondenceEmail"
              control={control}
              label={Labels.TENANT}
              disabled={isTenantEmailExists}
            />
          </div>
        </div>
      )}
    </div>
  );
};
