import { setPlanNumber } from "src/store/slice/planDetailSlice";
import { advanceSearch } from "src/constants/constant";
import { Routes } from "src/constants/pageRoutes";

interface NavigationProps {
  route: string;
  type: string;
  name: string;
  dispatch: any;
  navigate: any;
  setShowSidebar?: any;
  setSearchTerm?: any;
  autoCompleteRef?: any;
}

const handleAction = (dispatch: any, planNo: string, setShowSidebar?: any, setSearchTerm?: any, autoCompleteRef?: any) => {
  if (planNo) {
    dispatch(setPlanNumber(planNo));
  }
  if (setShowSidebar) {
    setShowSidebar(false);
  }
  if (setSearchTerm) {
    setSearchTerm("");
    autoCompleteRef?.current?.hide();
  }
};

export const handleItemClick = ({ route, type, name, dispatch, navigate, setShowSidebar, setSearchTerm, autoCompleteRef }: NavigationProps) => {
  if (type === advanceSearch.corporation) {
    navigate(Routes.corporations + route);
    handleAction(dispatch, name, setShowSidebar, setSearchTerm, autoCompleteRef);
  } else if (type === advanceSearch.owner || type === advanceSearch.tenant) {
    const [lotNo, planNo] = name?.split("/");
    const [corpId, lotId] = route.split("/");
    navigate(Routes.corporations + corpId + Routes.lots, {
      state: {
        lotID: lotId,
        openLotDetails: true,
        lotNo,
        planNumber: planNo,
        tab: type === advanceSearch.tenant ? 1 : 0,
      },
    });
    handleAction(dispatch, planNo, setShowSidebar, setSearchTerm, autoCompleteRef);
  } else if (type === advanceSearch.committee) {
    const [, planNo] = name?.split("/");
    const [corpId] = route.split("/");
    navigate(Routes.corporations + corpId + Routes.committee);
    handleAction(dispatch, planNo, setShowSidebar, setSearchTerm, autoCompleteRef);
  } else if (type === advanceSearch.buildingManager) {
    const [corpId] = route.split("/");
    navigate(Routes.corporations + corpId + Routes.contact);
    handleAction(dispatch, name, setShowSidebar, setSearchTerm, autoCompleteRef);
  }
};
