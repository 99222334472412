export enum AccessRight {
  None = 0,
  Read = 1,
  ReadWrite = 2,
}

export enum FunctionsList {
  SysAdmin = 0,
  ActivityList = 1,
  SelectOwner,
  CommunicationsWizard,
  Reminders,

  WebConfiguration = 10,
  WebAccess,

  ManageOwnersCorporation = 100,
  ManageOwner,
  ManageClaim,
  ManageCreditor,
  ManageDocumentRegister,
  ManageWorkOrders,
  ManageWorkOrder,
  ManageBatchAddressCleanup,
  ManageContacts,
  ManageDebtCollectionNotification,
  ManageRegisters,
  ManageGroupReporting,
  ManageInterimReporting,
  ManageAGMPreparation,
  ManageRepairsMaintenance,
  ManageReminders,
  QuickWorkOrders,
  StrataHubBulkSend,
  StrataHubRegistration,

  ManageBudget = 200,
  ManageSetManagementFees,
  ManageDiary,
  ManageMeter,
  ManageDirectDebitWizard,

  AccountingReceipting = 300,
  AccountingCredInvoices,
  AccountingCredCheques,
  AccountingBanking,
  AccountingQuickCheque,
  AccountingCancelReceipt,
  AccountingLevyProcessing,
  AccountingOwnerInvoice,
  AccountingJournalEntry,
  AccountingLevyJournalEntry,
  AccountingTransactionSearch,
  AccountingTrustLedgerCheque,
  AccountingReconciliation,
  AccountingDownload,
  AccountingProcessManagementFeesQuantity,
  AccountingProcessManagementFeesMonthly,
  AccountingCancelCheque,
  AccountingCancelLevyPosting,
  AccountingEndOfYear,
  AccountingRebuildAccounts,
  AccountingEFTPayments,
  AccountingRefundLevy,
  AccountingDiscountLevy,
  AccountingTransactionEdit,
  AccountingInvestmentReconciliation,
  AccountingTransferToInvestment,
  AccountingRemitGST,
  AccountingRecoverBatchFees,
  AccountingSubmeterInvoicing,
  AccountingTransferMoneyBetweenFunds,
  AccountingOpeningBalance,
  AccountingCreditorInvoiceApproval,
  AccountingPayDiscountingCred,
  AccountingBPAYPayments,
  AccountingCancelBPAYPayments,
  AccountingCreditLot,
  AccountingCancelEFTPaymentsFile,
  AccountingDebtRecoveryPendingCharges,
  AccountingBulkCredInvoices,
  AccountingBulkReconciliation,
  AccountingMultipleOwnerInvoice,
  AccountingCredInvoiceMultipleDissection,
  AccountingGSTRefund,
  AccountingFSCredInvoices,
  AccountingReceiptingMultipleLots,
  AccountingFSCIOnlineApproval,
  AccountingDirectDebit,

  ConfigManagementFees = 402,
  ConfigAgency = 403,
  ConfigUser = 404,
  ConfigChartOfAccounts,
  ConfigTrustLedgerAccounts,
  ConfigBanks,
  ConfigBankAccounts,
  ConfigStandardWorkDescriptions,
  ConfigExecutivePositions,
  ConfigAssociationTypes,
  ConfigCreditorTypes,
  ConfigInsurancePolicyTypes,
  ConfigInsuranceCoverage,
  ConfigLevyDescription,
  ConfigEntitlementTypes,
  ConfigTerminology,
  ConfigStandardFundAccounts,
  ConfigConfig,
  ConfigComplianceStatus,
  ConfigComplianceType,
  ConfigContractTypes,
  ShowActiveUsers,
  ConfigWorkGroupNames,
  ConfigMeetingWizardStatus,
  ConfigMeetingWizard,
  ConfigMeetingAgenda,

  ConfigLabel = 500,
  ConfigDownload,
  ConfigSubmetering,
  ConfigDebtRecovery,
  ConfigLotRegisterTypes,
  ConfigKeyRegisterTypes,
  ConfigBankStatementSplit,
  ConfigComplianceRegisterTypes,
  ConfigGroupReportingTypes,
  ConfigLevyWizardMessages,
  ConfigThirdPartyServices,

  WizardInterimReports = 600,
  WizardAGMPreparation,
  GSTReports,
  AuditTrailReports,
  TaxReports,
  ChangeOwnerWizard,
  ProxyWizard,
  GeneralLedgerByAccountsReports,
  CurrentOwnerAccountByLotsReports,
  ComplianceWizard,
  WizardAgenda,
  MeetingAgenda,
  MeetingReminderSMS,
  WizardMinutes,
  DelegatedFunctionsReports,
  OwnerDeliveryMethodChangeWizard,
  StrataVoteArchivingWizard,
  TradesmanWizard,

  UtilityResetChequeNumber = 650,
  UtilityUnpresentCheque,
  UtilityCancelLevyRefundAllocation,
  UtilityCloseDiaryWorkOrder,
  UtilitySetStandardFundAccount,
  UtilityChangeManagerUtility,
  UtilityClearDebtCollection,

  ToolsBackup = 700,
  ToolsRestore,
  ToolsImport,
  ToolsExport,
  ToolsQBExport,
  ToolsArchive,
  ToolsUpgrade,
  ToolsImportFromStrataMonitor,
  ToolsExportToStrataMonitor,
  ToolsUpgradeMSDE,
  ToolsExportBASSummary,
  ToolsXMLFees,
  ToolsExportIncomeTax,

  TestingTestForm = 780,
  ReportMailMerge,
  ReportBankStatementFiling,

  QuickReports = 800,
  Labels,
  FormattedReports,

  ReportStrataLedgerTrialBalance = 900,
  ReportTrustLedgerTrialBalance,
  ReportGST,
  ReportChartOfAccounts,
  ReportStrataLedger,
  ReportBankDepositSummary,
  ReportBankDepositDetail,
  ReportOwnerLedger,
  ReportStrataBankBalances,
  ReportStrataBankDetails,
  ReportCashPayments,
  ReportStrataRoll,
  ReportFinancialPosition,
  ReportFinancialPerformance,
  ReportBudget,
  ReportAssetReplacementBudget,
  ReportLeviesProposed,
  ReportLevyPositions,
  ReportIncomeNonLevy,
  ReportExpenditure,
  ReportInsurance,
  ReportCreditors,
  ReportManagementFeesStatistics,
  ReportBankReconciliationSummary,
  ReportJournal,
  ReportAssetRegister,
  ReportTrustLedgerTransactions,
  ReportPlanReceiptList,
  ReportGeneralLedger,
  ReportInvoices,
  Report12MonthsRevenueAndExpenses,
  ReportOwnerList,
  ReportExpenditureByAccount,
  ReportTrustPayments,
  ReportTrustReceipts,
  ReportTrustLedgerAccount,
  ReportExpenses,
  ReportCashManagement,
  ReportGSTDetail,
  ReportInvestments,
  ReportLevyDue,
  ReportOutstandingMiscOwnerInvoices,
  ReportUnpresentedWithdrawals,
  ReportOutstandingAllDeposits,
  ReportExecutiveCommittees,
  ReportAbridgedFinancialPosition,
  ReportTrustLedgerJournal,
  ReportRevenue,
  ReportTrustAccountCashFlow,
  ReportLevyPosting,
  ReportQuestionableTransactions,
  ReportDiaryNotes,
  ReportAgedArrearsList,
  ReportDebtRecovery,
  ReportDebtRecoveryHistory,
  ReportMeetingAttendance,
  ReportArrears,
  ReportCurrentOwnerAccount,
  ReportManagementFee,
  ReportAnnualManagementFee,
  ReportCancelledReceiptsPayments,
  ReportAdditionalDebtorBalance,
  ReportProposedInvoicePosting,
  ReportVotingRegister,
  ReportInvoiceDue,
  ReportLotDetail,
  ReportWebAccessActivity,
  ReportFinancialPositionGroup,
  ReportFinancialPerformanceGroup,
  ReportDetailedRevenueGroup,
  ReportDetailedExpensesGroup,
  ReportCashManagementGroup,
  ReportLevyPositionsGroup,
  ReportBudgetGroup,
  ReportLeviesProposedGroup,
  ReportInvestmentReconciliationSummary,
  ReportCommitteeMeetingAttendance,
  ReportStatementOfKeyFinancialInformation,
  ReportOnlineInvoiceApprovalHistory,
  WordStatusCertificate = 1000,
}

export enum ReportType {
  Corporations = 1,
  Lots = 2,
  Registers = 3,
  Insurance = 4,
  RM = 5,
  Receipts = 6,
  Payments = 7,
  Contacts = 8,
  CreditorInvoices = 9,
  Creditors = 10,
  Meetings = 11,
}

export enum Corporations {
  OwnersCorporations = 1,
  TradesmenPreference,
  ManagementFees,
  InterimReportSettings,
  BankAccounts,
  MandatoryAudits,
  StrataHub,
}
