"use client";

import { setBreadCrumbs, setIcons } from "src/store/slice/breadCrumbSlice";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { FinancialReporting } from "src/pages/financial/financialReporting/financialReporting";
import { BankBalanceSummary } from "src/pages/financial/bankBalance/bankBalanceSummary";
import { LevyDetails } from "src/pages/financial/levyDetails/levyDetails";
import { useParams } from "react-router-dom";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { Labels } from "src/constants/labels";
import { comingSoon } from "src/constants/constant";
import { Toast } from "primereact/toast";
import ToastContainer from "src/components/ui/toast/toast";
import usePlanNumber from "src/hooks/usePlanNumber";
import { FinancialDetail } from "./financialDetails";

export default function Finance() {
  const { corpId } = useParams();
  const plaNumber = usePlanNumber(corpId ?? "");
  const dispatch = useDispatch();
  const toastRef = useRef<Toast>(null);

  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { title: "Corporations", url: "/corporations" },
        { title: `${plaNumber}`, url: `/corporations/${corpId}` },
        { title: "Financial", url: `/corporations/${corpId}/financial` },
      ])
    );

    dispatch(
      setIcons([
        { name: "Financial", route: `${corpId}/financial`, isSelected: true },
        {
          name: "Bank Account",
          //route: `${corpId}/bank-account`,
          route: "",
          isSelected: false,
        },
        {
          name: "Budget",
          route: `${corpId}/budget`,
          isSelected: false,
        },
        {
          name: "Management Fees",
          route: `${corpId}/management-fees`,
          isSelected: false,
        },
      ])
    );
  }, [plaNumber]);

  const levyDetailButtons = [
    {
      buttonName: "Edit",
      type: "normal",
      onClick: () =>
        toastRef?.current?.show({
          severity: "info",
          summary: "Info",
          detail: comingSoon,
          life: 2000,
        }),
    },
  ];

  return (
    <div className="flex flex-wrap">
      <div className="basis-full pl-8 lg:basis-1/2">
        <FinancialReporting corpId={corpId} />
        <LevyDetails corpId={corpId} />
      </div>
      <div className="basis-full lg:basis-1/2 pl-8 md:pl-0">
        <CollapsibleCard
          id="BankBalanceSummary"
          title={Labels.BANK_BALANCE_SUMMARY}
          expand={true}
        >
          <BankBalanceSummary corpId={corpId} />
        </CollapsibleCard>
        <FinancialDetail
          corpId={corpId}
          FinancialReportingButtons={levyDetailButtons}
        />
      </div>
      <ToastContainer toastReference={toastRef} />
    </div>
  );
}
