import { useMemo } from "react";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { noDataFound } from "src/constants/constant";
import { Labels } from "src/constants/labels";
import { IContactDetail } from "src/interfaces/contactDetail";

const ContactDetailTable = ({ contactList, selectedRow, setSelectedRow }: { contactList: Array<IContactDetail>, selectedRow: any, setSelectedRow: any }) => {
  const dataWithFilters = contactList.map((item: IContactDetail) => ({
    ...item,
    fulName: `${item?.name ?? ""} ${item?.firstName ?? ""}`,
    address: `${item?.buildingName} ${item?.streetNumber} ${item?.streetName} ${item?.town} ${item?.state} ${item?.postcode}`
  }));

  const columns = useMemo(() => {
    return [
      {
        field: "name",
        header: <p className="text !font-bold uppercase">{Labels.NAME}</p>,
        body: (data: any) => {
            return <p>{`${data?.name ?? ""} ${data?.firstName ?? ""}`}</p>;
        },
      },
      {
        field: "otherName",
        header: <p className="text !font-bold uppercase">{Labels.OTHER_NAME}</p>,
        body: (data: any) => {
            return <p>{data?.otherNames}</p>;
        },
      },
      {
        field: "address",
        header: <p className="text !font-bold uppercase">{Labels.ADDRESS}</p>,
        body: (data: any) => {
          return <p>{data?.buildingName} {data?.streetNumber} {data?.streetName} {data?.town} {data?.state} {data?.postcode}</p>;
        },
      },
      {
        field: "telephone",
        header: <p className="text !font-bold uppercase">{Labels.TELEPHONE}</p>,
        body: (data: any) => {
          return <p>{data?.telephone1} {data?.telephone2} {data?.telephone3}</p>;
        },
      },
      {
        field: "email",
        header: <p className="text !font-bold uppercase">{Labels.EMAIL.substring?.(0, 5)}</p>,
        body: (data: any) => {
          return <p>{data?.email}</p>;
        },
      }
    ];
  }, []);
  return (
      contactList?.length > 0 ? (
        <div className="pt-5 px-5">
          <SMDataTableContainer 
            tableData={dataWithFilters} 
            tableColumns={columns} 
            showEditIcon={false} 
            showSelectableRowCheckbox={false} 
            showPaginator={true} 
            enableGlobalSearch={true}
            globalFilterFields={["fulName", "address", "name", "firstName", "otherNames", "buildingName", "streetNumber", "streetName", "town", "state", "postcode", "email", "telephone1", "telephone2", "telephone3"]}
            selectedRow={selectedRow}
            setSelectedRow={setSelectedRow}    
          />
        </div>
      ) : (
        <div className={`w-full rounded-lg text-center shadow-lg flex-row p-10 mt-5`}>
          <p>{noDataFound}</p>
        </div>
      )
  )
}
export default ContactDetailTable;