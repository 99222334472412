import React from "react";
import styles from "../../../../../../styles/texts.module.scss";
import { Card } from "src/components/ui/card/card";

export const NoticePreview: React.FC<{ ownerContactDetail: any; contactDetail: any }> = ({ ownerContactDetail, contactDetail }) => {
  return (
    <div className="p-4">
      <div className=" px-5 py-2 h-10 items-center text-sm">
        <label className="text-base font-bold">A label will look like this:</label>
      </div>
      <div className="pt-2">
        <Card id="preview">
          <div className={`${styles.address} !px-0 !font-bold`}>
            {ownerContactDetail?.title} {ownerContactDetail?.firstName} {ownerContactDetail?.otherNames} {ownerContactDetail?.name}
          </div>
          {contactDetail?.contactId !== ownerContactDetail?.contactId && (
            <div className={`${styles.address} !px-0 !font-bold`}>
              c/- {contactDetail?.title} {contactDetail?.firstName} {contactDetail?.otherNames} {contactDetail?.name}
            </div>
          )}
          {contactDetail?.poBox && <div className={`${styles.address} !px-0 !font-bold`}>{contactDetail?.poBox}</div>}
          {!contactDetail?.poBox && contactDetail?.buildingName && <div className={`${styles.address} !px-0 !font-bold`}>{contactDetail?.buildingName}</div>}
          {!contactDetail?.poBox && contactDetail?.streetName && (
            <div className={`${styles.address} !px-0 !font-bold`}>
              {contactDetail?.streetNumber} {contactDetail?.streetName}
            </div>
          )}
          <div className={`${styles.address} !px-0 !font-bold`}>
            {contactDetail?.town} {contactDetail?.state} {contactDetail?.postcode} {contactDetail?.country}
          </div>
        </Card>
      </div>
    </div>
  );
};
