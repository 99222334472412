import React from "react";
import styles from "./error.module.scss";

export const Errors: React.FC<{ error: any; alignment?: string }> = ({
  error,
  alignment,
}) => {
  const alignmentClass =
    alignment ?
      `text-${alignment}`
      : 'text-right';

  return <div className={`${styles.error} ${alignmentClass}`}>{error}</div>;
};
