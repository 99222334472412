export interface ConfigData {
  okta: {
    clientId: string;
    issuer: string;
    authorizationEndpoint: string;
  };

  env: {
    name: string;
  };

  appInsights: {
    connectionString: string;
  };

  apiServer: {
    strataApiUrl: string;
    agencyId: string;
  };
}

export class ConfigService {
  private static instance: ConfigService;

  getInstance() {
    if (!ConfigService.instance) {
      ConfigService.instance = new ConfigService();
    }
    return ConfigService.instance;
  }

  loadConfigData = async () => {
    try {
      const response = await fetch(`/assets/config.${process.env.NODE_ENV}.json`);
      const data: ConfigData = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching config data:", error);
    }
  };
}

export default ConfigService;
