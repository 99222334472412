import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  importantContacts: [],
  buildingManager: [],
  tradesmenContacts: [],
};

const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    setImportantContacts: (state, action: PayloadAction<any>) => {
      state.importantContacts = action.payload;
    },
    addImportantContact: (state, action: PayloadAction<any>) => {
      state.importantContacts = [...state.importantContacts, action.payload];
    },
    deleteImportantContact: (state, action: PayloadAction<any>) => {
      state.importantContacts = state?.importantContacts?.filter?.((contact: any) => action?.payload !== contact?.contactId);
    },
    setBuildingManager: (state, action: PayloadAction<any>) => {
      state.buildingManager = action.payload;
    },
    updateBuildingManager: (state, action: PayloadAction<any>) => {
      let updatedBuildingManager = state?.buildingManager?.map?.((contact: any) => {
        if (contact?.contactId === action?.payload?.contactId) {
          return {
            ...contact,
            [action.payload.key]: action?.payload?.value,
          };
        } else {
          return contact;
        }
      });
      state.buildingManager = updatedBuildingManager;
    },
    setTradesmenContacts: (state, action: PayloadAction<any>) => {
      state.tradesmenContacts = action.payload;
    },
    updateTradesmenContacts: (state, action: PayloadAction<any>) => {
      let updatedTradesmenContacts = state?.tradesmenContacts?.map?.((contact: any) => {
        if (contact?.contactId === action?.payload?.contactId) {
          return {
            ...contact,
            [action.payload.key]: action?.payload?.value,
          };
        } else {
          return contact;
        }
      });
      state.tradesmenContacts = updatedTradesmenContacts;
    },
  },
});

export const {
  setImportantContacts,
  setBuildingManager,
  setTradesmenContacts,
  deleteImportantContact,
  addImportantContact,
  updateTradesmenContacts,
  updateBuildingManager,
} = contactSlice.actions;
export default contactSlice.reducer;
