import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Delete } from "src/assets/icon";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { SMInputTextArea } from "src/components/ui/inputControl/inputTextarea";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Modal } from "src/components/ui/modal/modal";
import ToastContainer from "src/components/ui/toast/toast";
import {
  cancelButtonText,
  editButtonText,
  saveButtonText,
  httpCode,
  toastSuccessTimeLimit,
  toastErrorTimeLimit,
  modalDiscardMessageText,
} from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import { ICreditors } from "src/interfaces/creditors";
import { IFinancialDebtRecovery } from "src/interfaces/financialDebtRecovery";
import { httpUpdate } from "src/services/api";
import { findCode, findValue } from "src/utils";
import styles from "../financial.module.scss";

export const DebtRecoverySummary: React.FC<{ corpId: any; debtDetail: IFinancialDebtRecovery; creditorData: ICreditors[] }> = ({
  corpId,
  debtDetail,
  creditorData,
}) => {
  const [debtRecoveryDetail, setdebtRecoveryDetail] = useState(debtDetail);
  const [isEditableDebtRecovery, setIsEditableDebtRecovery] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);
  const debtCollectorActiveValue: any =
    String(creditorData?.find((x: ICreditors) => x.creditorId === debtRecoveryDetail?.debtCollectorId)?.active) === "Y" ? true : false;
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    watch,
    formState: { isDirty },
  } = useForm<IFinancialDebtRecovery>({
    defaultValues: {
      debtCollectorActive: debtCollectorActiveValue,
      debtCollectorId: debtRecoveryDetail?.debtCollectorId ?? 0,
      debtCollectorName: debtRecoveryDetail?.debtCollectorName ?? "",
      debtCollectorCode: debtRecoveryDetail?.debtCollectorCode ?? "",
      excludeLotsDebtRecovery: debtRecoveryDetail?.excludeLotsDebtRecovery,
      paymentPlanApproved: debtRecoveryDetail?.paymentPlanApproved,
      paymentPlanConditions: debtRecoveryDetail?.paymentPlanConditions ?? "",
    },
    mode: "onTouched",
  });
  useUnsavedChangesPrompt({ unsavedChanges: isDirty });
  const paymentPlanApprovedValue = watch("paymentPlanApproved");
  const paymentPlanConditionsValue = watch("paymentPlanConditions");

  const creditorList = creditorData
    ?.filter((x: any) => x.active === "Y")
    ?.map((creditor: { creditorId: number; creditorCode: string; creditorName: string; active: boolean }) => ({
      id: creditor.creditorId,
      code: creditor.creditorCode,
      name: creditor.creditorName,
      active: creditor.active,
    }));

  const handleOnChangeName = (value: any) => {
    let creditor = creditorList?.find((x: any) => x.id === value);
    if (creditor) {
      setValue("debtCollectorCode", creditor?.code);
      setValue("debtCollectorName", creditor?.name);
    }
  };

  const handleOnChangeCode = (value: any) => {
    let creditor = creditorList?.find((x: any) => x.code === value);
    if (creditor) {
      setValue("debtCollectorName", creditor?.name);
      setValue("debtCollectorId", creditor?.id);
    }
  };

  const debtRecoveryButton = [
    {
      buttonName: isEditableDebtRecovery ? cancelButtonText : editButtonText,
      type: "normal",
      onClick: isEditableDebtRecovery ? onCancel : () => setIsEditableDebtRecovery(true),
    },
    {
      buttonName: isEditableDebtRecovery ? saveButtonText : "",
      type: isEditableDebtRecovery ? "save" : "",
      buttonType: "submit",
      formId: "financialDebtForm",
    },
  ];

  function handleOnRemove() {
    setValue("debtCollectorName", "");
    setValue("debtCollectorCode", "");
    setValue("debtCollectorId", 0, { shouldDirty: true });
  }

  function onCancel() {
    if (isDirty) {
      setShowModal(true);
    } else {
      setIsEditableDebtRecovery(false);
    }
  }

  function onDiscard() {
    reset(debtRecoveryDetail);
    setShowModal(false);
    setIsEditableDebtRecovery(false);
  }

  async function onSubmit(data: IFinancialDebtRecovery) {
    setIsLoading(true);
    const output = await httpUpdate(EndPoints.corporation + corpId + EndPoints.financial + "/debt-recovery", data);
    if (output?.status === httpCode.SUCCESS) {
      setdebtRecoveryDetail(data);
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: toastSuccessTimeLimit,
      });
      reset({}, { keepDirty: false, keepValues: true });
      setIsEditableDebtRecovery(false);
    }
    if (output?.error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Error: ${output?.error}`,
        life: toastErrorTimeLimit,
      });
    }
    setIsLoading(false);
  }
  return (
    <>
      <CollapsibleCard id="DebtRecovery" title={Labels.DEBT_RECOVERY} expand={true} buttons={debtRecoveryButton}>
        <div className="pt-4">
          {isLoading && (
            <div className="spinnerContainer">
              <div className="w-20 h-20">
                <SpinnerHorizontal />
              </div>
            </div>
          )}
          <form id="financialDebtForm" onSubmit={handleSubmit(onSubmit)} noValidate>
            <div data-testid="miscSummaryTab" className="basis-full text-sm mt-4">
              <div className={styles.section}>
                <SMCheckbox
                  name="excludeLotsDebtRecovery"
                  control={control}
                  label={Labels.EXCLUDE_LOTS_FROM_DEBT_RECOVERY}
                  disabled={!isEditableDebtRecovery}
                />
              </div>
              <div className="flex justify-between h-10 mt-2">
                <label className="flex basis-2/5 sm:py-2 text-left font-normal items-center">{Labels.DEBT_COLLECTOR_}</label>
                {isEditableDebtRecovery ? (
                  <div className="flex basis-4/5 gap-1">
                    <span className="basis-1/3">
                      <SMDropDown
                        filter={true}
                        id="debtCollectorCode"
                        control={control}
                        name="debtCollectorCode"
                        options={creditorList}
                        optionLabel="code"
                        optionValue="code"
                        value={findCode(creditorList, debtCollectorActiveValue ? debtRecoveryDetail?.debtCollectorId : 0)}
                        disabled={!isEditableDebtRecovery}
                        onChange={(e: any) => handleOnChangeCode(e)}
                      />
                    </span>
                    <SMDropDown
                      filter={true}
                      id="debtCollectorId"
                      control={control}
                      name="debtCollectorId"
                      options={creditorList}
                      optionLabel="name"
                      optionValue="id"
                      value={findValue(creditorList, debtCollectorActiveValue ? debtRecoveryDetail?.debtCollectorId : 0)}
                      disabled={!isEditableDebtRecovery}
                      onChange={(e: any) => handleOnChangeName(e)}
                    />
                    <div className="flex items-center" onClick={handleOnRemove}>
                      <Delete />
                    </div>
                  </div>
                ) : (
                  <div className="flex basis-4/5 gap-1 items-center">
                    <span className="basis-1/3">{debtCollectorActiveValue ? debtRecoveryDetail?.debtCollectorCode : ""}</span>
                    <span className="basis-1/2">{debtCollectorActiveValue ? debtRecoveryDetail?.debtCollectorName : ""}</span>
                  </div>
                )}
              </div>
              <div className={styles.section}>
                <SMCheckbox name="paymentPlanApproved" control={control} label={Labels.PAYMENT_PLAN_APPROVED} disabled={!isEditableDebtRecovery} />
              </div>
              {paymentPlanApprovedValue && (
                <div className={`${styles.section} !h-auto`}>
                  <span className="mb-2 basis-full text-left" title={paymentPlanConditionsValue}>
                    <SMInputTextArea
                      name="paymentPlanConditions"
                      control={control}
                      disabled={!isEditableDebtRecovery}
                      placeholder={Labels.PAYMENT_PLAN_CONDITIONS}
                      maxlength={500}
                      autoResize={true}
                    />
                  </span>
                </div>
              )}
            </div>
          </form>
        </div>
      </CollapsibleCard>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Warning" onAction={() => onDiscard()}>
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
      <ToastContainer toastReference={toastRef} />
    </>
  );
};
