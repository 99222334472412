import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Labels } from "src/constants/labels";
import { Routes } from "src/constants/pageRoutes";
import { setBreadCrumbs, setIcons } from "src/store/slice/breadCrumbSlice";
import QuickReportsTabs from "./tabs";

const QuickReports = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setBreadCrumbs([{ title: Labels.QUICK_REPORTS, url: Routes.quickReports }]));
    dispatch(
      setIcons([
        { icon: "documentExcel", route: "/" },
        { icon: "print", route: "/" },
        { icon: "documentSearch", route: "/" },
        { icon: "binocular", route: "/" },
        { icon: "binocularPlus", route: "/" },
        { icon: "link", route: "/" },
        { icon: "stopCircle", route: "/" },
        { icon: "list", route: "/" },
      ])
    );
  }, []);

  return <QuickReportsTabs />;
};

export default QuickReports;
