import { useForm } from "react-hook-form";
import OwnerCorpDetails from "../../shared/ownerCorpDetails";
import Managed from "../../shared/managed";
import OutputOptions from "./outputOptions";
import RelevantDates from "./relevantDates";
import ReportTable from "../../shared/reportTable";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setTableColumns } from "src/store/slice/quickReports";
import { Labels } from "src/constants/labels";
import { Corporations } from "src/constants";
import ManagementFees from "./managementFees";
import { IUser } from "src/interfaces/users";

const CorporationsReports = ({ data }: { data: Array<IUser> }) => {
  const dispatch = useDispatch();
  const { control, watch } = useForm({
    defaultValues: {
      outputOptionValue: 1,
      planNumber: "",
      manager: "",
      streetNumber: "",
      street: "",
      town: "",
      postcode: "",
      managed: true,
    },
  });

  const outputOptionValue = watch("outputOptionValue");

  useEffect(() => {
    dispatch(
      setTableColumns([
        {
          field: "",
          header: <p className="text !font-bold">{Labels.lOwnersCorpId}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.PLANNO}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.BODY_CORPORATE_NAME}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.ADDRESS_FOR_SORTING}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.SUBURB_FOR_SORTING}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.TAX_YEAR_END}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.FINANCIAL_YEAR_END}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.LAST_LEVY_NOTICE_PRINTED}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.LAST_LEVY_NOTICE_EMAILED}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.ASSOCIATION_TYPE}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.JURISDICTION}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.DATE_BUILT}</p>,
        },
        {
          field: "",
          header: (
            <p className="text !font-bold">
              {Labels.DATE} {Labels.REGISTERED}
            </p>
          ),
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.INITIAL_PERIOD}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.ADDRESS}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.LOTS}</p>,
        },
        {
          field: "",
          header: (
            <p className="text !font-bold">
              {Labels.PRIMARY} {Labels.LOTS}
            </p>
          ),
        },
        {
          field: "",
          header: (
            <p className="text !font-bold">
              {Labels.UTILITY} {Labels.LOTS}
            </p>
          ),
        },
        {
          field: "",
          header: (
            <p className="text !font-bold">
              {Labels.RESIDENTIAL} {Labels.LOTS}
            </p>
          ),
        },
        {
          field: "",
          header: (
            <p className="text !font-bold">
              {Labels.COMMERCIAL} {Labels.LOTS}
            </p>
          ),
        },
        {
          field: "",
          header: (
            <p className="text !font-bold">
              {Labels.RETIREMENT} {Labels.LOTS}
            </p>
          ),
        },
        {
          field: "",
          header: (
            <p className="text !font-bold">
              {Labels.OTHER} {Labels.LOTS}
            </p>
          ),
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.MANAGER}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.ABN}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.TFN}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.GST_STATUS}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.GST_FREQUENCY}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.INTERIM_REPORTING_PERIOD}</p>,
        },
        {
          field: "",
          header: <p className="text !font-bold">{Labels.FIRST_AGM}</p>,
        },
      ])
    );
  }, []);

  return (
    <div className="w-full flex flex-col gap-4">
      <div className="w-full flex">
        <div className="w-full">
          <OwnerCorpDetails control={control} managerList={data} />
        </div>
        <div className="w-full">
          <Managed control={control} />
          <OutputOptions control={control} />
        </div>
        <div className="w-full">
          {outputOptionValue === Corporations.OwnersCorporations && <RelevantDates control={control} />}
          {outputOptionValue === Corporations.ManagementFees && <ManagementFees control={control} />}
        </div>
      </div>
      <div>
        <ReportTable />
      </div>
    </div>
  );
};

export default CorporationsReports;
