"use client";

import { useHttpGet } from "src/services/api";
import Mortgage from "./mortgage";
import { EndPoints } from "src/constants/endpoint";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Errors } from "src/components/shared/error/error";
import { errorMessage } from "src/constants/constant";

export default function MortgageTab({ corpId, lotId }: { corpId: string; lotId: string }) {
  const { data: mortgageDetails, error: mortgageDetailsError } = useHttpGet(EndPoints.corporation + corpId + "/lots/" + lotId + "/mortgage");

  if (!mortgageDetails) {
    return (
      <div className="flex w-full min-h-full items-center justify-center">
        <div className="w-20 h-20">
          <SpinnerHorizontal />
        </div>
      </div>
    );
  }

  if (mortgageDetailsError) {
    return <Errors error={errorMessage} alignment="left" />;
  }

  return <Mortgage mortgageDetails={mortgageDetails} corpId={corpId} />;
}
