import React, { useMemo, useState } from "react";
import { Pencil } from "src/assets/icon";
import FormModal from "src/components/ui/dialog/formModal";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { Labels } from "src/constants/labels";
import { IMortgage } from "src/interfaces/lotOwner";
import { FormatDate } from "src/utils/utility";
import MortgageTableForm from "./mortgageTableForm";

export const MortgageTable: React.FC<{ corpId: string; mortgageTableData: Array<IMortgage>; setMortgageTableData: any; toastRef: any; }> = ({
  corpId,
  mortgageTableData,
  setMortgageTableData,
  toastRef
}) => {
  const [mortgageRowDetails, setMortgageRowDetails] = useState<any>({});
  const [rowIndex, setRowIndex] = useState(0);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  let formId = "mortgageUpdateForm";

  const columns = useMemo(() => {
    return [
      {
        field: "dateofEntry",
        header: <p className="text !font-bold">{Labels.DATE_OF_ENTRY}</p>,
        body: (data: any) => {
          return <p>{FormatDate(data?.dateofEntry)}</p>;
        },
      },
      {
        field: "mortgagee",
        header: <p className="text !font-bold">{Labels.MORTGAGEE}</p>,
      },
      {
        field: "mortgageeType",
        header: <p className="text !font-bold">{Labels.CAPACITY}</p>,
      },
      {
        field: "noticeAddress",
        header: <p className="text !font-bold">{Labels.NOTICE_ADDRESS}</p>,
      },
      {
        field: "priority",
        header: <p className="text !font-bold">{Labels.PRIORITY}</p>,
      },
      {
        field: "dischargeDate",
        header: <p className="text !font-bold">{Labels.DISCHARGE_DATE}</p>,
        body: (data: any) => {
          return <p>{FormatDate(data?.dischargeDate)}</p>;
        },
      },
      {
        field: "edit",
        header: "",
        body: (data: any, options: any) => {
          return (
            <span
              className="cursor-pointer"
              onClick={() => {
                setRowIndex(options?.rowIndex);
                setMortgageRowDetails(data);
                setShowModal(true);
              }}
            >
              <Pencil />
            </span>
          );
        },
      },
    ];
  }, []);

  const handleChange = (rowIndex: number, mortgageRow: IMortgage) => {
    let newMortgageTableData = mortgageTableData?.map?.((mortgage, mortgageRowIndex) => {
      if (mortgageRowIndex === rowIndex) {
        return {
          ...mortgage,
          ...mortgageRow,
        };
      } else {
        return {
          ...mortgage,
        };
      }
    });
    setMortgageTableData(newMortgageTableData);
  };

  return (
    <>
      <SMDataTableContainer tableData={mortgageTableData} tableColumns={columns} showEditIcon={false} showSelectableRowCheckbox={false} />
      <FormModal
        header="Mortgage Update"
        formId={formId}
        isOpen={showModal}
        onClose={() => {
          if (isFormDirty) {
            setShowCancelModal(true);
          } else {
            setShowModal(false);
          }
        }}
        isDirty={isFormDirty}
      >
        <MortgageTableForm
          corpId={corpId}
          formData={mortgageRowDetails}
          formId={formId}
          showCancelModal={showCancelModal}
          rowIndex={rowIndex}
          handleChange={handleChange}
          setShowCancelModal={setShowCancelModal}
          setShowModal={setShowModal}
          setIsFormDirty={setIsFormDirty}
          toastRef={toastRef}
        />
      </FormModal>
    </>
  );
};
