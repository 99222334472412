'use client'
interface ICheckboxProps {
    id: string | number;
    label?: string;
    selected?: boolean;
    disabled?: boolean;
}

export const Checkbox: React.FC<ICheckboxProps> = ({id, label, selected, disabled}) => {
    
    const elementId : string = id.toString();

    return (
        label ?
        <>
            <label htmlFor={`${id}`} className='flex items-center gap-2 text-sm whitespace-nowrap'>
                <input id={`${id}`} 
                        type="checkbox" 
                        data-testid={elementId}
                        className="w-4 h-4 pr-1 rounded"
                        onChange={() => {}}
                        checked={selected}
                        disabled={disabled}/>
            <span className='pb-px'>{label}</span></label>
        </>
        :
        <input id={`${id}`} 
         type="checkbox" 
         data-testid={elementId}
         className="w-4 h-4 rounded"
         onChange={() => {}}
         checked={selected}
         disabled={disabled}
        />
    )
        
    
}