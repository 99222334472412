import React from "react";
import { useHttpGet } from "src/services/api";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { EndPoints } from "src/constants/endpoint";
import { errorMessage, httpCode } from "src/constants/constant";
import BankBalanceDetail from "./bankBalanceDetail";

export const BankBalanceSummary: React.FC<{ corpId: any }> = ({ corpId }) => {
  const { data, error, statusCode } = useHttpGet(EndPoints.corporation + `${corpId}/balance-summary`);

  if (error) {
    return (
      <>
        <div className="text-red-500 m-4">{errorMessage}</div>
      </>
    );
  }

  if (statusCode === httpCode.NOT_FOUND || statusCode === httpCode.NO_CONTENT || statusCode === httpCode.INTERNAL_SERVER_ERROR) {
    return <BankBalanceDetail data={[]} />;
  }

  if (!data) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-10 h-10">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }

  return <BankBalanceDetail data={data} />;
};
