import { Toast } from "primereact/toast";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Line, Pencil, Plus, ViewColumn } from "src/assets/icon";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { Drawer } from "src/components/ui/drawer/drawer";
import { IconYesNoNull } from "src/components/ui/iconYesNoNull/iconYesNoNull";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import ToastContainer from "src/components/ui/toast/toast";
import { comingSoon } from "src/constants/constant";
import { Labels } from "src/constants/labels";
import { IUnitEntitlementSet } from "src/interfaces/unitentitlementset";
import { RootState } from "src/store/rootReducer";
import { setEntitlementSetList, setSelectedEntitlementSet } from "src/store/slice/entitlementSlice";
import { toLocaleValue } from "src/utils/utility";
import UnitEntitlementSetTableDrawer from "./unitEntitlementSetTableDrawer";

const UnitEntitlementSetTable = ({
  unitEntitlementSetData,
  setHeaderEntitlement,
}: {
  unitEntitlementSetData: Array<IUnitEntitlementSet>;
  setHeaderEntitlement: any;
}) => {
  const { entitlementSetList } = useSelector((state: RootState) => state?.entitlementSlice);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);
  const dispatch = useDispatch();

  const unitEntitlementSetButtons = [
    {
      buttonName: <Plus height={24} width={24} />,
      type: "word",
      onClick: () => showComingSoon(),
    },
    { buttonName: <Line height={32} />, type: "line" },
    {
      buttonName: <ViewColumn />,
      onClick: () => showComingSoon(),
    },
  ];
  const columns = useMemo(() => {
    return [
      {
        field: "unitEntitlementName",
        header: <p className="text !font-bold">{Labels.NAME}</p>,
      },
      {
        field: "totalEntitlements",
        header: (
          <p className="text !font-bold uppercase">
            {Labels.TOTAL} {Labels.ENTITLEMENTS}
          </p>
        ),
        body: (data: any) => {
          return <p>{toLocaleValue(data?.totalEntitlements)}</p>;
        },
      },
      {
        field: "entitlementName",
        header: <p className="text !font-bold">ENTITLEMENT TYPE</p>,
      },
      {
        field: "description",
        header: <p className="text !font-bold uppercase">{Labels.DESCRIPTION}</p>,
        width: '15rem'
      },
      {
        field: "showOnPortals",
        header: <p className="text !font-bold uppercase">{Labels.SHOW_ON_PORTALS}</p>,
        body: (data: any) => {
          return <IconYesNoNull type={data?.showOnPortals} />;
        },
      },
      {
        field: "edit",
        header: "",
        body: (data: any) => {
          return (
            <span
              onClick={() => {
                setShowSidebar(true);
                dispatch(setSelectedEntitlementSet(data));
              }}
            >
              <Pencil />
            </span>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    if (unitEntitlementSetData?.length > 0) {
      setHeaderEntitlement(unitEntitlementSetData);
    }
    dispatch(setEntitlementSetList(unitEntitlementSetData));
  }, []);

  const showComingSoon = () => {
    toastRef?.current?.show({
      severity: "info",
      summary: "Info",
      detail: comingSoon,
      life: 2000,
    });
  };

  return (
    <CollapsibleCard id="unitEntitlementSetTable" title={Labels.UNIT_ENTITLEMENT_SETS} expand={true} isCollapsible={false} buttons={unitEntitlementSetButtons}>
      <div className="pt-4">
        <SMDataTableContainer tableData={entitlementSetList} tableColumns={columns} showEditIcon={false} showSelectableRowCheckbox={false} />
      </div>
      
      <Drawer
            title={Labels.EDIT_UNIT_ENTITLEMENT_SET_DETAILS}
            isOpen={showSidebar}
            onClose={() => {
              setShowSidebar(false);
            }}
            width="w-[50vw]"
          >
            <UnitEntitlementSetTableDrawer toastRef={toastRef} setShowSidebar={setShowSidebar} />
      </Drawer>
      <ToastContainer toastReference={toastRef} />
    </CollapsibleCard>
  );
};

export default UnitEntitlementSetTable;
