"use client";
import { useEffect } from "react";
import { PlanDetails } from "src/pages/plan/planDetails/planDetails";
import { useDispatch } from "react-redux";
import { setBreadCrumbs, setIcons } from "src/store/slice/breadCrumbSlice";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";
import { useParams } from "react-router-dom";

export default function PlanDetail() {
  const { corpId } = useParams();
  const dispatch = useDispatch();
  const { plaNumber } = useSelector((state: RootState) => state.planDetail);
  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { title: "Corporations", url: "/corporations" },
        { title: `${plaNumber}`, url: `/corporations/${corpId}` },
        { title: "Plan Details", url: `/corporations/${corpId}/plan` },
      ])
    );
    dispatch(
      setIcons([
        { name: "Plan Details", route: `${corpId}/plan`, isSelected: true },
        { 
          name: "Insurance", 
          route: `${corpId}/insurance`, 
          isSelected: false 
        },
        { name: "Structure", route: `${corpId}/structure`, isSelected: false },
      ])
    );
  }, [plaNumber]);

  return (
    <>
      <PlanDetails corpId={corpId} />
    </>
  );
}
