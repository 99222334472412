import { InputTextarea } from "primereact/inputtextarea";
import { KeyFilterType } from "primereact/keyfilter";
import { classNames } from "primereact/utils";
import React from "react";
import { Controller } from "react-hook-form";

interface ITextArea {
  name?: string;
  value?: any;
  disabled?: boolean;
  control?: any;
  rules?: object;
  maxlength?: number | undefined;
  keyfilter?: KeyFilterType;
  autoComplete?: string | undefined;
  placeholder?: string;
  autoResize?: boolean;
  rows?: number;
  cols?: number;
}

export const SMInputTextArea: React.FC<ITextArea> = ({
  name,
  value,
  disabled = false,
  control,
  rules,
  maxlength = undefined,
  keyfilter,
  autoComplete,
  placeholder,
  rows,
  cols,
  autoResize,
}) => {
  return (
    <Controller
      name={name ?? ""}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <InputTextarea
          id={field.name}
          ref={field.ref}
          onBlur={field.onBlur}
          value={field?.value || value}
          placeholder={placeholder}
          onChange={(e) => {
            const inputValue = e.target.value;
            const isWhitespace = /^\s*$/.test(inputValue);

            if (!isWhitespace || inputValue === "") {
              field.onChange(inputValue);
            }
          }}
          className={classNames({ "p-invalid": fieldState.error })}
          pt={{
            root: { className: "w-full !rounded !basis-full !font-semibold  !mt-0 h-10 !text-sm !outline-none" },
          }}
          disabled={disabled}
          maxLength={maxlength}
          keyfilter={keyfilter}
          autoComplete={autoComplete}
          rows={rows}
          cols={cols}
          autoResize={autoResize}
        />
      )}
    />
  );
};
