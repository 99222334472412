import React, { useMemo } from "react";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { Labels } from "src/constants/labels";
import { FormatDate } from "src/utils/utility";

export const DebtRecoveryTable: React.FC<{ debtRecoveryDetails: any; debtRecoveryStatus: string }> = ({ debtRecoveryDetails, debtRecoveryStatus }) => {
  let debtRecoveryTableData = debtRecoveryDetails?.sort?.((a: any, b: any) => b?.actionDate?.localeCompare?.(a?.actionDate));
  const columns = useMemo(() => {
    return [
      {
        field: "actionDate",
        header: <p className="text !font-bold">{Labels.ACTION_DATE}</p>,
        body: (data: any) => {
          return <p>{FormatDate(data?.actionDate)}</p>;
        },
      },
      {
        field: "debtRecoveryStage",
        header: <p className="text !font-bold">{Labels.DEBT_RECOVERY_STAGE}</p>,
        body: (data: any) => {
          return <p>{data?.debtRecoveryStage}</p>;
        },
      },
      {
        field: "debtCollectorName",
        header: <p className="text !font-bold">{Labels.DEBT_COLLECTOR}</p>,
      },
      {
        field: "charge",
        header: <p className="text !font-bold">{Labels.POSTED_CHARGE}</p>,
        body: (data: any) => {
          return <p>{`$${data?.charge?.toFixed?.(2)}`}</p>;
        },
      },
      {
        field: "pendingCharge",
        header: <p className="text !font-bold">{Labels.PENDING_CHARGE}</p>,
        body: (data: any) => {
          return <p>{`$${data?.pendingCharge?.toFixed?.(2)}`}</p>;
        },
      },
      {
        field: "waivedCharge",
        header: <p className="text !font-bold">{Labels.WAIVED_CHARGE}</p>,
        body: (data: any) => {
          return <p>{`$${data?.waivedCharge?.toFixed?.(2)}`}</p>;
        },
      },
      {
        field: "clearedDate",
        header: <p className="text !font-bold">{Labels.DATE_CLEARED}</p>,
        body: (data: any) => {
          return <p>{FormatDate(data?.clearedDate)}</p>;
        },
      },
      {
        field: "amountOwingInNotice",
        header: <p className="text !font-bold">{Labels.AMOUNT_OWNING_IN_NOTICE}</p>,
        body: (data: any) => {
          return <p>{`$${data?.amountOwingInNotice?.toFixed?.(2)}`}</p>;
        },
      },
      {
        field: "interestIncluded",
        header: <p className="text !font-bold">{Labels.INTEREST_INCLUDE}</p>,
        body: (data: any) => {
          return <p>{`$${data?.interestIncluded?.toFixed?.(2)}`}</p>;
        },
      },
      {
        field: "interestCalculatedTo",
        header: <p className="text !font-bold">{Labels.INTEREST_CALCULATED_TO}</p>,
        body: (data: any) => {
          return <p>{FormatDate(data?.interestCalculatedTo)}</p>;
        },
      },
      {
        field: "paymentPlan",
        header: <p className="text !font-bold">{Labels.PAYMENT_PLAN}</p>,
        body: (data: any) => {
          return <input className="rounded" type="checkbox" checked={data?.paymentPlan} disabled />;
        },
      },
      {
        field: "notify",
        header: <p className="text !font-bold">{Labels.NOTIFY}</p>,
        body: (data: any) => {
          return <input className="rounded" type="checkbox" checked={data?.notify} disabled />;
        },
      },
    ];
  }, []);

  return (
    <SMDataTableContainer
      tableData={debtRecoveryTableData}
      tableColumns={columns}
      showEditIcon={false}
      showSelectableRowCheckbox={false}
      showPaginator={false}
    />
  );
};
