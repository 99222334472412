import React, { useState } from "react";
import styles from "./leviesDetail.module.scss";
import { Labels } from "src/constants/labels";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { useForm } from "react-hook-form";
import { Messages } from "src/constants/messages";
import { EndPoints } from "src/constants/endpoint";
import { httpUpdate } from "src/services/api";
import { ILeviesDetail } from "src/interfaces/leviesDetail";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Modal } from "src/components/ui/modal/modal";
import { modalDiscardMessageText } from "src/constants/constant";
import { Errors } from "src/components/shared/error/error";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import { isNotBlank } from "src/utils";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";

export const LeviesDetailsSection: React.FC<{
  levyDetails: any;
  bankDetails: any;
  isEditableLeviesDetail: any;
  setIsEditableLeviesDetail: any;
  setLevyDetail: any;
  corpId: string;
  lotId: string;
  toastRef: any;
}> = ({
  levyDetails,
  bankDetails,
  isEditableLeviesDetail,
  setIsEditableLeviesDetail,
  setLevyDetail,
  corpId,
  lotId,
  toastRef
}) => {
  const bankList = bankDetails?.map(
    (bank: { bankId: number; code: string; name: string }) => ({
      id: bank.bankId,
      code: bank.code,
      name: bank.name,
    })
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm<ILeviesDetail>({
    defaultValues: {
      levyContactId: levyDetails.levyContactId,
      lotRefNumber: levyDetails.lotRefNumber ?? "",
      lotPlanNumber: levyDetails.lotPlanNumber ?? "",
      chequeDrawer: levyDetails.chequeDrawer ?? "",
      chequeBranch: levyDetails.chequeBranch ?? "",
      chequeBank: levyDetails.chequeBank ?? "",
      chequeBSB: levyDetails.chequeBSB ?? "",
      receiptMessage: levyDetails.receiptMessage ?? "",
      screenMessage: levyDetails.screenMessage ?? "",
      rejectLevyReceipts: levyDetails.rejectLevyReceipts,

      useOwnersLevyEmail: levyDetails.useOwnersLevyEmail,
      useAgentsLevyEmail: levyDetails.useAgentsLevyEmail,
      useTenantsLevyEmail: levyDetails.useTenantsLevyEmail,
      levyIssue: levyDetails.levyIssue,
      billerCode: levyDetails.billerCode ?? "",
      crn: levyDetails.crn ?? "",
    },
    mode: "onTouched",
  });

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  function onDiscard() {
    setIsEditableLeviesDetail(false);
    setShowModal(false);
  }

  const setData = (data: ILeviesDetail) => {
    setLevyDetail(data);
  };

  async function onSubmit(data: ILeviesDetail) {
    setIsLoading(true);
    const output = await httpUpdate(
      EndPoints.corporation + corpId + "/lot/" + lotId + "/levy",
      data
    );
    if (output?.status === 200) {
      setData(data);
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: 2000,
      });
      setIsEditableLeviesDetail(false);
    }
    if (output?.error) {
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `error:${output?.error}`,
        life: 3000,
      });
    }
  }
  return (
    <div className="pt-4">
      {isLoading && (
        <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-auto overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <form
        id="leviesDetailForm"
        key="isEditableLeviesDetail"
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className={styles.header}>
          <label
            className={`${styles.label} ${
              isEditableLeviesDetail ? "mr-2" : ""
            }`}
          >
            {Labels.LOT_REFERENCE_NUMBER}
          </label>
          {isEditableLeviesDetail ? (
            <SMInputText
              name="lotRefNumber"
              control={control}
              maxlength={10}
              keyfilter="int"
              rules={{
                required: Messages.REQUIRED_MESSAGE,
                validate: isNotBlank,
              }}
            />
          ) : (
            <span className={`${styles.value}`}>
              {levyDetails?.lotRefNumber}
            </span>
          )}
        </div>
        {errors.lotRefNumber && (
          <Errors
            error={errors.lotRefNumber.message || Messages.REQUIRED_MESSAGE}
            alignment="right"
          />
        )}
        <div className={`${styles.header} mt-2`}>
          <label
            className={`${styles.label} ${
              isEditableLeviesDetail ? "mr-2" : ""
            }`}
          >
            {Labels.LOT_PLAN_NUMBER}
          </label>
          {isEditableLeviesDetail ? (
            <SMInputText
              name="lotPlanNumber"
              control={control}
              maxlength={10}
            />
          ) : (
            <span className={styles.value}>{levyDetails?.lotPlanNumber}</span>
          )}
        </div>
        <div className={`${styles.header} mt-2`}>
          <label
            className={`${styles.label} ${
              isEditableLeviesDetail ? "mr-2" : ""
            }`}
          >
            {Labels.CHEQUE_DRAWER}
          </label>
          {isEditableLeviesDetail ? (
            <SMInputText name="chequeDrawer" control={control} maxlength={50} />
          ) : (
            <span className={styles.value}>{levyDetails?.chequeDrawer}</span>
          )}
        </div>
        <div className={`${styles.header} mt-2`}>
          <label
            className={`${styles.label} ${
              isEditableLeviesDetail ? "mr-2" : ""
            }`}
          >
            {Labels.BRANCH}
          </label>
          {isEditableLeviesDetail ? (
            <SMInputText name="chequeBranch" control={control} maxlength={50} />
          ) : (
            <span className={styles.value}>{levyDetails?.chequeBranch}</span>
          )}
        </div>
        <div className={`${styles.header} mt-2`}>
          <label
            className={`${styles.label} ${
              isEditableLeviesDetail ? "!basis-1/2" : ""
            }`}
          >
            {Labels.BANK}
          </label>
          {isEditableLeviesDetail ? (
            <div className="flex basis-1/2">
              <SMDropDown
                control={control}
                name="chequeBank"
                placeholder={"Select Bank"}
                options={bankList}
                editable={true}
                maxLength={50}
              />
            </div>
          ) : (
            <span className={styles.value}>{levyDetails?.chequeBank}</span>
          )}
        </div>
        <div className={`${styles.header} mt-2`}>
          <label
            className={`${styles.label} ${
              isEditableLeviesDetail ? "mr-2" : ""
            }`}
          >
            {Labels.BSB_NUMBER}
          </label>
          {isEditableLeviesDetail ? (
            <SMInputText
              name="chequeBSB"
              control={control}
              maxlength={7}
              rules={{
                pattern: {
                  value: /^[0-9-]*$/,
                  message: "Only digits (0-9) and dashes are allowed.",
                },
              }}
            />
          ) : (
            <span className={styles.value}>{levyDetails?.chequeBSB}</span>
          )}
        </div>
        {errors.chequeBSB && (
          <Errors error={errors.chequeBSB.message} alignment="right" />
        )}
        <div className={`${styles.header} mt-2`}>
          <label
            className={`${styles.label} ${
              isEditableLeviesDetail ? "mr-2" : ""
            }`}
          >
            {Labels.PRINTED_RECEIPT_MESSAGE}
          </label>
          {isEditableLeviesDetail ? (
            <SMInputText
              name="receiptMessage"
              control={control}
              maxlength={50}
            />
          ) : (
            <span className={styles.value}>{levyDetails?.receiptMessage}</span>
          )}
        </div>
        <div className={`${styles.header} mt-2`}>
          <label
            className={`${styles.label} ${
              isEditableLeviesDetail ? "mr-2" : ""
            }`}
          >
            {Labels.SCREENING_RECEIPTING_MESSAGE}
          </label>
          {isEditableLeviesDetail ? (
            <SMInputText
              name="screenMessage"
              control={control}
              maxlength={50}
            />
          ) : (
            <span className={styles.value}>{levyDetails?.screenMessage}</span>
          )}
        </div>
        <div className={`flex justify-between h-10 items-center mt-2`}>
          <SMCheckbox
            name="rejectLevyReceipts"
            control={control}
            label={Labels.REJECT_LEVY_RECEIPTS}
            disabled={!isEditableLeviesDetail}
          />
        </div>
      </form>
      <div className={`${styles.header} mt-2`}>
        <label className={styles.label}>{Labels.BILLER_CODE}</label>
        <span className={styles.value}>{levyDetails?.billerCode}</span>
      </div>
      <div className={`${styles.header} mt-2`}>
        <label className={styles.label}>{Labels.CRN}</label>
        <span className={styles.value}>{levyDetails?.crn}</span>
      </div>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Warning"
        onAction={() => onDiscard()}
      >
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
    </div>
  );
};
