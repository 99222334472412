import { Labels } from "src/constants/labels";
import styles from "../agent.module.scss";
import { useRef, useState } from "react";
import FormModal from "src/components/ui/dialog/formModal";
import { Toast } from "primereact/toast";
import ToastContainer from "src/components/ui/toast/toast";
import AdditionalContactDetails from "src/components/shared/contact/additionalContactDetails/additionalContactDetails";
import ContactForm from "../../../shared/contact";

const TenantContact = ({
  lotDetails,
  showTenantContactModal,
  tenantContact,
  tenantAdditionalContact,
  setShowTenantContactModal,
  setTenantContact,
  setTenantAdditionalContact
}: {
  lotDetails: any;
  showTenantContactModal: boolean;
  tenantContact: any;
  tenantAdditionalContact: any;
  setShowTenantContactModal: any;
  setTenantContact: any;
  setTenantAdditionalContact: any
}) => {
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);
  let formId = "lotTenantContactUpdate";

  function setData(data: any){
    setTenantContact(data);
  }
  function setAdditionalContactData(data: any){
    setTenantAdditionalContact(data);
  }

  return (
    tenantContact?.contactId && (
      <>
        <div className="mt-5 mb-4">
          <label className={`text !font-bold ${styles.textStyle}`}>
            {tenantContact?.businessContact
              ? Labels.BUSINESS_CONTACT
              : Labels.PRIVATE_RESIDENTIAL_CONTACT}
          </label>
        </div>
        <div
          className={`pt-4 pb-2 flex flex-col items-start self-stretch ${styles.borderTop}`}
        >
          {
              tenantContact?.businessContact && tenantContact?.otherNames &&
              <div className={`text !h-auto ${styles.textContainer}`}>
              <p className={`text ${styles.textStyle}`}>
                {Labels.CONTACT_PERSON}
              </p>
              <p className="ml-1">
                {tenantContact?.otherNames}{" "}
                {tenantContact?.salutation &&
                  `(${Labels.SALUTATION}
                  ${tenantContact?.salutation})`}
              </p>
            </div> 
          }
          {
                !tenantContact?.businessContact && tenantContact?.salutation && 
                    <div data-test-id='salutation' className={`text !h-auto ${styles.textContainer}`}>
                      <p data-test-id='lblSalution' className={`text ${styles.textStyle}`}>{Labels.SALUTATION}</p>
                      <p className="ml-1">{tenantContact?.salutation}</p>
                    </div>
          }
          <div className={`text !h-auto ${styles.textContainer}`}>
            {tenantContact?.businessContact ? (
              <p>{tenantContact?.name}</p>
            ) : (
              <p>
                {tenantContact?.title} {tenantContact?.firstName}{" "}
                {tenantContact?.otherNames} {tenantContact?.name}
              </p>
            )}
          </div>
          {tenantContact?.poBox && (
            <div className={`text !h-auto ${styles.textContainer}`}>
              <p>{tenantContact?.poBox}</p>
            </div>
          )}
          {!tenantContact?.poBox && tenantContact?.buildingName && (
            <div className={`text !h-auto ${styles.textContainer}`}>
              <p>{tenantContact?.buildingName}</p>
            </div>
          )}
          {!tenantContact?.poBox && (
            <div className={`text !h-auto ${styles.textContainer}`}>
              <p>
                {tenantContact?.streetNumber} {tenantContact?.streetName}
              </p>
            </div>
          )}
          <div className={`text !h-auto ${styles.textContainer}`}>
            <p>
              {tenantContact?.town} {tenantContact?.state}{" "}
              {tenantContact?.postcode} {tenantContact?.country}
            </p>
          </div>
        </div>
        <div
          className={`pt-4 flex flex-col items-start self-stretch ${styles.borderTop}`}
        >
          {tenantContact?.telephone1 && (
            <div className={`text ${styles.textContainer}`}>
              <p className={`text ${styles.textStyle}`}>
                {tenantContact?.businessContact
                  ? Labels.TELEPHONE_1
                  : Labels.HOME_PHONE}
              </p>
              <a
                href={`tel:${tenantContact?.telephone1}`}
                className="ml-1 underline"
              >
                {tenantContact?.telephone1}
              </a>
            </div>
          )}
          {tenantContact?.telephone2 && (
            <div className={`text ${styles.textContainer}`}>
              <p className={`text ${styles.textStyle}`}>
                {tenantContact?.businessContact
                  ? Labels.TELEPHONE_2
                  : Labels.WORK_PHONE}
              </p>
              <a
                href={`tel:${tenantContact?.telephone2}`}
                className="ml-1 underline"
              >
                {tenantContact?.telephone2}
              </a>
            </div>
          )}
          {tenantContact?.telephone3 && (
            <div className={`text ${styles.textContainer}`}>
              <p className={`text ${styles.textStyle}`}>{Labels.MOBILE}</p>
              <a
                href={`tel:${tenantContact?.telephone3}`}
                className="ml-1 underline"
              >
                {tenantContact?.telephone3}
              </a>
            </div>
          )}
          {tenantContact?.fax && (
            <div className={`text ${styles.textContainer}`}>
              <p className={`text ${styles.textStyle}`}>{Labels.FAX}</p>
              <p className="ml-1">{tenantContact?.fax}</p>
            </div>
          )}
          {tenantContact?.email && (
            <div className={`text ${styles.textContainer}`}>
              <p className={`text ${styles.textStyle}`}>{Labels.EMAIL}</p>
              <a
                href={`mailto:${tenantContact?.email}`}
                className="ml-1 underline hyperlink"
              >
                {tenantContact?.email}
              </a>
            </div>
          )}
          {tenantContact?.businessContact && tenantContact?.website && (
            <div className={`text ${styles.textContainer}`}>
              <p className={`text ${styles.textStyle}`}>{Labels.WEBSITE}</p>
              <a
                href={`https://${tenantContact?.website}`}
                target="_blank"
                className="ml-1 underline hyperlink"
                rel="noreferrer"
              >
                {tenantContact?.website}
              </a>
            </div>
          )}
        </div>
        <AdditionalContactDetails additionalContactDetails={tenantAdditionalContact} isBusiness={tenantContact?.businessContact}/>
        <FormModal
          width="80vw"
          formId={formId}
          header="Tenant Contact"
          isOpen={showTenantContactModal}
          onClose={() => {
            if (isFormDirty) {
              setShowCancelModal(true);
            } else {
              setShowTenantContactModal(false);
            }
          }}
          isDirty={isFormDirty}
        >
          <div className="pl-10 pr-10">
            <ContactForm
              contactId={tenantContact?.contactId}
              title=""
              formId={formId}
              contactType={Labels.TENANT_CONTACT_TYPE}
              showCollapsible={false}
              lmcCommunicationChecks={false}
              showCancelModal={showCancelModal}
              setIsFormDirty={setIsFormDirty}
              setShowCancelModal={setShowCancelModal}
              setEditable={setShowTenantContactModal}
              setData={setData}
              toastRef={toastRef}
              setAdditionalContactData={setAdditionalContactData}
            />
          </div>
        </FormModal>
        <ToastContainer toastReference={toastRef} />
      </>
    )
  );
};

export default TenantContact;
