import { useEffect, useState  } from 'react';

export const useCacheData = (key: string) => {
    const [cache, setCache] = useState(null);

    useEffect(() => {
        const appCache = localStorage.getItem('app-cache');
        if (appCache) {
            const storedValue = JSON.parse(localStorage.getItem('app-cache') || '')[key];;  
            setCache(storedValue);
        }
  }, [key]);

  return cache;

}

export const setCacheData = (localStorageKey: string, key: string, value: any): void => {
    localStorage.getItem(localStorageKey)
      ? localStorage.setItem(localStorageKey, JSON.stringify({ ...JSON.parse(localStorage.getItem(localStorageKey) || ""), [key]: value }))
      : localStorage.setItem(localStorageKey, JSON.stringify({ [key]: value }));
};
  
export const getCacheData = (localStorageKey: string, key: string): any => {
    const appCache = localStorage.getItem(localStorageKey);
    if (appCache) {
      return JSON.parse(localStorage.getItem(localStorageKey) || "")[key];
    }
  };


