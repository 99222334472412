import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { SMDatePicker } from "src/components/ui/datePicker/datePicker";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Modal } from "src/components/ui/modal/modal";
import { modalDiscardMessageText, mortgageeTypeList } from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import { IMortgage } from "src/interfaces/lotOwner";
import { httpUpdate } from "src/services/api";
import { FormatDate, toDate } from "src/utils/utility";
import styles from "./mortgageForm.module.scss";
import { Errors } from "src/components/shared/error/error";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";

const MortgageTableForm = ({
  corpId,
  formData,
  formId,
  showCancelModal,
  rowIndex,
  handleChange,
  setShowCancelModal,
  setShowModal,
  setIsFormDirty,
  toastRef
}: {
  corpId: string;
  formData: IMortgage;
  formId: string;
  showCancelModal: boolean;
  rowIndex: number;
  handleChange: any;
  setShowCancelModal: any;
  setShowModal: any;
  setIsFormDirty: any;
  toastRef: any;
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      dateofEntry: formData?.dateofEntry,
      mortgagee: formData?.mortgagee ?? "",
      mortgageeType: formData?.mortgageeType,
      noticeAddress: formData?.noticeAddress ?? "",
      priority: formData?.priority ?? "",
      dischargeDate: formData?.dischargeDate,
      lotId: formData?.lotId,
      mortgageId: formData?.mortgageId,
    },
  });

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty]);

  const onSubmit = async (data: any) => {
    let reqBody = {
      ...data,
      dateofEntry: toDate(FormatDate(data?.dateofEntry) ?? ""),
      dischargeDate: toDate(FormatDate(data?.dischargeDate) ?? ""),
    };
    setIsLoading(true);
    const response = await httpUpdate(EndPoints.corporation + `${corpId}/lots/${data?.lotId}/mortgage`, reqBody);
    if (response?.status === 200) {
      toastRef?.current?.show({ severity: "success", summary: "Success", detail: Messages.SUCCESS_MESSAGE, life: 2000 });
      setShowModal(false);
      handleChange(rowIndex, data);
    }
    if (response?.error) {
      toastRef?.current?.show({ severity: "error", summary: "Error", detail: Messages.ERROR_MESSAGE, life: 3000 });
    }
    setIsLoading(false);
  };

  return (
    formData && (
      <>
        {isLoading && (
          <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-modal overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
            <div className="w-20 h-20">
              <SpinnerHorizontal />
            </div>
          </div>
        )}
        <form id={formId} onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="w-full pl-6 pr-6">
            <div className={styles.labelInput}>
              <label>{Labels.DATE_OF_ENTRY}</label>
              <SMDatePicker inputDate={FormatDate(formData?.dateofEntry) ?? ""} name="dateofEntry" control={control} />
            </div>

            <div className={styles.labelInput}>
              <label>{Labels.MORTGAGEE}</label>
              <SMInputText name="mortgagee" control={control} maxlength={50} />
            </div>
            <div className={styles.labelInput}>
              <label>{Labels.CAPACITY}</label>
              <SMDropDown control={control} name="mortgageeType" options={mortgageeTypeList} rules={{ required: Messages.REQUIRED_MESSAGE }} />
              {errors?.mortgageeType && <Errors error={errors?.mortgageeType?.message} alignment="left" />}
            </div>
            <div className={styles.labelInput}>
              <label>{Labels.NOTICE_ADDRESS}</label>
              <SMInputText name="noticeAddress" control={control} maxlength={128} />
            </div>
            <div className={styles.labelInput}>
              <label>{Labels.PRIORITY}</label>
              <SMInputText name="priority" control={control} maxlength={10} />
            </div>
            <div className={styles.labelInput}>
              <label>{Labels.DISCHARGE_DATE}</label>
              <SMDatePicker inputDate={FormatDate(formData?.dischargeDate) ?? ""} name="dischargeDate" control={control} />
            </div>
          </div>
        </form>
        <Modal
          isOpen={showCancelModal}
          onClose={() => setShowCancelModal(false)}
          title="Warning"
          onAction={() => {
            setShowCancelModal(false);
            setShowModal(false);
          }}
        >
          <div>
            <h2>{modalDiscardMessageText}</h2>
          </div>
        </Modal>
      </>
    )
  );
};

export default MortgageTableForm;
