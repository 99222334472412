import styles from './investmentAcc.module.scss'
import { useHttpGet } from 'src/services/api'
import { IBankList } from 'src/interfaces/'
import { SpinnerHorizontal } from 'src/components/ui/loading/spinnerHorizontal'
import { Plus } from 'src/assets/icon'
import { editButtonText, errorMessage } from 'src/constants/constant'
import { InvestmentAccTable } from './investmentAccTable'
import { Labels } from 'src/constants/labels'
import { EndPoints } from 'src/constants/endpoint'

export const InvestmentAcc: React.FC<{corpId: any}> = ({corpId}) =>{

    const { data, error } = useHttpGet(EndPoints.bankaccount + `plan/${corpId}/investment-accounts`) as { data: IBankList[], error: any};

    if (error) {
      return (
        <>
          <div className="text-red-500 m-4">{errorMessage}</div>
        </>
      )
    }

    if (!data) {
      return (
        <>
          <div className='flex w-full min-h-full items-center justify-center'>
            <div className='w-10 h-10'>
              <SpinnerHorizontal />
            </div>
          </div>
        </>
      )
    }

    return (
      <>
        <div className="w-full flex justify-between">
          <label className={`${styles.header}`}>{Labels.INVESTMENT_ACCOUNTS}</label>
          <div className='flex gap-3 order-last items-center'>
              <button type="button" className="edit-btn">
                {editButtonText}
              </button>
              <button type="button">
                <Plus/>
              </button>
          </div>
        </div>
        <InvestmentAccTable corpId={corpId} investmentAccDetails={data}/>
      </>
    )

}
