import ContactForm from "src/pages/lot/owner/shared/contact";

const OwnerContactEdit = ({
  contactData,
  additionalContactDetails,
  setContactData,
  setShowModal,
  formId,
  setIsFormDirty,
  showCancelModal,
  setShowCancelModal,
  toastRef,
  setAdditionalContactDetails,
}: {
  contactData: any;
  additionalContactDetails: any;
  setContactData: any;
  setShowModal: any;
  formId: string;
  setIsFormDirty: any;
  showCancelModal: boolean;
  setShowCancelModal: any;
  toastRef: any;
  setAdditionalContactDetails: any;
}) => {
  return (
    <div className="pl-10 pr-10">
      <ContactForm
        contactId={contactData?.contactId}
        title=""
        showCollapsible={false}
        lmcCommunicationChecks={false}
        setData={setContactData}
        setEditable={setShowModal}
        formId={formId}
        setIsFormDirty={setIsFormDirty}
        showCancelModal={showCancelModal}
        setShowCancelModal={setShowCancelModal}
        committeeMember={contactData?.committeeMember ? true : false}
        toastRef={toastRef}
        setAdditionalContactData={setAdditionalContactDetails}
      />
    </div>
  );
};

export default OwnerContactEdit;
