import { Badge } from "primereact/badge";
import styles from "./badgeContainer.module.scss";

const BadgeComponent = ({ value, overlayPanelRef }: { value: number; overlayPanelRef?: any }) => {
  return (
    <Badge
      className={`${styles.badge}`}
      value={value}
      onMouseEnter={(e) => overlayPanelRef?.current?.show?.(e)}
      onMouseLeave={(e) => {
        const element = e?.currentTarget;
        const { top, left, width } = element?.getBoundingClientRect?.();
        const x = e?.clientX - left;
        const y = e?.clientY - top;
        if (y < 0 || x > width || x < 0) {
          overlayPanelRef?.current?.hide?.();
        }
      }}
    />
  );
};

export default BadgeComponent;
