import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  agency: null,
};

const agencySettingSlice = createSlice({
  name: "agency",
  initialState,
  reducers: {
    setAgencySettings: (state, action: PayloadAction<any>) => {
      state.agency = action.payload;
    },
  },
});

export const { setAgencySettings } = agencySettingSlice.actions;
export default agencySettingSlice.reducer;
