import { useEffect, useMemo, useState } from "react";
import { TabViewPanel } from "src/components/ui/tabView/tabViewPanel";
import { Labels } from "src/constants/labels";
import { ITab } from "src/interfaces/tab";
import AdministrativeFund from "./administrativeFund";
import CapitalWorksFund from "./capitalWorksFund";
import AssetReplacement from "./assetReplacement";
import LotContributions from "./lotContributions";
import BudgetDates from "../shared/budgetDates";
import { useParams } from "react-router-dom";
import { useHttpGet } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { useDispatch } from "react-redux";
import { setSelectedDate, setShowActualForNonBudgetAccounts } from "src/store/slice/budget";
import { IBudgetDate } from "src/interfaces";

const BudgetTabs = () => {
  const { corpId } = useParams();
  const { data, error, statusCode } = useHttpGet(`${EndPoints.corporation}${corpId}/${EndPoints.budget}budget-dates`);
  const dispatch = useDispatch();
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const budgetTabs: ITab[] = useMemo(() => {
    return [
      {
        title: Labels.ADMINISTRATIVE_FUND,
        component: <AdministrativeFund fundType={1} />,
      },
      {
        title: Labels.CAPITAL_WORKS_FUND,
        component: <CapitalWorksFund fundType={2} />,
      },
      {
        title: Labels.ASSET_REPLACEMENT,
        component: <AssetReplacement />,
      },
      {
        title: Labels.LOT_CONTRIBUTIONS,
        component: <LotContributions />,
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(setSelectedDate({} as IBudgetDate));
    dispatch(setShowActualForNonBudgetAccounts(false));
  }, []);

  return (
    <div className="flex justify-center ml-10">
      <TabViewPanel
        tabs={budgetTabs}
        activeIndex={activeTabIndex}
        setActiveIndex={setActiveTabIndex}
        headerComponent={<BudgetDates data={data} error={error} statusCode={statusCode} />}
      ></TabViewPanel>
    </div>
  );
};

export default BudgetTabs;
