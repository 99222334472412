import React from "react";
import { LeviesDetailsSection } from "./leviesDetailSection";
import { useHttpGet } from "src/services/api";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Errors } from "src/components/shared/error/error";
import { errorMessage } from "src/constants/constant";

export const LeviesDetails: React.FC<{ levyDetails: any, isEditableLeviesDetail: any, setIsEditableLeviesDetail: any, setLevyDetail: any, corpId: string, lotId: string; toastRef: any; }> = ({
  levyDetails, isEditableLeviesDetail, setIsEditableLeviesDetail, setLevyDetail, corpId, lotId, toastRef
}) => {
  const { data: bankDetails, error: bankError } = useHttpGet("banks");

  if (!bankDetails) {
    return (
      <div className="flex w-full min-h-full items-center justify-center">
        <div className="w-20 h-20">
          <SpinnerHorizontal />
        </div>
      </div>
    );
  }

  if (bankError) {
    return <Errors error={errorMessage} alignment="left" />;
  }
  return (
    <LeviesDetailsSection key={isEditableLeviesDetail} levyDetails={levyDetails} bankDetails={bankDetails} isEditableLeviesDetail={isEditableLeviesDetail} setIsEditableLeviesDetail={setIsEditableLeviesDetail} setLevyDetail={setLevyDetail} corpId={corpId} lotId={lotId} toastRef={toastRef} />
  );
};
