import { useEffect, useMemo, useRef, useState } from "react";
import { Line, Pencil, Plus, ViewColumn } from "src/assets/icon";
import { Drawer } from "src/components/ui/drawer/drawer";
import { IconYesNoNull } from "src/components/ui/iconYesNoNull/iconYesNoNull";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { Labels } from "src/constants/labels";
import { ICoverage } from "src/interfaces";
import { toLocaleValue } from "src/utils";
import CoverageTableDrawer from "./coverageTableDrawer";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { useDispatch } from "react-redux";
import { setCoverageList, setSelectedCoverage } from "src/store/slice/insurance";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";
import ToastContainer from "src/components/ui/toast/toast";

const CoverageTable = ({ data }: { data: Array<ICoverage> }) => {
  const dispatch = useDispatch();
  const { coverageList } = useSelector((state: RootState) => state?.insuranceSlice);
  const [showSidebar, setShowSidebar] = useState<boolean>(false);
  const toastRef = useRef(null);
  const columns = useMemo(() => {
    return [
      {
        field: "description",
        header: <p className="text !font-bold uppercase">{Labels.COVERAGE}</p>,
      },
      {
        field: "sumInsured",
        header: <p className="text !font-bold">{Labels.SUM_INSURED}</p>,
      },
      {
        field: "excess",
        header: <p className="text !font-bold">{Labels.EXCESS}</p>,
        body: (data: any) => {
          return <p>${toLocaleValue(data?.excess)}</p>;
        },
      },
      {
        field: "notes",
        header: <p className="text !font-bold uppercase">{Labels.NOTES}</p>,
      },
      {
        field: "buildingInsurance",
        header: (
          <p className="text !font-bold uppercase">
            {Labels.BUILDING} {Labels.INSURANCE}
          </p>
        ),
        body: (data: any) => {
          return <IconYesNoNull type={data?.buildingInsurance} />;
        },
      },
      {
        field: "edit",
        header: "",
        body: (data: any) => {
          return (
            <span
              onClick={() => {
                dispatch(setSelectedCoverage(data));
                setShowSidebar(true);
              }}
            >
              <Pencil />
            </span>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(setCoverageList(data));
  }, []);

  return (
    <div className="px-8 py-4 mr-2 bg-white rounded-lg box-shadow">
      <div className="w-full">
        <SMDataTableContainer tableData={coverageList} tableColumns={columns} showEditIcon={false} showSelectableRowCheckbox={false} />
        <Drawer
          title={Labels.EDIT_COVERAGE_DETAILS}
          isOpen={showSidebar}
          onClose={() => {
            setShowSidebar(false);
          }}
          width="w-[50vw]"
        >
          <CoverageTableDrawer setShowSidebar={setShowSidebar} toastRef={toastRef} />
        </Drawer>
      </div>
      <ToastContainer toastReference={toastRef} />
    </div>
  );
};

export default CoverageTable;
