import { OverlayPanel } from "primereact/overlaypanel";
import styles from "./badgeContainer.module.scss";

const OverlayComponent = ({ data, overlayPanelRef }: { data: any; overlayPanelRef: any }) => {
  return (
    <OverlayPanel
      ref={overlayPanelRef}
      onMouseLeave={() => {
        overlayPanelRef?.current?.hide?.();
      }}
      className={`${styles.overlayRoot}`}
    >
      {data?.map?.((d: any) => (
        <div key={d?.lContactID} className={`p-2 flex flex-col ${styles.overlayPanel}`}>
          <p className={`${styles.textStyle}`}>{`${d?.sFirstName} ${d?.sName}`}</p>
          <a href={`tel:${d?.sTelephone3}`} className={`hyperlink ${styles.textStyle}`}>
            {d?.sTelephone3}
          </a>
          <a href={`tel:${d?.sTelephone1}`} className={`hyperlink ${styles.textStyle}`}>
            {d?.sTelephone1}
          </a>
          <a href={`mailto:${d?.sEmail}`} className={`hyperlink ${styles.textStyle}`}>
            {d?.sEmail}
          </a>
        </div>
      ))}
    </OverlayPanel>
  );
};

export default OverlayComponent;
