import { Checkbox } from "src/components/ui/checkbox/checkbox";
import { contactType } from "src/constants/constant";
import { Labels } from "src/constants/labels";
import { IAdditionalContact } from "src/interfaces/additionalContact";
import styles from "../contact.module.scss";

const AdditionalContactDetails = ({
  additionalContactDetails,
  borderTop = "border-t-2",
  isBusiness,
}: {
  additionalContactDetails: Array<IAdditionalContact>;
  borderTop?: string;
  isBusiness: boolean;
}) => {
  return (
    <div>
      {additionalContactDetails?.length > 0 && (
        <div className={`pt-4 flex flex-col ${borderTop}`}>
          <div className="items-center">
            <p className={`!font-bold ${styles.textStyle}`}>{Labels.ADDITIONAL_CONTACT_DETAILS}:</p>
          </div>
          {additionalContactDetails?.map?.((contact: IAdditionalContact) => (
            <div key={contact?.contactDetailsId}>
              <div className={`${styles.textContainer} mt-5 !h-auto`}>
                <label className={`mr-1 ${styles.textStyle}`}>
                  {contact?.type === contactType.emailAddress ? Labels.EMAIL_RADIOBUTTON_TEXT : (isBusiness ? (contact?.type === contactType.homePhone ? contactType.phone1 : (contact?.type === contactType.workPhone ? contactType.phone2 : contact?.type)) : (contact?.type === contactType.phone1 ? contactType.homePhone : (contact?.type === contactType.phone2 ? contactType.workPhone : contact?.type)))}:
                </label>                
                {
                  contact?.type === contactType.homePhone || contact?.type === contactType.workPhone || contact?.type === contactType.mobile || contact?.type === contactType.phone1 ||contact?.type === contactType.phone2 ?
                    <a
                      href={`tel:${contact?.value}`}
                      className="ml-1 underline"
                    >
                      {contact?.value}
                    </a>
                  : (contact?.type === contactType.emailAddress ?
                      <a
                        href={`mailto:${contact?.value}`}
                        className="ml-1 underline hyperlink"
                      >
                        {contact?.value}
                      </a> 
                    : <p>{contact?.value}</p> )
                }
              </div>
              {contact?.notes && (
                <div className={`${styles.textContainer} mt-2 !h-auto`}>
                  <label className={`mr-1 ${styles.textStyle}`}>{Labels.NOTES}:</label>
                  <p>{contact?.notes}</p>
                </div>
              )}
              {contact?.type === Labels.EMAIL_ADDRESS_ && (
                <div className={`${styles.textContainer} flex gap-5 px-4`}>
                  <Checkbox id="levies" label={Labels.LEVIES} selected={contact?.emailLevies} />
                  <Checkbox id="meetingDocs" label={Labels.MEETING_DOCS} selected={contact?.emailMeetingDocs} />
                  <Checkbox id="correspondence" label={Labels.CORRESPONDENCE} selected={contact?.emailCorrespondence} />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdditionalContactDetails;
