import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUnitEntitlementSet } from "src/interfaces/unitentitlementset";

interface IEntitlementSlice {
  entitlementSetList: Array<IUnitEntitlementSet>;
  selectedEntitlementSet: IUnitEntitlementSet | null;
}

const initialState: IEntitlementSlice = {
  selectedEntitlementSet: null,
  entitlementSetList: [],
};

const entitlementSlice = createSlice({
  name: "entitlementSet",
  initialState,
  reducers: {
    setEntitlementSetList: (state, action: PayloadAction<Array<IUnitEntitlementSet>>) => {
      state.entitlementSetList = action.payload;
    },
    setSelectedEntitlementSet: (state, action: PayloadAction<IUnitEntitlementSet>) => {
      state.selectedEntitlementSet = action.payload;
    },
  },
});

export const { setSelectedEntitlementSet, setEntitlementSetList } = entitlementSlice.actions;
export default entitlementSlice.reducer;