export const Messages = {
  SOMETHING_WENT_WRONG: "Something went wrong!",
  SUCCESS_MESSAGE: "Data saved successfully!!",
  ERROR_MESSAGE: "We have encountered an error. Please try again.",
  DISCARD_MESSAGE: "Are you sure you want to discard changes ?",
  MAX_LIMIT_MESSAGE: "Max limit is 9999.",
  NOT_SUBJECT_TO_DEBT_RECOVERY: "Currently not subject to debt recovery",
  CURRENTLY_IN_DEBT_RECOVERY: "Currently in debt recovery",
  DATE_IS_REQUIRED: "Date is required",
  REQUIRED_MESSAGE: "This field is required.",
  INVALID_EMAIL: "Invalid email format.",
  NO_CONTACT_EXISTS: "You need to create a new contact before you can edit one.",
  NO_ACTIVE_POLICIES: "There are no active policies.",
  LINKED_LOT_OWNER: "This owner contact is linked to multiple lots",
  FEATURE_NOT_AVAILABLE: "Feature not available",
  NO_EMAIL_ADDRESSES: "No email addresses on record",
  NO_MOBILE_NUMBER: "No mobile number on record",
  USER_ACCESS_GRANTED: "User has been granted access to cloud application. Activation email has been sent to registered email.",
  DEACTIVATE_USER_CONFIRMATION: "Are you sure you want to deactivate this user. User won't have access to cloud application if you deactivate.",
  UNSAVED_CHANGES: "You have unsaved changes. Are you sure you want to leave this page?",
  NO_LOTS: "There are no lots.",
  CLIPBOARD: "Copied to clipboard!",
  PROVIDE_CONTACT_MESSAGE: "You must provide a contact for levies.",
  PROVIDE_CONTACT_MESSAGE_NOTICES: "You must provide a contact for notices.",
  NO_CONTACT_SELECTED: "No contact selected.",
  NO_CHANGE_FOR_REAL_ESTATE_AGENT: "Contact details for a real estate agent can only be changed with the contact management function in core.",
  ADD_OR_SELECT_CONTACT: "Please add or select a contact",
  RESERVE_FUND_VALIDATION: "Please enter less than or equal 2 digit after decimal.",
  MANAGEMENT_FEES_RATE_VALIDATION: "You are setting the new management fee rate to zero. Are you sure?",
  MANAGEMENT_FEES_DATE_VALIDATION: "A date has not been set for the new rate to apply.",
  LEVY_DISCOUNT_VALIDATION: "Discount rate should be greater than zero and less than 100%.",
  TOTAL_ENTITLEMENT_VALIDATION: "Please enter Total Entitlement less than or equal 9 digits.",
  BUILDING_INSURANCE_VALIDATION:
    "Maximum 1 building insurance can be selected at a time. Continuing will clear any other selections. Would you like to proceed?",
  BUDGET_ACCOUNT_VALIDATION: "Please select an account",
  BUDGET_ACCOUNT_EDIT_VALIDATION:
    "You cannot edit the account name of a budget item linked to an actual payment or a ‘last year’ budgeted amount. Please create a new budgeted line item.",
  BUDGETEDAMOUNT_EDIT_VALIDATION: "Please enter a budgeted amount to save this budgeted item",
  SEARCH_VALIDATION: "Minimum 2 letters are required",
};
