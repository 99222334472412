import styles from "./page.module.scss";
import { useState, useEffect, useMemo, useRef } from "react";
import { httpPost, useHttpGet } from "../../services/api";
import { NullOREmptyString, checkZeroValue } from "../../utils/utility";
import { DiagonalArrow, Star } from "../../assets/icon";
import { SpinnerHorizontal } from "../../components/ui/loading/spinnerHorizontal";
import { ILot, ILotInterface, ILots } from "../../interfaces/lotList";
import { useDispatch, useSelector } from "react-redux";
import { setBreadCrumbs, setIcons } from "../../store/slice/breadCrumbSlice";
import { EndPoints } from "../../constants/endpoint";
import { SMDataTableContainer } from "../../components/ui/table/tableContainer";
import BadgeContainer from "../../components/ui/badge/badgeContainer";
import { Drawer } from "../../components/ui/drawer/drawer";
import { ITab } from "../../interfaces/tab";
import AgentTab from "./owner/tabs/agent";
import DebtRecoveryTab from "./owner/tabs/debtRecovery";
import LinkedLotsTab from "./owner/tabs/linkedLots";
import MortgageTab from "./owner/tabs/mortgage";
import { IconYesNoNull } from "../../components/ui/iconYesNoNull";
import TabHeader from "./owner/shared/tabHeader";
import { TabViewPanel } from "../../components/ui/tabView/tabViewPanel";
import GeneralTab from "./owner/tabs/general";
import LeviesTab from "./owner/tabs/levies";
import NoticesTab from "./owner/tabs/notices";
import { Labels } from "../../constants/labels";
import { useLocation, useParams } from "react-router-dom";
import ToastContainer from "src/components/ui/toast/toast";
import { Toast } from "primereact/toast";
import { comingSoon, httpCode } from "src/constants/constant";
import { Messages } from "src/constants/messages";
import usePlanNumber from "src/hooks/usePlanNumber";
import { RootState } from "src/store/rootReducer";

export default function LotList() {
  // convert to layout component
  const { corpId } = useParams();
  const planNumber = usePlanNumber(corpId ?? "")
  const { loggedInUserInfo } = useSelector((state: RootState) => state?.userSlice);
  const [showSidebar, setShowSidebar] = useState(false);
  const [lotListData, setLotListData] = useState<Array<ILot>>([]);
  const [lot, setLot] = useState({ lotId: "", lotName: "", contactId: "", additionalDebtor: true});
  const [importContact, setImportantContact] = useState({id: "", isImportantContact: "", contactId: ""});
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const { data, error, statusCode } = useHttpGet(
    EndPoints.corporation + `${corpId}/lots`
  ) as { data: ILots; error: any; statusCode: any; };
  const dispatch = useDispatch();
  const location = useLocation();
  const toastRef = useRef<Toast>(null);
  const [isLoading, setIsLoading] = useState(false);

  const openDrawer = (data: any) => {
    setActiveTabIndex(data?.tab ?? 0);
    setShowSidebar(true);
    setLot({
      ...lot,
      lotId: String(data?.lotId),
      lotName:
        (data?.ownerName ?? data?.nameOnTitle) +
        " - Lot " +
        data?.lotNumber +
        " - " +
        (data?.planNumber ?? planNumber),
      contactId: String(
        data?.ownerContact?.[data?.ownerContact?.length - 1]?.lContactID ??
          data?.contactId
      ),
      additionalDebtor: data?.additionalDebtor?.toUpperCase?.() === "Y" ? false : true
    });
  };

  const columns = useMemo(() => {
    return [
      {
        field: "unitNo",
        header: "UNIT#",
        sortable: true,
      },
      {
        field: "lotNumber",
        header: "LOT#",
        body: (data: any) => {
          return (
            <a
              className={`font-bold underline hyperlink`}
              data-testid="planLink"
              onClick={() => openDrawer(data)}
            >
              {data?.lotNumber}
            </a>
          );
        },
        sortable: true,
      },
      {
        field: "ownerName",
        header: "OWNER",
        body: (data: any) => {
          return (
            <a
              className={`font-bold underline text-left hyperlink`}
              data-testid="ownerLink"
              onClick={() => openDrawer(data)}
            >
              {data?.ownerName}
            </a>
          );
        },
        sortable: true,
      },
      {
        field: "contact",
        header: "CONTACT",
        sortable: true,
      },
      {
        field: "ownerContact",
        header: "#OWNER",
        body: (data: any) => {
          return data?.ownerContact?.length > 0 ? (
            <BadgeContainer data={data?.ownerContact} />
          ) : (
            <p>No owners</p>
          );
        },
      },
      {
        field: "position",
        header: "POSITION",
        sortable: true,
      },
      {
        field: "additionalDebtor",
        header: "ADDITIONAL DEBTOR",
        body: (data: any) => {
          return <IconYesNoNull type={data?.additionalDebtor} />;
        },
        sortable: true,
      },
      {
        field: "email",
        header: "EMAIL",
        sortable: true,
      },
      {
        field: "address",
        header: "ADDRESS",
        sortable: true,
      },
      {
        field: "arrowIcon",
        header: "",
        body: (data: any) => {
          return (
            <div className="flex gap-1">
              <span onClick={() => markAsImportantContact(data?.ownerContact[0], data?.lotId)}>
                <Star height={18} width={18} fill={data?.ownerContact[0]?.bIsImportantContact === "Y" ? "#FFB433" : "none"} stroke={data?.ownerContact[0]?.bIsImportantContact === "Y" ? "" : "#4B5563"} />
              </span>
              <a
                className={`font-bold underline`}
                data-testid="arrowIconLink"
                onClick={() => openDrawer(data)}
              >
                <DiagonalArrow height={18} width={18} />
              </a>
            </div>
          );
        },
      },
    ];
  }, [planNumber]);

  const lotOwnerTabs: ITab[] = useMemo(() => {
    return [
      {
        title: Labels.GENERAL,
        component: (
          <GeneralTab
            setActiveTabIndex={setActiveTabIndex}
            corpId={corpId ?? ""}
            lotId={lot?.lotId}
          />
        ),
      },
      {
        title: Labels.AGENT_TENANT_LEASES,
        component: <AgentTab corpId={corpId ?? ""} lotId={lot?.lotId} />,
      },
      {
        title: Labels.LEVIES,
        component: <LeviesTab corpId={corpId ?? ""} lotId={lot?.lotId} />,
      },
      {
        title: Labels.NOTICES,
        visible: lot?.additionalDebtor,
        component: <NoticesTab corpId={corpId ?? ""} lotId={lot?.lotId} />,
      },
      {
        title: Labels.MORTGAGE,
        visible: lot?.additionalDebtor,
        component: <MortgageTab corpId={corpId ?? ""} lotId={lot?.lotId} />,
      },
      {
        title: Labels.DEBT_RECOVERY,
        visible: lot?.additionalDebtor,
        component: <DebtRecoveryTab corpId={corpId ?? ""} lotId={lot?.lotId} />,
      },
      {
        title: Labels.LINKED_LOTS,
        component: (
          <LinkedLotsTab contactId={lot?.contactId ?? ""} lotId={lot?.lotId} />
        ),
      },
    ];
  }, [corpId, lot?.lotId]);

  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { title: "Corporations", url: "/corporations" },
        { title: `${planNumber}`, url: `/corporations/${corpId}` },
        { title: "Lots", url: `/corporations/${corpId}/lots` },
      ])
    );
    dispatch(
      setIcons([
        {
          name: "Important Contacts",
          route: `${corpId}/contact`,
          isSelected: false,
        },
        { name: "Lots", route: `${corpId}/lots`, isSelected: true },
        { name: "Committee", route: `${corpId}/committee`, isSelected: false },
        {
          name: "Entitlements",
          route: `${corpId}/unitentitlements`,
          isSelected: false,
        },
      ])
    );
  }, [planNumber]);

  useEffect(() => {
    if (data) {
      let newLotListData = data?.lotList?.map((lot: ILotInterface) => {
        return {
          ...data,
          unitNo: `${lot?.sUnitNumberPrefix}${checkZeroValue(lot?.lUnitNumber) ?? ""}${lot?.sUnitNumberSuffix}`,
          lotId: lot?.lLotID,
          lotNumber: `${lot?.sLotNumberPrefix}${checkZeroValue(lot?.lLotNumber) ?? ""}${lot?.sLotNumberSuffix}`,
          ownerName: NullOREmptyString(lot?.sNameOntitle?.trim?.()),
          contact: NullOREmptyString(lot?.sTelephone3),
          email: NullOREmptyString(lot?.sEmail),
          address: `${lot?.sBuildingName} ${lot?.sStreetNumberPrefix}${checkZeroValue(lot?.lStreetNumber) ?? ""}${lot?.sStreetNumberSuffix} ${lot?.sStreet} ${lot?.sTown} ${lot?.sState} ${lot?.sPostCode}`,
          position: NullOREmptyString(lot?.sExecutivePositionName),
          additionalDebtor: NullOREmptyString(lot?.bNonLotOwner),
          ownerContact: lot?.OwnerContact,
        };
      });

      setLotListData(newLotListData);
    }
  }, [data]);

  useEffect(() => {
    window.history.replaceState({}, "");
    if (location?.state?.lotId) {
      openDrawer({...location?.state , additionalDebtor:location?.state?.additionalDebtor ? "Y" : "N"});
    }
  }, [location?.state?.lotId]);

  useEffect(() => {
    window.history.replaceState({}, "");
    if (data?.lotList?.length > 0 && location?.state?.openLotDetails) {
      let lotInfo: any = data?.lotList?.find?.(
        (lot) => lot?.lLotID === Number(location?.state?.lotID)
      );
      let lotObj = {
        lotId: lotInfo?.lLotID,
        nameOnTitle: lotInfo?.sNameOntitle,
        lotNumber: location?.state?.lotNo,
        planNumber: location?.state?.planNumber,
        ownerContact: lotInfo?.OwnerContact,
        additionalDebtor: lotInfo?.bNonLotOwner,
        tab: location?.state?.tab ?? 0,
      };
      openDrawer(lotObj);
    }
  }, [data, location?.state?.lotID]);

  useEffect(() =>{
    if(data)
    {
      let newLotListData = lotListData?.map((lotData: any) => {
          return {
            ...lotData,
            ownerContact: lotData?.ownerContact?.map((owner: any) => {
              if (owner?.lContactID === importContact?.contactId) {
                return {
                  ...owner,
                  bIsImportantContact: importContact?.isImportantContact,
                  lImportantContactID: importContact?.id,
                };
              }
              return owner;
            }),
          };
      });
      setLotListData(newLotListData);  
    }
  }, [importContact?.isImportantContact, importContact?.id, importContact?.contactId,]);

  const markAsImportantContact = async (lotOwner: any, lotId : any) => {
    setIsLoading(true);
    let importantContact = lotOwner?.bIsImportantContact === "Y" ? false : lotOwner?.bIsImportantContact === "N" ? true : false;
    let res: any = await httpPost(`${EndPoints.contact}${EndPoints.plan}${corpId}/important-contacts`, {
      contactId: lotOwner?.lContactID,
      isFavorite: importantContact,
    });
    if (res?.status === httpCode.SUCCESS) {
      toastRef?.current?.show({ severity: "success", summary: "Success", detail: Messages.SUCCESS_MESSAGE, life: 3000 });
      setImportantContact({
        ...importContact,
        id: res?.data?.Id,
        isImportantContact: lotOwner?.bIsImportantContact === "Y" ? "N" : lotOwner?.bIsImportantContact === "N" ? "Y" : "N",
        contactId: lotOwner?.lContactID
      });
    }
    else {
      toastRef?.current?.show({ severity: "error", summary: "Error", detail: Messages.ERROR_MESSAGE, life: 3000 });
    }
    setIsLoading(false);
  };

  if (error) {
    return (
      <>
        <div className="text-red-500">Something went wrong!</div>
      </>
    );
  }
  if (statusCode === 204 || statusCode === 404) {
    return (
      <>
        <div className='m-4'>{Messages.NO_LOTS}</div>
      </>
    )
  }

  if (!data) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {isLoading && (
        <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-modal overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <div className={styles.page_container}>
        <div className="w-full mb-5">
          <div className="flex justify-between">
            <div>
              <p className={styles.tableHeading}>Lots</p>
            </div>
            <div>
              <button
                className={"grey-btn"}
                onClick={() =>
                  toastRef?.current?.show({
                    severity: "info",
                    summary: "Info",
                    detail: comingSoon,
                    life: 2000,
                  })
                }
              >
                Create Lots
              </button>
            </div>
          </div>
        </div>
        {lotListData?.length > 0 && (
          <SMDataTableContainer
            id="lotTable"
            stateKey={`${loggedInUserInfo?.email}/lotTable`}
            tableData={lotListData}
            tableColumns={columns}
            showEditIcon={false}
            enableGlobalSearch
            enableColumnConfig
            sortField="lotNumber"
            globalFilterFields={["ownerName", "address", "email"]}
            resizableColumns
          />
        )}
      </div>
      <Drawer
        isOpen={showSidebar}
        onClose={() => setShowSidebar(false)}
        width="w-[85vw] md:w-[67vw]"
        panelMode={true}
        closeOnOutsideClick
      >
        <TabViewPanel
          tabs={lotOwnerTabs}
          headerComponent={<TabHeader lotName={lot?.lotName} />}
          activeIndex={activeTabIndex}
          setActiveIndex={setActiveTabIndex}
        ></TabViewPanel>
      </Drawer>
      <ToastContainer toastReference={toastRef} />
    </>
  );
}
