export const EndPoints = {
  corporation: "corporation/",
  structure: "structures/",
  plan: "plan/",
  planDetail: "planDetail",
  contact: "contact/",
  owners: "owners/",
  search: "search",
  users: "users/",
  userSetting: "usersetting/",
  insurance: "insurance",
  bankaccount: "bankaccount/",
  managementFees: "management-fees",
  budget: "budget/",
  committee: "committee",
  financial: "/financial",
  creditors: "creditors",
  agency: "agency"
} as const;
