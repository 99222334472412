import { useMemo } from "react";
import { useNavigate } from "react-router";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { Labels } from "src/constants/labels";
import { checkZeroValue } from "src/utils/utility";

const LinkedLotsTable = ({ linkedLotsData, selectedRow, setSelectedRow }: { linkedLotsData: any; selectedRow: any; setSelectedRow: any }) => {
  const navigate = useNavigate();
  const columns = useMemo(() => {
    return [
      {
        field: "planNumber",
        header: <p className="text !font-bold">PLAN #</p>,
      },
      {
        field: "wholeLotNumber",
        header: <p className="text !font-bold">LOT #</p>,
      },
      {
        field: "wholeUnitNumber",
        header: <p className="text !font-bold">UNIT #</p>,
      },
      {
        field: "noticeAddress",
        header: <p className="text !font-bold">{Labels.LOT_ADDRESS}</p>,
        body: (data: any) => {
          return (
            <p>
              {data?.buildingName} {data?.streetNumberPrefix}
              {checkZeroValue(data?.streetNumber)}
              {data?.streetNumberSuffix} {data?.street} {data?.town} {data?.state} {data?.postcode}
            </p>
          );
        },
      },
      {
        field: "nameOnTitle",
        header: <p className="text !font-bold">{Labels.NAME_ON_TITLE.toUpperCase?.()}</p>,
      },
      {
        field: "name",
        header: <p className="text !font-bold">{Labels.MAIN_CONTACT_NAME}</p>,
        body: (data: any) => {
          return (
            <p>
              {data?.title} {data?.firstName} {data?.name}
            </p>
          );
        },
      },
    ];
  }, []);

  const handleRowClick = (e: any) => {
    navigate(`/corporations/${e?.data?.ownersCorporationId}/lots`, {
      state: e?.data,
    });
  };

  return (
    <SMDataTableContainer
      tableData={linkedLotsData}
      tableColumns={columns}
      showEditIcon={false}
      showSelectableRowCheckbox={false}
      handleDoubleRowClick={handleRowClick}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRow}
    />
  );
};

export default LinkedLotsTable;
