import { Toast } from "primereact/toast";
import { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { DiagonalArrow, DocumentWord2, Line, MailMerge, Star, ViewColumn } from "src/assets/icon";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import ToastContainer from "src/components/ui/toast/toast";
import { comingSoon, contactType, httpCode, type } from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import { IImportantContact } from "src/interfaces";
import { httpPost } from "src/services/api";
import { RootState } from "src/store/rootReducer";
import { deleteImportantContact, setImportantContacts, updateBuildingManager, updateTradesmenContacts } from "src/store/slice/contact";
import { findValue, mailToSend } from "src/utils/utility";

const ImportantContactTable = ({ importantContactList }: { importantContactList: Array<IImportantContact> }) => {
  const { agency } = useSelector((state: RootState) => state?.agencySettingSlice);
  const { importantContacts } = useSelector((state: RootState) => state?.contactSlice);
  const { corpId } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const toast = useRef<Toast>();
  const importantContactTableButtons = [
    {
      buttonName: Labels.EDIT,
      type: "normal",
      buttonType: "button",
      onClick: () => {
        toast?.current?.show({
          severity: "info",
          summary: "Info",
          detail: comingSoon,
          life: 2000,
        });
      },
    },
    // { buttonName: <DocumentWord2 />, type: "word" },
    // { buttonName: <Line height={32} />, type: "line" },
    // { buttonName: <MailMerge />, type: "word" },
    // { buttonName: <Line height={32} />, type: "line" },
    // { buttonName: <ViewColumn /> },
  ];
  const columns = useMemo(() => {
    return [
      {
        field: "name",
        header: <p className="text !font-bold uppercase">{Labels.NAME}</p>,
        body: (data: any) => {
          return (
            <p>
              {data?.firstName} {data?.otherNames} {data?.name}
            </p>
          );
        },
      },
      {
        field: "type",
        header: <p className="text !font-bold uppercase">{Labels.TYPE}</p>,
        body: (data: any) => {
          return <p>{findValue(type, data?.contactType)}</p>;
        },
      },
      {
        field: "mobile",
        header: <p className="text !font-bold uppercase">{Labels.MOBILE?.substring?.(0, 6)}</p>,
        body: (data: any) => {
          return (
            <a href={`tel:${data?.mobile}`} className="hyperlink">
              {data?.mobile}
            </a>
          );
        },
      },
      {
        field: "email",
        header: <p className="text !font-bold uppercase">{Labels.EMAIL.substring?.(0, 5)}</p>,
        body: (data: any) => {
          return (
            <a
              className="hyperlink"
              onClick={() => {
                mailToSend(
                  [{ ...data, email: data?.committeeEmail ? data?.committeeEmail : data?.contactType === contactType.creditor ? data?.email2 : data?.email }],
                  toast,
                  agency?.useAdditionalEmailAddresses,
                  agency?.additionalEmailAddressOptions,
                  false
                );
              }}
            >
              {data?.committeeEmail ? data?.committeeEmail : data?.contactType === contactType.creditor ? data?.email2 : data?.email}
            </a>
          );
        },
      },
      {
        field: "homePhone",
        header: <p className="text !font-bold uppercase">{Labels.TELEPHONE_1?.substring?.(0, 11)} (H)</p>,
        body: (data: any) => {
          return (
            <a href={`tel:${data?.homePhone}`} className="hyperlink">
              {data?.homePhone}
            </a>
          );
        },
      },
      {
        field: "workPhone",
        header: <p className="text !font-bold uppercase">{Labels.TELEPHONE_2?.substring?.(0, 11)} (W)</p>,
        body: (data: any) => {
          return (
            <a href={`tel:${data?.workPhone}`} className="hyperlink">
              {data?.workPhone}
            </a>
          );
        },
      },
      {
        field: "option",
        header: <p className="text !font-bold uppercase"></p>,
        body: (data: any) => {
          return (
            <div className="flex gap-1 justify-end">
              <span onClick={() => unmarkImportantContact(data)}>
                <Star height={18} width={18} fill="#FFB433" stroke="" />
              </span>
              {/* <DiagonalArrow height={18} width={18} /> */}
            </div>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(setImportantContacts(importantContactList));
  }, [importantContactList]);

  const unmarkImportantContact = async (importantContact: any) => {
    setIsLoading(true);
    let res: any = await httpPost(`${EndPoints.contact}${EndPoints.plan}${corpId}/important-contacts`, {
      contactId: importantContact?.contactId,
      isFavorite: false,
    });
    if (res?.status === httpCode.SUCCESS) {
      toast?.current?.show({ severity: "success", summary: "Success", detail: Messages.SUCCESS_MESSAGE, life: 3000 });
      dispatch(deleteImportantContact(importantContact?.contactId));
      if (importantContact?.contactType === "C") {
        dispatch(updateTradesmenContacts({ contactId: importantContact?.contactId, key: "isImportantContact", value: false }));
      }
      if (importantContact?.contactType === "B") {
        dispatch(updateBuildingManager({ contactId: importantContact?.contactId, key: "isImportantContact", value: false }));
      }
    } else {
      toast?.current?.show({ severity: "error", summary: "Error", detail: Messages.ERROR_MESSAGE, life: 3000 });
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-modal overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <CollapsibleCard id="importantContactTable" title={Labels.IMPORTANT_CONTACTS} expand={true} buttons={importantContactTableButtons}>
        <div className="w-full pt-4">
          <SMDataTableContainer tableData={importantContacts} tableColumns={columns} showEditIcon={false} showSelectableRowCheckbox={false} />
          <ToastContainer toastReference={toast} />
        </div>
      </CollapsibleCard>
    </>
  );
};
export default ImportantContactTable;
