import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { SMMultiSelect } from "src/components/ui/multiSelect/multiSelect";
import { advanceSearch, advanceSearchCatrgories, noResultFound, resetButtonText, searchButtonText, toastErrorTimeLimit } from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import { Labels } from "src/constants/labels";
import { IUser } from "src/interfaces/users";
import { httpPost } from "src/services/api";
import { ListBox } from "primereact/listbox";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { SMRadioButton } from "src/components/ui/radioControl/radioButton";
import { Accordion, AccordionTab } from "primereact/accordion";
import SearchResultTemplate from "./searchResultTemplate";
import { Messages } from "src/constants/messages";
import { Errors } from "../error/error";

interface IAdvanceSearchModal {
  searchQuery?: string;
  categories?: string[];
  manager?: string[];
  managed?: string;
}

const CATEGORY_ORDER = [
  advanceSearch.corporation,
  advanceSearch.owner,
  advanceSearch.committee,
  advanceSearch.buildingManager,
  advanceSearch.agent,
  advanceSearch.tenant,
  advanceSearch.creditor,
];

const AdvanceSearchForm = ({ toastRef, managerList, setShowSidebar }: { toastRef: any; managerList: Array<IUser>; setShowSidebar: any }) => {
  const {
    control,
    reset,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      searchQuery: "",
      managed: "M",
      categories: [],
      manager: [],
    },
  });
  const searchTerm = watch("searchQuery");
  const [result, setResult] = useState<any[]>([]);
  const [resultCount, setResultCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sortResults = (data: any) => {
    const sortedData = CATEGORY_ORDER.map((category) => ({
      name: category,
      items: data.find((group: any) => group.name === category)?.items || [],
    }));
    return sortedData.filter((group) => group.items.length > 0);
  };

  const createPayload = (obj: IAdvanceSearchModal): Partial<IAdvanceSearchModal> => {
    const payload: Partial<IAdvanceSearchModal> = Object.entries(obj).reduce((acc, [key, value]) => {
      if (
        (Array.isArray(value) && value.length > 0) ||
        (typeof value === "boolean" && value) ||
        (typeof value === "string" && value.trim() !== "") ||
        typeof value === "number"
      ) {
        (acc as any)[key] = value;
      }
      return acc;
    }, {} as Partial<IAdvanceSearchModal>);

    if (obj.managed === Labels.B) {
      if (!obj.categories || obj.categories.length === 0) {
        payload.categories = advanceSearchCatrgories.map((category) => category.id);
      }
      delete payload.managed;
    } else if (obj.managed === Labels.N) {
      payload.managed = Labels.NO;
    } else if (obj.managed === Labels.M) {
      payload.managed = Labels.YES;
    }
    return payload;
  };

  const handleReset = () => {
    reset({});
    setResult([]);
    setResultCount(0);
  };

  async function onSubmit(data: any) {
    let payload = createPayload(data);
    let updatedPayloadd = JSON.stringify(payload);
    setIsLoading(true);
    const output: any = await httpPost(EndPoints.search + `?pageNo=1&pageSize=100`, JSON.stringify(updatedPayloadd));
    if (output?.status === 200) {
      setIsLoading(false);
      const sortedResult = sortResults(output?.data);
      setResult(sortedResult);
      reset({}, { keepDirty: false, keepValues: true });
    }
    if (output?.error) {
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `error:${output?.error}`,
        life: toastErrorTimeLimit,
      });
    }
  }

  const filterResultsBySearchTerm = (term: string) => {
    if (!term) {
      const totalItemsLength = result?.reduce((total: number, current: any) => total + current.items.length, 0);
      setResultCount(totalItemsLength);
    } else {
      const mergedResults = result.map((group) => {
        const searchTermFilteredItems = group.items.filter((item: any) => {
          const itemJsonString = JSON.stringify(item).toLowerCase();
          return itemJsonString.includes(term.toLowerCase());
        });
  
        const uniqueItems = Array.from(new Set(searchTermFilteredItems));
        return {
          ...group,
          items: uniqueItems
        };
      });
      const totalItemsLength = mergedResults.reduce((total: number, current: any) => total + current.items.length, 0);
      setResultCount(totalItemsLength);
    }
  };

  useEffect(() => {
    filterResultsBySearchTerm(searchTerm);
  }, [searchTerm, result]);
  return (
    <>
      {isLoading && (
        <div className="flex w-full bg-gray-900 fixed  bg-opacity-0 justify-center items-center z-50 h-auto lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <div id="searchSection">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-1.5">
            <label className="font-medium text-sm required">{Labels.KEYWORD_SEARCH}</label>
            <div
              className="flex items-center border rounded-md"
              style={{
                background: "#ffffff",
                borderStyle: "solid",
                borderColor: "#ced4da",
              }}
            >
              <SMInputText
                name="searchQuery"
                control={control}
                autoComplete="off"
                className="!border-none"
                rules={{
                  required: Messages.REQUIRED_MESSAGE,
                  minLength: {
                    value: 2,
                    message: Messages.SEARCH_VALIDATION,
                  },
                }}
              />
            </div>
            {errors?.searchQuery && <Errors error={errors?.searchQuery?.message} alignment="left" />}
          </div>
          <div className="mt-4">
            <Controller
              name="managed"
              control={control}
              render={({ field }) => (
                <>
                  <div className="flex justify-between h-10 items-center mt-1 gap-3">
                    <div className="flex basis-full text-center items-center">
                      <SMRadioButton
                        id="managedField"
                        field={field}
                        value={Labels.M}
                        checked={field?.value === Labels.M}
                        onChange={(e: any) => field.onChange(e.value)}
                      />
                      <label htmlFor="managed" className={`ml-2 font-normal text-center text-sm`}>
                        {Labels.MANAGED}
                      </label>
                    </div>
                    <div className="flex basis-full text-center items-center">
                      <SMRadioButton
                        id="notManagedField"
                        field={field}
                        value={Labels.N}
                        checked={field?.value === Labels.N}
                        onChange={(e: any) => field.onChange(e.value)}
                      />
                      <label htmlFor="notManaged" className={`ml-2 font-normal text-center text-sm`}>
                        {Labels.NOT_MANAGED}
                      </label>
                    </div>
                    <div className="flex basis-full text-center items-center">
                      <SMRadioButton
                        id="bothField"
                        field={field}
                        value={Labels.B}
                        checked={field?.value === Labels.B}
                        onChange={(e: any) => field.onChange(e.value)}
                      />
                      <label htmlFor="both" className={`ml-2 font-normal text-center text-sm`}>
                        {Labels.BOTH}
                      </label>
                    </div>
                  </div>
                </>
              )}
            />
          </div>
          <div className="flex flex-col gap-1.5 mt-4">
            <label>{Labels.CATEGORIES}</label>
            <SMMultiSelect
              name="categories"
              display="chip"
              control={control}
              options={advanceSearchCatrgories}
              optionLabel="name"
              optionValue="id"
              filter={true}
              resetFilterOnHide={true}
            />
          </div>
          <div className="flex flex-col gap-1.5 mt-5">
            <label>{Labels.MANAGER}</label>
            <SMMultiSelect
              name="manager"
              display="chip"
              control={control}
              options={managerList?.filter((x) => x?.active === true)}
              optionLabel="name"
              optionValue="name"
              filter={true}
              resetFilterOnHide={true}
            />
          </div>
          <div className={`flex justify-end mt-5`}>
            <div className="flex items-center gap-3 flex-row order-last">
              <button type="button" className="transparent-button" onClick={handleReset}>
                {resetButtonText}
              </button>
              <button type="submit" className="btn-primary !ml-0">
                {searchButtonText}
              </button>
            </div>
          </div>
        </form>
      </div>
      <div id="resultSection" className="mt-2">
        <span>
          {Labels.RESULTS} {resultCount}
        </span>
        <hr className="mt-1"></hr>
        {result?.length > 0 ? (
          <div className="mt-5">
            <Accordion multiple>
              {result.map((group, index) => (
                <AccordionTab key={index} header={group.name}>
                  <ListBox
                    options={group.items}
                    itemTemplate={(item) => <SearchResultTemplate item={item} searchTerm={searchTerm} setShowSidebar={setShowSidebar} />}
                    className="w-full lg:w-14rem"
                    listStyle={{ maxHeight: "250px" }}
                    pt={{ item: { className: "!py-0" } }}
                  />
                </AccordionTab>
              ))}
            </Accordion>
          </div>
        ) : (
          !isLoading && (
            <div className="flex p-8 items-center justify-center">
              <span>{noResultFound}</span>
            </div>
          )
        )}
      </div>
    </>
  );
};

export default AdvanceSearchForm;
