"use client";
import { useHttpGet } from "src/services/api";
import Levies from "./levies";
import { EndPoints } from "src/constants/endpoint";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Errors } from "src/components/shared/error/error";
import { errorMessage } from "src/constants/constant";

export default function LeviesTab({ corpId, lotId }: { corpId: string; lotId: string }) {
  const { data: levyDetails, error: levyDetailsError } = useHttpGet(EndPoints.corporation + corpId + "/lot/" + lotId + "/levy");
  const { data: lotDetails, error: lotDetailsError } = useHttpGet(EndPoints.corporation + corpId + "/lots/" + lotId);
  const { data: unpaidDebitDetails} = useHttpGet(EndPoints.corporation + corpId + "/lot/" + lotId + "/levy/unpaid-debit");

  if (!levyDetails || !lotDetails) {
    return (
      <div className="flex w-full min-h-full items-center justify-center">
        <div className="w-20 h-20">
          <SpinnerHorizontal />
        </div>
      </div>
    );
  }

  if (levyDetailsError || lotDetailsError) {
    return <Errors error={errorMessage} alignment="left" />;
  }

  return <Levies levyDetails={levyDetails} lotDetails={lotDetails} unpaidDebitDetails={unpaidDebitDetails} corpId={corpId} lotId={lotId} />;
}
