"use client";

import { LeviesDetails } from "./leviesDetail/leviesDetail";
import { useMemo, useRef, useState } from "react";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { Labels } from "src/constants/labels";
import { UnpaidDebitsTable } from "./unpaid Debits/unpaidDebits";
import {
  cancelButtonText,
  contactHeader,
  editButtonText,
  modalDiscardMessageText,
  saveButtonText,
  selectButtonText,
} from "src/constants/constant";
import { ILeviesAddress } from "src/interfaces/leviesAddress";
import { useForm } from "react-hook-form";
import { Toast } from "primereact/toast";
import { AddUser, Binocular, DocumentWord2, Edit, Line, Mail} from "src/assets/icon";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Modal } from "src/components/ui/modal/modal";
import ToastContainer from "src/components/ui/toast/toast";
import { LeviesAddress } from "./leviesAddress/leviesAddress";
import { Card } from "src/components/ui/card/card";
import { LeviesContact } from "./leviesContact/leviesContact";
import { LeviesDelivery } from "./leviesDelivery/leviesDelivery";
import { httpUpdate } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { Messages } from "src/constants/messages";
import { mailToSend } from "src/utils";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import FormModal from "src/components/ui/dialog/formModal";
import { ContactDetail } from "../../shared/contactDetail";
import { Errors } from "src/components/shared/error/error";

export default function Levies({
  levyDetails,
  lotDetails,
  unpaidDebitDetails,
  corpId,
  lotId,
}: {
  levyDetails: any;
  lotDetails: any;
  unpaidDebitDetails: any;
  corpId: string;
  lotId: string;
}) {
  const { agency } = useSelector(
    (state: RootState) => state?.agencySettingSlice
  );
  const [lotDetail, setLotDetail]= useState<any>(lotDetails);
  const [levyDetail, setLevyDetail] = useState(levyDetails);
  const [contactDetail, setContactDetail] = useState(
    Object.assign(
      {},
      ...lotDetail?.lotContacts?.filter?.(
        (contact: any) => contact?.contactId === levyDetail?.levyContactId
      )
    )
  );
  const [isEditableLeviesDetail, setIsEditableLeviesDetail] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showAddContactModal, setShowAddContactModal] = useState<boolean>(false);
  const [showContactModal, setShowContactModal] = useState<boolean>(false);
  const [selectedAddress, setSelectedAddress] = useState(
    contactDetail?.contactType
  );
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [addContact, setAddContact] = useState<any>(null);
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isDirty, errors },
  } = useForm<ILeviesAddress>({
    defaultValues: {
      levyContactId: levyDetail?.levyContactId,
      levyIssue: levyDetail?.levyIssue,
      useOwnersLevyEmail: levyDetail?.useOwnersLevyEmail,
      useAgentsLevyEmail: levyDetail?.useAgentsLevyEmail,
      useTenantsLevyEmail: levyDetail?.useTenantsLevyEmail,
    },
    mode: "onSubmit",
  });

  const toastRef = useRef<Toast>(null);
  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  let otherType = 
    contactDetail?.contactId === 0 ||
    (contactDetail?.contactId !== lotDetail?.ownerContactId &&
    contactDetail?.contactId !== lotDetail?.tenantContactId &&    
    contactDetail?.contactId !== lotDetail?.agentContactId)
      ? true
      : false;

  const leviesContactButtons = [
    { buttonName: otherType ? <AddUser height={24} width={24} /> : "", 
      type:  "",
      onClick: (e: any) => {
        e.preventDefault();
        setShowAddContactModal(true);
        setAddContact(true);
      }
    },
    { buttonName: otherType ? <Edit fill="#464B4F" /> : "",
      type:  "",
      onClick: (e: any) => {
        e.preventDefault();
        if(Object.values(contactDetail).length > 0){
          if(contactDetail?.contactType === Labels.AGENT_CONTACT_TYPE){
            toastRef?.current?.show({
              severity: "warn",
              summary: "Warning",
              detail: Messages.NO_CHANGE_FOR_REAL_ESTATE_AGENT,
              life: 2000,
            });
          }
          else {
            setShowAddContactModal(true);
            setAddContact(false);
          }
        }
        else {
          toastRef?.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: Messages.NO_CONTACT_SELECTED,
            life: 2000,
          });        
        }

    }
    },
    // { buttonName: <DocumentWord2 height={24} width={24} />, type: "word" },
    // { buttonName: <Line height={32} />, type: "line" },
    { buttonName: otherType ? <Binocular /> : "", 
      type:  "next",
      onClick: (e: any) => {
        e.preventDefault();
        setShowContactModal(true);
      }
    },
    {
      buttonName: <Mail height={24} width={24} />,
      type: "next",
      onClick: (e: any) => {
        e.preventDefault();
        mailToSend(
          [
            {
            ...contactDetail,
            committeeEmailAddress: null,
            }
          ],
          toastRef,
          agency?.useAdditionalEmailAddresses,
          agency?.additionalEmailAddressOptions
        );
      },
    },
  ];
  const leviesDetailButtons = useMemo(() => {
    return [
      {
        buttonName: isEditableLeviesDetail ? cancelButtonText : editButtonText,
        type: "normal",
        onClick: isEditableLeviesDetail
          ? onCancel
          : () => setIsEditableLeviesDetail(true),
      },
      {
        buttonName: isEditableLeviesDetail ? saveButtonText : "",
        type: isEditableLeviesDetail ? "save" : "",
        buttonType: "submit",
        formId: "leviesDetailForm",
      },
    ];
  }, [isEditableLeviesDetail]);

  const setData = (data: any) => {
    setLevyDetail({
      ...levyDetail,
      levyContactId: data?.levyContactId,
      levyIssue: data?.levyIssue,
      useOwnersLevyEmail: data?.useOwnersLevyEmail,
      useAgentsLevyEmail: data?.useAgentsLevyEmail,
      useTenantsLevyEmail: data?.useTenantsLevyEmail,
    });
  };

  async function onSubmitLevies(data: ILeviesAddress) {
    setIsLoading(true);
    const output = await httpUpdate(
      EndPoints.corporation + corpId + "/lot/" + lotId + "/levies-contact",
      data
    );
    if (output?.status === 200) {
      setData(data);
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: 2000,
      });
      reset({}, { keepDirty: false, keepValues: true });
    }
    if (output?.error) {
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `error:${output?.error}`,
        life: 3000,
      });
    }
  }

  function onCancel() {
    setLevyDetail(levyDetail);
    setIsEditableLeviesDetail(false);
  }

  function onDiscard() {
    reset({
      levyContactId: levyDetail?.levyContactId,
      levyIssue: levyDetail?.levyIssue,
      useOwnersLevyEmail: levyDetail?.useOwnersLevyEmail,
      useAgentsLevyEmail: levyDetail?.useAgentsLevyEmail,
      useTenantsLevyEmail: levyDetail?.useTenantsLevyEmail,
    });

    let othertypeConatct = otherType && contactDetail?.contactId && !Boolean(lotDetail?.lotContacts?.find?.((contact: any) => contact?.contactId === levyDetail?.levyContactId));
    if(!othertypeConatct){
      setContactDetail(
        Object.assign(
          {},
          ...lotDetail?.lotContacts?.filter?.(
            (contact: any) => contact?.contactId === levyDetail?.levyContactId
          )
        )
      );
    }
    setShowModal(false);
  }

  const footerContent = (
    <div className={`flex justify-end`}>
      <div className="flex gap-2 flex-row order-last mt-4 mr-4">
        <button type="button" className="transparent-button" onClick={() => onDiscardSelectedContact()}>
          {cancelButtonText}
        </button>
        <button
          type="button"
          className={`btn-primary ${selectedRow ? "" : "opacity-50"}`}
          onClick={() => onDiffrentContactSelect(selectedRow)}
          disabled={selectedRow ? false : true}
        >
          {selectButtonText}
        </button>
      </div>
    </div>
  );

  function onDiffrentContactSelect(row : any){
    setContactDetail(row);
    setShowContactModal(false);
    setSelectedRow(null);
    setValue("levyContactId",row?.contactId, { shouldDirty: true, shouldValidate: true });
  }

  function onDiscardSelectedContact(){
    setShowContactModal(false);
    setSelectedRow(null);
  }

  function setLevyContactId(contactId: any){
    setValue("levyContactId", contactId, { shouldDirty: true, shouldValidate: true});
  }

  return (
    <>
      {isLoading && (
        <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-auto overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      {isDirty && (
      <>
        {errors?.levyContactId && (
          <Errors
            error={errors?.levyContactId?.message}
            alignment="left"
          />
        )}
        <div className={`flex justify-end`}>
          <div className="flex gap-3 flex-row order-last mt-5 mr-5">
            <button
              type="button"
              className="transparent-button"
              onClick={() => setShowModal(true)}
            >
              {cancelButtonText}
            </button>
            <button
              type="submit"
              className="btn-primary !ml-0"
              form="leviesForm"
            >
              {saveButtonText}
            </button>
          </div>
        </div>
      </>
      )}
      <div className={`flex flex-wrap mt-4`}>
        <div className="basis-full lg:basis-1/2">
          <CollapsibleCard
            id="leviesDetail"
            title={Labels.LEVIES_DETAILS}
            expand={true}
            buttons={leviesDetailButtons}
          >
            <LeviesDetails
              levyDetails={levyDetail}
              isEditableLeviesDetail={isEditableLeviesDetail}
              setIsEditableLeviesDetail={setIsEditableLeviesDetail}
              setLevyDetail={setLevyDetail}
              corpId={corpId}
              lotId={lotId}
              toastRef={toastRef}
            />
          </CollapsibleCard>
        </div>
        <div className="basis-full lg:basis-1/2">
          <form id="leviesForm" onSubmit={handleSubmit(onSubmitLevies)} noValidate>
            <Card id="levieAddress">
              <LeviesAddress
                contactDetail={contactDetail}
                setContactDetail={setContactDetail}
                lotDetails={lotDetail}
                setSelectedAddress={setSelectedAddress}
                control={control}
              />
            </Card>
            <CollapsibleCard
              id="leviesContact"
              title={Labels.CONTACT_FOR_LEVIES}
              expand={true}
              buttons={leviesContactButtons}
            >
              <LeviesContact contactDetail={contactDetail} setShowAddContactModal={setShowAddContactModal} showAddContactModal={showAddContactModal} setContactDetail={setContactDetail} setLevyContactId={setLevyContactId} addContact={addContact} setLotDetail={setLotDetail} lotDetail={lotDetail}></LeviesContact>
            </CollapsibleCard>
            <Card id="leviesDelivery">
              <LeviesDelivery
                contactDetail={contactDetail}
                lotDetails={lotDetail}
                lotContacts={lotDetail?.lotContacts}
                selectedAddress={selectedAddress}
                control={control}
              />
            </Card>
          </form>
        </div>
        <div className="w-full">
          <CollapsibleCard
            id="unpaidDebitDetails"
            title={Labels.UNPAID_DEBITS}
            expand={true}
          >
            <UnpaidDebitsTable unpaidDebitDetails={unpaidDebitDetails} />
          </CollapsibleCard>
        </div>
      </div>
      <FormModal
        width="80vw"
        height="90vh"
        header={contactHeader}
        isOpen={showContactModal}
        onClose={() => setShowContactModal(false)}
        footer={footerContent}
      >
        <ContactDetail contactType="O" selectedRow={selectedRow} setSelectedRow={setSelectedRow} />
      </FormModal>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Warning"
        onAction={() => onDiscard()}
      >
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
      <ToastContainer toastReference={toastRef} />
    </>
  );
}
