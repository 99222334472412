import { Toast } from "primereact/toast";
import { useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { Modal } from "src/components/ui/modal/modal";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import {
  entitlementObjId,
  entitlementObjkey,
  entitlementObjSetId,
  httpCode,
  lotNumberObjKey,
  modalTotalexceedMessage,
  toastErrorTimeLimit,
} from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import { IUnitEntitlementData } from "src/interfaces/unitentitlement";
import { httpUpdate } from "src/services/api";
import { RootState } from "src/store/rootReducer";
import { findEntitlementDetails, generateTableData } from "./unitEntitlementHelper";

const UnitEntitlementTable = ({ unitEntitlementData, headerEntitlement }: { unitEntitlementData: Array<IUnitEntitlementData>; headerEntitlement: any }) => {
  const { corpId } = useParams();
  const toastRef = useRef<Toast>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const { entitlementSetList } = useSelector((state: RootState) => state?.entitlementSlice);

  const calculateTotals = (data: any[], headerEntitlement: any) => {
    const total: any = {};

    for (let innerIndex = 0; innerIndex < headerEntitlement.length; innerIndex++) {
      total[`${entitlementObjkey}${innerIndex + 1}`] = data.reduce((a: number, b: any) => a + parseFloat(b?.[`${entitlementObjkey}${innerIndex + 1}`] || 0), 0);
    }

    return total;
  };

  const assignValue = (payload: any, field: string) => {
    let updatedTableData = unitEntitlementList?.map?.((entitlementUnit: any) => {
      const lotNo = entitlementUnit.lotNo?.split("|");
      if (Number(lotNo[1]) === payload?.lotId && entitlementUnit[field + entitlementObjSetId] === payload?.unitEntitlementSetId) {
        let updatedUnit = {
          ...entitlementUnit,
          [field]: payload?.entitlement?.toFixed(2),
        };
        return updatedUnit;
      }
      return entitlementUnit;
    });
    setUnitEntitlementList(updatedTableData);
  };

  const handleCellDataSave = async (data: any) => {
    const entitlementData = findEntitlementDetails(data?.newRowData, data?.field);
    const totalEntitlement =
      entitlementSetList.find((item: any) => item.unitEntitlementSetId === entitlementData?.unitEntitlementSetId)?.totalEntitlements ?? 0;
    const lotId = entitlementData.lotId?.split("|");
    let payload = {
      ...entitlementData,
      lotId: Number(lotId[1] ?? entitlementData.lotId),
      entitlement: Number(entitlementData.entitlement),
    };
    assignValue(payload, data?.field);
    let response: any = await httpUpdate(EndPoints.corporation + corpId + "/unit-entitlements", [payload]);
    if (response?.status === httpCode.SUCCESS) {
      const updatedId = response?.data?.updatedIds ?? 0;
      let updatedTableData = unitEntitlementList?.map?.((entitlementUnit: any) => {
        const lotNo = entitlementUnit.lotNo?.split("|");
        if (
          Number(lotNo[1]) === payload?.lotId &&
          entitlementUnit[data.field + entitlementObjSetId] === payload?.unitEntitlementSetId &&
          updatedId?.length > 0
        ) {
          let updatedUnit = {
            ...entitlementUnit,
            [data?.field]: payload?.entitlement?.toFixed(2),
          };
      
          if (payload?.unitEntitlementId === 0) {
            updatedUnit = {
              ...updatedUnit,
              [data?.field + entitlementObjId]: updatedId[0],
            };
          }
          return updatedUnit;
        }
        return entitlementUnit;
      });
      const updatedTotals = calculateTotals(updatedTableData.slice(1), headerEntitlement);
      if (updatedTotals[data?.field] > totalEntitlement) {
        setShowModal(true);
      }
      updatedTableData[0] = { [lotNumberObjKey]: "Total", ...updatedTotals };
      setUnitEntitlementList(updatedTableData);
    }
    if (response?.error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Error: ${response?.error}`,
        life: toastErrorTimeLimit,
      });
    }
  };
  const initialTableData = generateTableData(unitEntitlementData, headerEntitlement, handleCellDataSave);
  const [unitEntitlementList, setUnitEntitlementList] = useState(initialTableData.tableData);
  const columns = useMemo(() => initialTableData.tableColumns, [initialTableData.tableColumns]);
  return (
    <>
      <CollapsibleCard id="unitEntitlementSetTable" title="" expand={true} isCollapsible={false} buttons={[]}>
        <div className="pt-4">
          <SMDataTableContainer tableData={unitEntitlementList} tableColumns={columns} showEditIcon={false} showSelectableRowCheckbox={false} editMode="cell" />
        </div>
      </CollapsibleCard>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Warning" hideFooter={true}>
        <div>
          <h2>{modalTotalexceedMessage}</h2>
        </div>
      </Modal>
    </>
  );
};

export default UnitEntitlementTable;
