import { Line, Plus, ViewColumn } from "src/assets/icon";
import { MortgageTable } from "./mortgageTable/mortgageTable";
import { IMortgage } from "src/interfaces/lotOwner";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { Labels } from "src/constants/labels";
import { useRef, useState } from "react";
import { comingSoon } from "src/constants/constant";
import { Toast } from "primereact/toast";
import ToastContainer from "src/components/ui/toast/toast";

export default function Mortgage({
  mortgageDetails,
  corpId,
}: {
  mortgageDetails: Array<IMortgage>;
  corpId: string;
}) {
  const [mortgageTableData, setMortgageTableData] = useState(mortgageDetails);
  const toastRef = useRef<Toast>(null);
  const mortgageTableButtons = [
    {
      buttonName: <Plus height={24} width={24} />,
      type: "word",
      onClick: () =>
        toastRef?.current?.show({
          severity: "info",
          summary: "Info",
          detail: comingSoon,
          life: 2000,
        }),
    },
    { buttonName: <Line height={32} />, type: "line" },
    {
      buttonName: <ViewColumn />,
      onClick: () =>
        toastRef?.current?.show({
          severity: "info",
          summary: "Info",
          detail: comingSoon,
          life: 2000,
        }),
    },
  ];
  return (
    <>
      <CollapsibleCard
        id="mortgageTable"
        title={Labels.PAST_AND_PRESENT_MORTGAGES}
        expand={true}
        buttons={mortgageTableButtons}
      >
        {mortgageDetails?.length > 0 ? (
          <div className="w-full pt-4">
            <MortgageTable
              corpId={corpId}
              mortgageTableData={mortgageTableData}
              setMortgageTableData={setMortgageTableData}
              toastRef={toastRef}
            />
          </div>
        ) : (
          <div
            className={`w-full rounded-lg text-center shadow-lg flex-row p-10 mt-5`}
          >
            <p>{Labels.NO_MORTGAGE_AVAILABLE}</p>
          </div>
        )}
      </CollapsibleCard>
      <ToastContainer toastReference={toastRef} />
    </>
  );
}
