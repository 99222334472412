import { useRef, useState } from "react";
import { Arrow } from "../../../assets/icon";
import BadgeComponent from "./badgeComponent";
import OverlayComponent from "./overlayComponent";

const BadgeContainer = ({ data }: { data: any }) => {
  const [rotate, setRotate] = useState<number>(90);
  const op = useRef<any>(null);

  return (
    <div className="flex justify-between">
      <BadgeComponent value={data?.length} overlayPanelRef={op} />
      <OverlayComponent data={data} overlayPanelRef={op} />
      <span
        onClick={(e) => {
          setRotate(360 - rotate);
          op?.current?.toggle?.(e);
        }}
      >
        <Arrow width={18} height={22} rotate={rotate} fill="#464B4F" />
      </span>
    </div>
  );
};

export default BadgeContainer;
