import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ChevronDown1, ChevronUp1, Plus } from "src/assets/icon";
import { Errors } from "src/components/shared/error/error";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Modal } from "src/components/ui/modal/modal";
import { titleList, type } from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import { IAdditionalContact } from "src/interfaces/additionalContact";
import { IContact } from "src/interfaces/ownerContact";
import { httpPost, httpUpdate } from "src/services/api";
import styles from "src/styles/texts.module.scss";
import { AdditionalContact } from "./additionalContact";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import { findId, findValue } from "src/utils";
export const EditableContact: React.FC<{
  contactDetail: IContact;
  setEditable?: any;
  title: string;
  additionalDetails: IAdditionalContact[];
  showCollapsible?: boolean;
  lmcCommunicationChecks?: boolean;
  showFullPortalAccess?: boolean;
  setData?: any;
  setAdditionalContactData?: any;
  formId?: string;
  setIsFormDirty?: any;
  showCancelModal?: any;
  setShowCancelModal?: any;
  contactType?: string;
  committeeMember?: boolean;
  toastRef: any;
}> = ({
  contactDetail,
  setEditable,
  title,
  additionalDetails,
  showCollapsible = true,
  lmcCommunicationChecks = true,
  showFullPortalAccess = false,
  setData,
  setAdditionalContactData,
  formId,
  setIsFormDirty,
  showCancelModal,
  setShowCancelModal,
  contactType = Labels.OWNER_CONTACT_TYPE,
  committeeMember = false,
  toastRef,
}) => {
  const {
    control,
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    formState: { isDirty, errors, isValid },
  } = useForm<IContact>({
    defaultValues: {
      businessContact: contactDetail?.businessContact ?? false,
      buildingName: contactDetail?.buildingName ?? "",
      salutation: contactDetail?.salutation ?? "",
      name: contactDetail?.name ?? "",
      firstName: contactDetail?.firstName ?? "",
      otherNames: contactDetail?.otherNames ?? "",
      title: contactDetail?.title ?? "",
      streetNumber: contactDetail?.streetNumber ?? "",
      streetName: contactDetail?.streetName ?? "",
      town: contactDetail?.town ?? "",
      state: contactDetail?.state ?? "",
      postcode: contactDetail?.postcode ?? "",
      country: contactDetail?.country ?? "",
      website: contactDetail?.website ?? "",
      fax: contactDetail?.fax ?? "",
      email: contactDetail?.email ?? "",
      telephone1: contactDetail?.telephone1 ?? "",
      telephone2: contactDetail?.telephone2 ?? "",
      telephone3: contactDetail?.telephone3 ?? "",
      committeeEmailAddress: contactDetail?.committeeEmailAddress ?? "",
      poBox: contactDetail?.poBox ?? "",
      postOfficeAddress: contactDetail?.poBox ? true : false,
      contactId: contactDetail?.contactId ?? 0,
      contactType: findValue(type, contactDetail?.contactType) ?? findValue(type, contactType),
      levyEmailAddress: contactDetail?.levyEmailAddress?? "",
      amasStatus: contactDetail?.amasStatus ?? "",
      bsp: contactDetail?.bsp ?? "",
      dpid: contactDetail?.dpid ?? 0,
      amasBarcode: contactDetail?.amasBarcode ?? "",
      franchise: contactDetail?.franchise ?? "",
      webAccessUsername: contactDetail?.webAccessUsername ?? "",
      webAccessPassword: contactDetail?.webAccessPassword ?? "",
      webAccessEnabled: contactDetail?.webAccessEnabled ?? false,
      email2: contactDetail?.email2 ?? "",
      email3: contactDetail?.email3 ?? "",
      webAccessNewPassword: contactDetail?.webAccessNewPassword ?? "",
      webAccessOTPExpiration: contactDetail?.webAccessOTPExpiration ?? null,
      webAccessPasswordResetMode: contactDetail?.webAccessPasswordResetMode ?? false,
      portalFullAccessRight: contactDetail?.portalFullAccessRight ?? false,
      smataUsername: contactDetail?.smataUsername ?? "",
    },
    mode: "onSubmit",
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isActive, setIsActive] = useState(true);
  const businessContact = watch("businessContact");
  const postOfficeAddress = watch("postOfficeAddress");

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  useEffect(() => {
    if (formId && setIsFormDirty) setIsFormDirty(isDirty);
  }, [isDirty]);

  useEffect(() => {
    if (postOfficeAddress) setValue("poBox", "");
  }, [postOfficeAddress]);

  function onDiscard() {
    setEditable(false);
    setShowModal(false);
    if (setShowCancelModal) setShowCancelModal(false);
    setData(contactDetail);
  }

  function onCancel() {
    if (isDirty) {
      setShowModal(true);
      if (setShowCancelModal) setShowCancelModal(true);
    } else {
      setEditable(false);
    }
  }

  async function onSubmitContactDetails(e: React.FormEvent<HTMLFormElement> ) {
    e.preventDefault();
    e.stopPropagation();
    if(!isValid) {
      trigger() 
      return;
    }
    let data = getValues();
    let payload = {
      ...data,
      contactType: findId(type, data?.contactType),
      buildingName: postOfficeAddress ? "" : data?.buildingName,
      streetNumber: postOfficeAddress ? "" : data?.streetNumber,
      streetName: postOfficeAddress ? "" : data?.streetName,
    }
    setIsLoading(true);
    const output = contactDetail?.contactId ?
        await httpUpdate(EndPoints.contact + `${contactDetail.contactId}`, payload)
      :
        await httpPost(`contact`, payload);

    if (output?.status === 200) {
      if (setData) {
        contactDetail?.contactId ?
          setData({
            ...payload,
            email: payload?.email?.trim(),
            additionalContactDetails: additionalDetails,
          })
        :
          setData({
            ...payload,
            email: payload?.email?.trim()
          }, output?.data)
      }
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: 2000,
      });
      reset({}, {keepDirty: false})
      setEditable(false);
    }
    if (output?.error) {
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `error:${output?.error}`,
        life: 3000,
      });
    }
  }

  const checkKeyDown = (e: any) => {
    if (e.key === "Enter") e.preventDefault();
  };
  const isEmailValid = (value: any) => {
    if (value) {
      const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      const emails = value?.split(",");
      for (const email of emails) {
        const trimmedEmail = email?.trim();
        if (!emailRegex.test(trimmedEmail)) {
          return false;
        }
      }
    }
    return true;
  };
  return (
    <>
      {isLoading && (
        <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-auto overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <form
        id={formId ?? ""}
        onSubmit={onSubmitContactDetails}
        onKeyDown={(e) => checkKeyDown(e)}
        noValidate
      >
        {showCollapsible && (
          <div className="w-full flex justify-between h-10 items-center py-2">
            <div className="flex items-center self-stretch gap-4">
              <label className="section-header">{title}</label>
            </div>
            <div className="flex order-last items-center justify-center">
              <button
                type="button"
                className={`order-last edit-btn`}
                onClick={onCancel}
              >
                {Labels.CANCEL}
              </button>
              <button
                id="submit"
                className={`order-last edit-btn btn-primary ${
                  !isDirty ? "opacity-60" : "opacity-100"
                }`}
                type="submit"
                disabled={!isDirty}
              >
                {Labels.SAVE}
              </button>
              <span
                className="order-last ml-4 p-2"
                onClick={() => setIsActive(!isActive)}
              >
                {isActive ? (
                  <ChevronUp1 height={16} width={16} stroke="#626262" />
                ) : (
                  <ChevronDown1 height={16} width={16} stroke="#626262" />
                )}
              </span>
            </div>
          </div>
        )}
        {isActive && (
          <>
            <div>
              <div className={`flex py-4 border-b-2 gap-10`}>
                <div className="basis-full lg:basis-1/2">
                  <div className="w-full flex justify-between h-10 items-center">
                    <label className="text-sm font-bold">
                      {Labels.CONTACT_DETAILS_}
                    </label>
                    <SMCheckbox
                      name="businessContact"
                      control={control}
                      label={Labels.BUSINESS}
                    />
                  </div>
                  <div className={`${styles.header} mt-2 !border-none`}>
                    <label className={styles.label}>
                      {Labels.CONTACT_TYPE}
                    </label>
                    <SMInputText
                      name="contactType"
                      control={control}
                      value={contactType}
                      disabled={true}
                    />
                  </div>
                  {!businessContact && (
                    <div className={`${styles.header} mt-2 !border-none`}>
                      <label className={styles.label}>{Labels.TITLE}</label>
                      <SMDropDown
                        control={control}
                        name="title"
                        placeholder={"Title"}
                        options={titleList}
                        editable={true}
                      />
                    </div>
                  )}
                  <div className={`${styles.header} mt-2 !border-none`}>
                    <label className={styles.label}>
                      {Labels.SALUTATION.replace(/:/g, "")}
                    </label>
                    <SMInputText
                      control={control}
                      name="salutation"
                      maxlength={50}
                    />
                  </div>
                  <div className={`${styles.header} mt-2 !border-none`}>
                    <label className={styles.label}>
                      {businessContact ? Labels.BUSINESS : Labels.SURNAME}
                    </label>
                    <SMInputText control={control} name="name" maxlength={50} />
                  </div>
                  {!businessContact && (
                    <div className={`${styles.header} mt-2 !border-none`}>
                      <label className={styles.label}>
                        {Labels.FIRST_NAME}
                      </label>
                      <SMInputText
                        control={control}
                        name="firstName"
                        maxlength={50}
                      />
                    </div>
                  )}
                  <div className={`${styles.header} mt-2 !border-none`}>
                    <label className={styles.label}>
                      {businessContact
                        ? Labels.CONTACT_NAME
                        : Labels.OTHER_NAME}
                    </label>
                    <SMInputText
                      control={control}
                      name="otherNames"
                      maxlength={50}
                    />
                  </div>
                </div>
                {lmcCommunicationChecks && (
                  <div className="basis-full lg:basis-1/2">
                    <div className="w-full flex justify-between h-10 items-center">
                      <label className="text-sm font-bold">
                        {Labels.RECEIVE_COMMUNICATION}
                      </label>
                      <SMCheckbox
                        name="mainContact"
                        control={control}
                        label={Labels.MAIN_CONTACT}
                        disabled={true}
                        value={true}
                      />
                    </div>
                    {/* <div className={`${styles.section}`}>
                      <div className="basis-full pt-5">
                        <SMCheckbox
                          name="levies"
                          control={control}
                          label={Labels.LEVIES}
                          disabled={true}
                          value={true}
                        />
                      </div>
                      <div className="basis-full pt-5">
                        <SMCheckbox
                          name="meetingDocs"
                          control={control}
                          label={Labels.MEETING_DOCS}
                          disabled={true}
                          value={true}
                        />
                      </div>
                      <div className="basis-full pt-5">
                        <SMCheckbox
                          name="correspondence"
                          control={control}
                          label={Labels.CORRESPONDENCE}
                          disabled={true}
                          value={true}
                        />
                      </div>
                    </div> */}
                  </div>
                )}
              </div>
              <div className={`flex py-4 border-b-2 gap-10`}>
                <div className="basis-full lg:basis-1/2">
                  <div className="w-full flex justify-between h-10 items-center">
                    <label className="text-sm font-bold">
                      {Labels.ADDRESS}
                    </label>
                    <SMCheckbox
                      name="postOfficeAddress"
                      control={control}
                      label={Labels.POST_OFFICE_ADDRESS}
                    />
                  </div>
                  {postOfficeAddress ? (
                    <div className={`${styles.header} mt-2 !border-none`}>
                      <label className={styles.label}>{Labels.PO_BOX}</label>
                      <SMInputText
                        control={control}
                        name="poBox"
                        maxlength={20}
                      />
                    </div>
                  ) : (
                    <>
                      <div className={`${styles.header} mt-2 !border-none`}>
                        <label className={styles.label}>
                          {Labels.BUILDING_NAME}
                        </label>
                        <SMInputText
                          control={control}
                          name="buildingName"
                          maxlength={50}
                        />
                      </div>
                      <div className={`${styles.header} mt-2 !border-none`}>
                        <label className={styles.label}>
                          {Labels.UNIT_STREET_NUMBER}
                        </label>
                        <SMInputText
                          control={control}
                          name="streetNumber"
                          maxlength={20}
                        />
                      </div>
                      <div className={`${styles.header} mt-2 !border-none`}>
                        <label className={styles.label}>{Labels.STREET}</label>
                        <SMInputText
                          control={control}
                          name="streetName"
                          maxlength={80}
                        />
                      </div>
                    </>
                  )}
                  <div className={`${styles.header} mt-2 !border-none`}>
                    <label className={styles.label}>{Labels.TOWN_SUBURB}</label>
                    <SMInputText control={control} name="town" maxlength={80} />
                  </div>
                  <div className={`${styles.header} mt-2 !border-none`}>
                    <label className={styles.label}>{Labels.STATE}</label>
                    <SMInputText
                      control={control}
                      name="state"
                      maxlength={50}
                    />
                  </div>
                  <div className={`${styles.header} mt-2 !border-none`}>
                    <label className={styles.label}>{Labels.POSTCODE}</label>
                    <SMInputText
                      control={control}
                      name="postcode"
                      maxlength={10}
                    />
                  </div>
                  <div className={`${styles.header} mt-2 !border-none`}>
                    <label className={styles.label}>{Labels.COUNTRY}</label>
                    <SMInputText
                      control={control}
                      name="country"
                      maxlength={50}
                    />
                  </div>
                </div>
                <div className="basis-full lg:basis-1/2">
                  <div className="w-full flex h-10 items-center">
                    <label className="text-sm font-bold">
                      {Labels.PRIMARY_CONTACT_DETAILS}
                    </label>
                  </div>
                  <div className={`${styles.header} mt-2 !border-none`}>
                    <label className={styles.label}>
                      {businessContact
                        ? Labels.TELEPHONE_1.replace(/:/g, "")
                        : Labels.HOME_PHONE.replace(/:/g, "")}
                    </label>
                    <SMInputText
                      control={control}
                      name="telephone1"
                      maxlength={50}
                    />
                  </div>
                  <div className={`${styles.header} mt-2 !border-none`}>
                    <label className={styles.label}>
                      {businessContact
                        ? Labels.TELEPHONE_2.replace(/:/g, "")
                        : Labels.WORK_PHONE.replace(/:/g, "")}
                    </label>
                    <SMInputText
                      control={control}
                      name="telephone2"
                      maxlength={50}
                    />
                  </div>
                  <div className={`${styles.header} mt-2 !border-none`}>
                    <label className={styles.label}>
                      {Labels.MOBILE.replace(/:/g, "")}
                    </label>
                    <SMInputText
                      control={control}
                      name="telephone3"
                      maxlength={50}
                    />
                  </div>
                  <div className={`${styles.header} mt-2 !border-none`}>
                    <label className={styles.label}>
                      {Labels.FAX.replace(/:/g, "")}
                    </label>
                    <SMInputText control={control} name="fax" maxlength={50} />
                  </div>
                  <div className={`${styles.header} mt-2 !border-none`}>
                    <label className={styles.label}>
                      {Labels.EMAIL_ADDRESS}
                    </label>
                    <SMInputText
                      control={control}
                      name="email"
                      maxlength={100}
                      rules={{ validate: { validEmail: isEmailValid } }}
                    />
                  </div>
                  {errors?.email && (
                    <Errors
                      error={errors?.email?.message || Messages.INVALID_EMAIL}
                      alignment="right"
                    />
                  )}
                  {committeeMember && (
                    <div className={`${styles.header} mt-2 !border-none`}>
                      <label className={styles.label}>
                        {Labels.COMMITTEE_EMAIL_ADDRESS}
                      </label>
                      <SMInputText
                        control={control}
                        name="committeeEmailAddress"
                        maxlength={100}
                        rules={{ validate: isEmailValid }}
                      />
                    </div>
                  )}
                  {errors?.committeeEmailAddress && (
                    <Errors
                      error={
                        errors?.committeeEmailAddress?.message ||
                        Messages.INVALID_EMAIL
                      }
                      alignment="right"
                    />
                  )}
                  {businessContact && (
                    <div className={`${styles.header} mt-2 !border-none`}>
                      <label className={styles.label}>
                        {Labels.WEBSITE.replace(/:/g, "")}
                      </label>
                      <SMInputText
                        control={control}
                        name="website"
                        maxlength={100}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div>
              {showFullPortalAccess && <div className="w-full flex h-10 items-center py-10">
                <SMCheckbox
                  name="portalFullAccessRight"
                  control={control}
                  label={Labels.FULL_PORTAL_ACCESS}
                  />
              </div>}
              </div>
              {
                contactDetail?.contactId &&
                <div className={`py-4`}>
                  <div className="w-full">
                    <AdditionalContact
                      additionalDetails={additionalDetails}
                      setAdditionalContactData={setAdditionalContactData}
                      businessContact={businessContact}
                      contactId={contactDetail?.contactId}
                    />
                  </div>
                </div>
              }
            </div>
            <Modal
              isOpen={showCancelModal ?? showModal}
              onClose={() => {
                setShowModal(false);
                if (setShowCancelModal) setShowCancelModal(false);
              }}
              title="Warning"
              onAction={() => onDiscard()}
            >
              <div>
                <h2>{Messages.DISCARD_MESSAGE}</h2>
              </div>
            </Modal>
          </>
        )}
      </form>
    </>
  );
};
