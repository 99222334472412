import { Labels } from "src/constants/labels";
import styles from "../managementFee.module.scss";
import { SMDatePicker } from "src/components/ui/datePicker/datePicker";
import { FormatDate, toDate, toLocaleValue, validateDecimal } from "src/utils/utility";
import { Controller, useForm } from "react-hook-form";
import { IManagementFeesData, IManagementFees } from "src/interfaces/managementfees";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { useEffect, useState } from "react";
import { NIL, cancelButtonText, httpCode, modalDiscardMessageText, saveButtonText, toastErrorTimeLimit, toastSuccessTimeLimit } from "src/constants/constant";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import { SMRadioButton } from "src/components/ui/radioControl/radioButton";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { httpUpdate } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { useParams } from "react-router-dom";
import { Messages } from "src/constants/messages";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import { Modal } from "src/components/ui/modal/modal";
import { Errors } from "src/components/shared/error/error";

const ManagementFeeSelected = ({
  selectedFee,
  managementFeesData,
  setManagementFeesData,
  setSelectedFee,
  toastRef,
  setIsFormDirty,
}: {
  selectedFee: IManagementFees;
  managementFeesData: IManagementFeesData;
  setManagementFeesData: any;
  setSelectedFee: any;
  toastRef: any;
  setIsFormDirty: any;
}) => {
  const { corpId } = useParams();
  const [isEditable, setIsEditable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showRateModal, setShowRateModal] = useState<boolean>(false);
  const formId = "managementFeeSelectedForm";
  const feeSelectedButtons = [
    {
      buttonName: isEditable ? cancelButtonText : Labels.EDIT,
      type: "normal",
      buttonType: "button",
      disabled: !selectedFee?.feeCodeId,
      onClick: () => {
        if (isEditable) {
          onCancel();
        } else {
          setIsEditable(true);
        }
      },
    },
    {
      buttonName: isEditable ? saveButtonText : "",
      type: isEditable ? "save" : "",
      buttonType: "submit",
      formId: formId,
    },
  ];
  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      rate: selectedFee?.rate?.replaceAll?.(",", ""),
      newRate: selectedFee?.newRate?.replaceAll?.(",", ""),
      autoIncrement: selectedFee?.autoIncrement,
      newFeeScheduleApplies: managementFeesData?.newFeeScheduleApplies,
      autoIncrementBy: managementFeesData?.autoIncrementBy,
      autoIncrementFixedRate: managementFeesData?.autoIncrementFixedRate,
    },
  });
  const autoIncrementBy = watch("autoIncrementBy");
  const rate = watch("rate");
  const newRate = watch("newRate");

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  useEffect(() => {
    setIsFormDirty(isDirty);
  }, [isDirty]);

  const checkNewRate = (data: any) => {
    if (!data?.newFeeScheduleApplies || !Boolean(FormatDate(data?.newFeeScheduleApplies))) {
      if (Number(newRate) === Number(rate)) {
        onSubmit(data);
      } else {
        toastRef?.current?.show({
          severity: "warn",
          summary: "Warning",
          detail: Messages.MANAGEMENT_FEES_DATE_VALIDATION,
          life: toastErrorTimeLimit,
        });
      }
    } else {
      if (data?.newRate === NIL || !Boolean(Number(data?.newRate))) {
        setShowRateModal(true);
        return;
      } else {
        onSubmit(data);
      }
    }
  };

  const onSubmit = async (data: any) => {
    let payload = {
      ...data,
      rate: data?.rate === NIL || !Boolean(data?.rate) ? 0 : data?.rate,
      newRate: data?.newRate === NIL || !Boolean(data?.newRate) ? 0 : data?.newRate,
      newFeeScheduleApplies: toDate(FormatDate(data?.newFeeScheduleApplies) ?? ""),
      feeCodeId: selectedFee?.feeCodeId,
    };
    setIsLoading(true);
    const output: any = await httpUpdate(`${EndPoints.corporation}${corpId}/${EndPoints.managementFees}/${selectedFee?.feeId}`, payload);
    let createdFeeId = output?.data?.result?.[0]?.Id;
    if (output?.status === httpCode.SUCCESS) {
      setManagementFeesData((prevState: IManagementFeesData) => {
        return {
          ...prevState,
          managementFeeDetails: prevState?.managementFeeDetails?.map?.((managementFee: IManagementFees) => {
            if (createdFeeId ? selectedFee?.feeCodeId === managementFee?.feeCodeId : selectedFee?.feeId === managementFee?.feeId) {
              return {
                ...managementFee,
                rate: !Number(data?.rate) || data?.rate === NIL ? NIL : toLocaleValue(Number(data?.rate)),
                newRate: !Number(data?.newRate) || data?.newRate === NIL ? NIL : toLocaleValue(Number(data?.newRate)),
                autoIncrement: data?.autoIncrement,
                feeId: createdFeeId ? createdFeeId : selectedFee?.feeId,
              };
            } else {
              return {
                ...managementFee,
              };
            }
          }),
          newFeeScheduleApplies: data?.newFeeScheduleApplies,
          autoIncrementBy: data?.autoIncrementBy,
          autoIncrementFixedRate: Boolean(autoIncrementBy) ? data?.autoIncrementFixedRate : 0,
        };
      });
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: toastSuccessTimeLimit,
      });
      reset({}, { keepDirty: false, keepValues: true });
      setIsEditable(false);
      setSelectedFee({});
    }
    if (output?.error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Error: ${output?.error}`,
        life: toastErrorTimeLimit,
      });
    }
    setIsLoading(false);
  };

  const onCancel = () => {
    if (isDirty) {
      setShowModal(true);
    } else {
      setIsEditable(false);
    }
  };

  const onDiscard = () => {
    setShowModal(false);
    setIsEditable(false);
    setSelectedFee({});
  };

  return (
    <>
      <CollapsibleCard id="feeSelected" title="Fee Selected" expand={true} isCollapsible={false} buttons={feeSelectedButtons}>
        <div className="w-full pt-4">
          {isLoading && (
            <div className="spinnerContainer">
              <div className="w-20 h-20">
                <SpinnerHorizontal />
              </div>
            </div>
          )}
          <form className="mt-5" id={formId} onSubmit={handleSubmit(checkNewRate)}>
            {selectedFee?.feeCodeId && (
              <div>
                <div className="w-full flex gap-4">
                  <div className={`${styles.labelValueContainer} ${styles.lineBreak}`}>
                    <label className={styles.label}>{Labels.CODE}</label>
                    <div className="w-1/2 text-left text !text-sm !font-bold">{selectedFee?.code}</div>
                  </div>
                  <div className={`${styles.labelValueContainer} ${styles.lineBreak}`}>
                    <label className={styles.label}>{Labels.CURRENT_RATE}</label>
                    <div className="w-1/2 text-left text !text-sm !font-bold">
                      {isEditable ? (
                        <>
                          <SMInputText
                            name="rate"
                            control={control}
                            keyfilter={"num"}
                            autoComplete="off"
                            maxlength={17}
                            rules={{
                              validate: (value: string) => {
                                return validateDecimal(value, 2);
                              },
                            }}
                          />
                          {errors.rate && <Errors error={Messages.RESERVE_FUND_VALIDATION} alignment="left" />}
                        </>
                      ) : (
                        selectedFee?.rate
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full flex gap-4 mt-5">
                  <div className={`${styles.labelValueContainer} ${styles.lineBreak}`}>
                    <label className={styles.label}>Service</label>
                    <div className="w-1/2 text-left text !text-sm !font-bold">{selectedFee?.description}</div>
                  </div>
                  <div className={`${styles.labelValueContainer} ${styles.lineBreak}`}>
                    <label className={styles.label}>{Labels.NEW_RATE}</label>
                    <div className="w-1/2 text-left text !text-sm !font-bold">
                      {isEditable ? (
                        <SMInputText
                          name="newRate"
                          control={control}
                          keyfilter={"num"}
                          autoComplete="off"
                          maxlength={17}
                          rules={{
                            validate: (value: string) => {
                              return validateDecimal(value, 2);
                            },
                          }}
                        />
                      ) : (
                        selectedFee?.newRate
                      )}
                      {errors.newRate && <Errors error={Messages.RESERVE_FUND_VALIDATION} alignment="left" />}
                    </div>
                  </div>
                </div>
                <div className="w-full flex gap-4 mt-5">
                  <div className={`w-1/2 flex items-center`}>
                    <SMCheckbox
                      name="autoIncrement"
                      control={control}
                      disabled={!isEditable}
                      label={"Fee subject to auto increment for the selected fee code"}
                    />
                  </div>
                  <div className={`${styles.labelValueContainer} ${styles.lineBreak}`}>
                    <label className={styles.label}>Billing Unit (per)</label>
                    <div className="w-1/2 text-left text !text-sm !font-bold">{selectedFee?.billingUnit}</div>
                  </div>
                </div>
                <div className="w-full text-right text-sm mt-2">
                  {selectedFee?.feeType === "M" ? "This fee is included in the monthly billing process" : "This fee is manually processed"}
                </div>
              </div>
            )}
            <div className={`${styles.lineBreak} mt-4`}></div>
            <div className="w-4/6 flex justify-between items-center mt-5">
              <label className="w-1/4 text !text-sm !font-bold">Auto Increment Date</label>
              <div className="w-full flex justify-between items-center">
                <SMDatePicker
                  inputDate={FormatDate(managementFeesData?.newFeeScheduleApplies)}
                  name="newFeeScheduleApplies"
                  control={control}
                  disabled={!isEditable}
                  width="w-1/3"
                />
                <div className="w-1/3 flex items-center justify-center">
                  <Controller
                    name="autoIncrementBy"
                    control={control}
                    render={({ field }) => (
                      <div className="w-full flex">
                        <div className="flex justify-end basis-full text-left">
                          <SMRadioButton id="cpi" field={field} value={0} checked={field?.value === 0} disabled={!isEditable} />
                          <label className={`ml-2 font-normal text-left text-sm`}>{"Use CPI"}</label>
                        </div>
                        <div className="flex justify-center basis-full text-left items-center">
                          <SMRadioButton id="use" field={field} value={1} checked={field?.value === 1} disabled={!isEditable} />
                          <label className={`ml-2 font-normal text-left text-sm`}>{"Use"}</label>
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div className="w-1/3 flex flex-col">
                  <div className="w-full flex items-center gap-1">
                    <SMInputText
                      name="autoIncrementFixedRate"
                      control={control}
                      keyfilter={"num"}
                      autoComplete="off"
                      disabled={isEditable ? !Boolean(autoIncrementBy) : true}
                      maxlength={12}
                      rules={{
                        validate: (value: string) => {
                          return validateDecimal(value, 2);
                        },
                      }}
                    />
                    <span className="text-base font-semibold text-gray-500">{"%"}</span>
                  </div>
                  {errors.autoIncrementFixedRate && <Errors error={Messages.RESERVE_FUND_VALIDATION} alignment="left" />}
                </div>
              </div>
            </div>
          </form>
        </div>
        <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Warning" onAction={() => onDiscard()}>
          <div>
            <h2>{modalDiscardMessageText}</h2>
          </div>
        </Modal>
        <Modal
          isOpen={showRateModal}
          onClose={() => setShowRateModal(false)}
          title="Warning"
          onAction={() => {
            let data = getValues();
            setShowRateModal(false);
            onSubmit(data);
          }}
        >
          <div>
            <h2>{Messages.MANAGEMENT_FEES_RATE_VALIDATION}</h2>
          </div>
        </Modal>
      </CollapsibleCard>
    </>
  );
};

export default ManagementFeeSelected;
