import { Column } from "primereact/column";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { useMemo } from "react";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { Labels } from "src/constants/labels";
import styles from "../../../budget.module.scss";
import { checkZeroValue, convertToCurrency, toLocaleValue } from "src/utils/utility";
import { ILotContributionData } from "src/interfaces/lotContribution";

const LotContributionsTable = ({ data }: { data: ILotContributionData }) => {
  const total = useMemo(() => {
    return data?.lotContributions?.reduce(
      (accumulator, current) => {
        return {
          ...accumulator,
          ueTotal: (accumulator?.ueTotal ?? 0) + (current?.ue ?? 0),
          adminContributionTotal: (accumulator?.adminContributionTotal ?? 0) + (current?.adminContribution ?? 0),
          capitalWorksTotal: (accumulator?.capitalWorksTotal ?? 0) + (current?.capitalWorksContribution ?? 0),
          finalTotal: (accumulator?.finalTotal ?? 0) + ((current?.adminContribution ?? 0) + (current?.capitalWorksContribution ?? 0)),
        };
      },
      {
        ueTotal: 0,
        adminContributionTotal: 0,
        capitalWorksTotal: 0,
        finalTotal: 0,
      }
    );
  }, []);

  const columns = useMemo(() => {
    return [
      {
        field: "unitNumber",
        header: "",
        body: (data: any) => {
          return (
            <p className={`text !font-semibold`}>
              {data?.unitNumberPrefix}
              {checkZeroValue(data?.unitNumber) ?? ""}
              {data?.unitNumberSuffix}
            </p>
          );
        },
      },
      {
        field: "lotNumber",
        header: "",
        body: (data: any) => {
          return (
            <p className={`text !font-semibold`}>
              {data?.lotNumberPrefix}
              {data?.lotNumber}
              {data?.lotNumberSuffix}
            </p>
          );
        },
      },
      {
        field: "owner",
        header: "",
        body: (data: any) => {
          return (
            <p className={`text !font-semibold hyperlink`}>
              {data?.contactTitle} {data?.contactFirstName} {data?.contactOtherNames} {data?.contactName}
            </p>
          );
        },
      },
      {
        field: "ue",
        header: "",
        body: (data: any) => {
          return <p className={`${styles.textStyle}`}>{toLocaleValue(data?.ue)}</p>;
        },
      },
      {
        field: "adminContribution",
        header: "",
        body: (data: any) => {
          return <p className={`${styles.textStyle}`}>{toLocaleValue(data?.adminContribution)}</p>;
        },
      },
      {
        field: "capitalWorksContribution",
        header: "",
        body: (data: any) => {
          return <p className={`${styles.textStyle}`}>{toLocaleValue(data?.capitalWorksContribution)}</p>;
        },
      },
      {
        field: "total",
        header: "",
        body: (data: any) => {
          return <p className={`${styles.textStyle}`}>{toLocaleValue(data?.adminContribution + data?.capitalWorksContribution)}</p>;
        },
      },
    ];
  }, []);

  const headerGroup = (
    <ColumnGroup>
      <Row>
        <Column className={`${styles.textStyle} ${styles.expenseColumnGroup}`} header="Contributions" colSpan={7} />
      </Row>
      <Row>
        <Column className={`${styles.textStyle} ${styles.lotContributionColumns}`} header={Labels.UNIT} />
        <Column className={`${styles.textStyle} ${styles.lotContributionColumns}`} header={Labels.LOT} />
        <Column className={`${styles.textStyle} ${styles.lotContributionColumns}`} header={Labels.OWNER} />
        <Column className={`${styles.textStyle} ${styles.lotContributionColumns}`} header={Labels.UE} />
        <Column className={`${styles.textStyle} ${styles.lotContributionColumns}`} header={Labels.ADMIN_CONTRIBUTION} />
        <Column className={`${styles.textStyle} ${styles.lotContributionColumns}`} header={Labels.CAPITAL_WORKS} />
        <Column className={`${styles.textStyle} ${styles.lotContributionColumns}`} header={Labels.TOTAL} />
      </Row>
    </ColumnGroup>
  );

  const footerGroup = (
    <ColumnGroup>
      <Row>
        <Column
          className={`${styles.lotContributionFooter} ${styles.textStyle}`}
          footer={Labels.TOTALS}
          colSpan={3}
          footerStyle={{ textAlign: "right", textTransform: "uppercase" }}
        />
        <Column className={`${styles.lotContributionFooter} ${styles.textStyle}`} footer={toLocaleValue(total?.ueTotal)} />
        <Column className={`${styles.lotContributionFooter} ${styles.textStyle}`} footer={convertToCurrency(total?.adminContributionTotal)} />
        <Column className={`${styles.lotContributionFooter} ${styles.textStyle}`} footer={convertToCurrency(total?.capitalWorksTotal)} />
        <Column className={`${styles.lotContributionFooter} ${styles.textStyle}`} footer={convertToCurrency(total?.finalTotal)} />
      </Row>
    </ColumnGroup>
  );

  return (
    <div className="w-auto h-full p-6 mt-6 shadow-lg rounded-md bg-white">
      <div className="w-full mb-5">
        <div className="flex justify-between">
          <div>
            <p className="text-base font-bold">{Labels.LOT_CONTRIBUTIONS}</p>
          </div>
        </div>
      </div>
      {data?.entitlementSet && (
        <div className="flex justify-end my-4 mr-10 gap-1">
          <label className="text-base font-semibold">{Labels.ENTITLEMENT_SET}:</label>
          <div className="text-base font-semibold">{data?.entitlementSet}</div>
        </div>
      )}
      <SMDataTableContainer
        tableData={data?.lotContributions}
        tableColumns={columns}
        showEditIcon={false}
        showSelectableRowCheckbox={false}
        headerGroup={headerGroup}
        footerGroup={footerGroup}
        showGridlines
        stripedRows
      />
    </div>
  );
};

export default LotContributionsTable;
