import styles from './mainAcc.module.scss'
import { useHttpGet } from 'src/services/api'
import { IBankList } from 'src/interfaces/'
import { SpinnerHorizontal } from 'src/components/ui/loading/spinnerHorizontal'
import { Checkbox } from 'src/components/ui/checkbox/checkbox';
import { MainAccountList } from './mainAccountList'
import { editButtonText, errorMessage } from 'src/constants/constant';
import { Labels } from 'src/constants/labels';
import { EndPoints } from 'src/constants/endpoint';

export const MainAcc: React.FC<{corpId: any}> = ({corpId}) =>{
    const { data, error } = useHttpGet(EndPoints.bankaccount + `bankaccount/plan/${corpId}/main-accounts`) as { data: IBankList[], error: any};

    if (error) {
      return (
        <>
          <div className="text-red-500 m-4">{errorMessage}</div>
        </>
      )
    }

    if (!data) {
      return (
        <>
          <div className='flex w-full min-h-full items-center justify-center'>
            <div className='w-10 h-10'>
              <SpinnerHorizontal />
            </div>
          </div>
        </>
      )
    }
    
    return (
      <>
          <div className="w-full flex justify-between items-center">
              <label className={`${styles.header}`}>{Labels.MAIN_BANK_ACCOUNT}</label>
              <button type="button" className={`order-last edit-btn`}>
                {editButtonText}
              </button>
          </div>
          <div className="flex basis-full gap-10 mt-4 h-10">
            <div className="flex basis-1/2">
                <label className="flex basis-full font-normal text-sm items-center">{Labels.ACCOUNT_NAME}</label>
                <span className="flex-wrap basis-full font-normal text-sm border-2 rounded items-center"><MainAccountList corpId={corpId}/></span>
            </div>
            <div className="flex basis-1/2 border-b items-center">
                <label className="flex basis-full font-normal text-sm">{Labels.BSB}</label>
                <span className="basis-full px-4 text-sm font-bold text-right">{data[0].sBSB}</span>
            </div>
          </div>
          <div className="flex basis-full gap-10 mt-4 h-10">
            <div className="flex basis-1/2 items-center">
                <Checkbox id="useGeneralTrustAccount" label={Labels.USE_GENERAL_TRUST_ACCOUNT} selected={true} />            
            </div>
            <div className="flex basis-1/2 border-b items-center">
                <label className="flex basis-full font-normal text-sm">{Labels.ACCOUNT_NUMBER_}</label>
                <span className="basis-full px-4 text-sm font-bold text-right">{data[0].sAccountNumber}</span>
            </div>
          </div>
      </>
  )
}