import styles from "./planDetails.module.scss";
import { Labels } from "src/constants/labels";
import { useEffect, useRef, useState } from "react";
import { minDate, modalDiscardMessageText, saveButtonText } from "src/constants/constant";
import { Modal } from "src/components/ui/modal/modal";
import { Link } from "react-router-dom";
import { FormatDate, checkZeroValue, seprateStreetNumber, toDate } from "src/utils/utility";
import { httpUpdate } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { Messages } from "src/constants/messages";
import { useForm } from "react-hook-form";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { SMDatePicker } from "src/components/ui/datePicker/datePicker";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import ToastContainer from "src/components/ui/toast/toast";
import { Toast } from "primereact/toast";
import { Errors } from "src/components/shared/error/error";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import { useDispatch } from "react-redux";
import { setPlanNumber } from "src/store/slice/planDetailSlice";
import { IUser } from "src/interfaces/users";

const Plan = ({ data, userList, corpId, setIsLoading }: { data: any; userList: Array<IUser>; corpId: string; setIsLoading: any }) => {
  const [planData, setPlanData] = useState<any>({
    ...data,
    streetNumber: (data?.streetNumberPrefix ?? "") + (checkZeroValue(data?.streetNumber) ?? "") + (data?.streetNumberSuffix ?? ""),
  });
  const [planEditable, setPlanEditable] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showFinancialYearEndModal, setShowFinancialYearEndModal] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    setValue,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      ownersCorporationID: planData?.ownersCorporationID,
      planNumber: planData?.planNumber,
      bodyCorporateName: planData?.bodyCorporateName ?? "",
      buildingName: planData?.buildingName ?? "",
      streetNumberPrefix: planData?.streetNumberPrefix ?? "",
      streetNumber: planData?.streetNumber,
      streetNumberSuffix: planData?.streetNumberSuffix ?? "",
      streetName: planData?.streetName ?? "",
      town: planData?.town ?? "",
      state: planData?.state ?? "",
      postcode: planData?.postcode ?? "",
      financialYearEnd: planData?.financialYearEnd,
      inInitialPeriod: planData?.inInitialPeriod,
      lastLevyNotice: planData?.lastLevyNotice,
      lastEmailedLevyNotice: planData?.lastEmailedLevyNotice,
      allowElectronicVoting: planData?.allowElectronicVoting,
      electronicVotingDate: planData?.electronicVotingDate,
      buildingImageFileName: planData?.buildingImageFileName ?? "",
      managerId: planData?.managerId,
      quorumPercentage: planData?.quorumPercentage,
      managerName: planData?.managerName,
      streetAddressId: planData?.streetAddressId,
      managed: planData?.managed,
      isAllowed: planData?.isAllowed,
      showBudgetAndLevyReports: planData?.showBudgetAndLevyReports,
    },
  });
  const dispatch = useDispatch();

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  useEffect(() => {
    dispatch(setPlanNumber(data?.planNumber));
  }, []);

  function checkFinancialYearEnd(data: any) {
    let financialYearEndDate = new Date(data?.financialYearEnd)?.getTime?.();
    let oneYearAgo = new Date();
    oneYearAgo?.setFullYear?.(oneYearAgo?.getFullYear?.() - 1);
    let oneYearAgoTime = oneYearAgo?.getTime?.();
    if (financialYearEndDate < oneYearAgoTime) {
      setShowFinancialYearEndModal(true);
      return;
    }
    onSubmit(data);
  }

  async function onSubmit(data: any) {
    const street = seprateStreetNumber(data?.streetNumber);
    let body = {
      ...data,
      managerId: userList?.find?.((user: IUser) => user?.name === data?.managerName)?.userId,
      financialYearEnd: toDate(FormatDate(data?.financialYearEnd) ?? ""),
      lastLevyNotice: toDate(FormatDate(data?.lastLevyNotice) ?? ""),
      lastEmailedLevyNotice: toDate(FormatDate(data?.lastEmailedLevyNotice) ?? ""),
      electronicVotingDate: toDate(FormatDate(data?.electronicVotingDate) ?? ""),
      streetNumber: Number(street?.number),
      streetNumberPrefix: street?.prefix ?? "",
      streetNumberSuffix: street?.suffix ?? "",
    };
    let manager = data.managerName;
    delete data.managerName;
    setIsLoading(true);
    const output = await httpUpdate(EndPoints.corporation + corpId, body);
    if (output?.data) {
      setIsLoading(false);
      setPlanEditable(false);
      setPlanData({
        ...data,
        managerName: manager,
        streetNumber: (street?.prefix ?? "") + (checkZeroValue(Number(street?.number)) ?? "") + (street?.suffix ?? ""),
      });
      toastRef?.current?.show({ severity: "success", summary: "Success", detail: Messages.SUCCESS_MESSAGE, life: 3000 });
      reset({}, { keepDirty: false, keepValues: true });
    }
    if (output?.error) {
      setIsLoading(false);
      toastRef?.current?.show({ severity: "error", summary: "Error", detail: Messages.ERROR_MESSAGE, life: 3000 });
    }
  }

  function onDiscard() {
    setPlanEditable(false);
    reset({ ...planData }, { keepDirty: false });
    setShowModal(false);
  }

  const isLastDateOfMonth = (date: number, month: number, year: number) => {
    var lastDate = new Date(year, month, date),
      month = lastDate.getMonth();
    lastDate.setDate(lastDate.getDate() + 1);
    return lastDate.getMonth() !== month;
  };

  return (
    <>
      <form data-testid="planDetailCard1" className={`flex-auto basis-full ${styles.card}`} onSubmit={handleSubmit(checkFinancialYearEnd)} noValidate>
        <div className="w-full flex justify-between">
          <label className={`pl-2.5 section-header`}>{Labels.PLAN_DETAILS}</label>
          {planEditable ? (
            <div className="gap-1">
              <button
                type="button"
                className={`order-last edit-btn`}
                onClick={() => {
                  if (isDirty) {
                    setShowModal(true);
                  } else {
                    setPlanEditable(false);
                  }
                }}
              >
                {Labels.CANCEL}
              </button>
              <button type="submit" className={`order-last btn-primary ${!isDirty ? "opacity-60" : "opacity-100"}`} disabled={!isDirty}>
                {saveButtonText}
              </button>
            </div>
          ) : (
            <button type="button" className={`order-last edit-btn`} onClick={() => setPlanEditable(true)}>
              {Labels.EDIT}
            </button>
          )}
        </div>
        {/* <div>
          <img alt="planDetail" width={611} height={146} src="/img/planDetail.jpg" className="h-full w-full rounded-md py-8" />
        </div> */}
        <div data-testid="planDetailTab" className="basis-full text-sm mt-4">
          <div className={`flex items-center mb-2.5 ${planEditable ? "border-remove" : "border-bottom"}`}>
            <label className={`!mt-0 ${styles.key} ${planEditable ? "!w-1/2 required" : "!w-full"} ${planEditable ? "border-bottom" : "border-remove"}`}>
              {Labels.PLANNO}
            </label>
            {!planEditable ? (
              <span className={styles.readMode}>{planData?.planNumber}</span>
            ) : (
              <div className="w-1/2">
                <SMInputText name="planNumber" rules={{ required: Messages.REQUIRED_MESSAGE }} control={control} maxlength={10} />
                {errors.planNumber && <Errors error={errors.planNumber.message} alignment="left" />}
              </div>
            )}
          </div>

          <div className={`flex items-center mb-2.5 ${planEditable ? "border-remove" : "border-bottom"}`}>
            <label className={`!mt-0 ${styles.key} ${planEditable ? "border-bottom" : "border-remove"}`}>{Labels.BUILDING}</label>
            {!planEditable ? (
              <span className={styles.readMode}>{planData?.buildingName}</span>
            ) : (
              <SMInputText name="buildingName" control={control} maxlength={30} />
            )}
          </div>

          <div className={`flex items-center mb-2.5 ${planEditable ? "border-remove" : "border-bottom"}`}>
            <label className={`!mt-0 ${styles.key} ${planEditable ? "border-bottom" : "border-remove"}`}>{Labels.STREET_NUMBER}</label>
            {!planEditable ? (
              <span className={styles.readMode}>{planData?.streetNumber}</span>
            ) : (
              <SMInputText name="streetNumber" control={control} maxlength={10} />
            )}
          </div>

          <div className={`flex items-center mb-2.5 ${planEditable ? "border-remove" : "border-bottom"}`}>
            <label className={`!mt-0 ${styles.key} ${planEditable ? "border-bottom" : "border-remove"}`}>{Labels.STREET}</label>
            {!planEditable ? <span className={styles.readMode}>{planData?.streetName}</span> : <SMInputText name="streetName" control={control} />}
          </div>

          <div className={`flex items-center mb-2.5 ${planEditable ? "border-remove" : "border-bottom"}`}>
            <label className={`!mt-0 ${styles.key} ${planEditable ? "border-bottom" : "border-remove"}`}>{Labels.TOWN}</label>
            {!planEditable ? <span className={styles.readMode}>{planData?.town}</span> : <SMInputText name="town" control={control} />}
          </div>

          <div className={`flex items-center mb-2.5 ${planEditable ? "border-remove" : "border-bottom"}`}>
            <label className={`!mt-0 ${styles.key} ${planEditable ? "border-bottom" : "border-remove"}`}>{Labels.STATE}</label>
            {!planEditable ? <span className={styles.readMode}>{planData?.state}</span> : <SMInputText name="state" control={control} maxlength={50} />}
          </div>

          <div className={`flex items-center mb-2.5 ${planEditable ? "border-remove" : "border-bottom"}`}>
            <label className={`flex !mt-0 ${styles.key} ${planEditable ? "border-bottom" : "border-remove"}`}>{Labels.POSTCODE}</label>
            {!planEditable ? <span className={styles.readMode}>{planData?.postcode}</span> : <SMInputText name="postcode" control={control} maxlength={10} />}
          </div>

          <div className={`flex items-center mb-2.5 ${planEditable ? "border-remove" : "border-bottom"}`}>
            <label className={`${styles.key} ${planEditable && "required"} !w-1/2`}>{Labels.FINANCIAL_YEAR_END}</label>
            <div className="flex w-1/2 gap-2">
              {!planEditable ? (
                <span className={`${styles.readMode} ml-1`}>{FormatDate(planData?.financialYearEnd)}</span>
              ) : (
                <>
                  <div className="w-full flex flex-col">
                    <SMDatePicker
                      inputDate={FormatDate(planData?.financialYearEnd) ?? ""}
                      name="financialYearEnd"
                      control={control}
                      disabled={!planEditable}
                      width="w-full"
                      rules={{ required: Messages.REQUIRED_MESSAGE }}
                      dateTemplate={(event) => {
                        return isLastDateOfMonth(event?.day, event?.month, event?.year) ? (
                          <button className="font-semibold z-50">{event?.day}</button>
                        ) : (
                          <button disabled className="opacity-60 w-16 h-16">
                            {event?.day}
                          </button>
                        );
                      }}
                    />
                    {errors.financialYearEnd && <div className="w-max"><Errors error={errors.financialYearEnd.message} alignment="left" /></div>}
                  </div>
                  <SMCheckbox name="inInitialPeriod" control={control} label={Labels.INITIAL_PERIOD} width="w-full" />{" "}
                </>
              )}
            </div>
          </div>

          <div className={`flex items-center mb-2.5 ${planEditable ? "border-remove" : "border-bottom"}`}>
            <label className={`${styles.key} ${planEditable ? "!w-1/2" : "!w-full"}`}>{Labels.LAST_LEVY_NOTICE_PRINTED}</label>
            {!planEditable ? (
              <span className={styles.readMode}>{FormatDate(planData?.lastLevyNotice)}</span>
            ) : (
              <SMDatePicker inputDate={FormatDate(planData?.lastLevyNotice) ?? ""} control={control} name="lastLevyNotice" disabled={!planEditable} />
            )}
          </div>

          <div className={`flex items-center mb-2.5 ${planEditable ? "border-remove" : "border-bottom"}`}>
            <label className={`${styles.key} ${planEditable ? "!w-1/2" : "!w-full"}`}>{Labels.LAST_LEVY_NOTICE_EMAILED}</label>
            {!planEditable ? (
              <span className={styles.readMode}>{FormatDate(planData?.lastEmailedLevyNotice)}</span>
            ) : (
              <SMDatePicker
                inputDate={FormatDate(planData?.lastEmailedLevyNotice) ?? ""}
                control={control}
                name="lastEmailedLevyNotice"
                disabled={!planEditable}
              />
            )}
          </div>

          <div className={`flex items-center mb-2.5 ${planEditable ? "border-remove" : "border-bottom"}`}>
            <label className={`${styles.key} ${planEditable && "required"}`}>{Labels.MANAGER}</label>
            {!planEditable ? (
              <span className={styles.readMode}>{planData?.managerName}</span>
            ) : (
              <div className="w-full">
                <SMDropDown
                  control={control}
                  name="managerName"
                  options={userList}
                  rules={{
                    validate: (value: string) => {
                      return Boolean(userList?.find?.((user: IUser) => user?.name === value)?.userId);
                    },
                  }}
                />
                {errors.managerName && <Errors error={Messages.REQUIRED_MESSAGE} alignment="left" />}
              </div>
            )}
          </div>

          <div className={`flex items-center mb-2.5 ${planEditable ? "border-remove" : "border-bottom"}`}>
            <label className={`${styles.key}`}>{Labels.PLAN_MANAGED}</label>
            <span className={`${styles.readMode} items-center`}>{planData?.managed ? "Yes" : "No"}</span>
          </div>

          {planData?.managed && planData?.isAllowed && (
            <div className={`flex items-center mb-2.5 ${planEditable ? "border-remove" : "border-bottom"}`}>
              <div className={`${styles.key}`}>
                <SMCheckbox name="showBudgetAndLevyReports" control={control} label={Labels.WEB_ACCESS} disabled />
              </div>
            </div>
          )}

          <div className={`flex items-center mb-1 ${planEditable ? "border-remove" : "border-bottom"}`}>
            <div className={`${styles.key} ${planEditable ? "!w-1/2" : "!w-full"}`}>
              <SMCheckbox
                name="allowElectronicVoting"
                control={control}
                label={Labels.ONLINE_VOTING_APPROVED}
                disabled={!planEditable}
                onChange={(value: boolean) => {
                  setPlanData({
                    ...planData,
                    allowElectronicVoting: value,
                    electronicVotingDate: Boolean(value) ? setValue("electronicVotingDate", new Date()) : setValue("electronicVotingDate", minDate),
                  });
                }}
              />
            </div>
            {!planEditable ? (
              <span className={`${styles.readMode}`}>{FormatDate(planData?.electronicVotingDate)}</span>
            ) : (
              <SMDatePicker
                inputDate={FormatDate(planData?.electronicVotingDate) ?? ""}
                control={control}
                name="electronicVotingDate"
                disabled={!planEditable}
              />
            )}
          </div>
          {planData?.allowElectronicVoting && (
            <div className="flex pl-2 px-2.5 pt-3 pb-2">
              <Link to="https://kb.rockend.com/help/online-voting-references" className={`underline font-semibold ${styles.btn}`} target="_blank">
                {Labels.ONLINE_VOTING_REFERENCES}
              </Link>
            </div>
          )}
        </div>
      </form>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Warning" onAction={() => onDiscard()}>
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
      <Modal
        isOpen={showFinancialYearEndModal}
        onClose={() => setShowFinancialYearEndModal(false)}
        title=""
        onAction={() => {
          let data = getValues();
          setShowFinancialYearEndModal(false);
          onSubmit(data);
        }}
      >
        <div>
          <h2>{`You have set a financial year end date of ${FormatDate(getValues?.().financialYearEnd)}. Are you sure this is correct?`}</h2>
        </div>
      </Modal>
      <ToastContainer toastReference={toastRef} />
    </>
  );
};

export default Plan;
