import { ChevronDown, ChevronUp, Eye } from "../../../assets/icon";
import React, { useEffect, useState } from "react";
import styles from "./accordion.module.scss";

interface IAccordion {
  id: string;
  title: string;
  label?: string;
  showLabel?: boolean;
  icon?: React.ReactNode;
  expand: boolean;
  btnId?: string;
  btnName?: string;
  onClick?: any;
  children: React.ReactNode;
  route?: string;
  onToggle?: (id: string, expand: boolean) => void;
}

export const Accordion: React.FC<IAccordion> = ({ id, title, label, showLabel = false, icon, expand, btnName, btnId, onClick, children, route, onToggle }) => {
  const [isOpen, setIsOpen] = useState(expand);

  useEffect(() => {
    setIsOpen(expand);
  }, [expand]);

  const handleToggle = () => {
    const newExpandState = !isOpen;
    setIsOpen(newExpandState);
    if (onToggle) {
      onToggle(id, newExpandState);
    }
  };
  return (
    <div id={id} className={`${styles.accordion}`}>
      <div className={`${styles.accordionTitle}`}>
        <div className="justify-start flex">
          {icon && <span className="px-2 place-self-center">{icon}</span>}
          <label className="accordian-header">{title}</label>
        </div>
        {showLabel
          ? label && (
              <div>
                <label className={`${styles.accordionLabel2}`}>{label}</label>
              </div>
            )
          : !isOpen &&
            label && (
              <div>
                <label className={`${styles.accordionLabel2}`}>{label}</label>
              </div>
            )}
        <div className="justify-end flex flex-row">
          {btnName && (
            <>
              <button data-testid={`${btnId ?? title}_ViewMore`} className={`${styles.accordionBtn}`} onClick={onClick}>
                <a href={route && `${window.location.origin}/${route}`} onClick={(e) => e?.preventDefault?.()}>
                  {btnName}
                </a>
              </button>
              <span data-testid={`m_${btnId ?? title}_ViewMore`} className={`${styles.accordionEye}`} onClick={onClick}>
                <a href={route && `${window.location.origin}/${route}`} onClick={(e) => e?.preventDefault?.()}>
                  <Eye />
                </a>
              </span>
            </>
          )}
          <span onClick={handleToggle}>{isOpen ? <ChevronUp /> : <ChevronDown />}</span>
        </div>
      </div>
      {isOpen && <div className={`${styles.accordionContent}`}>{children}</div>}
    </div>
  );
};
