"use client";

import { useHttpGet } from "src/services/api";
import DebtRecoveryTab from "./debtRecovery";
import { EndPoints } from "src/constants/endpoint";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Errors } from "src/components/shared/error/error";
import { errorMessage } from "src/constants/constant";

export default function DebtRecoveryRoot({ corpId, lotId }: { corpId: string; lotId: string }) {
  const { data: lotDetails, error: lotDetailsEror } = useHttpGet(EndPoints.corporation + corpId + "/lots/" + lotId);

  if (!lotDetails) {
    return (
      <div className="flex w-full min-h-full items-center justify-center">
        <div className="w-20 h-20">
          <SpinnerHorizontal />
        </div>
      </div>
    );
  }

  if (lotDetailsEror) {
    return <Errors error={errorMessage} alignment="left" />;
  }

  return <DebtRecoveryTab lotDetails={lotDetails} corpId={corpId} lotId={lotId} />;
}
