import { useEffect, useMemo, useRef, useState } from "react";
import { Pencil, Plus } from "src/assets/icon";
import { Checkbox } from "src/components/ui/checkbox/checkbox";
import FormModal from "src/components/ui/dialog/formModal";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { Labels } from "src/constants/labels";
import { EditableAdditionalContact } from "./editableAdditionalContact";
import ToastContainer from "src/components/ui/toast/toast";
import { Toast } from "primereact/toast";
import { contactType } from "src/constants/constant";

export const AdditionalContact: React.FC<{
  additionalDetails?: any;
  setAdditionalContactData?: any;
  businessContact: any;
  contactId: any;
}> = ({
  additionalDetails,
  setAdditionalContactData,
  businessContact,
  contactId,
}) => {
  const [contactTableData, setContactTableData] = useState(additionalDetails);
  const [showEditModal, setEditShowModal] = useState<boolean>(false);
  const [contactRow, setContactRow] = useState<any>({});
  const [rowIndex, setRowIndex] = useState(0);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);

  const handleChange = (rowIndex: number, contactRow: any, response?: any) => {
    if (response) {
      let updatedContact = {
        ...contactRow,
        contactDetailsId: response?.id,
      };
      setContactTableData([...contactTableData ?? [], updatedContact]);
      if (setAdditionalContactData) {
        setAdditionalContactData([...contactTableData ?? [], updatedContact]);
      }
    } else {
      let newTableData = contactTableData?.map?.(
        (contact: any, contactRowIndex: number) => {
          if (contactRowIndex === rowIndex) {
            return {
              ...contact,
              ...contactRow,
            };
          } else {
            return {
              ...contact,
            };
          }
        }
      );
      setContactTableData(newTableData);
      if (setAdditionalContactData) {
        setAdditionalContactData(newTableData);
      }
    }
  };

  const columns = useMemo(() => {
    return [
      {
        field: "type",
        header: <p className=" text-sm !font-semibold"> {Labels.TYPE} </p>,
        body: (data: any, options: { rowIndex: any }) => {
          const valueId = `type-${options.rowIndex}`;
          return (
            <p id={valueId} data-test-id={valueId} className="text-sm font-normal">
              {data?.type}
            </p>
          );
        },
      },
      {
        field: "value",
        header: <p className="text-sm !font-semibold">{Labels.VALUE}</p>,
        body: (data: any, options: { rowIndex: any }) => {
          const uniqueId = `value-${options.rowIndex}`;
          return (
            <p id={uniqueId} data-test-id={uniqueId} className="text-sm font-normal">
              {data?.value}
            </p>
          );
        },
      },
      {
        field: "l",
        header: <p className="text-sm !font-semibold">{Labels.L}</p>,
        body: (data: any) => {
          return <Checkbox id="emailLevies" data-test-id="emailLevies" selected={data?.emailLevies} />;
        },
      },
      {
        field: "m",
        header: <p className="text-sm !font-semibold">{Labels.M}</p>,
        body: (data: any) => {
          return <Checkbox id="emailMeetingDocs" data-test-id="emailMeetingDocs" selected={data?.emailMeetingDocs} />;
        },
      },
      {
        field: "c",
        header: <p className="text-sm !font-semibold">{Labels.C}</p>,
        body: (data: any) => {
          return <Checkbox id="emailCorrespondence" data-test-id="emailCorrespondence" selected={data?.emailCorrespondence} />;
        },
      },
      {
        field: "notes",
        header: <p className="text-sm !font-semibold">{Labels.NOTES.toUpperCase()}</p>,
        body: (data: any) => {
          return <p className="text-sm font-normal">{data?.notes}</p>;
        },
      },
      {
        field: "action",
        header: "",
        body: (data: any, options: any) => {
          return (
            <span
              className="px-2 py-3 cursor-pointer"
              onClick={() => {
                setRowIndex(options?.rowIndex);
                setContactRow(data);
                setEditShowModal(true);
              }}
            >
              <Pencil />
            </span>
          );
        },
      },
    ];
  }, []);

  useEffect(() => {
    let newContactListData = contactTableData?.map((contact: any) => {
      return {
        ...contact,
        type: businessContact
          ? contact?.type === contactType.homePhone
            ? contactType.phone1
            : contact?.type === contactType.workPhone
            ? contactType.phone2
            : contact?.type
          : contact?.type === contactType.phone1
          ? contactType.homePhone
          : contact?.type === contactType.phone2
          ? contactType.workPhone
          : contact?.type,
      };
    });
    setContactTableData(newContactListData);
    if (setAdditionalContactData) {
      setAdditionalContactData(newContactListData);
    }
  }, [businessContact]);

  return (
    <>
      <div className="w-full flex justify-between h-10 items-center">
        <label className="text-sm font-bold">{Labels.ADDITIONAL_CONTACT_DETAILS_}</label>
        <span
          id="btnAddContact"
          onClick={() => {
            setEditShowModal(true);
            setRowIndex(0);
            setContactRow(null);
          }}
        >
          <Plus />
        </span>
      </div>
      {contactTableData?.length > 0 ? (
        <div className="w-full pt-4">
          <SMDataTableContainer
            tableData={contactTableData}
            tableColumns={columns}
            showEditIcon={false}
            showPaginator={false}
            showSelectableRowCheckbox={false}
          />
        </div>
      ) : (
        <div className={`w-full rounded-lg text-center shadow-lg flex-row p-10 mt-5`}>
          <p>{Labels.NO_DATA_AVAILABLE}</p>
        </div>
      )}
      <FormModal
        header={contactRow ? Labels.UPDATE_ADDITIONAL_CONTACT : Labels.ADD_ADDITIONAL_CONTACT}
        formId={contactRow ? "additionalContactEdit" : "additionalContactAdd"}
        isOpen={showEditModal}
        onClose={() => {
          if (isFormDirty) {
            setShowCancelModal(true);
          } else {
            setEditShowModal(false);
          }
        }}
        isDirty={isFormDirty}
      >
        <EditableAdditionalContact
          formData={contactRow}
          setShowModal={setEditShowModal}
          handleChange={handleChange}
          rowIndex={rowIndex}
          toastRef={toastRef}
          formId={contactRow ? "additionalContactEdit" : "additionalContactAdd"}
          setIsFormDirty={setIsFormDirty}
          showCancelModal={showCancelModal}
          setShowCancelModal={setShowCancelModal}
          businessContact={businessContact}
          contactId={contactId}
        />
      </FormModal>
      <ToastContainer toastReference={toastRef} />
    </>
  );
};
