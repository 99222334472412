import { useHttpGet } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { Errors } from "src/components/shared/error/error";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import DebtRecoverySection from "./debtRecoverySection";

const DebtRecoveryTab: React.FC<{ lotDetails: any; corpId: string; lotId: string }> = ({ lotDetails, corpId, lotId }) => {
  const { data: debtRecoveryDetails, error: debtRecoveryDetailsError } = useHttpGet(
    EndPoints.corporation + corpId + "/lots/" + lotId + "/debt-recovery/" + lotDetails?.currentOwnerId
  );

  if (debtRecoveryDetailsError) {
    return <Errors error={debtRecoveryDetailsError?.response?.data} alignment="left" />;
  }

  if (!debtRecoveryDetails) {
    return (
      <div className="flex w-full min-h-full items-center justify-center">
        <div className="w-20 h-20">
          <SpinnerHorizontal />
        </div>
      </div>
    );
  }
  return <DebtRecoverySection debtRecoveryDetails={debtRecoveryDetails} corpId={corpId}/>
};

export default DebtRecoveryTab;
