import React from "react";
import styles from './mainContact.module.scss';
import { Checkbox } from "../../../../../../components/ui/checkbox/checkbox";
import { Labels } from "../../../../../../constants/labels";
import AdditionalContactDetails from "src/components/shared/contact/additionalContactDetails/additionalContactDetails";

export const MainContact : React.FC<{selectedOwner: any}> = ({selectedOwner}) =>{
    const isBusiness = selectedOwner?.contact?.businessContact;
    const primaryDetails = selectedOwner?.contact?.telephone1 || selectedOwner?.contact?.telephone2 || selectedOwner?.contact?.telephone3 || selectedOwner?.contact?.fax || selectedOwner?.contact?.email || selectedOwner?.contact?.website;
    return(
      <>
          <div className="flex-auto py-4 ml-4">
              <div className="flex basis-full">
                  <Checkbox data-test-id='chkMainContact' id="mainContact" label={Labels.MAIN_CONTACT} selected={true} />
              </div>
              {/* <div className={styles.section}>
                  <Checkbox id="levies" label={Labels.LEVIES} selected={true} />
                  <Checkbox id="meetingDocs" label={Labels.MEETING_DOCS} selected={true} />
                  <Checkbox id="correspondence" label={Labels.CORRESPONDENCE} selected={true} />
              </div> */}
          </div>
          <div className="flex justify-between h-10 items-center">
              <label className={styles.title}>
                {
                    isBusiness ? Labels.BUSINESS_CONTACT : Labels.PRIVATE_RESIDENTIAL_CONTACT
                }
              </label>
          </div> 
          <div className="pt-1 border-t border-gray-400">
              <div className="py-4 border-b border-gray-400 !h-auto">
                  {
                        isBusiness && selectedOwner?.contact?.otherNames &&
                            <div className={styles.address}>
                                <label data-test-id='lblContactPerson' className="font-semibold">{Labels.CONTACT_PERSON}</label>
                                {selectedOwner?.contact?.otherNames}{" "}{selectedOwner?.contact?.salutation && `(${Labels.SALUTATION}${selectedOwner?.contact?.salutation})`}
                            </div>                         
                  }
                  {
                        !isBusiness && selectedOwner?.contact?.salutation && 
                            <div data-test-id='salutation' className={styles.address}><label data-test-id='lblSalution' className="font-semibold">{Labels.SALUTATION}</label>{selectedOwner?.contact?.salutation}</div>
                  }
                  {
                        isBusiness ? <div data-test-id='lblName' className={styles.address}>{selectedOwner?.contact?.name}</div> : 
                        <div data-test-id='lblFullName' className={styles.address}>{selectedOwner?.contact?.title} {selectedOwner?.contact?.firstName} {selectedOwner?.contact?.otherNames} {selectedOwner?.contact?.name}</div>                           
                  }
                  {
                        selectedOwner?.contact?.poBox && 
                            <div data-test-id='lblPoBox' className={styles.address}>{selectedOwner?.contact?.poBox}</div>
                  }
                  {
                        !selectedOwner?.contact?.poBox && selectedOwner?.contact?.buildingName && 
                            <div data-test-id='lblBuildingName' className={styles.address}>{selectedOwner?.contact?.buildingName}</div>
                  }
                  {
                        !selectedOwner?.contact?.poBox && selectedOwner?.contact?.streetName &&
                            <div data-test-id='lblStreetName' className={`${styles.address} !h-auto`}>{selectedOwner?.contact?.streetNumber} {selectedOwner?.contact?.streetName}</div>
                  }
                  <div data-test-id='lblAddress' className={`${styles.address} !h-auto`}>{selectedOwner?.contact?.town} {selectedOwner?.contact?.state} {selectedOwner?.contact?.postcode} {selectedOwner?.contact?.country}</div>
              </div>
              {
                primaryDetails &&
                <div className="py-4 border-b border-gray-400">
                {
                    selectedOwner?.contact?.telephone1 &&
                        <div className={styles.address}>
                            <label data-test-id='lblContactNumber' className="font-semibold">{ isBusiness ? Labels.TELEPHONE_1 : Labels.HOME_PHONE }</label>
                            <a data-test-id='ContactNumber' href={`tel:${selectedOwner?.contact?.telephone1}`} className="underline">
                                {selectedOwner?.contact?.telephone1}
                            </a>
                        </div>                                                                    
                }
                {
                    selectedOwner?.contact?.telephone2 && 
                        <div className={styles.address}>
                            <label data-test-id='lblContactNumber1'  className="font-semibold">{ isBusiness ? Labels.TELEPHONE_2 : Labels.WORK_PHONE }</label>
                            <a data-test-id='ContactNumber1' href={`tel:${selectedOwner?.contact?.telephone2}`} className="underline">
                                {selectedOwner?.contact?.telephone2}
                            </a>
                        </div>                        
                }
                {
                    selectedOwner?.contact?.telephone3 && 
                        <div className={styles.address}>
                            <label data-test-id='lblContactNumber3' className="font-semibold">{Labels.MOBILE}</label>
                            <a data-test-id='ContactNumber3' href={`tel:${selectedOwner?.contact?.telephone3}`} className="underline">
                                {selectedOwner?.contact?.telephone3}
                            </a>
                        </div>
                }
                {
                    selectedOwner?.contact?.fax &&
                        <div data-test-id='fax' className={styles.address}><label data-test-id='lblFax' className="font-semibold">{Labels.FAX}</label>{selectedOwner?.contact?.fax}</div>
                }
                {
                    selectedOwner?.contact?.email &&
                        <div className={styles.address}>
                            <label data-test-id='lblEmail' className="font-semibold">{Labels.EMAIL}</label>
                            <a data-test-id='email' href={`mailto:${selectedOwner?.contact?.email}`} className="underline hyperlink">
                                {selectedOwner?.contact?.email}
                            </a>
                        </div>
                }
                {
                    isBusiness && selectedOwner?.contact?.website &&
                        <div className={styles.address}>
                            <label data-test-id='lblWebSite' className="font-semibold">{Labels.WEBSITE}</label>
                            <a data-test-id='webSite' href={`https://${selectedOwner?.contact?.website}`} target="_blank" className="underline hyperlink" rel="noreferrer">
                                {selectedOwner?.contact?.website}
                            </a>
                        </div>
                }
                </div>
              }
          </div>
          <div className="pt-4">
            <AdditionalContactDetails additionalContactDetails={selectedOwner?.contactDetails} isBusiness={isBusiness} />
          </div>
      </>           
    )
};
