import { OverlayPanel } from "primereact/overlaypanel";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { Mail, Mobile } from "src/assets/icon";
import { Errors } from "src/components/shared/error/error";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import ToastContainer from "src/components/ui/toast/toast";
import { contactType, httpCode, noDataFound, type } from "src/constants/constant";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import { IImportantContact } from "src/interfaces";
import { createMailLink, findValue } from "src/utils/utility";
import styles from "./contact.module.scss";

export const ContactSummary: React.FC<{ data: Array<IImportantContact>; error: any; status: any }> = ({ data, error, status }) => {
  const toast = useRef<Toast>();

  if (error) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (httpCode.NOT_FOUND === status || httpCode.NO_CONTENT === status) {
    return <div className="ml-3.5">{noDataFound}</div>;
  }

  if (!data) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-10 h-10">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="basis-full overflow-y-scroll text-sm" style={{ maxHeight: "400px" }}>
      {data?.map((contact: IImportantContact) => {
        return (
          <div key={contact.importantContactId} className="w-full flex justify-between border-b !h-auto">
            <div className="w-1/3 px-3 py-2 text-left text-sm font-normal">{findValue(type, contact?.contactType)}</div>
            <label className={`w-1/3 px-3 py-2 text-left font-semibold ${styles.contactNameMobileView}`}>
              {contact?.firstName} {contact?.otherNames} {contact?.name}
            </label>
            <span className="w-1/3 flex pl-6 py-2 justify-end place-items-center font-thin gap-2">
              <a
                onClick={() => {
                  let email = contact?.contactType === contactType?.creditor ? contact?.email2 : contact?.email;
                  if (email) {
                    createMailLink(email);
                  } else {
                    toast?.current?.show({
                      severity: "warn",
                      summary: "Warning",
                      detail: Messages.NO_EMAIL_ADDRESSES,
                      life: 2000,
                    });
                  }
                }}
              >
                <Mail />
              </a>
              <MobileIconOverlay contact={contact} toast={toast} />
            </span>
          </div>
        );
      })}
      <ToastContainer toastReference={toast} />
    </div>
  );
};

const MobileIconOverlay = ({ contact, toast }: { contact: IImportantContact; toast: any }) => {
  const overlayPanelRef = useRef<any>(null);

  return (
    <span
      onClick={(e) => {
        if (Boolean(contact?.homePhone) || Boolean(contact?.mobile) || Boolean(contact?.workPhone)) {
          overlayPanelRef?.current?.toggle?.(e);
        } else {
          toast?.current?.show({
            severity: "warn",
            summary: "Warning",
            detail: Messages.NO_MOBILE_NUMBER,
            life: 2000,
          });
        }
      }}
      onMouseLeave={(e) => {
        const element = e?.currentTarget;
        const { top } = element?.getBoundingClientRect?.();
        const y = e?.clientY - top;
        if (y < 0) {
          overlayPanelRef?.current?.hide?.(e);
        }
      }}
    >
      <Mobile />
      <OverlayPanel
        ref={overlayPanelRef}
        onMouseLeave={() => {
          overlayPanelRef?.current?.hide?.();
        }}
      >
        <div className="p-4 hover:bg-gray-300">
          {contact?.mobile && (
            <div>
              <label className="text-sm font-semibold">{Labels.MOBILE}</label>
              <a href={`tel:${contact?.mobile}`} className={`hyperlink`}>
                {contact?.mobile}
              </a>
            </div>
          )}
          {contact?.workPhone && (
            <div>
              <label className="text-sm font-semibold">{Labels.TELEPHONE_2?.substring?.(0, 11)} (W) : </label>
              <a href={`tel:${contact?.workPhone}`} className={`hyperlink`}>
                {contact?.workPhone}
              </a>
            </div>
          )}
          {contact?.homePhone && (
            <div>
              <label className="text-sm font-semibold">{Labels.TELEPHONE_1?.substring?.(0, 11)} (H) : </label>
              <a href={`tel:${contact?.homePhone}`} className={`hyperlink`}>
                {contact?.homePhone}
              </a>
            </div>
          )}
        </div>
      </OverlayPanel>
    </span>
  );
};
