import { useMemo, useRef, useState } from "react";
import styles from "./agent.module.scss";
import AgentContact from "./agentContact/agentContact";
import Notes from "./notes/notes";
import TenantContact from "./tenantContact/tenantContact";
import Lease from "./lease/lease";
import {
  DocumentWord2,
  Information,
  Line,
  Mail,
  Mobile,
  ViewColumn,
} from "src/assets/icon";
import { Labels } from "src/constants/labels";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import {
  cancelButtonText,
  closeButtonText,
  comingSoon,
  editButtonText,
  modalDiscardMessageText,
  saveButtonText,
} from "../../../../../constants/constant";
import { Toast } from "primereact/toast";
import ToastContainer from "src/components/ui/toast/toast";
import { mailToSend } from "src/utils";
import { RootState } from "src/store/rootReducer";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { httpUpdate } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Messages } from "src/constants/messages";
import { Modal } from "src/components/ui/modal/modal";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import { SMRadioButton } from "src/components/ui/radioControl/radioButton";
import { Controller } from "react-hook-form";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";

const Agent = ({
  lotDetails,
  corpId,
  lotId,
}: {
  lotDetails: any;
  corpId: string;
  lotId: string;
}) => {
  const [lotDetail, setLotDetail] = useState(lotDetails);
  const [showTenantContactModal, setShowTenantContactModal] = useState(false);
  const [isNotesEditable, setIsNotesEditable] = useState(false);
  const [notes, setNotes] = useState({
    note: lotDetail?.tenantNotes,
  });
  let tenantContactDetails = Object.assign(
    {},
    ...lotDetails?.lotContacts?.filter?.(
      (contact: any) => contact?.contactType?.toUpperCase?.() === Labels.TENANT_CONTACT_TYPE && contact?.contactId === lotDetails?.tenantContactId
    )
  );
  const [tenantContact, setTenantContact] = useState<any>({
    contactId: tenantContactDetails?.contactId,
    businessContact: tenantContactDetails?.businessContact,
    buildingName: tenantContactDetails?.buildingName,
    salutation: tenantContactDetails?.salutation,
    name: tenantContactDetails?.name,
    firstName: tenantContactDetails?.firstName,
    otherNames: tenantContactDetails?.otherNames,
    title: tenantContactDetails?.title,
    streetNumber: tenantContactDetails?.streetNumber,
    streetName: tenantContactDetails?.streetName,
    town: tenantContactDetails?.town,
    state: tenantContactDetails?.state,
    postcode: tenantContactDetails?.postcode,
    country: tenantContactDetails?.country,
    website: tenantContactDetails?.website,
    fax: tenantContactDetails?.fax,
    email: tenantContactDetails?.email,
    telephone1: tenantContactDetails?.telephone1,
    telephone2: tenantContactDetails?.telephone2,
    telephone3: tenantContactDetails?.telephone3,
    committeeEmailAddress: tenantContactDetails?.committeeEmailAddress,
    poBox: tenantContactDetails?.poBox,
    levyEmailAddress: tenantContactDetails?.levyEmailAddress,
  });
  const [tenantAdditionalContact, setTenantAdditionalContact] = useState(tenantContactDetails?.additionalContactDetails);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);
  const { agency } = useSelector(
    (state: RootState) => state?.agencySettingSlice
  );
  const disableEmail = tenantContact?.email ? false : true;
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      agentContactId: lotDetail?.agentContactId,
      ownerOccupied: lotDetail?.ownerOccupied,
      tenantNoticesIssue: lotDetail?.tenantNoticesIssue,
    },
  });

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });
  
  const ownerOccupied = watch("ownerOccupied");
  let agentContact = Object.assign(
    {},
    ...lotDetail?.lotContacts?.filter?.(
      (contact: any) =>
        contact?.contactType?.toUpperCase?.() === Labels.AGENT_CONTACT_TYPE && contact.contactId === lotDetail?.agentContactId
    )
  );

  const agentContactButtons = [
    // { buttonName: <DocumentWord2 height={24} width={24} />, type: "word" },
    // { buttonName: <Line height={32} />, type: "line" },
    {
      buttonName: <Mail height={24} width={24} />,
      type: "mail",
      onClick: (e: any) => {
        e.preventDefault();
        mailToSend(
          [agentContact],
          toastRef,
          agency?.useAdditionalEmailAddresses,
          agency?.additionalEmailAddressOptions
        );
      },
    },
  ];
  const tenantContactButtons = [
    {
      buttonName: tenantContact?.contactId ? "Edit" : "",
      type: tenantContact?.contactId ? "normal" : "",
      onClick: () => setShowTenantContactModal(true),
    },
    // { buttonName: <DocumentWord2 height={24} width={24} />, type: "word" },
    // { buttonName: <Line height={32} />, type: "line" },
    {
      buttonName: <Mail height={24} width={24} />,
      type: "mail",
      onClick: (e: any) => {
        e.preventDefault();
        mailToSend(
          [{ ...tenantContact, additionalContactDetails: tenantAdditionalContact }],
          toastRef,
          agency?.useAdditionalEmailAddresses,
          agency?.additionalEmailAddressOptions
        );
      },
    },
    // { buttonName: <Line height={32} />, type: "line" },
    // { buttonName: <Mobile /> },
  ];
  const notesButton = useMemo(() => {
    return [
      {
        buttonName: isNotesEditable ? closeButtonText : Labels.ADD_EDIT_NOTE,
        type: "normal",
        onClick: isNotesEditable
          ? () => setIsNotesEditable(false)
          : () => setIsNotesEditable(true),
      },
    ];
  }, [isNotesEditable]);

  const leaseTableButtons = [
    {
      buttonName: <ViewColumn />,
      onClick: () =>
        toastRef?.current?.show({
          severity: "info",
          summary: "Info",
          detail: comingSoon,
          life: 2000,
        }),
    },
  ];

  const setData = (data: any) => {
    setLotDetail({
      ...lotDetail,
      agentContactId: data?.agentContactId,
      ownerOccupied: data?.ownerOccupied,
      tenantNoticesIssue: data?.tenantNoticesIssue,
    });
  };
  async function onSubmit(data: any) {
    setIsLoading(true);
    const payload = {
      ...data,
      agentContactId: data?.ownerOccupied ? 0 : data?.agentContactId
    }
    const output = await httpUpdate(
      EndPoints.corporation + corpId + "/lots/" + lotId + "/lease-details",
      payload
    );
    if (output?.status === 200) {
      setData(payload);
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: 2000,
      });
      reset({}, { keepDirty: false, keepValues: true });
    }
    if (output?.error) {
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `error:${output?.error}`,
        life: 3000,
      });
    }
  }
  function onDiscard() {
    reset({
      agentContactId: lotDetail?.agentContactId,
      ownerOccupied: lotDetail?.ownerOccupied,
      tenantNoticesIssue: lotDetail?.tenantNoticesIssue,
    });
    setShowModal(false);
  }

  return (
    <>
      {isLoading && (
        <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-auto overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      {isDirty && (
        <div className={`flex justify-end`}>
          <div className="flex items-center gap-3 flex-row order-last mt-5 mb-2 mr-5">
            <button
              type="button"
              className="transparent-button"
              onClick={() => setShowModal(true)}
            >
              {cancelButtonText}
            </button>
            <button
              type="submit"
              className="btn-primary !ml-0"
              form="ownerOccupiedForm"
            >
              {saveButtonText}
            </button>
          </div>
        </div>
      )}
      <div className="flex flex-wrap">
        <form
          className="flex flex-row basis-full"
          id="ownerOccupiedForm"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className="flex flex-col basis-full gap-2 lg:flex-row">
            <div
              className={`flex w-full rounded-lg py-4 px-8 ${styles.card}`}
            >
              <div>
                <SMCheckbox
                  name="ownerOccupied"
                  control={control}
                  label={Labels.OWNER_OCCUPIED_CHECKBOX}
                  labelClassName="font-bold"
                />
              </div>
            </div>
            <div
              className={`flex w-full rounded-lg mr-2 pl-8 pr-8 pt-4 pb-4 justify-center ${styles.card}`}
            >
              <div className="flex w-full">
                <div className={styles.width}>
                  <p className="font-bold">{Labels.NOTICE_DELIVERY}</p>
                </div>
                <Controller
                  name="tenantNoticesIssue"
                  control={control}
                  render={({ field }) => (
                    <>
                      <div className="flex w-full justify-between items-center mt-1">
                        <div className="flex w-full basis-full text-center items-center">
                          <SMRadioButton
                            id="printField"
                            field={field}
                            value="P"
                            checked={
                              field?.value === "E" && disableEmail
                                ? true
                                : field?.value === "P"
                                ? true
                                : false
                            }
                            onChange={(e: any) => field.onChange(e.value)}
                          />
                          <label
                            htmlFor="print"
                            className={`ml-2 font-normal text-center text-sm`}
                          >
                            {Labels.PRINT_RADIOBUTTON_TEXT}
                          </label>
                        </div>
                        <div className="flex w-full basis-full text-center items-center">
                          <SMRadioButton
                            id="emailField"
                            field={field}
                            value="E"
                            checked={
                              field?.value === "E" && disableEmail
                                ? false
                                : field?.value === "E"
                                ? true
                                : false
                            }
                            onChange={(e: any) => field.onChange(e.value)}
                            disabled={disableEmail}
                          />
                          <label
                            htmlFor="email"
                            className={`ml-2 font-normal text-center text-sm`}
                          >
                            {Labels.EMAIL_RADIOBUTTON_TEXT}
                          </label>
                        </div>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>
          </div>
        </form>
        <div className="basis-full lg:basis-1/2">
          {!ownerOccupied && (
            <CollapsibleCard
              id="agentContact"
              title={Labels.AGENT}
              expand={true}
              buttons={agentContactButtons}
              icon={<Information />}
            >
              <AgentContact agentContact={agentContact} agentContactId={lotDetail?.agentContactId} />
            </CollapsibleCard>
          )}
        </div>
        <div className="basis-full lg:basis-1/2">
          {
            <CollapsibleCard
              id="tenantContact"
              title={Labels.TENANT_CONTACT}
              expand={true}
              buttons={tenantContactButtons}
            >
              <TenantContact
                lotDetails={lotDetail}
                showTenantContactModal={showTenantContactModal}
                tenantContact={tenantContact}
                tenantAdditionalContact={tenantAdditionalContact}
                setShowTenantContactModal={setShowTenantContactModal}
                setTenantContact={setTenantContact}
                setTenantAdditionalContact={setTenantAdditionalContact}
              />
            </CollapsibleCard>
          }
        </div>
      </div>
      <div className={styles.notesContainer}>
        <CollapsibleCard
          id="notes"
          title={Labels.NOTES}
          expand={true}
          buttons={notesButton}
        >
          <div className="w-full flex mt-5">
            <Notes
              corpId={corpId}
              lotId={lotId}
              isNotesEditable={isNotesEditable}
              notes={notes}
              setNotes={setNotes}
            />
          </div>
        </CollapsibleCard>
      </div>
      <div className="flex-auto mt-5">
        <CollapsibleCard
          id="leaseTable"
          title={Labels.PAST_AND_PRESENT_LEASE}
          expand={true}
          buttons={leaseTableButtons}
        >
          <Lease lotDetails={lotDetail} corpId={corpId} lotId={lotId} />
        </CollapsibleCard>
      </div>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Warning"
        onAction={() => onDiscard()}
      >
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
      <ToastContainer toastReference={toastRef} />
    </>
  );
};

export default Agent;
