import { Suspense, lazy, useEffect } from "react";
import { RootState } from "./store/rootReducer";
import { useDispatch, useSelector } from "react-redux";
import LoadingPage from "./components/shared/loading/loadingPage";
import { Navbar } from "./components/menu/navbar";
import { PageHeader } from "./components/shared/pageHeader/pageHeader";
import styles from "./App.module.scss";
import { Outlet } from "react-router-dom";
import { axiosInstance } from "./services/interceptors";
import { setAgencySettings } from "./store/slice/agencySlice";

const SideNav = lazy(() => import("./components/menu/sideNav"));

function RootLayout() {
  const { user } = useSelector((state: RootState) => state?.userSlice);
  const { sideNavCollapsed } = useSelector((state: RootState) => state.userSettings);
  const { breadCrumb, icons } = useSelector((state: RootState) => state.breadCrumbs);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.agencyId) {
      getAgencySettings();
    }
  }, []);

  const getAgencySettings = async () => {
    let agencySetting = await axiosInstance.get(`agency-setting`);
    if (agencySetting?.data) {
      dispatch(setAgencySettings(agencySetting?.data));
    }
  };
  return (
    <div className="flex">
      <aside className={`${styles.side_nav_container} ${sideNavCollapsed ? styles.nav_collapsed : ""}`}>
        <Suspense fallback={<LoadingPage />}>
          <SideNav collapsed={sideNavCollapsed} />
        </Suspense>
      </aside>
      <section className={`${sideNavCollapsed ? styles.section_container : styles.section_container_collapsed}`}>
        <Navbar />
        <main className={styles.main_container}>
          <PageHeader breadCrumb={breadCrumb} headerData={icons} />
          <div className={`${styles.maxHeight} overflow-y-auto overflow-x-hidden`}>
            <Outlet />
          </div>
        </main>
      </section>
    </div>
  );
}

export default RootLayout;
