import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  user: null,
  userInfo: null,
  loggedInUserInfo: null,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<any>) => {
      state.user = action.payload;
    },
    setUserInfo: (state, action: PayloadAction<any>) => {
      state.userInfo = action.payload;
    },
    setLoggedInUserInfo: (state, action: PayloadAction<any>) => {
      state.loggedInUserInfo = action.payload;
    },
  },
});

export const { setUserDetails, setLoggedInUserInfo, setUserInfo } = userSlice.actions;
export default userSlice.reducer;
