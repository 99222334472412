import { UserManager, WebStorageStateStore } from "oidc-client-ts";
import store from "src/store/store";

export class AuthService {
  userManager;
  config;

  constructor() {
    this.config = store?.getState?.()?.authSlice?.authConfig;

    this.userManager = new UserManager({
      userStore: new WebStorageStateStore({ store: window.localStorage }),
      redirect_uri: this.config?.okta?.authorizationEndpoint + "/Redirect" ?? "",
      post_logout_redirect_uri: window?.location?.origin,
      client_id: this.config?.okta?.clientId ?? "",
      authority: this.config?.okta?.issuer ?? "",
      scope: "openid profile email",
      automaticSilentRenew: false,
      response_type: "code",
      response_mode: "query",
      extraQueryParams: {
        $state: `{"source_redirect_url":"${window?.location?.origin}","authorize_url":"${this.config?.okta?.issuer}/v1/authorize"}`,
      },
      metadata: {
        issuer: this.config?.okta?.issuer,
        authorization_endpoint: `${this.config?.okta?.authorizationEndpoint}/Forward?$authorize_url=${this.config?.okta?.issuer}/v1/authorize&$interstitial_prompt_mri_client_id=false&$interstitial_tryGetClientIdFromCookie=false&$interstitial_email_federation=true`,
        token_endpoint: this.config?.okta?.issuer + "/v1/token",
        end_session_endpoint: this.config?.okta?.authorizationEndpoint + "/ForwardLogout",
      },
    });
  }

  signinRedirectCallback = () => {
    return this.userManager.signinRedirectCallback();
  };

  getUser = async () => {
    const user = await this.userManager.getUser();
    return user;
  };

  signinRedirect = async () => {
    return this.userManager.signinRedirect();
  };

  logout = () => {
    return this.userManager.signoutRedirect({
      id_token_hint: localStorage.getItem("id_token") ?? "",
      extraQueryParams: {
        state: `{"logout_url":"${this.config?.okta?.issuer}/v1/logout"}`,
      },
    });
  };

  signoutRedirectCallback = () => {
    return this.userManager?.signoutRedirectCallback?.();
  };

  removeUser = () => {
    return this.userManager?.removeUser?.();
  }
}
