import PolicyForm from "./policyForm";
import { useHttpGet } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { Errors } from "src/components/shared/error/error";
import { Messages } from "src/constants/messages";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { creditorType } from "src/constants/constant";

const PolicyTableDrawer = ({ toastRef, setShowSidebar }: { toastRef: any; setShowSidebar: any }) => {
  const { data: policyTypes, error: policyTypesError, statusCode: policyTypesStatus } = useHttpGet(EndPoints.agency + "/policy-types");
  const {
    data: brokers,
    error: brokersError,
    statusCode: brokersStatus,
  } = useHttpGet(EndPoints.creditors, {
    creditorType: creditorType.broker,
  });
  const {
    data: companies,
    error: companiesError,
    statusCode: companiesStatus,
  } = useHttpGet(EndPoints.creditors, {
    creditorType: creditorType.company,
  });

  if (policyTypesError || companiesError) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (!policyTypes || !brokers || !companies) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }
  return <PolicyForm policyTypes={policyTypes} brokers={brokers} companies={companies} toastRef={toastRef} setShowSidebar={setShowSidebar}/>;
};

export default PolicyTableDrawer;
