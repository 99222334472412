import { useRef, useState } from "react";
import styles from "../ownerContact.module.scss";
import { DocumentWord2, Line, Mail } from "src/assets/icon";
import FormModal from "src/components/ui/dialog/formModal";
import OwnerContactEdit from "../ownerContactEdit";
import { Toast } from "primereact/toast";
import ToastContainer from "src/components/ui/toast/toast";
import { Messages } from "src/constants/messages";
import { Labels } from "src/constants/labels";
import { mailToSend } from "src/utils";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";
import AdditionalContactDetails from "src/components/shared/contact/additionalContactDetails/additionalContactDetails";

const OwnerContactDetails = ({ data }: { data: any }) => {
  const [contactData, setContactData] = useState({
    contactId: data?.lContactID,
    businessContact: data?.bBusinessContact?.toUpperCase?.() === "N" ? false : true,
    buildingName: data?.sBuildingName,
    salutation: data?.sSalutation,
    name: data?.sName,
    firstName: data?.sFirstName,
    otherNames: data?.sOtherNames,
    title: data?.sTitle,
    streetNumber: data?.sStreetNumber,
    streetName: data?.sStreetName,
    town: data?.sTown,
    state: data?.sState,
    postcode: data?.sPostcode,
    country: data?.sCountry,
    website: data?.sWebsite,
    fax: data?.sFax,
    email: data?.sEmail,
    telephone1: data?.sTelephone1,
    telephone2: data?.sTelephone2,
    telephone3: data?.sTelephone3,
    committeeEmailAddress: data?.committeeEmailAddress,
    poBox: data?.sPOBox,
    committeeMember: data?.lExecutiveMemberID,
  });
  const [additionalContactDetails, setAdditionalContactDetails] = useState(
    data?.sAdditionalContactDetails?.map?.((contact: any) => ({
      type: contact?.sType,
      value: contact?.sValue,
      emailLevies: contact?.bEmailLevies?.toUpperCase?.() === "N" ? false : true,
      emailMeetingDocs: contact?.bEmailMeetingDocs?.toUpperCase?.() === "N" ? false : true,
      emailCorrespondence: contact?.bEmailCorrespondence?.toUpperCase?.() === "N" ? false : true,
      notes: contact?.sNotes,
      contactDetailsId: contact?.lContactDetailsID, 
    }))
  );
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [isFormDirty, setIsFormDirty] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  let formId = "originalOwnerContactUpdate";
  const { agency } = useSelector((state: RootState) => state?.agencySettingSlice);

  return (
    <div data-testid="ownerContactCard" className={`flex-auto basis-full lg:ml-0 ${styles.card}`}>
      <div className="w-full flex justify-between">
        <label className="section-header mb-2">Original Owner Contact</label>
        <div className="flex order-last items-center justify-center">
          <button
            className={`${styles.editBtn}`}
            onClick={() => {
              if (!contactData?.contactId) {
                toast?.current?.show({ severity: "warn", summary: "Warning", detail: Messages.NO_CONTACT_EXISTS, life: 5000 });
              } else {
                setShowModal(true);
              }
            }}
          >
            Edit
          </button>
          {/* <button className="pr-2.5">
            <DocumentWord2 />
          </button>
          <Line /> */}
          <button
            className="pl-2.5"
            onClick={() =>
              mailToSend(
                [
                  {
                    ...contactData,
                    committeeEmailAddress: null,
                    additionalContactDetails: additionalContactDetails,
                  },
                ],
                toast,
                agency?.useAdditionalEmailAddresses,
                agency?.additionalEmailAddressOptions
              )
            }
          >
            <Mail />
          </button>
        </div>
      </div>
      {contactData?.contactId && (
        <div className="mt-4">
          {contactData != null && (
            <>
              <div className="py-4">
                <label className="text-sm font-bold">{contactData?.businessContact ? Labels.BUSINESS_CONTACT : Labels.PRIVATE_RESIDENTIAL_CONTACT}</label>
              </div>
              <hr className="mt-1" />
            </>
          )}
          <div className="text-left font-normal text-sm mt-5 px-4">
            {contactData?.businessContact && contactData?.otherNames && (
              <div className={styles.address}>
                <label className="font-semibold">{Labels.CONTACT_PERSON}</label>
                {contactData?.otherNames}
              </div>
            )}
            {contactData?.salutation && (
              <div className={styles.address}>
                <label className="font-semibold">{Labels.SALUTATION}</label>
                {contactData?.salutation}
              </div>
            )}
          </div>
          <div className="text-left font-normal text-sm my-2 px-4">
            {contactData?.businessContact ? (
              <div className={styles.address}>{contactData?.name}</div>
            ) : (
              <div className={styles.address}>
                {contactData?.title} {contactData?.firstName} {contactData?.otherNames} {contactData?.name}
              </div>
            )}
            {contactData?.poBox && <div className={styles.address}>{contactData?.poBox}</div>}
            {!contactData?.poBox && contactData?.buildingName && <div className={styles.address}>{contactData?.buildingName}</div>}
            {!contactData?.poBox && contactData?.streetName && (
              <div className={`${styles.address} !h-auto`}>
                {contactData?.streetNumber} {contactData?.streetName}
              </div>
            )}
            <div className={styles.address}>
              {contactData?.town} {contactData?.state} {contactData?.postcode} {contactData?.country}
            </div>
          </div>
          {contactData != null && <hr className="mt-1" />}
          <div className="text-left font-normal text-sm my-2 px-4">
            {contactData?.telephone1 && (
              <div className="py-2">
                <label className="font-semibold">{contactData?.businessContact ? Labels.TELEPHONE_1 : Labels.HOME_PHONE}</label>
                <a href={`tel:${contactData?.telephone1}`} className="ml-1 underline">
                  {contactData?.telephone1}
                </a>
              </div>
            )}
            {contactData?.telephone2 && (
              <div className="py-2">
                <label className="font-semibold">{contactData?.businessContact ? Labels.TELEPHONE_2 : Labels.WORK_PHONE}</label>
                <a href={`tel:${contactData?.telephone2}`} className="ml-1 underline">
                  {contactData?.telephone2}
                </a>
              </div>
            )}
            {contactData?.telephone3 && (
              <div className="py-2">
                <label className="font-semibold">{Labels.MOBILE}</label>
                <a href={`tel:${contactData?.telephone3}`} className="ml-1 underline">
                  {contactData?.telephone3}
                </a>
              </div>
            )}
            {contactData?.fax && (
              <div className="py-2">
                <label className="font-semibold">{Labels.FAX}</label>
                {contactData?.fax}
              </div>
            )}
            {contactData?.email && (
              <div className="py-2">
                <label className="font-semibold">{Labels.EMAIL}</label>
                <a href={`mailto:${contactData?.email}`} className="ml-1 underline hyperlink" rel="noreferrer">
                  {contactData?.email}
                </a>
              </div>
            )}
            {contactData?.businessContact && contactData?.website && (
              <div className="py-2">
                <label className="font-semibold">{Labels.WEBSITE}</label>
                <a href={`https://${contactData?.website}`} target="_blank" className="ml-1 underline hyperlink" rel="noreferrer">
                  {contactData?.website}
                </a>
              </div>
            )}
          </div>
          <AdditionalContactDetails additionalContactDetails={additionalContactDetails} borderTop="border-t" isBusiness={contactData?.businessContact} />
        </div>
      )}
      <FormModal
        width="80vw"
        formId={formId}
        header="Update Original Owner Contact Details"
        isOpen={showModal}
        onClose={() => {
          if (isFormDirty) {
            setShowCancelModal(true);
          } else {
            setShowModal(false);
          }
        }}
        isDirty={isFormDirty}
      >
        <OwnerContactEdit
          contactData={contactData}
          additionalContactDetails={additionalContactDetails}
          setContactData={setContactData}
          setShowModal={setShowModal}
          formId={formId}
          setIsFormDirty={setIsFormDirty}
          showCancelModal={showCancelModal}
          setShowCancelModal={setShowCancelModal}
          toastRef={toast}
          setAdditionalContactDetails={setAdditionalContactDetails}
        />
      </FormModal>
      <ToastContainer toastReference={toast} />
    </div>
  );
};

export default OwnerContactDetails;
