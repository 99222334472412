import axios from "axios";
import { axiosInstance } from "./interceptors";
import { useEffect, useState } from "react";

export const useHttpGet = (path: string, params: any = {}, shouldFetch: boolean = true) => {
  const [data, setData] = useState<any>(null);
  const [error, setError] = useState<any>(null);
  const [statusCode, setStatusCode] = useState<number | null>(null);

  useEffect(() => {
    const cancelToken = axios?.CancelToken?.source?.();

    const getData = async () => {
      try {
        let res = await axiosInstance.get(path, {
          params,
          cancelToken: cancelToken?.token,
          validateStatus: () => true
        });
        if (res?.data && res?.status === 200) {
          setData(res.data);
        }
        if (res?.status) {
          setStatusCode(res.status);
        }
      } catch (err) {
        if (axios?.isCancel?.(err)) {
          console.log("Request canceled", err.message);
        } else {
          setError(err);
        }
      }
    };

    getData();

    return () => {
      cancelToken?.cancel?.();
    };
  }, [path]);

  return { data, error, statusCode };
};

export const httpDelete = async (path: string, params: any = {}, optimistic?: boolean) => {
  let output = { data: null, error: null, status: null  };

  await axiosInstance
    .delete(path)
    .then((res: any) => {})
    .catch((err: any) => {
      if (err.response && err.response.data) {
        const responseData = err.response.data;
        let errorMessage: any = '';

        if (responseData.statusCode && responseData.message) {
          errorMessage = responseData.message;
        } else {
          errorMessage = Object.values(responseData).map((value: any) => value.join(', ')).join('; ');
        }

        output = { data: null, error: errorMessage, status: err.response.status };
      } else {
        output = { data: null, error: err.message, status: null };
      }
    });

  return output;
};

export const httpPost = async (path: string, body: any, optimistic?: boolean, params: any = {}) => {
  let output = { data: null, error: null, status: null };

  await axiosInstance
    .post(path, body, {
      params: params,
    })
    .then((res: any) => {
      output = { data: res.data, error: null, status: res.status };
    })
    .catch((err: any) => {
      if (err.response && err.response.data) {
        const responseData = err.response.data;
        let errorMessage: any = '';

        if (responseData.statusCode && responseData.message) {
          errorMessage = responseData.message;
        } else {
          errorMessage = Object.values(responseData).map((value: any) => value.join(', ')).join('; ');
        }

        output = { data: null, error: errorMessage, status: err.response.status };
      } else {
        output = { data: null, error: err.message, status: null };
      }
    });

  return output;
};

export const httpUpdate = async (path: string, body: any, optimistic?: boolean, params: any = {}) => {
  let output = { data: null, error: null, status: null };

  await axiosInstance
    .put(path, body)
    .then((res: any) => {
      output = { data: res.data, error: null, status: res.status };
    })
    .catch((err: any) => {
      if (err.response && err.response.data) {
        const responseData = err.response.data;
        let errorMessage: any = '';

        if (responseData.statusCode && responseData.message) {
          errorMessage = responseData.message;
        } else {
          errorMessage = Object.values(responseData).map((value: any) => value.join(', ')).join('; ');
        }

        output = { data: null, error: errorMessage, status: err.response.status };
      } else {
        output = { data: null, error: err.message, status: null };
      }
    });

  return output;
};
