"use client";
import { OwnerTitle } from "./ownerTitle/ownerTitle";
import { MainContact } from "./mainContact/mainContact";
import { CollapsibleCard } from "../../../../../components/ui/collapsibleCard/collapsibleCard";
import {
  Building,
  DocumentWord2,
  Line,
  Mail,
  Owner,
} from "../../../../../assets/icon";
import { MessagesMessage } from "primereact/messages";
import MessageContainer from "../../../../../components/ui/toast/messages";
import { OwnerDetails } from "./ownerDetails/ownerDetails";
import { CorpNotes } from "../../../../corporation/corpNotes/corpNotes";
import { useEffect, useRef, useState } from "react";
import { Labels } from "../../../../../constants/labels";
import { EditMainContact } from "./mainContact/editMainContact";
import { httpUpdate, useHttpGet } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import {
  cancelButtonText,
  closeButtonText,
  editButtonText,
  modalDiscardMessageText,
  saveButtonText,
} from "src/constants/constant";
import { Messages } from "src/constants/messages";
import { mailToSend } from "src/utils";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";
import { Toast } from "primereact/toast";
import ToastContainer from "src/components/ui/toast/toast";
import { Modal } from "src/components/ui/modal/modal";
import { useForm } from "react-hook-form";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";

export default function General({
  setActiveTabIndex,
  lotDetails,
  corpId,
  lotId,
}: {
  setActiveTabIndex: any;
  lotDetails: any;
  corpId: string;
  lotId: string;
}) {
  const [selectedOwner, setSelectedOwner] = useState(lotDetails?.owner[0]);
  const [lotDetail, setLotDetail] = useState(lotDetails);
  const [isEditableContact, setEditableContact] = useState(false);
  const [isNotesEditable, setIsNotesEditable] = useState(false);
  const [isEditableOwnerDetail, setEditableOwnerDetail] = useState<any>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { data: linkedLotsDetails } = useHttpGet(
    EndPoints.owners + selectedOwner?.contact?.contactId + "/linked-lots"
  );
  const toastRef = useRef<Toast>(null);
  const { agency } = useSelector(
    (state: RootState) => state?.agencySettingSlice
  );
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    formState: { isDirty },
  } = useForm({
    defaultValues: {
      ownerId: selectedOwner?.ownerId,
      nameOnTitle: selectedOwner?.nameOnTitle?.trim() ?? "",
      companyNominee: selectedOwner?.companyNominee?.trim() ?? "",
      ownerType: selectedOwner?.ownerType,
    },
  });
  const ownerType = watch("ownerType");

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  useEffect(() => {
    if (ownerType === "I")
    {
       setValue("companyNominee", "");
    }
  }, [ownerType]);
  
  const mainContactButtons = [
    {
      buttonName: "Edit",
      type: "normal",
      onClick: () => setEditableContact(true),
    },
    // { buttonName: <DocumentWord2 height={24} width={24} />, type: "word" },
    // { buttonName: <Line height={32} />, type: "line" },
    {
      buttonName: <Mail height={24} width={24} />,
      type: "mail",
      onClick: (e: any) => {
        e.preventDefault();
        mailToSend(
          [
            {
              ...selectedOwner?.contact,
              committeeEmailAddress: null,
              additionalContactDetails: selectedOwner?.contactDetails,
            },
          ],
          toastRef,
          agency?.useAdditionalEmailAddresses,
          agency?.additionalEmailAddressOptions
        );
      },
    },
  ];
  const ownerDetailButtons = [
    {
      buttonName: isEditableOwnerDetail ? cancelButtonText : editButtonText,
      type: "normal",
      onClick: isEditableOwnerDetail
        ? () => setEditableOwnerDetail(false)
        : () => setEditableOwnerDetail(true),
    },
    {
      buttonName: isEditableOwnerDetail ? saveButtonText : "",
      type: isEditableOwnerDetail ? "save" : "",
      buttonType: "submit",
      formId: "ownerDetailForm",
    },
  ];
  const notesButtons = [
    {
      buttonName: isNotesEditable ? closeButtonText : Labels.ADD_EDIT_NOTE,
      type: "normal",
      onClick: isNotesEditable
        ? () => setIsNotesEditable(false)
        : () => setIsNotesEditable(true),
    },
  ];
  const messagePropCommittee: MessagesMessage[] = [
    {
      sticky: true,
      icon: <Owner stroke="#1A56DB" />,
      severity: "info",
      detail: `${
        selectedOwner?.executivePosition
      } of the committee for strata plan ${lotDetails?.planNumber?.trim?.()}`,
      closable: false,
      pt: {
        wrapper: { className: "px-2" },
      },
    },
  ];
  const messagePropLinkedLot: MessagesMessage[] = [
    {
      sticky: true,
      icon: <Building stroke="#287347" />,
      severity: "success",
      detail: Messages.LINKED_LOT_OWNER,
      closable: false,
      pt: {
        wrapper: { className: "px-4" },
      },
    },
  ];
  const setNotes = (notes: any) => {
    setLotDetail({
      ...lotDetail,
      notes: notes,
    });
  };
  function navigateToLinkedLot() {
    setActiveTabIndex(6);
  }
  const setData = (data: any) => {
    setSelectedOwner({
      ...selectedOwner,
      ownerId: data?.ownerId,
      nameOnTitle: data?.nameOnTitle,
      ownerType: data?.ownerType,
      companyNominee: data?.companyNominee,
    });
  };
  async function onSubmit(data: any) {
    const payload = {
      ...data,
      nameOnTitle: data?.nameOnTitle?.trim(),
      companyNominee: data?.companyNominee?.trim(),
    }

    setIsLoading(true);
    const output = await httpUpdate(
      EndPoints.corporation + corpId + "/lots/" + lotId + "/ownerdetails?ownerId=" + data.ownerId,
      payload
    );
    if (output?.status === 200) {
      setData(payload);
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: 2000,
      });
      reset({
        ownerId: payload?.ownerId,
        nameOnTitle: payload?.nameOnTitle,
        ownerType: payload?.ownerType,
        companyNominee: payload?.companyNominee,
      }, { keepDirty: false });
    }
    if (output?.error) {
      setIsLoading(false);
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `error:${output?.error}`,
        life: 3000,
      });
    }
  }
  function onDiscard() {
    reset({
      ownerId: selectedOwner?.ownerId,
      nameOnTitle: selectedOwner?.nameOnTitle,
      ownerType: selectedOwner?.ownerType,
      companyNominee: selectedOwner?.companyNominee,
    });
    setShowModal(false);
  }
  return (
    <>
      {isLoading && (
        <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-auto overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      {isDirty && (
        <div className={`flex justify-end`}>
          <div className="flex items-center gap-3 flex-row order-last mt-5 mb-2 mr-5">
            <button
              type="button"
              className="transparent-button"
              onClick={() => setShowModal(true)}
            >
              {cancelButtonText}
            </button>
            <button
              type="submit"
              className="btn-primary !ml-0"
              form="ownerTitleForm"
            >
              {saveButtonText}
            </button>
          </div>
        </div>
      )}
      {isEditableContact ? (
        <EditMainContact
          selectedOwner={selectedOwner}
          setEditableContact={setEditableContact}
          setSelectedOwner={setSelectedOwner}
          toastRef={toastRef}
        />
      ) : (
        <div className={`flex flex-wrap`}>
          <div className="basis-full lg:basis-1/2">
          <form id="ownerTitleForm" onSubmit={handleSubmit(onSubmit)} noValidate>
            <OwnerTitle
              lotDetails={lotDetail}
              selectedOwner={selectedOwner}
              setSelectedOwner={setSelectedOwner}
              control={control}
              ownerType={ownerType}
            />
          </form>
            <CollapsibleCard
              id="MainCOntact"
              title={Labels.MAIN_CONTACT_FOR_OWNER}
              expand={true}
              buttons={mainContactButtons}
            >
              <MainContact selectedOwner={selectedOwner} />
            </CollapsibleCard>
            <CollapsibleCard
              id="GeneralNotes"
              title={Labels.NOTES}
              expand={true}
              buttons={notesButtons}
            >
              <div className="pt-4">
                <CorpNotes
                  isEditable={isNotesEditable}
                  corpId={corpId}
                  data={{ note: lotDetail?.notes }}
                  updateUrl={
                    EndPoints.corporation + corpId + "/lots/" + lotId + "/notes"
                  }
                  setNotes={setNotes}
                />
              </div>
            </CollapsibleCard>
          </div>
          <div className="basis-full lg:basis-1/2">
            <CollapsibleCard
              id="OwnerDetail"
              title={Labels.OWNER_CORPORATION_DETAILS}
              expand={true}
              buttons={ownerDetailButtons}
            >
              <OwnerDetails
                key={isEditableOwnerDetail}
                corpId={corpId}
                lotId={lotId}
                lotDetails={lotDetail}
                selectedOwner={selectedOwner}
                isEditableOwnerDetail={isEditableOwnerDetail}
                setEditableOwnerDetail={setEditableOwnerDetail}
                setLotDetail={setLotDetail}
                setSelectedOwner={setSelectedOwner}
                toastRef={toastRef}
              />
            </CollapsibleCard>
            {selectedOwner?.executivePosition && (
              <div className="px-4">
                <MessageContainer messageProp={messagePropCommittee} />
              </div>
            )}
            {linkedLotsDetails?.length > 1 && (
              <div className="px-4">
                <MessageContainer
                  messageProp={messagePropLinkedLot}
                  onClick={() => navigateToLinkedLot()}
                />
              </div>
            )}
          </div>
        </div>
      )}
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Warning"
        onAction={() => onDiscard()}
      >
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
      <ToastContainer toastReference={toastRef} />
    </>
  );
}
