import UserTable from "../userTable/userTable";
import { Labels } from "src/constants/labels";
import { useHttpGet } from "src/services/api";
import { errorMessage } from "src/constants/constant";
import { Errors } from "src/components/shared/error/error";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";

const UserList = () => {
  const { user } = useSelector((state: RootState) => state?.userSlice);
  const { data: users, error: userError } = useHttpGet(`users/${user?.agencyId}`);
  const { data: agencies, error: agenciesError } = useHttpGet("agencies");

  if (userError || agenciesError) {
    return <Errors error={errorMessage} alignment="left" />;
  }

  if (!users || !agencies) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="w-auto h-full p-2 shadow-lg m-5 rounded-lg bg-white">
      {users?.length > 0 ? <UserTable users={users} agencies={agencies} /> : <div className="w-ful text-center flex-row p-10">{Labels.NO_USERS_FOUND}</div>}
    </div>
  );
};

export default UserList;
