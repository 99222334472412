import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { SMDatePicker } from "src/components/ui/datePicker/datePicker";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Modal } from "src/components/ui/modal/modal";
import ToastContainer from "src/components/ui/toast/toast";
import {
  cancelButtonText,
  editButtonText,
  saveButtonText,
  httpCode,
  toastSuccessTimeLimit,
  toastErrorTimeLimit,
  modalDiscardMessageText,
  datePickerPlaceHolder,
} from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import { IFinancialStatus } from "src/interfaces/financialStatusCertificate";
import { httpUpdate } from "src/services/api";
import { FormatDate, toDate } from "src/utils/utility";
import styles from "../financial.module.scss";

export const StatusCertificateSummary: React.FC<{
  corpId: any;
  statusDetails: IFinancialStatus;
}> = ({ corpId, statusDetails }) => {
  const [statusDetail, setStatusDetail] = useState(statusDetails);
  const [isEditableFinancialStatus, setIsEditableFinancialStatus] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty },
  } = useForm<IFinancialStatus>({
    defaultValues: {
      levyYearFrom: statusDetail?.levyYearFrom,
      levyYearTo: statusDetail?.levyYearTo,
      statusCertAttachment: statusDetail?.statusCertAttachment,
    },
    mode: "onTouched",
  });
  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  const statusCertificateButton = [
    {
      buttonName: isEditableFinancialStatus ? cancelButtonText : editButtonText,
      type: "normal",
      onClick: isEditableFinancialStatus ? onCancel : () => setIsEditableFinancialStatus(true),
    },
    {
      buttonName: isEditableFinancialStatus ? saveButtonText : "",
      type: isEditableFinancialStatus ? "save" : "",
      buttonType: "submit",
      formId: "financialStatusForm",
    },
  ];

  function onCancel() {
    if (isDirty) {
      setShowModal(true);
    } else {
      setIsEditableFinancialStatus(false);
    }
  }

  function onDiscard() {
    reset(statusDetail);
    setShowModal(false);
    setIsEditableFinancialStatus(false);
  }

  async function onSubmit(data: IFinancialStatus) {
    let payload = {
      ...data,
      levyYearFrom: toDate(FormatDate(data?.levyYearFrom) ?? ""),
      levyYearTo: toDate(FormatDate(data?.levyYearTo) ?? ""),
    };
    setIsLoading(true);
    const output = await httpUpdate(EndPoints.corporation + corpId + EndPoints.financial + "/status-certificate", payload);
    if (output?.status === httpCode.SUCCESS) {
      setStatusDetail(data);
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: toastSuccessTimeLimit,
      });
      reset({}, { keepDirty: false, keepValues: true });
      setIsEditableFinancialStatus(false);
    }
    if (output?.error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Error: ${output?.error}`,
        life: toastErrorTimeLimit,
      });
    }
    setIsLoading(false);
  }
  return (
    <>
      <CollapsibleCard id="Status Certificate" title={Labels.STATUS_CERTIFICATE} expand={true} buttons={statusCertificateButton}>
        <div className="pt-4">
          {isLoading && (
            <div className="spinnerContainer">
              <div className="w-20 h-20">
                <SpinnerHorizontal />
              </div>
            </div>
          )}
          <form id="financialStatusForm" onSubmit={handleSubmit(onSubmit)} noValidate>
            <div data-testid="statusSummaryTab" className="basis-full text-sm mt-2 pb-2">
              <div className={`${styles.section} `}>
                <label className={styles.label}>{Labels.LEVY_YEAR}</label>
                {isEditableFinancialStatus ? (
                  <span className="mb-2 flex basis-2/3 text-left items-center">
                    <SMDatePicker
                      inputDate={FormatDate(statusDetail?.levyYearFrom) ?? ""}
                      control={control}
                      name="levyYearFrom"
                      disabled={!isEditableFinancialStatus}
                      width="w-full"
                    />
                    <label className="basis-1/3 text-center font-normal">{Labels.TO}</label>
                    <SMDatePicker
                      inputDate={FormatDate(statusDetail?.levyYearTo) ?? ""}
                      control={control}
                      name="levyYearTo"
                      disabled={!isEditableFinancialStatus}
                      width="w-full"
                    />
                  </span>
                ) : (
                  <div className="flex basis-2/3 text-left items-center">
                    <span className={styles.value}>
                      {FormatDate(statusDetail?.levyYearFrom) === "" ? datePickerPlaceHolder : FormatDate(statusDetail?.levyYearFrom)}
                    </span>
                    <label className="basis-full text-center font-normal">{Labels.TO}</label>
                    <span className={styles.value}>
                      {FormatDate(statusDetail?.levyYearTo) === "" ? datePickerPlaceHolder : FormatDate(statusDetail?.levyYearTo)}
                    </span>
                  </div>
                )}
              </div>
              <div className={styles.section}>
                <SMCheckbox
                  name="statusCertAttachment"
                  control={control}
                  label={Labels.PRINT_ALL_ATTACHMENTS_FOR_STATUS_CERTIFICATE}
                  disabled={!isEditableFinancialStatus}
                />
              </div>
            </div>
          </form>
        </div>
      </CollapsibleCard>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Warning" onAction={() => onDiscard()}>
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
      <ToastContainer toastReference={toastRef} />
    </>
  );
};
