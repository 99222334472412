import React from "react";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Messages } from "src/constants/messages";
import { ILotSummary } from "src/interfaces/lotSummary";
import { EndPoints } from "src/constants/endpoint";
import { Errors } from "src/components/shared/error/error";
import { useHttpGet } from "src/services/api";
import { LotSummaryDetail } from "./lotSummaryDetail";

export const LotSummary: React.FC<{
  corpId: string;
  setIsLoading: Function;
}> = ({ corpId, setIsLoading }) => {
  const { data, error } = useHttpGet(EndPoints.corporation + `${corpId}/lots/summary`) as {
    data: ILotSummary;
    error: any;
  };

  if (error) {
    return (
      <>
        <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />
      </>
    );
  }

  if (!data) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-10 h-10">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }

  return (
    <LotSummaryDetail corpId={corpId} setIsLoading={setIsLoading} lotDetails={data}/>
  );
};
