import { MultiSelect } from "primereact/multiselect";
import { classNames } from "primereact/utils";
import { Controller } from "react-hook-form";

interface IMultiSelect {
  id?: string;
  name?: string;
  rules?: object;
  control?: any;
  placeholder?: string;
  onChange?: any;
  options: any;
  optionLabel?: string;
  optionValue?: string;
  disabled?: boolean;
  filter?: boolean;
  display?: "comma" | "chip" | undefined;
  maxSelectedLabels?: number;
  resetFilterOnHide?: boolean;
}

export const SMMultiSelect: React.FC<IMultiSelect> = ({
  id,
  name,
  rules,
  control,
  placeholder,
  onChange,
  options,
  optionLabel = "name",
  optionValue = "name",
  disabled = false,
  filter,
  display,
  maxSelectedLabels,
  resetFilterOnHide
}) => {
  return name && control && (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <MultiSelect 
          id={field.name ?? ""}
          name={field.name}
          value={field.value}
          placeholder={placeholder}
          filter={filter}
          resetFilterOnHide={resetFilterOnHide}
          options={options}
          optionLabel={optionLabel}
          optionValue={optionValue}
          display={display}
          maxSelectedLabels={maxSelectedLabels}
          onChange={(e) => {
            field.onChange(e.value);
            if (onChange) onChange(e?.value);
          }}
          disabled={disabled}
          className={classNames({ "p-invalid": fieldState.error })}
          pt={{
            root: {
              className: "input-control  items-center !rounded-md !shadow-none !basis-full !p-0",
            },
            input: {
              className: "!p-1 !text-sm !font-semibold ",
            },
            trigger: {
              className: "!w-7",
            },
            item: {
              className: "!p-2 !text-sm !bg-gray-100 !text-neutral-500",
            },
            token: {
              className: "!p-1.5"
            },
            labelContainer: {
              className: "!h-11"
            },
            header: {
              className: "!p-2",
            },
            filterInput: {
              className: "!p-2",
            },
            clearIcon: {
              className: "!cursor-pointer",
            },
          }}
        />
      )}
    />
  )
};
