import UnitEntitlementSet from "src/pages/unitEntitlement/unitEntitlementSet/unitEntitlementset";
import { UnitEntitlement } from "src/pages/unitEntitlement/unitEntitlement/unitEntitlement";
import { useEffect, useState } from "react";
import { setBreadCrumbs, setIcons } from "src/store/slice/breadCrumbSlice";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import usePlanNumber from "src/hooks/usePlanNumber";

export default function Entitlements() {
  const { corpId } = useParams();
  const dispatch = useDispatch();
  const plaNumber = usePlanNumber(corpId ?? "");
  const [headerEntitlement, setHeaderEntitlement] = useState([]);

  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { title: "Corporations", url: "/corporations" },
        { title: `${plaNumber}`, url: `/corporations/${corpId}` },
        { title: "Unit Entitlements", url: `/corporations/${corpId}/unitentitlements` },
      ])
    );

    dispatch(
      setIcons([
        { name: "Important Contacts", route: `${corpId}/contact`, isSelected: false },
        { name: "Lots", route: `${corpId}/lots`, isSelected: false },
        { name: "Committee", route: `${corpId}/committee`, isSelected: false },
        { name: "Entitlements", route: `${corpId}/unitentitlements`, isSelected: true },
      ])
    );
  }, [plaNumber]);

  return (
    <>
      <div className={`page-container`}>
        <UnitEntitlementSet setHeaderEntitlement={setHeaderEntitlement} />
        {headerEntitlement?.length > 0 && (
          <div className={`pt-4 rounded-md `}>
            <UnitEntitlement headerEntitlement={headerEntitlement} corpId={corpId} />
          </div>
        )}
      </div>
    </>
  );
}
