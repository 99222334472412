import { useForm } from "react-hook-form";
import { errorText, modalDiscardMessageText, successText, typeList1, typeList2 } from "src/constants/constant";
import { httpPost, httpUpdate } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { Modal } from "src/components/ui/modal/modal";
import { useEffect, useState } from "react";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import { Labels } from "src/constants/labels";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { IAdditionalContact } from "src/interfaces/additionalContact";
import { Messages } from "src/constants/messages";
import { Errors } from "src/components/shared/error/error";
import { isNotBlank } from "src/utils";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";

export const EditableAdditionalContact = ({
  formData,
  setShowModal,
  handleChange,
  rowIndex,
  toastRef,
  formId,
  setIsFormDirty,
  showCancelModal,
  setShowCancelModal,
  businessContact,
  contactId,
}: {
  formData: IAdditionalContact;
  setShowModal: any;
  handleChange: any;
  rowIndex: number;
  toastRef: any;
  formId: string;
  setIsFormDirty: any;
  showCancelModal: boolean;
  setShowCancelModal: any;
  businessContact: any;
  contactId: any;
}) => {
  const {
    control,
    getValues,
    watch,
    trigger,
    reset,
    formState: { isDirty, errors, isValid },
  } = useForm<IAdditionalContact>({
    defaultValues: {
      type: formData?.type ?? "",
      value: formData?.value ?? "",
      emailLevies: formData?.emailLevies ?? false,
      emailMeetingDocs: formData?.emailMeetingDocs ?? false,
      emailCorrespondence: formData?.emailCorrespondence ?? false,
      notes: formData?.notes ?? "",
    },
    mode: "onTouched",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const type = watch("type");
  const typeList = businessContact ? typeList2 : typeList1;

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  useEffect(() => {
    if (setIsFormDirty) setIsFormDirty(isDirty);
  }, [isDirty]);

  const onSubmitContact = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault?.();
    e?.stopPropagation?.();
    if (!isValid) {
      trigger();
      return;
    }
    let data = getValues();
    let payload = {
      ...data,
      emailLevies: data.type !== Labels.EMAIL_ADDRESS_ ? false : data?.emailLevies,
      emailMeetingDocs: data.type !== Labels.EMAIL_ADDRESS_ ? false : data?.emailMeetingDocs,
      emailCorrespondence: data.type !== Labels.EMAIL_ADDRESS_ ? false : data?.emailCorrespondence,
    };
    setIsLoading(true);
    const response = formData
      ? await httpUpdate(EndPoints.contact + `${formData?.contactId ?? contactId}/contact-details/${formData?.contactDetailsId}`, payload)
      : await httpPost(EndPoints.contact + `${contactId}/contact-details`, payload);
    if (response?.status === 200) {
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: 3000,
      });
      handleChange(rowIndex, payload, response?.data);
      setShowModal(false);
      reset({}, { keepDirty: false });
    }
    if (response?.error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: errorText,
        life: 3000,
      });
    }
    setIsLoading(false);
  };

  const isEmailValid = (value: any) => {
    if (type === Labels.EMAIL_ADDRESS_) {
      const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      const emails = value.split(",");

      for (const email of emails) {
        const trimmedEmail = email.trim();
        if (!emailRegex.test(trimmedEmail)) {
          return false;
        }
      }
      return true;
    }
    return true;
  };
  return (
    <>
      {isLoading && (
        <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-modal overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <form id={formId} onSubmit={onSubmitContact} noValidate>
        <div className="w-full pl-6 pr-6">
          <div className="flex flex-col mt-5">
            <label className="mb-1.5 text-sm">{Labels.TYPE.charAt(0).toUpperCase() + Labels.TYPE.substring(1).toLowerCase()}</label>
            <SMDropDown
              control={control}
              name="type"
              options={typeList}
              rules={{ required: Messages.REQUIRED_MESSAGE, validate: isNotBlank }}
              onChange={() => {
                trigger("type");
              }}
            />
            {errors?.type && <Errors error={errors?.type?.message || Messages.REQUIRED_MESSAGE} alignment="left" />}
          </div>
          <div className="flex flex-col mt-5">
            <label className="mb-1.5 text-sm">{Labels.VALUE.charAt(0).toUpperCase() + Labels.VALUE.substring(1).toLowerCase()}</label>
            <SMInputText
              name="value"
              control={control}
              maxlength={100}
              rules={{ required: Messages.REQUIRED_MESSAGE, validate: { notBlank: isNotBlank, validEmail: isEmailValid } }}
              onChange={() => {
                trigger("value");
              }}
            />
            {errors?.value && (
              <Errors
                error={errors?.value?.type === "validEmail" ? Messages.INVALID_EMAIL : errors?.value?.message || Messages.REQUIRED_MESSAGE}
                alignment="left"
              />
            )}
          </div>
          {type === Labels.EMAIL_ADDRESS_ && (
            <div className="flex gap-6 mt-5 text-sm">
              <SMCheckbox name="emailLevies" control={control} label={Labels.LEVIES} />
              <SMCheckbox name="emailMeetingDocs" control={control} label={Labels.MEETING_DOCS} />
              <SMCheckbox name="emailCorrespondence" control={control} label={Labels.CORRESPONDENCE} />
            </div>
          )}

          <div className="flex flex-col mt-5 mb-5">
            <label className="mb-1.5 text-sm">{Labels.NOTES}</label>
            <SMInputText name="notes" control={control} maxlength={20} />
          </div>
        </div>
      </form>
      <Modal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        title="Warning"
        onAction={() => {
          setShowCancelModal(false);
          setShowModal(false);
        }}
      >
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
    </>
  );
};
