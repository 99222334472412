import React from "react";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Messages } from "src/constants/messages";
import { Errors } from "src/components/shared/error/error";
import { httpCode } from "src/constants/constant";
import { Labels } from "src/constants/labels";

export const LotSummary : React.FC<{data: any, error: any, status: any}> = ({data, error, status}) =>{
    if (error || httpCode.INTERNAL_SERVER_ERROR === status) {
      return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
    }

    if (httpCode.NOT_FOUND === status || httpCode.NO_CONTENT === status) {
      return <div className='flex w-full m-4'>{Messages.NO_LOTS}</div>;
    }

    if (!data) {
      return (
        <>
          <div className='flex w-full min-h-full items-center justify-center'>
            <div className='w-10 h-10'>
              <SpinnerHorizontal />
            </div>
          </div>
        </>
      )
    }
    return(
        <div className="flex flex-wrap text-sm m-2">
            <div data-testid="lotSummaryTab1" className="basis-full lg:basis-1/2 lg:pr-3">
                <div className="flex justify-between border-b">
                    <label className="basis-full sm:px-3 py-2 text-left font-normal">{Labels.REGISTERED}</label>
                    <span className="basis-full sm:px-3 py-2 text-right font-semibold">{data?.registeredLots}</span>
                </div>
                <div className="flex justify-between border-b">
                    <label className="basis-full sm:px-3 py-2 text-left font-normal">{Labels.RESIDENTIAL}</label>
                    <span className="basis-full sm:px-3 py-2 text-right font-semibold">{data?.residentialLots}</span>
                </div>
            </div>
            <div data-testid="lotSummaryTab1" className="basis-full lg:basis-1/2 lg:pr-3">
                <div className="flex justify-between border-b">
                    <label className="basis-full sm:px-3 py-2 text-left font-normal">{Labels.COMMERCIAL}</label>
                    <span className="basis-full sm:px-3 py-2 text-right font-semibold">{data?.commercialLots}</span>
                </div>

                <div className="flex justify-between border-b">
                    <label className="basis-full sm:px-3 py-2 text-left font-normal">{Labels.PRIMARY}</label>
                    <span className="basis-full sm:px-3 py-2 text-right font-semibold">{data?.primaryLots}</span>
                </div>
            </div>
            <div data-testid="lotSummaryTab2" className="basis-full lg:basis-1/2 lg:pr-3">
                <div className="flex justify-between border-b">
                    <label className="basis-full sm:px-3 py-2 text-left font-normal">{Labels.TOTAL}</label>
                    <span className="basis-full sm:px-3 py-2 text-right font-semibold">{data?.totalLots}</span>
                </div>
            </div>
        </div>
    )
}