import styles from './loadingPage.module.scss'
import { MriSM } from "../../../assets/icon";

const LoadingPage = () => {
    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <MriSM />
                <div className={styles.progress}>
                    <div className={styles.progress_value}></div>
                </div>
            </div>
        </div>
    );
};

export default LoadingPage;