import { useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Errors } from "src/components/shared/error/error";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { SMInputTextArea } from "src/components/ui/inputControl/inputTextarea";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Modal } from "src/components/ui/modal/modal";
import { cancelButtonText, httpCode, modalDiscardMessageText, saveButtonText, toastErrorTimeLimit, toastSuccessTimeLimit } from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import { ICoverage, ICoverageType } from "src/interfaces";
import { httpUpdate } from "src/services/api";
import { RootState } from "src/store/rootReducer";
import { setCoverageList } from "src/store/slice/insurance";
import { toLocaleValue, validateDecimal } from "src/utils";

const CoverageForm = ({
  setShowSidebar,
  coverageTypes,
  toastRef,
}: {
  setShowSidebar: React.Dispatch<React.SetStateAction<boolean>>;
  coverageTypes: Array<ICoverageType>;
  toastRef: any;
}) => {
  const { corpId } = useParams();
  const dispatch = useDispatch();
  const { selectedCoverage, coverageList } = useSelector((state: RootState) => state?.insuranceSlice);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [showInsuranceModal, setShowInsuranceModal] = useState<boolean>(false);
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    watch,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      coverageCodeId: selectedCoverage?.coverageCodeId,
      sumInsured: selectedCoverage?.sumInsured,
      excess: toLocaleValue(selectedCoverage?.excess)?.replaceAll?.(",", ""),
      notes: selectedCoverage?.notes ?? "",
      displayOrder: selectedCoverage?.displayOrder,
      buildingInsurance: selectedCoverage?.buildingInsurance,
    },
  });

  const buildingInsurance = watch("buildingInsurance");

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  const checkInsurance = (data: any) => {
    if (
      coverageList?.find?.((coverage: ICoverage) => coverage?.policyCoverageId !== selectedCoverage?.policyCoverageId && coverage?.buildingInsurance)
        ?.policyCoverageId &&
      selectedCoverage?.buildingInsurance !== buildingInsurance
    ) {
      setShowInsuranceModal(true);
    } else {
      onSubmit(data);
    }
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    let body = { ...data, excess: Boolean(data?.excess) ? Number(data?.excess) : 0 };
    let response = await httpUpdate(
      `${EndPoints.corporation}${corpId}/${EndPoints.insurance}/${selectedCoverage?.insuranceId}/policy-coverage/${selectedCoverage?.policyCoverageId}`,
      body
    );
    if (response?.status === httpCode.SUCCESS) {
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: toastSuccessTimeLimit,
      });
      dispatch(
        setCoverageList(
          coverageList?.map((coverage: ICoverage) => {
            if (coverage?.policyCoverageId === selectedCoverage?.policyCoverageId) {
              return {
                ...coverage,
                ...body,
                description: coverageTypes?.find?.((coverage: ICoverageType) => coverage?.coverageCodeId === data?.coverageCodeId)?.description,
              };
            } else {
              return {
                ...coverage,
                buildingInsurance: buildingInsurance ? false : coverage?.buildingInsurance,
              };
            }
          })
        )
      );
      reset({}, { keepDirty: false, keepValues: true });
      setShowSidebar(false);
    }
    if (response?.error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Error: ${response?.error}`,
        life: toastErrorTimeLimit,
      });
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div className="flex fixed justify-center items-center z-50 lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <form onSubmit={handleSubmit(checkInsurance)}>
        <div className="flex flex-col gap-1.5">
          <label className="required">{Labels.COVERAGE}</label>
          <SMDropDown
            name="coverageCodeId"
            control={control}
            options={coverageTypes}
            optionLabel="description"
            optionValue="coverageCodeId"
            rules={{
              validate: (value: number) => {
                return Boolean(coverageTypes?.find?.((coverageType: ICoverageType) => coverageType?.coverageCodeId === value)?.coverageCodeId);
              },
            }}
          />
          {errors?.coverageCodeId && <Errors error={Messages.REQUIRED_MESSAGE} alignment="left" />}
        </div>
        <div className="flex flex-col gap-1.5 mt-5">
          <label>{Labels.SUM_INSURED}</label>
          <SMInputText name="sumInsured" control={control} maxlength={20} />
        </div>
        <div className="flex flex-col gap-1.5 mt-5">
          <label>{Labels.EXCESS}</label>
          <SMInputText
            name="excess"
            control={control}
            maxlength={17}
            keyfilter={"num"}
            autoComplete="off"
            rules={{
              validate: (value: string) => {
                return validateDecimal(value, 2);
              },
            }}
          />
          {errors.excess && <Errors error={Messages.RESERVE_FUND_VALIDATION} alignment="left" />}
        </div>
        <div className="flex flex-col gap-1.5 mt-5">
          <label className="uppercase">{Labels.NOTES}</label>
          <div className="h-auto">
            <SMInputTextArea name="notes" control={control} autoResize={true} />
          </div>
        </div>
        <div className="flex flex-col gap-1.5 mt-5">
          <div className="items-center gap-1.5 uppercase">
            <SMCheckbox name="buildingInsurance" control={control} label={`${Labels.BUILDING} ${Labels.INSURANCE}`} />
          </div>
        </div>
        <div className="flex justify-end gap-2 flex-row order-last mt-5 mr-5">
          <button
            type="button"
            className="bg-white text-black py-1.5 px-4 text-md rounded-md items-center border-2"
            onClick={() => {
              if (isDirty) {
                setShowCancelModal(true);
              } else {
                setShowSidebar(false);
              }
            }}
          >
            {cancelButtonText}
          </button>
          <button
            type="submit"
            disabled={!isDirty}
            className={`bg-blue-500 text-white py-1.5 px-6 text-md rounded-md items-center ${!isDirty ? "opacity-60" : "opacity-100"}`}
          >
            {saveButtonText}
          </button>
        </div>
      </form>
      <Modal
        isOpen={showCancelModal}
        onClose={() => setShowCancelModal(false)}
        title="Warning"
        onAction={() => {
          setShowCancelModal(false);
          setShowSidebar(false);
        }}
      >
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
      <Modal
        isOpen={showInsuranceModal}
        onClose={() => setShowInsuranceModal(false)}
        title="Warning"
        onAction={() => {
          let data = getValues();
          setShowInsuranceModal(false);
          onSubmit(data);
        }}
      >
        <div>
          <h2>{Messages.BUILDING_INSURANCE_VALIDATION}</h2>
        </div>
      </Modal>
    </>
  );
};

export default CoverageForm;
