import React, { useRef } from "react";
import styles from  "./tooltip.module.scss";

interface ITooltip{
    content : string,
    theme?: string,
    children : React.ReactNode,    
}

export const Tooltip : React.FC<ITooltip> = ({content, theme, children}) => {
  const tooltipRef = useRef<HTMLSpanElement>(null);
  const container = useRef<HTMLDivElement>(null);
  
  return (
    <div
      ref={container}
      onMouseEnter={({ clientX }) => {
        if (!tooltipRef.current || !container.current) return;
        const { right } = container.current.getBoundingClientRect();

        tooltipRef.current.style.right = clientX - right + "px";
      }}
      className="group relative inline-block"
    >
      {children}
      {content ? (
        <span
          ref={tooltipRef}
          className={`invisible group-hover:visible opacity-0 group-hover:opacity-100 m-4 ${styles.content} ${theme === 'light' ? styles.light : styles.dark}`}
        >
          {content}
        </span>
      ) : null}
    </div>
  );
};

 
