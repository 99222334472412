import { Control, Controller } from "react-hook-form";
import { Card } from "src/components/ui/card/card";
import { SMRadioButton } from "src/components/ui/radioControl/radioButton";
import { Labels } from "src/constants/labels";
import styles from "../quickReports.module.scss";

const Managed = ({ control }: { control: Control<any, any> }) => {
  return (
    <Card>
      <div className="flex flex-col gap-4">
        <div className={`flex gap-4`}>
          <label className="w-full border-b pt-2 text-sm font-semibold text-gray-500 pb-4">{Labels.MANAGED}</label>
        </div>
        <div>
          <Controller
            name="managed"
            control={control}
            render={({ field }) => (
              <div className="flex gap-2">
                <div className="flex items-center">
                  <SMRadioButton id="managed" field={field} value={true} checked={field?.value === true} />
                  <label htmlFor="managed" className={`ml-2 ${styles.label}`}>
                    {Labels.MANAGED_PLANS}
                  </label>
                </div>
                <div className="flex items-center">
                  <SMRadioButton id="unmanaged" field={field} />
                  <label htmlFor="unmanaged" className={`ml-2 ${styles.label}`}>
                    {Labels.UNMANAGED_PLANS}
                  </label>
                </div>
                <div className="flex items-center">
                  <SMRadioButton id="all" field={field} />
                  <label htmlFor="all" className={`ml-2 ${styles.label}`}>
                    {Labels.ALL_PLANS}
                  </label>
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </Card>
  );
};

export default Managed;
