import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import store from "./store/store";

export const appInsightsInit = () => {
  var reactPlugin = new ReactPlugin();

  var appInsights = new ApplicationInsights({
    config: {
      connectionString: store?.getState?.()?.authSlice?.authConfig?.appInsights?.connectionString,
      enableAutoRouteTracking: true,
      extensions: [reactPlugin],
    },
  });

  appInsights.loadAppInsights();

  return { reactPlugin, appInsights };
};
