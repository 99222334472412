
import { useHttpGet } from "src/services/api";
import { Errors } from "src/components/shared/error/error";
import { Messages } from "src/constants/messages";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { httpCode, noDataFound } from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import AdvanceSearchForm from "./advanceSearchForm";

const AdvanceSearchDrawer = ({ toastRef, setShowSidebar }: { toastRef: any; setShowSidebar: any; }) => {
  const { data: managerList, error: managerListError, statusCode: managerListStatus  } = useHttpGet(EndPoints.corporation + "manager-list");

  if (managerListError || httpCode.INTERNAL_SERVER_ERROR === managerListStatus) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (httpCode.NOT_FOUND === managerListStatus || httpCode.NO_CONTENT === managerListStatus) {
    return <div className="flex w-full m-4">{noDataFound}</div>;
  }

  if (!managerList) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }
  return <AdvanceSearchForm toastRef={toastRef} managerList={managerList} setShowSidebar={setShowSidebar}/>;
};

export default AdvanceSearchDrawer;