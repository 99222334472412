import React from "react";
import { Errors } from "src/components/shared/error/error";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { httpCode, noDataFound } from "src/constants/constant";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import { FormatDate, checkZeroValue } from "src/utils/utility";

export const CorpSummary : React.FC<{data: any, error: any, status: any}> = ({data, error, status}) =>{
  if (error || httpCode.INTERNAL_SERVER_ERROR === status) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }
  if (httpCode.NOT_FOUND === status || httpCode.NO_CONTENT === status) {
    return <div className='flex w-full m-4'>{noDataFound}</div>;
  }
  if (!data) {
    return (
      <>
        <div className='flex w-full min-h-full items-center justify-center'>
          <div className='w-10 h-10'>
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    )
  }
  return(
      <div data-testid="corpSummaryTab" className="flex flex-wrap text-sm">
          {/* <div className="basis-full lg:basis-2/5 mt-2">
              <img alt='planDetail' width={196} height={171} src='/img/plansummary.jpg' className="h-full w-full rounded-md"/>
          </div> */}
          <div className="basis-full">
              <div className="flex justify-between border-b">
                  <label className="basis-full sm:px-3 py-2 text-left font-normal">{Labels.PLANNO}</label>
                  <span className="basis-full sm:px-3 py-2 text-left font-semibold">{data?.planNumber}</span>
              </div>
              <div className="flex justify-between border-b">
                  <label className="basis-full sm:px-3 py-2 text-left font-normal">{Labels.BUILDING}</label>
                  <span className="basis-full sm:px-3 py-2 text-left font-semibold">{data?.buildingName}</span>
              </div>
              <div className="flex justify-between border-b">
                  <label className="basis-full sm:px-3 py-2 text-left font-normal">{Labels.ADDRESS}</label>
                  <span className="basis-full sm:px-3 py-2 text-left font-semibold">{data?.streetNumberPrefix}{checkZeroValue(data?.streetNumber)}{data?.streetNumberSuffix} {data?.streetName} {data?.town} {data?.state} {data?.postcode}</span>
              </div>
              <div className="flex justify-between border-b">
                  <label className="basis-full sm:px-3 py-2 text-left font-normal">{Labels.MANAGED_BY}</label>
                  <span className="basis-full sm:px-3 py-2 text-left font-semibold">{data?.managerName}</span>
              </div>
              <div className="flex justify-between border-b">
                  <label className="basis-full sm:px-3 py-2 text-left font-normal">{Labels.FYE}</label>
                  <span className="basis-full sm:px-3 py-2 text-left font-semibold">{FormatDate(data?.financialYearEnd)}</span>
              </div>
          </div>
      </div>
  )
}