import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ConfigData } from "src/services/configService";

interface IAuthConfig {
  authConfig: ConfigData;
}

const initialState: IAuthConfig = {
  authConfig: {
    env: {
      name: "",
    },
    apiServer: {
      strataApiUrl: "",
      agencyId: "",
    },
    appInsights: {
      connectionString: "",
    },
    okta: {
      clientId: "",
      issuer: "",
      authorizationEndpoint: "",
    },
  },
};

const authSlice = createSlice({
  name: "authConfig",
  initialState,
  reducers: {
    setAuthConfig: (state, action: PayloadAction<ConfigData>) => {
      state.authConfig = action.payload;
    },
  },
});

export const { setAuthConfig } = authSlice.actions;
export default authSlice.reducer;
