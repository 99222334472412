import { useMemo } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { advanceSearch } from "src/constants/constant";
import { handleItemClick } from "src/utils/advanceSearchHelper";
import styles from "./advanceSearch.module.scss";
interface SearchResultTemplateProps {
  item: any;
  searchTerm: string;
  setShowSidebar?: any;
  setSearchTerm?: any;
  autoCompleteRef?: any;
}

const SearchResultTemplate: React.FC<SearchResultTemplateProps> = ({ item, searchTerm, setShowSidebar, setSearchTerm, autoCompleteRef }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const fields = useMemo(() => {
    try {
      return JSON.parse(item?.name) || {};
    } catch {
      return {};
    }
  }, [item?.name]);

  const matchingFields = useMemo(
    () => Object.keys(fields)?.filter((key) => fields[key]?.toLowerCase().includes(searchTerm?.toLowerCase())),
    [fields, searchTerm]
  );

  if (matchingFields?.length === 0) {
    return null;
  }

  const id =
    item.type === advanceSearch.corporation
      ? fields["PlanNumber"]
      : item.type === advanceSearch.owner || item.type === advanceSearch.committee || item.type === advanceSearch.tenant
      ? fields["Lot"]
      : item.type === advanceSearch.buildingManager
      ? fields["Plan"]
      : 0;

  const description =
    item.type === advanceSearch.corporation
      ? fields["Plan Number"] + (fields["Street Number"]?.trim() + fields["Street"]?.trim() ? ", " + fields["Street Number"] + " " + fields["Street"] : "")
      : item.type === advanceSearch.owner
      ? fields["Lot"] +
        (fields["Contact Name"]?.trim() ? ", " + fields["Contact Name"] : "")
      : item.type === advanceSearch.creditor
      ? (fields["Plan Number"]?.trim() ? fields["Plan Number"] : "") +
        (fields["Business"]?.trim() ? ", " + fields["Business"] : "") +
        (fields["Surname"]?.trim() ? ", " + fields["Surname"] : "")
      : item.type === advanceSearch.committee
      ? fields["Lot"] + (fields["Committee Name"]?.trim() ? ", " + fields["Committee Name"] : "") + (fields["Post"]?.trim() ? ", " + fields["Post"] : "")
      : item.type === advanceSearch.tenant
      ? fields["Lot"] + (fields["Contact Name"]?.trim() ? ", " + fields["Contact Name"] : "")
      : item.type === advanceSearch.agent
      ? fields["Lot"] +
        (fields["Business"]?.trim() ? ", " + fields["Business"] : "") +
        (fields["Surname"]?.trim() ? ", " + fields["Surname"] : "") +
        (fields["Franchise"]?.trim() ? ", " + fields["Franchise"] : "")
      : item.type === advanceSearch.buildingManager
      ? fields["Plan Number"] +
        (fields["Business"]?.trim() ? ", " + fields["Business"] : "") +
        (fields["Surname"]?.trim() ? ", " + fields["Surname"] : "") +
        (fields["Franchise"]?.trim() ? ", " + fields["Franchise"] : "")
      : "";

  const highlightMatches = (text: string, searchTerm: string) => {
    const regex = new RegExp(`(${searchTerm})`, "gi");
    if (searchTerm?.trim() !== "") {
      return text.split(regex).map((part, index) => (regex.test(part) ? <mark key={index}>{part}</mark> : part));
    } else {
      return text;
    }
  };

  return (
    <>
      {matchingFields?.map(
        (key, index) =>
          fields[key]?.trim?.() && (
            <div
              key={`${item.id}-${index}`}
              className={styles.itemContainer}
              onClick={() =>
                handleItemClick({
                  route: item.route,
                  type: item.type,
                  name: id,
                  navigate,
                  dispatch,
                  setShowSidebar,
                  setSearchTerm,
                  autoCompleteRef,
                })
              }
              role="option"
              aria-selected="true"
            >
              <span className={styles.itemValue}>
                <div key={index}>
                  <strong>{key}:</strong> {highlightMatches(fields[key], searchTerm)} {description ? `(${description})` : ""}
                </div>
              </span>
            </div>
          )
      )}
    </>
  );
};

export default SearchResultTemplate;
