import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { IBudgetDatesData, IBudgetDate } from "src/interfaces/budgetList";
import { RootState } from "src/store/rootReducer";
import { FormatDate } from "src/utils/utility";
import { setBudgetDates, setSelectedDate } from "src/store/slice/budget";
import { NA, NONE } from "src/constants/constant";

const BudgetDatesDropdown = ({ budgetDates }: { budgetDates: IBudgetDatesData }) => {
  const { selectedDate } = useSelector((state: RootState) => state?.budgetSlice);
  const dispatch = useDispatch();
  const [displayedDates, setDisplayedDates] = useState(budgetDates?.budgetDates);

  useEffect(() => {
    let displayedBudgetDates = budgetDates.budgetDates?.map((budget: IBudgetDate) => {
      return { ...budget, budgetDates: `${FormatDate(budget?.budgetDates)}${budget?.groupName === NONE ? "" : " " + budget?.groupName}` };
    });
    setDisplayedDates(displayedBudgetDates);
    dispatch(setBudgetDates(budgetDates));
    dispatch(setSelectedDate(selectedDate?.budgetId ? selectedDate : displayedBudgetDates?.[0]));
  }, []);

  const handleBudgetDateChange = (data: any) => {
    let newBudget = displayedDates?.find?.((budget: IBudgetDate) => budget?.budgetDates === data?.value) as IBudgetDate;
    dispatch(setSelectedDate(newBudget));
  };

  const getCorpGSTStatus = (method: string): string => {
    if (method === "U") {
      return "Not registered for GST";
    }
    if (method === "R") {
      return "Registered for GST";
    }
    return NA;
  };

  return (
    <div className="w-full flex justify-between items-center mt-4">
      <div>
        {displayedDates?.find?.((budget: IBudgetDate) => budget?.groupName !== NONE) && (
          <label className="px-4 py-1 text-xs font-semibold bg-gray-300 rounded-xl">Active Groups</label>
        )}
      </div>
      <div className="w-2/5 flex justify-between items-center">
        <div className="flex w-full items-center gap-1">
          <p className="flex-none text-xs font-semibold">Date Budget applies from</p>
          <SMDropDown
            options={displayedDates}
            optionLabel="budgetDates"
            optionValue="budgetDates"
            value={selectedDate?.budgetDates}
            onChange={handleBudgetDateChange}
          />
        </div>
        <p className="flex-none text-xs font-semibold mx-2">{getCorpGSTStatus(budgetDates?.gsTstatus)}</p>
      </div>
    </div>
  );
};

export default BudgetDatesDropdown;
