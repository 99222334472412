import { Toast } from "primereact/toast";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { SMInputTextArea } from "src/components/ui/inputControl/inputTextarea";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Modal } from "src/components/ui/modal/modal";
import ToastContainer from "src/components/ui/toast/toast";
import {
  cancelButtonText,
  editButtonText,
  httpCode,
  modalDiscardMessageText,
  saveButtonText,
  toastErrorTimeLimit,
  toastSuccessTimeLimit,
} from "src/constants/constant";
import { EndPoints } from "src/constants/endpoint";
import { Labels } from "src/constants/labels";
import { Messages } from "src/constants/messages";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import { IFinancialMisc } from "src/interfaces/financialMisc";
import { httpUpdate } from "src/services/api";
import styles from "../financial.module.scss";

export const MiscSummary: React.FC<{ corpId: any; miscDetails: IFinancialMisc }> = ({ corpId, miscDetails }) => {
  const [miscDetail, setMiscDetail] = useState(miscDetails);
  const [isEditableFinancialMisc, setIsEditableFinancialMisc] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);
  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { isDirty },
  } = useForm<IFinancialMisc>({
    defaultValues: {
      strataRenewalCommittee: miscDetail?.strataRenewalCommittee,
      strataRenewalCommitteeComment: miscDetail?.strataRenewalCommitteeComment,
      recordProposals: miscDetail?.recordProposals,
      proposals: miscDetail?.proposals,
    },
    mode: "onTouched",
  });
  useUnsavedChangesPrompt({ unsavedChanges: isDirty });
  const strataRenewalCommitteeCommentValue = watch("strataRenewalCommitteeComment");
  const proposalsCommentValue = watch("proposals");

  const miscellaneousButton = [
    {
      buttonName: isEditableFinancialMisc ? cancelButtonText : editButtonText,
      type: "normal",
      onClick: isEditableFinancialMisc ? onCancel : () => setIsEditableFinancialMisc(true),
    },
    {
      buttonName: isEditableFinancialMisc ? saveButtonText : "",
      type: isEditableFinancialMisc ? "save" : "",
      buttonType: "submit",
      formId: "financialMiscForm",
    },
  ];

  function onCancel() {
    if (isDirty) {
      setShowModal(true);
    } else {
      setIsEditableFinancialMisc(false);
    }
  }

  function onDiscard() {
    reset(miscDetail);
    setShowModal(false);
    setIsEditableFinancialMisc(false);
  }

  async function onSubmit(data: IFinancialMisc) {
    setIsLoading(true);
    const output = await httpUpdate(EndPoints.corporation + corpId + EndPoints.financial + "/miscellaneous-details", data);
    if (output?.status === httpCode.SUCCESS) {
      setMiscDetail(data);
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: toastSuccessTimeLimit,
      });
      reset({}, { keepDirty: false, keepValues: true });
      setIsEditableFinancialMisc(false);
    }
    if (output?.error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Error: ${output?.error}`,
        life: toastErrorTimeLimit,
      });
    }
    setIsLoading(false);
  }
  return (
    <>
      <CollapsibleCard id="Miscellaneous" title={Labels.MISCELLANEOUS} expand={true} buttons={miscellaneousButton}>
        <div className="pt-4">
          {isLoading && (
            <div className="spinnerContainer">
              <div className="w-20 h-20">
                <SpinnerHorizontal />
              </div>
            </div>
          )}
          <form id="financialMiscForm" onSubmit={handleSubmit(onSubmit)} noValidate>
            <div data-testid="miscSummaryTab" className="basis-full text-sm mt-4">
              <div className={styles.section}>
                <SMCheckbox name="strataRenewalCommittee" control={control} label={Labels.STRATA_RENEWAL_COMMITEE} disabled={!isEditableFinancialMisc} />
              </div>
              <div className={`${styles.section} !h-auto`}>
                <span className="mb-2 basis-full text-left" title={strataRenewalCommitteeCommentValue}>
                  <SMInputTextArea
                    name="strataRenewalCommitteeComment"
                    control={control}
                    disabled={!isEditableFinancialMisc}
                    placeholder={Labels.ADD_STRATA_RENEWAL_COMMENT}
                    maxlength={500}
                    autoResize={true}
                    autoComplete="off"
                  />
                </span>
              </div>
              <div className={styles.section}>
                <SMCheckbox name="recordProposals" control={control} label={Labels.RECORD_PROPOSALS_FOR_FUNDING_MATTERS} disabled={!isEditableFinancialMisc} />
              </div>
              <div className={`${styles.section} !h-auto`}>
                <span className="mb-2 basis-full text-left" title={proposalsCommentValue}>
                  <SMInputTextArea
                    name="proposals"
                    control={control}
                    disabled={!isEditableFinancialMisc}
                    placeholder={Labels.PROPOSALS}
                    autoResize={true}
                    maxlength={500}
                    autoComplete="off"
                  />
                </span>
              </div>
            </div>
          </form>
        </div>
      </CollapsibleCard>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Warning" onAction={() => onDiscard()}>
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
      <ToastContainer toastReference={toastRef} />
    </>
  );
};
