import { IBudgetDatesData } from "src/interfaces";
import Expense from "../expense/expense";
import AdminFundCard from "./adminFundCard";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";

const AdminFund = ({ budgetDates, fundType }: { budgetDates: IBudgetDatesData; fundType: number }) => {
  const { selectedDate } = useSelector((state: RootState) => state?.budgetSlice);
  const [balance, setBalance] = useState();
  let fundTypeId = fundType;
  return (
    <>
      <AdminFundCard balance={balance} fundType={fundTypeId} />
      <Expense key={selectedDate?.budgetId} budgetDates={budgetDates} selectedDate={selectedDate} setBalance={setBalance} fundType={fundTypeId} />
    </>
  );
};

export default AdminFund;
