import { useParams } from "react-router-dom";
import LotContributionsTable from "./lotContributionsTable";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";
import { EndPoints } from "src/constants/endpoint";
import { useHttpGet } from "src/services/api";
import { httpCode } from "src/constants/constant";
import { Errors } from "src/components/shared/error/error";
import { Messages } from "src/constants/messages";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";

const LotContributionsCard = () => {
  const { corpId } = useParams();
  const { selectedDate } = useSelector((state: RootState) => state?.budgetSlice);
  const { data, error, statusCode } = useHttpGet(`${EndPoints.corporation}${corpId}/${EndPoints.budget}lot-contribution`, {
    budgetId: selectedDate?.budgetId,
    groupId: selectedDate?.groupCodeId,
  });

  if (error || httpCode.INTERNAL_SERVER_ERROR === statusCode) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (httpCode.NOT_FOUND === statusCode || httpCode.NO_CONTENT === statusCode) {
    return <LotContributionsTable data={{ entitlementSet: "", lotContributions: [] }} />;
  }

  if (!data) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-20 h-20 mt-10">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }

  return <LotContributionsTable data={data} />;
};

export default LotContributionsCard;
