export const Labels = {
  PLAN_DETAILS: "Plan Details",
  PLANNO: "Plan no.",
  BUILDING: "Building",
  STREET_NUMBER: "Street Number",
  STREET: "Street",
  TOWN: "Town/Suburb",
  STATE: "State",
  POSTCODE: "Postcode",
  FINANCIAL_YEAR_END: "Financial Year End",
  INITIAL_PERIOD: "In Initial period",
  LAST_LEVY_NOTICE_PRINTED: "Last Levy Notice Printed",
  LAST_LEVY_NOTICE_EMAILED: "Last Levy Notice Emailed",
  MANAGER: "Manager",
  PLAN_MANAGED: "This plan is managed",
  WEB_ACCESS: "Approved for web access of report(s): Budget & Levy Schedule",
  ONLINE_VOTING_APPROVED: "Online Voting Approved",
  ONLINE_VOTING_REFERENCES: "View Online Voting References",
  CANCEL: "Cancel",
  EDIT: "Edit",
  LOTS: "Lots",
  REGISTERED: "Registered",
  TOTAL: "Total",
  RESIDENTIAL: "Residential",
  COMMERCIAL: "Commercial",
  PRIMARY: "Primary",
  UTILITY: "Utility",
  RETIREMENT: "Retirement",
  OTHER: "Other",
  SAVE: "Save",
  LOT_REFERENCE_NUMBER: "Lot Reference Number",
  LOT_PLAN_NUMBER: "Lot Plan Number",
  CHEQUE_DRAWER: "Cheque Drawer",
  BRANCH: "Branch",
  BANK: "Bank",
  BSB_NUMBER: "BSB Number",
  PRINTED_RECEIPT_MESSAGE: "Printed Receipt Message",
  SCREENING_RECEIPTING_MESSAGE: "Screening Receipting Message",
  REJECT_LEVY_RECEIPTS: "Reject levy receipts",
  BILLER_CODE: "Biller Code",
  CRN: "CRN",
  USE_OWNERS_ADDRESS: "Use Owner's address",
  USE_AGENTS_ADDRESS: "Use Agent's address",
  USE_TENANTS_ADDRESS: "Use Tenant's address",
  DIFFERENT_CONTACT_FOR_LEVIES: "Different contact for levies",
  LEVIES_DETAILS: "Levies Details",
  CONTACT_FOR_LEVIES: "Contact for Levies",
  MANAGEMENT_COMMENCED: "Management commenced",
  MANAGEMENT_END: "Management end",
  ASSOCIATION_TYPE: "Association Type",
  BUILD_DATE: "Built Date",
  PLAN_REGISTERED: "Plan registered",
  FIRST_AGM: "First AGM",
  NUMBER_OF_STOREYS_ABOVE_GROUND: "No. Storey's above ground",
  OCCUPATION_CERTIFICATE_DATE: "Occupation Cert. Issued",
  DEVELOPER: "Developer",
  BUILDER: "Builder",
  DEVELOPER_CONTRACT: "Dev Contract Works Ins.",
  OWNER_OCCUPIED_CHECKBOX: "Owner occupied",
  NOTICE_DELIVERY: "Notices delivery",
  PRINT_RADIOBUTTON_TEXT: "Print",
  EMAIL_RADIOBUTTON_TEXT: "Email",
  PRINT_TENANT_NOTICE_ISSUES: "P",
  EMAIL_TENANT_NOTICE_ISSUES: "E",
  BOTH_TENANT_NOTICE_ISSUES: "B",
  OWNER: "Owner",
  AGENT: "Agent",
  TENANT: "Tenant",
  TENANT_CONTACT: "Tenant Contact",
  BUSINESS_CONTACT: "BUSINESS CONTACT:",
  PRIVATE_RESIDENTIAL_CONTACT: "PRIVATE/RESIDENTIAL CONTACT:",
  CONTACT_PERSON: "Contact Person: ",
  SALUTATION: "Salutation: ",
  TELEPHONE_1: "Telephone 1: ",
  TELEPHONE_2: "Telephone 2: ",
  HOME_PHONE: "Home phone: ",
  WORK_PHONE: "Work phone: ",
  FAX: "Fax: ",
  EMAIL: "Email: ",
  LEVY_EMAIL_ADDRESS: "Levy Email Address: ",
  WEBSITE: "Website: ",
  MOBILE: "Mobile: ",
  NOTES: "Notes",
  ADD_NOTE: "Add note",
  PAST_AND_PRESENT_LEASE: "Past and Present Leases",
  NO_LEASE_AVAILABLE: "No lease available",
  DATE_OF_ENTRY: "DATE OF ENTRY",
  LESSEE: "LESSEE",
  NOTICE_ADDRESS: "NOTICE ADDRESS",
  TERM: "TERM",
  COMMENCED: "COMMENCED",
  TERMINATED: "TERMINATED",
  LEVIES_DELIVERY: "Levies Delivery",
  ADDITIONAL_EMAILS: "Additional Emails",
  LEVY_ISSUE_PRINT: "Levy Issue Print",
  LEVY_ISSUE_EMAIL: "Levy Issue Email",
  MACQUARIE_DIRECT_DEBIT: "Macquarie Direct Debit",
  CHECK_STATUS: "Check Status",
  SEND_DIRECT_DEBIT_INVITATION: "Send Direct Debit Invitation",
  GENERAL: "General",
  AGENT_TENANT_LEASES: "Agent/Tenant/Leases",
  LEVIES: "Levies",
  NOTICES: "Notices",
  MORTGAGE: "Mortgage",
  DEBT_RECOVERY: "Debt Recovery",
  LINKED_LOTS: "Linked Lots",
  NAME_ON_TITLE: "Name on Title",
  COMPANY_NOMINEE: "Company Nominee",
  OWNERS: "Owners",
  MAIN_CONTACT: "Main Contact",
  MEETING_DOCS: "Meeting Docs",
  CORRESPONDENCE: "Correspondence",
  CONTACT_DETAILS: "CONTACT DETAILS:",
  ADDITIONAL_CONTACT_DETAILS: "ADDITIONAL CONTACT DETAILS",
  LOT_NO: "Lot no",
  ADDRESS: "Address",
  DATE_OF_PURCHASE: "Date of Purchase",
  PAID_TO: "Paid to",
  ARREARS: "Arrears",
  INTEREST_DUE_TO_DATE: "Interest due to date",
  LAST_RECEIPT: "Last Receipt",
  STATUS_CERTIFICATE_ISSUED: "Status certificate issued",
  STATUS_CERTIFICATE_UPDATED: "Status certificate updated",
  UPDATE_CERTIFICATE: "Update Certificate",
  MAIN_CONTACT_FOR_OWNER: "Main Contact for Owner",
  OWNER_CORPORATION_DETAILS: "Owner Corporation Details",
  COMPANY: "Company",
  PRIVATE: "Private",
  NOTICES_MINUTES: "Notices & Minutes",
  RECEIVE_EXECUTIVE_COMMITTEE_NOTICE_MINUTES: "Receive Executive Committee notices/minutes",
  DIFFERENT_CONTACT_FOR_NOTICES: "Different contact for notices",
  CONTACT_FOR_NOTICES: "Contact for notices",
  EXCLUDE_FROM_NOTICES_AND_MINUTES: "Exclude from Notices and Minutes",
  PAST_AND_PRESENT_MORTGAGES: "Past and Present Mortgages",
  NO_MORTGAGE_AVAILABLE: "No mortgage available",
  NO_LINKED_LOTS_AVAILABLE: "No linked lots available",
  LINKED_LOTS_HEADER: "This owner is linked to the following lot(s)",
  MORTGAGEE: "MORTGAGEE",
  CAPACITY: "CAPACITY",
  PRIORITY: "PRIORITY",
  DISCHARGE_DATE: "DISCHARGE DATE",
  NO_DEBT_AVAILABLE: "No debt available",
  ACTION_DATE: "ACTION DATE",
  DEBT_RECOVERY_STAGE: "DEBT RECOVERY STAGE",
  DEBT_COLLECTOR: "DEBT COLLECTOR",
  POSTED_CHARGE: "POSTED CHARGE",
  PENDING_CHARGE: "PENDING CHARGE",
  WAIVED_CHARGE: "WAIVED CHARGE",
  DATE_CLEARED: "DATE CLEARED",
  AMOUNT_OWNING_IN_NOTICE: "AMOUNT OWNING IN NOTICE",
  INTEREST_INCLUDE: "INTEREST INCLUDED",
  INTEREST_CALCULATED_TO: "INTEREST CALCULATED TO",
  PAYMENT_PLAN: "PAYMENT PLAN",
  NOTIFY: "NOTIFY",
  EXCLUDE_FROM_DEBT_RECOVERY: "Exclude from debt recovery",
  PLAN_NUMBER: "PLAN NO.",
  LOT_NUMBER: "LOT NO.",
  STAGE_3: "Stage 3",
  STAGE_2: "Stage 2",
  STAGE_1: "Stage 1",
  COMMITTEE: "Committee",
  NO_COMMITTEE_AVAILABLE: "No committee available",
  DATE_OF_ENTRY_LOT: "Date of Entry",
  UNIT: "UNIT#",
  LOT: "LOT#",
  POSITION: "POSITION",
  NAME: "NAME",
  NOMINATED_BY: "NOMINATED BY",
  REPORTS: "REPORTS",
  METHOD: "METHOD",
  APPROVER: "APPROVER",
  ADDRESS_1: "ADDRESS",
  NUMBER_OF_MEMBERS: "Number of Members",
  LAST_MEETING: "Last Meeting",
  NUMBER_OF_APPROVERS_REQUIRED: "Number of approvers required",
  INVOICE_APPROVAL_REQUIRED_FOR_ALL_INVOICES: "Invoice approval required for all invoices",
  BANK_ACCOUNT: "BANK ACCOUNT",
  EXPIRY: "EXPIRY",
  RATE: "RATE",
  G_L_ACCOUNT: "G.L. ACCOUNT",
  BSB: "BSB",
  ACCOUNT_NUMBER: "ACCOUNT NUMBER",
  GROUP: "GROUP",
  NO_DATA_AVAILABLE: "No data available",
  ACCOUNT_NAME: "Account Name",
  MAIN_BANK_ACCOUNT: "Main Bank Account",
  USE_GENERAL_TRUST_ACCOUNT: "Use General Trust Account",
  ACCOUNT_NUMBER_: "Account Number",
  INVESTMENT_ACCOUNTS: "Investment Accounts",
  ADMIN: "ADMIN",
  CAP_WORKS: "CAP WORKS",
  TOTAL_: "TOTAL",
  CASH_AT_BANK: "Cash at Bank",
  O_S_INVOICES: "O/S Invoices",
  NET_CASH: "Net cash",
  RESERVE_FUNDS: "Reserve funds",
  AVAILABLE_CASH: "Available cash",
  TOTAL_INVESTMENTS: "Total Investments",
  RECEIVABLE_STD: "Receivable-Std.",
  RECEIVABLE_SPECIAL: "Receivable-Special",
  CONTACT_DETAILS_: "Contact Details",
  CONTACT_TYPE: "Contact Type",
  TITLE: "Title",
  SURNAME: "Surname",
  FIRST_NAME: "First Name",
  LAST_NAME: "Last Name",
  OTHER_NAME: "Other Name",
  RECEIVE_COMMUNICATION: "Receive Communication",
  BUILDING_NAME: "Building Name",
  UNIT_STREET_NUMBER: "Unit/Street Number",
  TOWN_SUBURB: "Town/Suburb",
  COUNTRY: "Country",
  PRIMARY_CONTACT_DETAILS: "Primary Contact Details",
  COMMITTEE_EMAIL_ADDRESS: "Committee Email Address",
  EMAIL_ADDRESS: "Email Address",
  BUSINESS: "Business",
  POST_OFFICE_ADDRESS: "Post Office Address",
  TYPE: "TYPE",
  VALUE: "VALUE",
  L: "L",
  M: "M",
  C: "C",
  PO_BOX: "PO box/bag",
  LOT_ADDRESS: "LOT ADDRESS",
  MAIN_CONTACT_NAME: "MAIN CONTACT NAME",
  UPDATE_ADDITIONAL_CONTACT: "Update Additional Contact",
  ADD_ADDITIONAL_CONTACT: "Add Additional Contact",
  EMAIL_ADDRESS_: "Email address",
  CONTACT_NAME: "Contact name",
  DATE: "Date",
  DESCRIPTION: "Description",
  ADMIN_DUE: "Admin Due",
  PAID: "Paid",
  CAPITAL_WORKS_DUE: "Capital Works Due",
  OTHER_DUE: "Other Due",
  OUTSTANDING: "Outstanding",
  UNPAID_DEBITS: "Unpaid Debits",
  LAST_AGM: "Last AGM",
  LAST_EGM: "Last EGM",
  TAX_YEAR_END: "Tax year end",
  INTERIM_REPORTING_PERIOD: "Interim reporting period",
  TFN: "TFN",
  ABN: "ABN",
  GST_METHOD: "GST Method",
  GST_FREQUENCY: "GST Frequency",
  GST_STATUS: "GST Status",
  GENERATE_REPORTS_IN_MRI_STRATA_CONNECT: "Generate Reports in MRI Strata Connect",
  NORMAL_LEVY_FREQUENCY: "Normal levy frequency",
  LEVY_DISCOUNT_RATE: "Levy discount rate (%)",
  LEVY_DISCOUNT_GRACE_DAYS: "Levy discount grace days",
  LEVY_INTEREST_RATE: "Levy interest rate (%)",
  ADJUST_LEVY_INTEREST_RATE: "Adjust Levy Interest Rate",
  INTEREST_FREE_PERIOD: "Interest free period",
  CHARGE_UNPAID_INTEREST: "Charge unpaid interest",
  CREDIT_INVOICE_HOLD_STATUS: "Credit invoice hold status",
  INCLUDE_SUBMETER_INVOICES_IN_QUORUM_CALCULATION: "Include sub-meter invoices in Quorum calculation",
  FINANCIAL_REPORTING: "Financial Reporting",
  LEVY_DETAILS: "Levy Details",
  BANK_BALANCE_SUMMARY: "Bank Balance Summary",
  MISCELLANEOUS: "Miscellaneous",
  STATUS_CERTIFICATE: "Status Certificate",
  ADDITIONAL_CONTACT_DETAILS_: "Additional Contact Details",
  CLOUD_ACCESS: "Cloud Access",
  ACTIVE: "Active",
  MRI_CLIENT_ID: "MRI Client ID",
  NO_USERS_FOUND: "No Users Found",
  AGENCYID: "AGENCY ID",
  AGENCY_NAME: "AGENCY NAME",
  REQUESTER: "REQUESTER",
  BRIDGE_ID: "BRIDGE ID",
  CLOUD: "DB ON CLOUD",
  CLIENT_KEY: "CLIENT KEY",
  LOCALPORT: "LOCALPORT",
  AZURE_ENDPOINT: "AZURE ENDPOINT",
  API_KEY: "API KEY",
  NO_AGENCIES_FOUND: "No Agencies Found",
  OWNER_CONTACT_TYPE: "O",
  AGENT_CONTACT_TYPE: "R",
  TENANT_CONTACT_TYPE: "T",
  CORPORATIONS: "Corporations",
  BUDGET: "Budget",
  STRATA_VOTE: "Strata Vote",
  STRATA_CONNECT: "Strata Connect",
  FAVORITES: "Favorites",
  LOGOUT: "Logout",
  AGENCY: "Agency",
  AGENCIES: "Agencies",
  USERS: "Users",
  ADD_EDIT_NOTE: "Add/Edit Note",
  IMPORTANT_CONTACTS: "Important Contacts",
  FINANCIAL: "Financial",
  INSURANCE: "Insurance",
  STRUCTURE: "Structure",
  TOTAL_LOTS: "Total Lots",
  NEXT_LEVY_DUE_DATE: "Next Levy Due Date",
  MANAGEMENT_FEES: "Management Fees",
  CODE: "Code",
  DESCRIPTION_OF_SERVICE: "Description of Service",
  CURRENT_RATE: "Current Rate",
  NEW_RATE: "New Rate",
  COST_UNIT: "Cost Unit",
  AUTO_BILL: "Auto Bill",
  SCHEDULED: "Scheduled",
  AUTO_INCREMENT: "Auto Increment",
  POLICY: "Policy",
  POLICIES: "Policies",
  POLICY_NUMBER: "POLICY NUMBER",
  BROKER: "BROKER",
  DATE_PAID: "DATE PAID",
  LAST_PREMIUM: "LAST PREMIUM",
  RENEWAL_DATE: "RENEWAL DATE",
  POLICY_START_DATE: "POLICY START DATE",
  VALUATION: "Valuation",
  COVERAGE_DETAILS: "Coverage Details for Selected Policy",
  COVERAGE: "COVERAGE",
  SUM_INSURED: "SUM INSURED",
  EXCESS: "EXCESS",
  BUILDING_MANAGER: "Building Manager",
  TELEPHONE: "telephone",
  CONTACT: "contact",
  TRADESMEN: "Tradesmen",
  STATUS: "Status",
  FREQUENCY: "frequency",
  STRATA_RENEWAL_COMMITEE: "Strata renewal committee",
  ADD_STRATA_RENEWAL_COMMENT: "Add Strata renewal comment",
  RECORD_PROPOSALS_FOR_FUNDING_MATTERS: "Record proposals for funding matters",
  PROPOSALS: "Proposals",
  LEVY_YEAR: "Levy year",
  EXCLUDE_LOTS_FROM_DEBT_RECOVERY: "Exclude all lots from debt recovery",
  DEBT_COLLECTOR_: "Debt collector",
  PAYMENT_PLAN_APPROVED: "Payment Plan approved",
  PRINT_ALL_ATTACHMENTS_FOR_STATUS_CERTIFICATE: "Print all attachements for Status Certificates",
  ENTITLEMENTS: "Entitlements",
  SHOW_ON_PORTALS: "Show On Portals",
  TO: "To",
  ADMINISTRATIVE_FUND: "Administrative Fund",
  CAPITAL_WORKS_FUND: "Capital Works Fund",
  ASSET_REPLACEMENT: "Asset Replacement",
  LOT_CONTRIBUTIONS: "Lot Contributions",
  SHOW_ACTUAL_NON_BUDGET_ACCOUNTS: "Show actual for non-budget accounts",
  CURRENT_PERIOD: "CURRENT PERIOD",
  LAST_PERIOD: "LAST PERIOD",
  NEXT_PERIOD: "NEXT PERIOD",
  SURPLUS_DEFICIT: "Surplus/Deficit",
  OPENING_BALANCE: "Opening balance",
  CLOSING_BALANCE: "Closing balance",
  PAYMENT_PLAN_CONDITIONS: "Payment Plan conditions",
  DAYS: "Days",
  UNIT_ENTITLEMENT_SETS: "Unit Entitlement Sets",
  ENTITLEMENT_SET: "Entitlement Set",
  BUDGETED: "Budgeted",
  ACTUAL: "Actual",
  EXPENSE: "Expense",
  REVENUE: "Revenue",
  ADD_CONTACT: "Add Contact",
  EDIT_CONTACT: "Edit Contact",
  UE: "U/E",
  ADMIN_CONTRIBUTION: "Admin Contribution",
  CAPITAL_WORKS: "Capital Works",
  REPLACEMENT_COST: "Replacement Cost",
  EXPECTED_LIFE: "Expected Life",
  LAST_REPLACED: "Last Replaced",
  LAST_YEARS_BUDGET: "Last Years Budget",
  BUDGETED_AMOUNT: "Budgeted Amount",
  ASSET_REPLACEMENT_CONTINGENCY: "Asset replacement contingency",
  FYE: "FYE",
  MANAGED_BY: "Managed by",
  POLICY_DATE: "Policy Date",
  TOTALS: "Totals",
  EDIT_COMMITTEE_DETAILS: "Edit Committee Details",
  ADD_COMMITTEE_MEMBER: "Add Committee Member",
  FULL_PORTAL_ACCESS: "Full Portal Access",
  SELECT_CONTACT: "Select Contact",
  EDIT_INSURANCE_POLICY: "Edit Insurance Policy",
  ESTIMATE: "Estimate",
  DUE: "Due",
  RECEIVED: "Received",
  EDIT_UNIT_ENTITLEMENT_SET_DETAILS: "Edit Unit Entitlement Sets Details",
  ENTITLEMENT_TYPE: "Entitlement Type",
  RECORD_COMMISSION: "Record Commission",
  EDIT_COVERAGE_DETAILS: "Edit Coverage Details",
  KEYWORD_SEARCH: "Keyword Search",
  MANAGED: "Managed",
  NOT_MANAGED: "Not Managed",
  BOTH: "Both",
  CATEGORIES: "Categories",
  RESULTS: "Results:",
  YES: "Y",
  NO: "N",
  N: "N",
  B: "B",
  QUICK_REPORTS: "Quick Reports",
  REGISTERS: "Registers",
  R_M: "R&M",
  RECEIPTS: "Receipts",
  PAYMENTS: "Payments",
  CONTACTS: "Contacts",
  CREDITORS: "Creditors",
  MEETINGS: "Meetings",
  CREDITOR_INVOICES: "Cr. Invoices",
  MANAGED_PLANS: "Managed Plans",
  UNMANAGED_PLANS: "Unmanaged Plans",
  ALL_PLANS: "All Plans",
  OUTPUT_OPTIONS: "Output Options",
  SHOW_OWNERS_CORPORATION: "Show Owners Corporation",
  SHOW_TRADESMEN_PREFERENCE: "Show Tradesmen Preference",
  SHOW_MANAGEMENT_FEES: "Show Management Fees",
  SHOW_INTERIM_REPORT_SETTINGS: "Show Interim Report Settings",
  SHOW_BANK_ACCOUNTS: "Show Bank Accounts",
  CALCULATE_MANDATORY_AUDITS: "Calculate Mandatory Audits NSW/ACT",
  STRATA_HUB: "Strata Hub",
  LAST_LEVY_NOTICE: "Last Levy Notice",
  YEAR_END_DATE: "Year End Date",
  RELEVANT_DATES: "Relevant Dates",
  BODY_CORPORATE_NAME: "Body Corporate Name",
  lOwnersCorpId: "lOwnersCorpId",
  ADDRESS_FOR_SORTING: "Address For Sorting",
  SUBURB_FOR_SORTING: "Suburb For Sorting",
  PHONE: "Phone",
  WORK_FREQUENCY: "Work Frequency",
  WORK_DAYS: "Work Days",
  FEE_CODE_SERVICE: "Fee code - service",
  REPORT_NAME: "Report Name",
  JURISDICTION: "Jurisdiction",
  DATE_BUILT: "Date Built",
  BANK_ACCOUNT_ID: "BankAccountId",
  ALLOCATED_ACCOUNT: "Allocated Account",
  BPAY_BILLER_CODE: "BPAY Biller Code",
  PAYWAY_BILLER_CODE: "PayWay Biller Code",
  LAST_CHEQUE_NO: "Last Cheque No.",
  LAST_DIRECT_ENTRY_PAYMENT_NO: "Last Direct Entry Payment No.",
  LAST_BPAY_PAYMENT_NO: "Last BPAY Payment No.",
  LAST_OTHER_PAYMENT_NO: "Last Other Payment No.",
  INTEREST_RATE: "Interest Rate",
  EXPIRY_DATE: "Expiry Date",
  TRADESMEN_PREFERENCE_STATUS: "Tradesmen Preference Status",
  CREDITOR: "Creditor",
  INTERIM_REPORT: "Interim Report",
  ALL_ACCOUNTS: "All Accounts",
  WORKING_ACCOUNTS: "Working Accounts",
  BANK_ACCOUNTS: "Bank Accounts",
  INACTIVE: "Inactive",
} as const;
