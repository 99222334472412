"use client";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import Committee from "./committee";
import { setBreadCrumbs, setIcons } from "src/store/slice/breadCrumbSlice";
import { useParams } from "react-router-dom";
import usePlanNumber from "src/hooks/usePlanNumber";

const CommitteeTab = () => {
  const { corpId } = useParams();
  const plaNumber = usePlanNumber(corpId ?? "")
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { title: "Corporations", url: "/corporations" },
        { title: `${plaNumber}`, url: `/corporations/${corpId}` },
        { title: "Committee", url: `/corporations/${corpId}/committee` },
      ])
    );
    dispatch(
      setIcons([
        { 
          name: "Important Contacts", 
          route: `${corpId}/contact`, 
          isSelected: false 
        },
        { name: "Lots", route: `${corpId}/lots`, isSelected: false },
        { name: "Committee", route: `${corpId}/committee`, isSelected: true },
        { name: "Entitlements", route: `${corpId}/unitentitlements`, isSelected: false },
      ])
    );
  }, [plaNumber]);

  return <Committee corpId={corpId ?? ""} />;
};

export default CommitteeTab;
