
import { DeleteCircle, MinusCircle, CheckCircle } from "../../../assets/icon";

export const IconYesNoNull: React.FC<{type: boolean | string | null}> = ({ type }) => {
    let icon: JSX.Element;

    switch (type) {
        case true:
        case 'Y':
            icon = <div className='flex items-center gap-2'><span className='pt-0.5'><CheckCircle /></span> <span className='font-semibold'>Yes</span></div> 
            break
        case false:
        case 'N':
            icon = <div className='flex items-center gap-2'><span className='pt-0.5'><DeleteCircle /></span> <span className='font-semibold'>No</span></div> 
            break
        default:
            icon = <div className='flex items-center gap-2'><span className='pt-0.5'><MinusCircle /></span> <span className='font-semibold'>-</span></div> 
    }

    return (
        <>
            {icon}
        </>
    )
}