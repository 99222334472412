"use client";
import { useHttpGet } from "../../../../../services/api";
import { EndPoints } from "../../../../../constants/endpoint";
import { SpinnerHorizontal } from "../../../../../components/ui/loading/spinnerHorizontal";
import { Errors } from "../../../../../components/shared/error/error";
import { errorMessage } from "../../../../../constants/constant";
import General from "./general";

export default function GeneralTab({ corpId, lotId, setActiveTabIndex }: { corpId: string; lotId: string; setActiveTabIndex: any; }) {
  const { data: lotDetails, error: lotDetailsError } = useHttpGet(EndPoints.corporation + corpId + "/lots/" + lotId + "/owners");

  if (!lotDetails) {
    return (
      <div className="flex w-full min-h-full items-center justify-center">
        <div className="w-20 h-20">
          <SpinnerHorizontal />
        </div>
      </div>
    );
  }

  if (lotDetailsError) {
    return <Errors error={errorMessage} alignment="left" />;
  }

  return <General setActiveTabIndex={setActiveTabIndex} lotDetails={lotDetails} corpId={corpId} lotId={lotId} />;
}
