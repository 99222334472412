import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setBreadCrumbs, setIcons } from "src/store/slice/breadCrumbSlice";
import { useParams } from "react-router-dom";
import usePlanNumber from "src/hooks/usePlanNumber";
import BudgetTabs from "./tabs";

export default function Budget() {
  const { corpId } = useParams();
  const dispatch = useDispatch();
  const planNumber = usePlanNumber(corpId ?? "");

  useEffect(() => {
    dispatch(
      setBreadCrumbs([
        { title: "Corporations", url: "/corporations" },
        { title: `${planNumber}`, url: `/corporations/${corpId}` },
        { title: "Budget", url: `/corporations/${corpId}/budget` },
      ])
    );

    dispatch(
      setIcons([
        { name: "Financial", route: `${corpId}/financial`, isSelected: false },
        { name: "Bank Account", route: ``, isSelected: false },
        { name: "Budget", route: `${corpId}/budget`, isSelected: true },
        { name: "Management Fees", route: `${corpId}/management-fees`, isSelected: false },
      ])
    );
  }, [planNumber]);

  return <BudgetTabs />;
}
