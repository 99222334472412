import styles from "./corpList.module.scss";
import { useEffect, useMemo, useState } from "react";
import { useHttpGet } from "../../../services/api";
import { ICorpList } from "../../../interfaces/corpList";
import { IconYesNoNull } from "../../../components/ui/iconYesNoNull";
import { SpinnerHorizontal } from "../../../components/ui/loading/spinnerHorizontal";
import { setBreadCrumbs, setIcons } from "../../../store/slice/breadCrumbSlice";
import { useDispatch, useSelector } from "react-redux";
import { setPlanNumber } from "src/store/slice/planDetailSlice";
import { SMDataTableContainer } from "src/components/ui/table/tableContainer";
import { EndPoints } from "src/constants/endpoint";
import { Link } from "react-router-dom";
import { checkZeroValue } from "src/utils";
import { RootState } from "src/store/rootReducer";

export default function CorpList() {
  const { loggedInUserInfo } = useSelector((state: RootState) => state?.userSlice);
  const { data, error } = useHttpGet(EndPoints.corporation + "list-summary") as {
    data: ICorpList[];
    error: any;
  };
  const dispatch = useDispatch();
  const [dataWithAddress, setDataWithAddress] = useState<ICorpList[]>([]);
  const columns = useMemo(() => {
    return [
      {
        field: "sPlanNumber",
        header: "PLAN NO.",
        body: (data: any) => {
          return (
            <Link to={`/corporations/${data?.lOwnersCorporationID}`} className="font-bold underline" data-testid="planLink">
              <span onClick={() => dispatch(setPlanNumber(data?.sPlanNumber))}>{data?.sPlanNumber}</span>
            </Link>
          );
        },
        sortable: true,
      },
      {
        field: "sBodyCorporateName",
        header: "BODY CORPORATE NAME",
        sortable: true,
      },
      {
        field: "lNumberOfLots",
        header: "LOTS",
        sortable: true,
      },
      {
        field: "address",
        header: "ADDRESS",
        sortable: true,
      },
      {
        field: "sBuildingName",
        header: "BUILDING",
        sortable: true,
      },
      {
        field: "sManager",
        header: "STRATA MANAGER",
        sortable: true,
      },
      {
        field: "bManaged",
        header: "MANAGED",
        body: (data: any) => {
          return <IconYesNoNull type={data?.bManaged} />;
        },
        sortable: true,
      },
    ];
  }, []);

  useEffect(() => {
    dispatch(setBreadCrumbs([{ title: "Corporations", url: "/corporations" }]));
    dispatch(setIcons([{ icon: "", route: "/" }]));

    if (data) {
      const dataWithAddress = data.map((item: ICorpList) => ({
        ...item,
        address: `${item?.sStreetNumberPrefix || ""}${checkZeroValue(Number(item?.lStreetNumber), "")}${item?.sStreetNumberSuffix || ""} ${
          item?.sStreet || ""
        } ${item?.sTown || ""} ${item?.sState || ""} ${item?.sPostcode || ""}`,
        sPlanNumber: item?.sPlanNumber?.trim?.(),
      }));
      setDataWithAddress(dataWithAddress);
    }
  }, [data]);

  if (error) {
    return (
      <>
        <div className="text-red-500">Something went wrong!</div>
      </>
    );
  }

  if (!data) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="w-auto h-full p-2 shadow-lg m-5 rounded-lg bg-white">
      <div className={styles.page_container}>
        {data?.length > 0 && (
          <SMDataTableContainer
            id="corporationTable"
            stateKey={`${loggedInUserInfo?.email}/corporationTable`}
            tableData={dataWithAddress}
            tableColumns={columns}
            showEditIcon={false}
            enableGlobalSearch
            enableColumnConfig
            sortField="sPlanNumber"
            globalFilterFields={[
              "sPlanNumber",
              "sBodyCorporateName",
              "sBuildingName",
              "sManager",
              "address",
              "sStreetNumberPrefix",
              "lStreetNumber",
              "sStreetNumberSuffix",
              "sStreet",
              "sTown",
              "sState",
              "sPostcode",
            ]}
            resizableColumns
          />
        )}
      </div>
    </div>
  );
}
