import { useHttpGet } from "src/services/api";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { useParams } from "react-router-dom";
import { EndPoints } from "src/constants/endpoint";
import { Messages } from "src/constants/messages";
import { Errors } from "src/components/shared/error/error";
import { httpCode } from "src/constants/constant";
import CoverageTable from "./coverageTable/coverageTable";

const Coverages = ({ insuranceId }: { insuranceId: number }) => {
  const { corpId } = useParams();
  const { data, error, statusCode } = useHttpGet(`${EndPoints.corporation}${corpId}/${EndPoints.insurance}/${insuranceId}/policy-coverage`);

  if (error) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (statusCode === httpCode.NOT_FOUND || statusCode === httpCode.NO_CONTENT) {
    return <CoverageTable data={[]} />;
  }

  if (!data) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-10 h-10">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }

  return <CoverageTable data={data} />;
};

export default Coverages;
