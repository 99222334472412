import { useSelector } from "react-redux";
import AssetReplacementCard from "./assetReplacementCard/assetReplacementCard";
import { RootState } from "src/store/rootReducer";

const AssetReplacement = () => {
  const { selectedDate } = useSelector((state: RootState) => state?.budgetSlice);
  return <AssetReplacementCard key={selectedDate?.budgetId} />;
};

export default AssetReplacement;
