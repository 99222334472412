import { useEffect, useState } from "react";
import LoadingPage from "./components/shared/loading/loadingPage";
import Dashboard from "./components/shared/dashboard/dashboard";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import CorpDetails from "./pages/corporation/corporationDetail/corpDetails";
import PlanDetail from "./pages/plan/plan";
import CorpList from "./pages/corporation/corporationList/corpList";
import Structure from "./pages/structure/structure";
import Finance from "./pages/financial/financial";
import Contacts from "./pages/contact/contact";
import Insurance from "./pages/insurance/insurance";
import BankAccount from "./pages/bankAccount/bankAccount";
import Entitlements from "./pages/unitEntitlement/entitlement";
import CommitteeTab from "./pages/committee";
import Budget from "./pages/budget";
import ManagementFees from "./pages/managementFee/managementFee";
import { AuthService } from "./services/authService";
import RootLayout from "./rootLayout";
import ProtectedRoute from "./protectedRoute";
import HeightContainer from "./components/shared/heightContainer/heightContainer";
import { axiosInstance } from "./services/interceptors";
import { useDispatch } from "react-redux";
import { setLoggedInUserInfo, setUserDetails, setUserInfo } from "./store/slice/userSlice";
import Users from "./pages/users";
import Agencies from "./pages/agencies";
import { useSelector } from "react-redux";
import { RootState } from "./store/rootReducer";
import UnauthorizedAccess from "./components/shared/unauthorizedAccess/unauthorizedAccess";
import PageNotFound from "./components/shared/pageNotFound/pageNotFound";
import Lots from "./pages/lot";
import { Routes as pageRoutes } from "./constants/pageRoutes";
import QuickReports from "./pages/quickReports";

const privateRoutes = [
  {
    path: "corporations",
    element: (
      <HeightContainer>
        <CorpList />
      </HeightContainer>
    ),
    index: true,
    childRoutes: [
      {
        path: ":corpId",
        element: <CorpDetails />,
        index: true,
        childRoutes: [
          {
            path: "plan",
            element: (
              <HeightContainer>
                <PlanDetail />
              </HeightContainer>
            ),
          },
          {
            path: "lots",
            element: (
              <HeightContainer>
                <Lots />
              </HeightContainer>
            ),
          },
          {
            path: "structure",
            element: (
              <HeightContainer>
                <Structure />
              </HeightContainer>
            ),
          },
          {
            path: "financial",
            element: (
              <HeightContainer>
                <Finance />
              </HeightContainer>
            ),
          },
          {
            path: "insurance",
            element: (
              <HeightContainer>
                <Insurance />
              </HeightContainer>
            ),
          },
          {
            path: "contact",
            element: (
              <HeightContainer>
                <Contacts />
              </HeightContainer>
            ),
          },
          {
            path: "bank-account",
            element: (
              <HeightContainer>
                <BankAccount />
              </HeightContainer>
            ),
          },
          {
            path: "unitentitlements",
            element: (
              <HeightContainer>
                <Entitlements />
              </HeightContainer>
            ),
          },
          {
            path: "committee",
            element: (
              <HeightContainer>
                <CommitteeTab />
              </HeightContainer>
            ),
          },
          {
            path: "budget",
            element: (
              <HeightContainer>
                <Budget />
              </HeightContainer>
            ),
          },
          {
            path: "management-fees",
            element: (
              <HeightContainer>
                <ManagementFees />
              </HeightContainer>
            ),
          },
        ],
      },
    ],
  },
  {
    path: "users",
    element: (
      <HeightContainer>
        <Users />
      </HeightContainer>
    ),
    index: true,
  },
  {
    path: "agencies",
    element: (
      <HeightContainer>
        <Agencies />
      </HeightContainer>
    ),
    index: true,
  },
  {
    path: pageRoutes.quickReports,
    element: (
      <HeightContainer>
        <QuickReports />
      </HeightContainer>
    ),
    index: true,
  },
];

const Main = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const auth = new AuthService();
  const [params] = useSearchParams();
  let state = Boolean(params.get("state"));
  let isAuthenticated = Boolean(localStorage.getItem("access_token"));
  let navigateRoute = window?.location?.pathname;
  const { user, userInfo } = useSelector((state: RootState) => state?.userSlice);

  const getUserDetailsByEmail = async (email: string) => {
    try {
      let res = await axiosInstance.get(`login/agencies/?email=${email}`);
      if (res?.data?.isActive && res?.data?.isAgencyActive) {
        dispatch(setUserDetails(res?.data));
        let userInfo = await axiosInstance.get(`login/user-info/${res?.data?.userId}`);
        if (userInfo?.data?.isActive) {
          dispatch(setUserInfo(userInfo?.data));
          navigate(navigateRoute);
        } else {
          unauthorizedAccess();
        }
      } else {
        unauthorizedAccess();
      }
    } catch (err) {
      if (err) {
      }
    }
  };

  const unauthorizedAccess = () => {
    dispatch(setUserDetails({}));
    dispatch(setUserInfo({}));
    navigate("unauthorized-access");
  };

  const setInfo = (res: any) => {
    localStorage.setItem("access_token", res?.access_token.replace(/"/g, ""));
    localStorage.setItem("id_token", res?.id_token?.replace(/"/g, "") ?? "");
    dispatch(setLoggedInUserInfo(res?.profile));
    getUserDetailsByEmail(res?.profile?.email ?? "");
    navigate(navigateRoute);
  };

  useEffect(() => {
    auth
      ?.getUser?.()
      .then((res) => {
        if (res) {
          setInfo(res);
        } else {
          if (state) {
            auth
              ?.signinRedirectCallback?.()
              .then((res) => {
                if (res) {
                  setInfo(res);
                }
              })
              .catch((err) => {
                unauthorizedAccess();
              });
          } else {
            auth?.signinRedirect?.();
          }
        }
      })
      .catch((err) => {});
  }, []);

  if (!user || !userInfo) {
    return <LoadingPage />;
  }

  return (
    <Routes>
      <Route element={<ProtectedRoute />}>
        <Route path="/" element={<RootLayout />}>
          <Route
            index
            element={
              <HeightContainer>
                <Dashboard />
              </HeightContainer>
            }
          />
          {privateRoutes?.map?.((privateRoute, index) => (
            <Route key={index} path={privateRoute.path}>
              {privateRoute?.index && <Route index element={privateRoute.element} />}
              {privateRoute?.childRoutes?.map((childRoute, index) => (
                <Route key={index} path={childRoute.path}>
                  {childRoute?.index && <Route index element={childRoute.element} />}
                  {childRoute?.childRoutes?.map?.((childRoute, index) => (
                    <Route key={index} path={childRoute.path} element={childRoute.element} />
                  ))}
                </Route>
              ))}
            </Route>
          ))}
        </Route>
      </Route>
      <Route path="unauthorized-access" element={<UnauthorizedAccess />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default Main;
