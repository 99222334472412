'use client'

import styles from './dropdownOptions.module.scss';
import { ChevronRight } from "../../../assets/icon";
import { useState , useRef, useEffect } from 'react';
import { UseFormRegister, FieldValues, UseFormSetValue} from 'react-hook-form';

interface IDropdownProps {
    defaultLabel?: string;
    selected?: IDropdownOption;
    selectedId?: number;
    id: string | number;
    styleClass? : string; 
    options: IDropdownOption[];
    register?: UseFormRegister<FieldValues>;
    setValue?: UseFormSetValue<FieldValues>
}

interface IDropdownOption {
    id: number | string;
    value: any;
    label: string;
    htmlLabel?: JSX.Element
  }
  

export const DropdownOptions : React.FC<IDropdownProps> = (
        { defaultLabel , id, options, selected, selectedId, setValue, styleClass, register = (e) => {} }
) => {

    const elementId : string = id.toString();

    defaultLabel = defaultLabel || 'Please select';

    const itemStyle = styleClass || 'option_item';

    const defaultOption = selected ? 
      options.find((option) => option.id === selected.id) : 
      selectedId && options.find((option) => option.id === selectedId);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(defaultOption || {} as IDropdownOption);
    const [widthSize, setWidthSize] = useState(0);
    const dropdownRef = useRef<HTMLDivElement>(null);

    function handleClickOutside(event: MouseEvent) {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target as Node)
        ) {
          setIsOpen(false);
        }
      }
    
    useEffect(() => {
        if (dropdownRef.current) {
          setWidthSize(dropdownRef.current.offsetWidth)
        }
        window.addEventListener('click', handleClickOutside);
        window.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
                setIsOpen(false);
            }
        });
                
        return () => {
            window.removeEventListener('click', handleClickOutside);
            window.removeEventListener('keydown', (event) => {
                if (event.key === 'Escape') {
                    setIsOpen(false);
                }
            });
        };
    }, []);
  
    function toggleDropdown() {
      setIsOpen(!isOpen);
    }
  
    function handleOptionClick(option: IDropdownOption) {
      setSelectedOption(option);
      if (setValue) {
        setValue(elementId, option.value);
      }
      toggleDropdown();
    }

    return (
        <div className='relative' ref={dropdownRef}>
          <button 
              type='button' 
              className={`${styles.btn_wrapper} ${isOpen ? styles.open : styles.close}`}
              onClick={toggleDropdown}>
              <span className={styles.btn_label}>{selectedOption.label || defaultLabel}</span>
              <span className='justify-end'><ChevronRight width={12} height={12} rotate={isOpen ? 270 :90} /></span>
              
          </button>
          { isOpen && 
          <ul className={styles.option_wrapper} style={{minWidth:widthSize}}>
          {
            options.length === 0 &&
              <li className={`${styles[itemStyle]}`}>
                No options available
              </li>
          }
          {options.map((option) => (
              <li className={`${styles[itemStyle]}`}  key={option.id} onClick={() => handleOptionClick(option)}>
              {option.htmlLabel || option.label }
              </li>
          ))}
          </ul>
          }
          <input
              type="hidden"
              data-testid={elementId}
              {...register(elementId)}
          />
        </div>
    );
  }


  

