import React from "react";

interface ICard{
    id?: string,
    children: React.ReactNode,
    bgColor?: string
}

export const Card : React.FC<ICard> = ({id, children, bgColor = "bg-white"})=>{
    return (
      <div id={id} className={`flex-auto basis-full px-8 py-4 mt-2 mr-2 ${bgColor} rounded-lg shadow-md`}>
        {children}
      </div>
    );   
};


