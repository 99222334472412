import { EndPoints } from "src/constants/endpoint";
import { CorpNotes } from "src/pages/corporation/corpNotes/corpNotes";

const Notes = ({ corpId, lotId, isNotesEditable, notes, setNotes }: { corpId: string; lotId: string; isNotesEditable: boolean; notes: any; setNotes: any }) => {
  return (
    <div className="flex flex-col w-full">
      <CorpNotes
        isEditable={isNotesEditable}
        corpId={corpId}
        data={notes}
        updateUrl={EndPoints.corporation + corpId + "/lots/" + lotId + "/tenant-notes"}
        bodyKey="tenantNotes"
        isConvertToRTF={false}
        setNotes={(data: string) => {
          setNotes({ note: data });
        }}
      />
    </div>
  );
};

export default Notes;
