import React, { useState } from "react";
import styles from "./planDetails.module.scss";
import { useHttpGet } from "src/services/api";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { Messages } from "src/constants/messages";
import { EndPoints } from "src/constants/endpoint";
import { ICorpsNotes } from "src/interfaces/corpNotes";
import { CorpNotes } from "../../corporation/corpNotes/corpNotes";
import Plan from "./plan";
import { useSelector } from "react-redux";
import { RootState } from "src/store/rootReducer";
import { LotSummary } from "../lotSummaryDetail/lotSummary";
import { Labels } from "src/constants/labels";

export const PlanDetails: React.FC<{ corpId: any }> = ({ corpId }) => {
  const { user } = useSelector((state: RootState) => state?.userSlice);
  const [isNotesEditable, setIsNotesEditable] = useState(false);
  const { data: managerList, error: userListError } = useHttpGet(EndPoints.corporation + "manager-list");
  const { data, error } = useHttpGet(EndPoints.corporation + corpId);
  const { data: notesData, error: notesError } = useHttpGet(EndPoints.corporation + `${corpId}/notes`) as { data: ICorpsNotes; error: any };
  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (error) {
    return (
      <>
        <div className="text-red-500 m-4">{Messages.SOMETHING_WENT_WRONG}</div>
      </>
    );
  }

  if (!data) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-10 h-10">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }

  return (
    <div className={`px-9 flex flex-wrap`}>
      {isLoading && (
        <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-modal overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
          <div className="w-20 h-20">
            <SpinnerHorizontal />
          </div>
        </div>
      )}
      <div data-testid="plan-details" className="p-1 basis-full lg:basis-1/2">
        {data && <Plan data={data} userList={managerList} corpId={corpId} setIsLoading={setIsLoading} />}
      </div>
      <div className="p-1 basis-full lg:basis-1/2">
        <div data-testid="planDetailCard2" className={`flex-auto h-auto basis-full text-sm ${styles.card}`}>
          <div className="w-full flex justify-between">
            <label className="section-header">Notes</label>
            <div className="flex order-last place-self-center">
              <button
                data-testid={isNotesEditable ? "CloseNote" : "EditNote"}
                className={`order-last edit-btn`}
                onClick={isNotesEditable ? () => setIsNotesEditable(false) : () => setIsNotesEditable(true)}
              >
                {isNotesEditable ? "Close" : Labels.ADD_EDIT_NOTE}
              </button>
            </div>
          </div>
          <div className="mt-5 mb-10">
            <CorpNotes isEditable={isNotesEditable} corpId={corpId} data={notesData} error={notesError} />
          </div>
        </div>
        <div data-testid="lotDetail" className={`flex-auto h-auto basis-full text-sm ${styles.card}`}>
          <LotSummary corpId={corpId} setIsLoading={setIsLoading} />
        </div>
      </div>
    </div>
  );
};
