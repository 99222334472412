import { Errors } from "src/components/shared/error/error";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { httpCode } from "src/constants/constant";
import { Messages } from "src/constants/messages";
import BudgetDatesDropdown from "./budgetDatesDropdown";

const BudgetDates = ({ data, error, statusCode }: { data: any; error: any; statusCode: any }) => {
  if (error || httpCode.INTERNAL_SERVER_ERROR === statusCode) {
    return <Errors error={Messages.SOMETHING_WENT_WRONG} alignment="left" />;
  }

  if (!data) {
    return (
      <>
        <div className="flex w-full min-h-full items-center justify-center">
          <div className="w-20 h-20 mt-10">
            <SpinnerHorizontal />
          </div>
        </div>
      </>
    );
  }
  return <BudgetDatesDropdown budgetDates={data} />;
};

export default BudgetDates;
