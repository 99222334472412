import { InputText } from "primereact/inputtext";
import { KeyFilterType } from "primereact/keyfilter";
import { classNames } from "primereact/utils";
import React from "react";
import { Controller } from "react-hook-form";

interface IModal {
  name?: string;
  type?: string;
  value?: any;
  disabled?: boolean;
  control?: any;
  rules?: object;
  maxlength?: number;
  keyfilter?: KeyFilterType;
  autoComplete?: string | undefined;
  placeholder?: string;
  className?: string;
  onChange?: any;
}

export const SMInputText: React.FC<IModal> = ({
  name,
  value,
  type = "text",
  disabled = false,
  control,
  rules,
  maxlength = 80,
  keyfilter,
  autoComplete,
  placeholder,
  className,
  onChange,
}) => {
  return (
    <Controller
      name={name ?? ""}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <InputText
          id={field.name}
          ref={field.ref}
          onBlur={field.onBlur}
          type={type}
          value={field?.value ? field?.value : value ? value : ""}
          placeholder={placeholder}
          onChange={(e) => {
            const inputValue = e.target.value;
            const isWhitespace = /^\s*$/.test(inputValue);

            if (!isWhitespace || inputValue === "") {
              field.onChange(inputValue);
              if (onChange) {
                onChange(inputValue);
              }
            }
          }}
          className={classNames({ "p-invalid": fieldState.error })}
          pt={{
            root: { className: `w-full !rounded !basis-full !font-semibold  !mt-0 h-10 !text-sm !outline-none ${className ?? ""}` },
          }}
          disabled={disabled}
          maxLength={maxlength}
          keyfilter={keyfilter}
          autoComplete={autoComplete}
        />
      )}
    />
  );
};
