import React, { useRef, useState } from "react";
import { Labels } from "src/constants/labels";
import { useForm } from "react-hook-form";
import { Messages } from "src/constants/messages";
import { EndPoints } from "src/constants/endpoint";
import { httpUpdate } from "src/services/api";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import {
  cancelButtonText,
  comingSoon,
  editButtonText,
  httpCode,
  interestFreePeriod,
  levyFrequency,
  modalDiscardMessageText,
  saveButtonText,
  toastErrorTimeLimit,
  toastSuccessTimeLimit,
} from "src/constants/constant";
import { findValue, FormatDate, getIntegerList, toDate, validateDecimal } from "src/utils";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";
import { Toast } from "primereact/toast";
import ToastContainer from "src/components/ui/toast/toast";
import styles from "../financial.module.scss";
import { SMDatePicker } from "src/components/ui/datePicker/datePicker";
import { Errors } from "src/components/shared/error/error";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { CollapsibleCard } from "src/components/ui/collapsibleCard/collapsibleCard";
import { Modal } from "src/components/ui/modal/modal";
import { IFinancialLevyDetail } from "src/interfaces/financialLevyDetail";
import { SMCheckbox } from "src/components/ui/checkbox/smCheckbox";

export const LevyDetailSection: React.FC<{
  financialLevyDetails: IFinancialLevyDetail;
  corpId: string;
}> = ({ financialLevyDetails, corpId }) => {
  const [financialLevyDetail, setFinancialLevyDetail] = useState(financialLevyDetails);
  const [isEditableFinancialLevy, setIsEditableFinancialLevy] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, errors },
  } = useForm<IFinancialLevyDetail>({
    defaultValues: {
      levyFrequency: financialLevyDetail?.levyFrequency,
      levyDiscount: financialLevyDetail?.levyDiscount,
      levyInterest: financialLevyDetail?.levyInterest,
      levyDiscountGraceDays: financialLevyDetail?.levyDiscountGraceDays,
      fundsReserve: financialLevyDetail?.fundsReserve,
      interestFreePeriods: financialLevyDetail?.interestFreePeriods,
      interestFreePeriodUnit: financialLevyDetail?.interestFreePeriodUnit,
      rejectLevyReceipts: financialLevyDetail?.rejectLevyReceipts,
      chargeUnpaidInterest: financialLevyDetail?.chargeUnpaidInterest,
      onHoldInvoiceStatus: financialLevyDetail?.onHoldInvoiceStatus,
      includeSubmeterInvoicesInQuorumCalc: financialLevyDetail?.includeSubmeterInvoicesInQuorumCalc,
      lastLevyNotice: financialLevyDetail?.lastLevyNotice,
      lastEmailedLevyNotice: financialLevyDetail?.lastEmailedLevyNotice,
    },
    mode: "onTouched",
  });
  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  const levyDetailButtons = [
    {
      buttonName: isEditableFinancialLevy ? cancelButtonText : editButtonText,
      type: "normal",
      onClick: isEditableFinancialLevy ? onCancel : () => setIsEditableFinancialLevy(true),
    },
    {
      buttonName: isEditableFinancialLevy ? saveButtonText : "",
      type: isEditableFinancialLevy ? "save" : "",
      buttonType: "submit",
      formId: "financialLevyDetailForm",
    },
  ];

  function onCancel() {
    if (isDirty) {
      setShowModal(true);
    } else {
      setIsEditableFinancialLevy(false);
    }
  }

  function onDiscard() {
    reset(financialLevyDetail);
    setShowModal(false);
    setIsEditableFinancialLevy(false);
  }

  async function onSubmit(data: IFinancialLevyDetail) {
    let payload = {
      ...data,
      lastLevyNotice: toDate(FormatDate(data?.lastLevyNotice) ?? ""),
      lastEmailedLevyNotice: toDate(FormatDate(data?.lastEmailedLevyNotice) ?? ""),
      fundsReserve: Number(data?.fundsReserve) ?? 0,
      levyDiscount: Number(data?.levyDiscount) ?? 0,
      levyDiscountGraceDays: data?.levyDiscountGraceDays && data?.levyDiscountGraceDays !== "" ? data?.levyDiscountGraceDays : 0
    };
    setIsLoading(true);
    const output = await httpUpdate(EndPoints.corporation + corpId + EndPoints.financial + "/levy-details", payload);
    if (output?.status === httpCode.SUCCESS) {
      setFinancialLevyDetail(data);
      toastRef?.current?.show({
        severity: "success",
        summary: "Success",
        detail: Messages.SUCCESS_MESSAGE,
        life: toastSuccessTimeLimit,
      });
      reset({}, { keepDirty: false, keepValues: true });
      setIsEditableFinancialLevy(false);
    }
    if (output?.error) {
      toastRef?.current?.show({
        severity: "error",
        summary: "Error",
        detail: `Error: ${output?.error}`,
        life: toastErrorTimeLimit,
      });
    }
    setIsLoading(false);
  }

  return (
    <>
      <CollapsibleCard id="LevyDetails" title={Labels.LEVY_DETAILS} expand={true} buttons={levyDetailButtons}>
        <div className="pt-4">
          {isLoading && (
            <div className="spinnerContainer">
              <div className="w-20 h-20">
                <SpinnerHorizontal />
              </div>
            </div>
          )}
          <form id="financialLevyDetailForm" onSubmit={handleSubmit(onSubmit)} noValidate>
            <div data-testid="levyDetailsTab" className="basis-full text-sm mt-4">
              <div className={`${styles.section} ${isEditableFinancialLevy ? "border-remove" : "border-b"} ${errors.levyFrequency ? "!h-15 my-2" : ""}`}>
                <label className={`${styles.label} ${isEditableFinancialLevy ? "!w-1/2 required" : "!w-full"}`}>{Labels.NORMAL_LEVY_FREQUENCY}</label>
                {isEditableFinancialLevy ? (
                  <span className="mb-2 basis-1/2 text-left">
                    <SMDropDown
                      id="levyFrequency"
                      control={control}
                      name="levyFrequency"
                      options={levyFrequency}
                      optionLabel="name"
                      optionValue="id"
                      value={findValue(levyFrequency, financialLevyDetail?.levyFrequency)}
                      rules={{ required: Messages.REQUIRED_MESSAGE }}
                    />
                    {errors.levyFrequency && <Errors error={errors.levyFrequency.message} alignment="left" />}
                  </span>
                ) : (
                  <span className={styles.value}>{findValue(levyFrequency, financialLevyDetail?.levyFrequency)}</span>
                )}
              </div>
              <div className={`${styles.section} ${isEditableFinancialLevy ? "border-remove" : "border-b"} ${
                  errors?.levyDiscount ? "!h-20 my-2" : ""
                }`}>
                <label className={styles.label}>{Labels.LEVY_DISCOUNT_RATE}</label>
                {isEditableFinancialLevy ? (
                  <span className="mb-2 basis-1/2 text-left">
                    <SMInputText name="levyDiscount" keyfilter={"num"} autoComplete="off" control={control} maxlength={8} rules={{ min: { value: 0, message: Messages.LEVY_DISCOUNT_VALIDATION}, max: { value: 99, message: Messages.LEVY_DISCOUNT_VALIDATION}, validate: (value: string) => {
                          return validateDecimal(value, 2) || Messages.RESERVE_FUND_VALIDATION
                        }}} />
                    {errors.levyDiscount && <Errors error={errors.levyDiscount.message} alignment="left" />}
                  </span>
                ) : (
                  <span className={styles.value}>{financialLevyDetail?.levyDiscount}</span>
                )}
              </div>
              <div className={`${styles.section} ${isEditableFinancialLevy ? "border-remove" : "border-b"}`}>
                <label className={styles.label}>{Labels.LEVY_DISCOUNT_GRACE_DAYS}</label>
                {isEditableFinancialLevy ? (
                  <span className="mb-2 basis-1/2 text-left">
                    <SMInputText name="levyDiscountGraceDays" keyfilter={"alphanum"} control={control} maxlength={8} />
                  </span>
                ) : (
                  <span className={styles.value}>{financialLevyDetail?.levyDiscountGraceDays}</span>
                )}
              </div>
              <div className={`${styles.section} ${isEditableFinancialLevy ? "border-remove" : "border-b"}`}>
                <label className={styles.label}>{Labels.LEVY_INTEREST_RATE}</label>
                {isEditableFinancialLevy ? (
                  <span className="mb-2 basis-1/2 text-left">
                    <SMInputText name="levyInterest" keyfilter={"alphanum"} control={control} disabled={true} />
                  </span>
                ) : (
                  <span className={styles.value}>{financialLevyDetail?.levyInterest}</span>
                )}
              </div>
              <div className="flex justify-end h-10 mt-4 items-center">
                <button
                  className="grey-btn"
                  onClick={(e) => {
                    e.preventDefault();
                    toastRef?.current?.show({
                      severity: "info",
                      summary: "Info",
                      detail: comingSoon,
                      life: toastSuccessTimeLimit,
                    });
                  }}
                >
                  {Labels.ADJUST_LEVY_INTEREST_RATE}
                </button>
              </div>
              <div
                className={`${styles.section} mt-6 ${isEditableFinancialLevy ? "border-remove" : "border-b"} ${
                  errors?.interestFreePeriods || errors?.interestFreePeriodUnit ? "!h-15 my-2" : ""
                }`}
              >
                <label className={`${styles.label} ${isEditableFinancialLevy ? "!w-1/2 required" : "!w-full"}`}>{Labels.INTEREST_FREE_PERIOD}</label>
                {isEditableFinancialLevy ? (
                  <div className="mb-2 basis-1/2 text-left">
                    <span className="flex gap-1">
                      <SMDropDown
                        id="interestFreePeriods"
                        control={control}
                        name="interestFreePeriods"
                        options={getIntegerList(96)}
                        optionLabel="name"
                        optionValue="id"
                        value={findValue(getIntegerList(96), financialLevyDetail?.interestFreePeriods)}
                        rules={{ required: Messages.REQUIRED_MESSAGE }}
                        valueTemplate={(option: any) => {
                          return <div>{option?.name}</div>;
                        }}
                      />
                      <SMDropDown
                        id="interestFreePeriodUnit"
                        control={control}
                        name="interestFreePeriodUnit"
                        options={interestFreePeriod}
                        optionLabel="name"
                        optionValue="id"
                        value={findValue(interestFreePeriod, financialLevyDetail?.interestFreePeriodUnit)}
                        rules={{ required: Messages.REQUIRED_MESSAGE }}
                      />
                    </span>
                    {(errors.interestFreePeriods || errors.interestFreePeriodUnit) && <Errors error={Messages.REQUIRED_MESSAGE} alignment="left" />}
                  </div>
                ) : (
                  <span className={styles.value}>
                    {financialLevyDetail.interestFreePeriods} {findValue(interestFreePeriod, financialLevyDetail?.interestFreePeriodUnit)}
                  </span>
                )}
              </div>
              <div className={`${styles.section} ${isEditableFinancialLevy ? "border-remove" : "border-b"} ${errors.fundsReserve ? "!h-20 my-2" : ""}`}>
                <label className={styles.label}>{Labels.RESERVE_FUNDS}</label>
                {isEditableFinancialLevy ? (
                  <span className="mb-2 basis-1/2 text-left">
                    <SMInputText
                      name="fundsReserve"
                      keyfilter={"num"}
                      autoComplete="off"
                      control={control}
                      maxlength={12}
                      rules={{
                        validate: (value: string) => {
                          return validateDecimal(value, 2)
                        }
                      }}
                    />
                    {errors.fundsReserve && <Errors error={Messages.RESERVE_FUND_VALIDATION} alignment="left" />}
                  </span>
                ) : (
                  <span className={styles.value}>{financialLevyDetail?.fundsReserve}</span>
                )}
              </div>
              <div className={`${styles.section} ${isEditableFinancialLevy ? "border-remove" : "border-b"}`}>
                <label className={styles.label}>{Labels.LAST_LEVY_NOTICE_PRINTED}</label>
                {isEditableFinancialLevy ? (
                  <span className="mb-2 basis-1/2 text-left">
                    <SMDatePicker
                      inputDate={FormatDate(financialLevyDetail?.lastLevyNotice) ?? ""}
                      control={control}
                      name="lastLevyNotice"
                      disabled={!isEditableFinancialLevy}
                      width="w-full"
                    />
                  </span>
                ) : (
                  <span className={styles.value}>{FormatDate(financialLevyDetail?.lastLevyNotice)}</span>
                )}
              </div>
              <div className={`${styles.section} ${isEditableFinancialLevy ? "border-remove" : "border-b"}`}>
                <label className={styles.label}>{Labels.LAST_LEVY_NOTICE_EMAILED}</label>
                {isEditableFinancialLevy ? (
                  <span className="mb-2 basis-1/2 text-left">
                    <SMDatePicker
                      inputDate={FormatDate(financialLevyDetail?.lastEmailedLevyNotice) ?? ""}
                      control={control}
                      name="lastEmailedLevyNotice"
                      disabled={!isEditableFinancialLevy}
                      width="w-full"
                    />
                  </span>
                ) : (
                  <span className={styles.value}>{FormatDate(financialLevyDetail?.lastEmailedLevyNotice)}</span>
                )}
              </div>
              <div className={`${styles.section} mt-8`}>
                <SMCheckbox name="rejectLevyReceipts" control={control} label={Labels.REJECT_LEVY_RECEIPTS} disabled={!isEditableFinancialLevy} />
              </div>
              <div className={styles.section}>
                <SMCheckbox name="chargeUnpaidInterest" control={control} label={Labels.CHARGE_UNPAID_INTEREST} disabled={!isEditableFinancialLevy} />
              </div>
              <div className={styles.section}>
                <SMCheckbox name="onHoldInvoiceStatus" control={control} label={Labels.CREDIT_INVOICE_HOLD_STATUS} disabled={!isEditableFinancialLevy} />
              </div>
              <div className={styles.section}>
                <SMCheckbox
                  name="includeSubmeterInvoicesInQuorumCalc"
                  control={control}
                  label={Labels.INCLUDE_SUBMETER_INVOICES_IN_QUORUM_CALCULATION}
                  disabled={!isEditableFinancialLevy}
                />
              </div>
            </div>
          </form>
        </div>
      </CollapsibleCard>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)} title="Warning" onAction={() => onDiscard()}>
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
      <ToastContainer toastReference={toastRef} />
    </>
  );
};
