import { format } from "date-fns";
import { IBudgetExpense } from "../interfaces/budgetExpense";
import { currency, defaultDateString, defaultDateTimeString, lineBreak, locale } from "src/constants/constant";
import { Messages } from "src/constants/messages";

export const NullOREmptyString = (value: string | null) => {
  if (value == null || value === undefined || value === "") {
    return "-";
  }
  return value;
};

export const FindPath = (path: string | null): string => {
  if (path == null || path === undefined || path === "") {
    return "";
  }
  return path;
};

export const SetOptionKeys = (options: any[], id: string, label: string | number, value: any): any[] => {
  options.forEach((option) => {
    option.id = option[id];
    option.label = option[label];
    option.value = option[value];
  });

  return options;
};

export const FormatDate = (value: string | null, dateFormat: string = "dd/MM/yyyy") => {
  let date = format(new Date(value ?? defaultDateTimeString), dateFormat);
  return date === defaultDateString ? "" : date;
};

export const ConvertRTFToPlainText = (rtf: string): string => {
  rtf = rtf?.replace?.(/\\par[d]?/g, "");
  return rtf?.replace?.(/\{\*?\\[^{}]+}|[{}]|\\\n?[A-Za-z]+\n?(?:-?\d+)?[ ]?/g, "")?.trim?.();
};

export const ConvertPlainTextToRTF = (plain: string): string => {
  plain = plain?.replace?.(/\n/g, "\\par\n");
  return (
    "{\\rtf1\\ansi\\ansicpg1252\\deff0\\deflang2057{\\fonttbl{\\f0\\fnil\\fcharset0 Microsoft Sans Serif;}}\n\\viewkind4\\uc1\\pard\\f0\\fs17 " +
    plain +
    "\\par\n}"
  );
};

export const ConvertTransactionStatusToName = (status: string): string => {
  if (status === "A") {
    return "Pay";
  }
  if (status === "H") {
    return "On Hold";
  }
  if (status === "P") {
    return "Paid";
  }
  if (status === "N") {
    return "Normal";
  }
  if (status === "C") {
    return "Cancelled";
  }

  return status;
};

export const ConvertTransactionGroupCodeToShortName = (groupCode: number): string => {
  if ((groupCode = 0)) {
    return "None";
  }

  return "";
};

export const FilterTableByColumn = (data: IBudgetExpense[], accountType: string): IBudgetExpense[] => {
  const filteredTable = data.filter((row) => row.sAccountType === accountType);
  return filteredTable;
};

export const getLocalStorage = (value: string) => {
  if (typeof window !== "undefined" && value) {
    const data = localStorage.getItem(value);
    if (data && data !== "undefined") {
      return JSON.parse(data);
    }
  }
  return null;
};

export const setLocalStorage = (value: string, item: any) => {
  if (typeof window !== "undefined" && value) {
    return localStorage.setItem(value, JSON.stringify(item));
  }
  return null;
};

export const seprateStreetNumber = (inputString: string) => {
  const value = inputString?.match(/^([A-Za-z \/-]*)(\d*)([A-Za-z0-9 \/-]*)$/);

  if (value) {
    const [_, prefix, number, suffix] = value;
    return { prefix, number, suffix };
  }
  return null;
};

export const toDate = (date: string) => {
  const [day, month, year] = date?.split("/");
  return Boolean(date) ? new Date(Number(year), Number(month) - 1, Number(day) + 1) : defaultDateTimeString;
};

export const toLocaleValue = (value: number) => {
  let currency = new Intl.NumberFormat(locale, { minimumFractionDigits: 2 }).format(value);
  return currency;
};

export const findValue = (list: any, value: any) => {
  let fieldValue = list?.find((x: any) => x?.id === value);
  return fieldValue?.name;
};

export const findId = (list: any, value: any) => {
  let fieldValue = list?.find((x: any) => x?.name === value);
  return fieldValue?.id;
};

export const findCode = (list: any, value: any) => {
  let fieldValue = list?.find((x: any) => x?.id === value);
  return fieldValue?.code;
};

export const checkZeroValue = (value: number, returnValue: any = null) => {
  return value === 0 ? returnValue : value;
};

export const isNotBlank = (value: any) => {
  return value.trim() !== "";
};

export const getIntegerList = (value: number = 100) => {
  const list: { id: number; name: number }[] = Array.from(
    { length: value },
    (_, i) => ({ id: i, name: i })
  );
  return list;
} 

export const validateDecimal = (value: string, precision: number) => {
  if (value?.split?.(".")?.length > 2) {
    return false;
  }
  const dotIndex = String(value)?.indexOf('.');
  if (dotIndex !== -1) {
    return (String(value)?.length - (dotIndex + 1)) <= precision;
  }
  return true;
};

export const mailToSend = (data: any, toast?: any, useAdditionalEmailAddresses?: boolean, additionalEmailAddressOptions?: number, bulk?: boolean) => {
  let mail = createMail(data, toast, useAdditionalEmailAddresses, additionalEmailAddressOptions, bulk ?? true);
  if (mail) {
    let link = document?.createElement("a");
    link.href = `mailto:${mail}`;
    link?.click?.();
    link?.remove?.();
  }
};

const createMail = (data: any, toast?: any, useAdditionalEmailAddresses?: boolean, additionalEmailAddressOptions?: number, bulk?: boolean) => {
  let mailTo = createMailTo(data, bulk ?? true);

  if (mailTo?.length === 0) {
    toast?.current?.show({
      severity: "warn",
      summary: "Warning",
      detail: Messages.NO_EMAIL_ADDRESSES,
      life: 2000,
    });
    return false;
  }

  let mailBody = createMailBody(data);

  const { mailCC, mailBCC } = createMailCarbonCopy(data, useAdditionalEmailAddresses, additionalEmailAddressOptions, bulk ?? true);

  let emailTemplate =
    mailTo?.join?.(" ;") +
    "?" +
    `${mailCC?.length > 0 ? `cc=` + mailCC?.join?.(" ;") : ""}` +
    `${mailBCC?.length > 0 ? `${mailCC?.length > 0 ? "&" : ""}bcc=` + mailBCC?.join?.(" ;") : ""}` +
    `${mailBCC?.length > 0 || mailCC?.length > 0 ? "&" : ""}${
      bulk && data?.length !== mailTo?.length + data?.filter?.((committee: any) => committee?.executivePositionName === "Building Manager")?.length
        ? `body=Mailed copies to be sent to:${lineBreak}${mailBody?.join?.("")}`
        : ""
    }`;
  return emailTemplate;
};

const createMailTo = (data: any, bulk: boolean) => {
  const mailToContent = data
    ?.filter?.(
      (committee: any) =>
        (Boolean(committee?.committeeEmailAddress) || Boolean(committee?.email)) && (bulk ? committee?.executivePositionName !== "Building Manager" : true)
    )
    ?.map((data: any) => (data?.committeeEmailAddress ? data?.committeeEmailAddress : data?.email));
  return mailToContent;
};

const createMailBody = (data: any) => {
  const mailBodyContent = data
    ?.filter?.((committee: any) => !Boolean(committee?.committeeEmailAddress) && !Boolean(committee?.email))
    ?.map(
      (data: any) =>
        `${data?.name ? `${data?.name} ` : ""}${
          data?.buildingName ? lineBreak : ""
        }${data?.buildingName}${lineBreak}${data?.streetNumber ? `${data?.streetNumber} ` : ""}${data?.streetName ? `${data?.streetName} ` : ""}${lineBreak}${
          data?.town ? `${data?.town} ` : ""
        }${data?.state ? `${data?.state} ` : ""}${data?.postcode ? `${data?.postcode} ` : ""}${lineBreak}${lineBreak}`
    );
  return mailBodyContent;
};

const createMailCarbonCopy = (data: any, useAdditionalEmailAddresses?: boolean, additionalEmailAddressOptions?: number, bulk?: boolean) => {
  let mailCC: string[] = [];
  let mailBCC: string[] = [];
  if (useAdditionalEmailAddresses) {
    data?.filter?.((committee: any) => {
      if (committee?.executivePositionName === "Building Manager" && bulk) {
        mailCC?.push?.(committee?.committeeEmailAddress || committee?.email);
      }
      if (
        committee?.additionalContactDetails?.length > 0 &&
        committee?.email !== "" &&
        (committee?.committeeEmailAddress === "" || committee?.committeeEmailAddress === null)
      ) {
        committee?.additionalContactDetails?.map?.((contact: any) => {
          if (contact?.type === "Email address") {
            if (additionalEmailAddressOptions === 1) {
              mailCC?.push?.(contact?.value);
            } else {
              mailBCC?.push?.(contact?.value);
            }
          }
        });
      }
    });
  }
  return { mailCC, mailBCC };
};

export const copyToClipboard = (copyData: any, toast: any) => {
  let textarea = document?.createElement("textarea");
  textarea.value = JSON.stringify(copyData, undefined, 2)?.replaceAll?.("{", "")?.replaceAll?.("}", "");
  navigator.clipboard.writeText(textarea.value);
  textarea?.remove?.();
  toast?.current?.show({ severity: "success", summary: "Success", detail: Messages.CLIPBOARD, life: 3000 });
}

export const convertToCurrency = (value: number, currencyFormat = currency, localeString = locale, returnValue: string | number | null = 0.0) => {
  return !isNaN(value)
    ? value?.toLocaleString?.(localeString, {
        style: "currency",
        currency: currencyFormat,
        minimumFractionDigits: 2,
      })
    : returnValue;
};

export const removeTokensFromLocalStorage = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("id_token");
};

export const createMailLink = (email: string) => {
  let link = document?.createElement("a");
  if (link) {
    link.href = `mailto:${email}`;
    link?.click?.();
    link?.remove?.();
  }
};

export const getFromUnitLot = (data: any) => {
  return (
    data?.unitNumberPrefix +
    String(checkZeroValue(data?.unitNumber) ?? "") +
    data?.unitNumberSuffix +
    "," +
    " " +
    "Lot" +
    " " +
    data?.lotNumberprefix +
    String(checkZeroValue(data?.lotNumber) ?? "") +
    data?.lotNumberSuffix
  );
};