import React from "react";
import styles from "./ownerTitle.module.scss";
import { SMDropDown } from "../../../../../../components/ui/dropdown2/dropdown";
import BadgeComponent from "../../../../../../components/ui/badge/badgeComponent";
import { Labels } from "../../../../../../constants/labels";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { SMRadioButton } from "src/components/ui/radioControl/radioButton";
import { Controller } from "react-hook-form";

export const OwnerTitle: React.FC<{
  lotDetails: any;
  selectedOwner: any;
  setSelectedOwner: any;
  control: any;
  ownerType: any;
}> = ({ lotDetails, selectedOwner, setSelectedOwner, control, ownerType }) => {
  const ownerList = lotDetails?.owner?.map((owner: any)=> ({
      id: owner.ownerId,
      name: (owner?.contact?.firstName ?? "") + " " + (owner?.contact?.name ?? ""),
    })
  );

  function onChange(e: any) {
    setSelectedOwner(
      lotDetails?.owner.filter((x: any) => x.ownerId === e.value)
    );
  }
  return (
    <div
      data-testid="ownerContactCard"
      className={`flex-auto gap-2 basis-full ${styles.card}`}
    >
      <div className={`${styles.header}`}>
        <label className={`${styles.label} border-b pb-3`}>{Labels.NAME_ON_TITLE}</label>
        <div className="basis-full text-left font-semibold text-sm pl-2">
          <SMInputText name="nameOnTitle" control={control} maxlength={50} />
        </div>
      </div>
      {ownerType === "C" && (
        <div className={`${styles.header} mt-2`}>
          <label className={`${styles.label} border-b pb-3`}>{Labels.COMPANY_NOMINEE}</label>
          <div className="basis-full text-left font-semibold text-sm pl-2">
            <SMInputText name="companyNominee" control={control} maxlength={50} />
          </div>
        </div>
      )}
      <div className={`${styles.header} mt-2`}>
        <label className={`${styles.label} border-b pb-3`}>
          {Labels.OWNERS}
          {` : `}
          <BadgeComponent value={ownerList.length} />
        </label>
        <div className="basis-full text-left font-semibold text-sm pl-2">
          <SMDropDown
            value={ownerList[0].name}
            placeholder={"Select Owner"}
            onChange={onChange}
            options={ownerList}
          />
        </div>
      </div>
      <div>
        <Controller
          name="ownerType"
          control={control}
          render={({ field }) => (
            <>
              <div className="flex justify-between h-10 items-center mt-1">
                <div className="flex basis-full text-center items-center">
                  <SMRadioButton
                    id="companyField"
                    field={field}
                    value="C"
                    checked={field?.value === "C"}
                    onChange={(e: any) => field.onChange(e.value)}
                  />
                  <label
                    htmlFor="company"
                    className={`ml-2 font-normal text-center text-sm`}
                  >
                    {Labels.COMPANY}
                  </label>
                </div>
                <div className="flex basis-full text-center items-center">
                  <SMRadioButton
                    id="privateField"
                    field={field}
                    value="I"
                    checked={field?.value === "I"}
                    onChange={(e: any) => field.onChange(e.value)}
                  />
                  <label
                    htmlFor="private"
                    className={`ml-2 font-normal text-center text-sm`}
                  >
                    {Labels.PRIVATE}
                  </label>
                </div>
              </div>
            </>
          )}
        />
      </div>
    </div>
  );
};
