import styles from "./structureDetail.module.scss";
import { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { IAssociationType, IStructure } from "src/interfaces/structure";
import { Labels } from "src/constants/labels";
import { FormatDate, toDate } from "src/utils/utility";
import { SpinnerHorizontal } from "src/components/ui/loading/spinnerHorizontal";
import { httpUpdate } from "src/services/api";
import { EndPoints } from "src/constants/endpoint";
import { cancelButtonText, editButtonText, errorText, modalDiscardMessageText, saveButtonText, successText } from "src/constants/constant";
import { SMDatePicker } from "src/components/ui/datePicker/datePicker";
import { Modal } from "src/components/ui/modal/modal";
import ToastContainer from "src/components/ui/toast/toast";
import { useForm } from "react-hook-form";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { Messages } from "src/constants/messages";
import { Errors } from "src/components/shared/error/error";
import useUnsavedChangesPrompt from "src/hooks/useUnsavedChangesPrompt";

const StructureDetailSection = ({
  corpId,
  data,
  error,
  associationTypeData,
}: {
  corpId: string;
  data: IStructure;
  error: any;
  associationTypeData: Array<IAssociationType>;
}) => {
  let associationTypes = associationTypeData?.map?.((association: IAssociationType) => {
    return {
      ...association,
      sAssociationName: (association?.name ?? "") + "-" + (association?.state ?? ""),
    };
  });
  const [structureSectionData, setStructureSectionData] = useState<any>({ ...data, sAssociationName: data?.sName + "-" + data?.sState });
  const [editMode, setEditMode] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const toastRef = useRef<Toast>(null);
  const {
    handleSubmit,
    control,
    reset,
    formState: { isDirty, errors },
  } = useForm({
    defaultValues: {
      lAssociationTypeID: structureSectionData?.lAssociationTypeID,
      sName: structureSectionData?.sName,
      sState: structureSectionData?.sState,
      sCommunityPlanNumber: structureSectionData?.sCommunityPlanNumber,
      sCommunityLotNumber: structureSectionData?.sCommunityLotNumber,
      sPrecinctPlanNumber: structureSectionData?.sPrecinctPlanNumber,
      sPrecinctLotNumber: structureSectionData?.sPrecinctLotNumber,
      sBMCPlanNumber: structureSectionData?.sBMCPlanNumber,
      sBMCLotNumber: structureSectionData?.sBMCLotNumber,
      dBuilt: structureSectionData?.dBuilt,
      dRegistered: structureSectionData?.dRegistered,
      dFirstAGM: structureSectionData?.dFirstAGM,
      dManagementCommencement: structureSectionData?.dManagementCommencement,
      dManagementEnded: structureSectionData?.dManagementEnded,
      sStoreyAboveGround: structureSectionData?.sStoreyAboveGround,
      dOccupationCertDate: structureSectionData?.dOccupationCertDate,
      sDeveloper: structureSectionData?.sDeveloper,
      sBuilder: structureSectionData?.sBuilder,
      sDeveloperContractWorksInsurance: structureSectionData?.sDeveloperContractWorksInsurance,
      sAssociationName: structureSectionData?.sAssociationName,
    },
  });

  useUnsavedChangesPrompt({ unsavedChanges: isDirty });

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    let apiData = {
      ...data,
      lAssociationTypeID: associationTypes?.find((x: IAssociationType) => x?.sAssociationName === data?.sAssociationName)?.associationTypeId,
      dBuilt: toDate(FormatDate(data?.dBuilt) ?? ""),
      dRegistered: toDate(FormatDate(data?.dRegistered) ?? ""),
      dFirstAGM: toDate(FormatDate(data?.dFirstAGM) ?? ""),
      dManagementCommencement: toDate(FormatDate(data?.dManagementCommencement) ?? ""),
      dManagementEnded: toDate(FormatDate(data?.dManagementEnded) ?? ""),
      dOccupationCertDate: toDate(FormatDate(data?.dOccupationCertDate) ?? ""),
    };

    const response = await httpUpdate(EndPoints.corporation + `${corpId}/structure/details`, apiData);
    if (response?.status === 200) {
      toastRef?.current?.show({ severity: "success", summary: "Success", detail: successText, life: 3000 });
      setEditMode(false);
      setStructureSectionData({ ...data, sAssociationName: data?.sAssociationName });
    }
    if (response?.error) {
      toastRef?.current?.show({ severity: "error", summary: "Error", detail: errorText, life: 3000 });
    }
    setIsLoading(false);
    reset({}, { keepDirty: false, keepValues: true });
  };

  return (
    <>
      <form data-testid="structureCard" className={`flex-auto basis-full ${styles.card}`} onSubmit={handleSubmit(onSubmit)}>
        {isLoading && (
          <div className="flex bg-gray-900 bg-opacity-50 fixed justify-center items-center z-50 h-modal overflow-y-auto overflow-x-hidden lg:inset-0 lg:h-full cursor-pointer">
            <div className="w-20 h-20">
              <SpinnerHorizontal />
            </div>
          </div>
        )}
        <div className="w-full flex justify-between">
          <label className="section-header">Structure</label>
          {editMode ? (
            <div className="gap-1">
              <button
                type="button"
                className={`order-last edit-btn`}
                onClick={() => {
                  if (isDirty) {
                    setShowModal(true);
                  } else {
                    setEditMode(false);
                  }
                }}
              >
                {cancelButtonText}
              </button>
              <button className={`order-last btn-primary ${!isDirty ? "opacity-60" : "opacity-100"}`} type="submit" disabled={!isDirty}>
                {saveButtonText}
              </button>
            </div>
          ) : (
            <button type="button" className={`order-last edit-btn`} onClick={() => setEditMode(true)}>
              {editButtonText}
            </button>
          )}
        </div>
        <div data-testid="structTable1" className={`basis-full text-sm table-auto mt-10 ${styles.table}`}>
          <div className="flex justify-between border-b">
            <label className="flex-auto basis-full lg:basis-1/2 sm:px-2 py-2 text-left font-normal"></label>
            <div className="w-1/3">
              <label className="flex-auto basis-full pl-4 lg:basis-1/4 font-semibold">{Labels?.PLAN_NUMBER}</label>
            </div>
            <div className="w-1/5 text-center">
              <label className="flex-auto basis-full pl-3.5 lg:basis-1/4 font-semibold">{Labels.LOT_NUMBER}</label>
            </div>
          </div>
          <div className={`flex justify-between`}>
            <label className={`flex-auto basis-full lg:basis-1/2 sm:px-2 py-2 text-left font-normal border-b ${editMode ? "mr-1 pt-4" : ""}`}>Community</label>
            {editMode ? (
              <div className={styles.planNumberInput}>
                <SMInputText name="sCommunityPlanNumber" control={control} maxlength={10} />
              </div>
            ) : (
              <>
                <span className="flex-auto basis-full lg:basis-1/4 sm:px-2 py-2 text-left font-semibold border-b">
                  {structureSectionData?.sCommunityPlanNumber}
                </span>
              </>
            )}
            {editMode ? (
              <div className="w-1/5 mt-3">
                <SMInputText name="sCommunityLotNumber" control={control} maxlength={10} />
              </div>
            ) : (
              <span className="flex-auto basis-full lg:basis-1/4 sm:px-2 py-2 font-semibold border-b text-end">
                {structureSectionData?.sCommunityLotNumber}
              </span>
            )}
          </div>
          <div className={`flex justify-between`}>
            <label className={`flex-auto basis-full lg:basis-1/2 sm:px-2 py-2 text-left font-normal border-b ${editMode ? "mr-1 pt-4" : ""}`}>Precinct</label>
            {editMode ? (
              <div className={styles.planNumberInput}>
                <SMInputText name="sPrecinctPlanNumber" control={control} maxlength={10} />
              </div>
            ) : (
              <>
                <span className="flex-auto basis-full lg:basis-1/4 sm:px-2 py-2 text-left font-semibold border-b">
                  {structureSectionData?.sPrecinctPlanNumber}
                </span>
              </>
            )}
            {editMode ? (
              <div className="w-1/5 mt-3">
                <SMInputText name="sPrecinctLotNumber" control={control} maxlength={10} />
              </div>
            ) : (
              <span className="flex-auto basis-full lg:basis-1/4 sm:px-2 py-2 font-semibold border-b text-end">{structureSectionData?.sPrecinctLotNumber}</span>
            )}
          </div>
          <div className={`flex justify-between`}>
            <label className={`flex-auto basis-full lg:basis-1/2 sm:px-2 py-2 text-left font-normal border-b ${editMode ? "mr-1 pt-4" : ""}`}>BMC</label>
            {editMode ? (
              <div className={styles.planNumberInput}>
                <SMInputText name="sBMCPlanNumber" control={control} maxlength={10} />
              </div>
            ) : (
              <>
                <span className="flex-auto basis-full lg:basis-1/4 sm:px-2 py-2 text-left font-semibold border-b">{structureSectionData?.sBMCPlanNumber}</span>
              </>
            )}
            {editMode ? (
              <div className="w-1/5 mt-3">
                <SMInputText name="sBMCLotNumber" control={control} maxlength={10} />
              </div>
            ) : (
              <span className="flex-auto basis-full lg:basis-1/4 sm:px-2 py-2 font-semibold border-b text-end">{structureSectionData?.sBMCLotNumber}</span>
            )}
          </div>
        </div>
        <div data-testid="structTable2" className={`table-auto mt-10 ${styles.table}`}>
          <div>
            <div className={`flex mt-2`}>
              <div className={`${styles.editMode} ${editMode ? "mr-1" : ""}`}>{Labels.MANAGEMENT_COMMENCED}</div>
              {editMode ? (
                <SMDatePicker
                  inputDate={FormatDate(structureSectionData?.dManagementCommencement)}
                  name="dManagementCommencement"
                  control={control}
                  disabled={!editMode}
                />
              ) : (
                <div className={styles.readMode}>{FormatDate(structureSectionData?.dManagementCommencement)}</div>
              )}
            </div>
            <div className={`flex mt-2`}>
              <div className={`${styles.editMode} ${editMode ? "mr-1" : ""}`}>{Labels.MANAGEMENT_END}</div>
              {editMode ? (
                <SMDatePicker inputDate={FormatDate(structureSectionData?.dManagementEnded)} name="dManagementEnded" control={control} disabled={!editMode} />
              ) : (
                <div className={styles.readMode}>{FormatDate(structureSectionData?.dManagementEnded)}</div>
              )}
            </div>
            <div className={`flex mt-10`}>
              <div className={`${styles.editMode} ${editMode ? "mr-1" : ""}`}>{Labels.ASSOCIATION_TYPE}</div>
              {editMode ? (
                <div className="w-1/2">
                  <SMDropDown
                    control={control}
                    name="sAssociationName"
                    options={associationTypes}
                    optionLabel="sAssociationName"
                    optionValue="sAssociationName"
                    rules={{ required: Messages.REQUIRED_MESSAGE }}
                  />
                  {errors?.sAssociationName && <Errors error={errors?.sAssociationName?.message} alignment="left" />}
                </div>
              ) : (
                <div className={styles.readMode}>{structureSectionData?.sAssociationName}</div>
              )}
            </div>
            <div className={`flex mt-2`}>
              <div className={`${styles.editMode} ${editMode ? "mr-1" : ""}`}>{Labels.BUILD_DATE}</div>
              {editMode ? (
                <SMDatePicker inputDate={FormatDate(structureSectionData?.dBuilt)} name="dBuilt" control={control} disabled={!editMode} />
              ) : (
                <div className={styles.readMode}>{FormatDate(structureSectionData?.dBuilt)}</div>
              )}
            </div>
            <div className={`flex mt-2`}>
              <div className={`${styles.editMode} ${editMode ? "mr-1" : ""}`}>{Labels.PLAN_REGISTERED}</div>
              {editMode ? (
                <SMDatePicker inputDate={FormatDate(structureSectionData?.dRegistered)} name="dRegistered" control={control} disabled={!editMode} />
              ) : (
                <div className={styles.readMode}>{FormatDate(structureSectionData?.dRegistered)}</div>
              )}
            </div>
            <div className={`flex mt-2`}>
              <div className={`${styles.editMode} ${editMode ? "mr-1" : ""}`}>{Labels.FIRST_AGM}</div>
              {editMode ? (
                <SMDatePicker inputDate={FormatDate(structureSectionData?.dFirstAGM)} name="dFirstAGM" control={control} disabled={!editMode} />
              ) : (
                <div className={styles.readMode}>{FormatDate(structureSectionData?.dFirstAGM)}</div>
              )}
            </div>
            <div className={`flex mt-2`}>
              <div className={`${styles.editMode} ${editMode ? "mr-1" : ""}`}>{Labels.NUMBER_OF_STOREYS_ABOVE_GROUND}</div>
              {editMode ? (
                <div className="w-1/2">
                  <SMInputText name="sStoreyAboveGround" control={control} maxlength={3} keyfilter="int" autoComplete="off" />
                </div>
              ) : (
                <div className={styles.readMode}>{structureSectionData?.sStoreyAboveGround}</div>
              )}
            </div>
            <div className={`flex mt-2`}>
              <div className={`${styles.editMode} ${editMode ? "mr-1" : ""}`}>{Labels.OCCUPATION_CERTIFICATE_DATE}</div>
              {editMode ? (
                <SMDatePicker
                  inputDate={FormatDate(structureSectionData?.dOccupationCertDate)}
                  name="dOccupationCertDate"
                  control={control}
                  disabled={!editMode}
                />
              ) : (
                <div className={styles.readMode}>{FormatDate(structureSectionData?.dOccupationCertDate)}</div>
              )}
            </div>
            <div className={`flex mt-10`}>
              <div className={`${styles.editMode} ${editMode ? "mr-1" : ""}`}>{Labels.DEVELOPER}</div>
              {editMode ? (
                <div className="w-1/2">
                  <SMInputText name="sDeveloper" control={control} maxlength={50} />
                </div>
              ) : (
                <div className={styles.readMode}>{structureSectionData?.sDeveloper}</div>
              )}
            </div>
            <div className={`flex mt-2`}>
              <div className={`${styles.editMode} ${editMode ? "mr-1" : ""}`}>{Labels.BUILDER}</div>
              {editMode ? (
                <div className="w-1/2">
                  <SMInputText name="sBuilder" control={control} maxlength={50} />
                </div>
              ) : (
                <div className={styles.readMode}>{structureSectionData?.sBuilder}</div>
              )}
            </div>
            <div className={`flex mt-2`}>
              <div className={`${styles.editMode} ${editMode ? "mr-1" : ""}`}>{Labels.DEVELOPER_CONTRACT}</div>
              {editMode ? (
                <div className="w-1/2">
                  <SMInputText name="sDeveloperContractWorksInsurance" control={control} />
                </div>
              ) : (
                <div className={styles.readMode}>{structureSectionData?.sDeveloperContractWorksInsurance}</div>
              )}
            </div>
          </div>
        </div>
      </form>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Warning"
        onAction={() => {
          setEditMode(false);
          setShowModal(false);
          reset({ ...structureSectionData }, { keepDirty: false });
        }}
      >
        <div>
          <h2>{modalDiscardMessageText}</h2>
        </div>
      </Modal>
      <ToastContainer toastReference={toastRef} />
    </>
  );
};

export default StructureDetailSection;
