import { Card } from "src/components/ui/card/card";
import { SMDropDown } from "src/components/ui/dropdown2/dropdown";
import { SMInputText } from "src/components/ui/inputControl/inputText";
import { Labels } from "src/constants/labels";
import styles from "../quickReports.module.scss";
import { Control } from "react-hook-form";
import { IUser } from "src/interfaces/users";

const OwnerCorpDetails = ({ control, managerList }: { control: Control<any, any>; managerList: Array<IUser> }) => {
  return (
    <Card>
      <div className="flex flex-col gap-4">
        <div className={`flex gap-4`}>
          <label className={`w-full border-b pt-2  font-semibold pb-4 ${styles.label}`}>{Labels.OWNER_CORPORATION_DETAILS}</label>
        </div>
        <div className={`flex gap-4`}>
          <label className={`w-1/2 border-b pt-2 ${styles.label}`}>{Labels.PLANNO}</label>
          <div className="w-1/2">
            <SMInputText name="planNumber" control={control} maxlength={10} />
          </div>
        </div>
        <div className={`flex gap-4`}>
          <label className={`w-1/2 border-b pt-2 ${styles.label}`}>{Labels.MANAGER}</label>
          <div className="w-1/2">
            <SMDropDown control={control} name="manager" options={managerList} />
          </div>
        </div>
        <div className={`flex gap-4`}>
          <label className={`w-1/2 border-b pt-2 ${styles.label}`}>{Labels.STREET_NUMBER}</label>
          <div className="w-1/2">
            <SMInputText name="streetNumber" control={control} maxlength={10} />
          </div>
        </div>
        <div className={`flex gap-4`}>
          <label className={`w-1/2 border-b pt-2 ${styles.label}`}>{Labels.STREET}</label>
          <div className="w-1/2">
            <SMInputText name="street" control={control} maxlength={10} />
          </div>
        </div>
        <div className={`flex gap-4`}>
          <label className={`w-1/2 border-b pt-2 ${styles.label}`}>{Labels.TOWN_SUBURB}</label>
          <div className="w-1/2">
            <SMInputText name="town" control={control} maxlength={10} />
          </div>
        </div>
        <div className={`flex gap-4`}>
          <label className={`w-1/2 border-b pt-2 ${styles.label}`}>{Labels.POSTCODE}</label>
          <div className="w-1/2">
            <SMInputText name="postcode" control={control} maxlength={10} />
          </div>
        </div>
      </div>
    </Card>
  );
};

export default OwnerCorpDetails;
